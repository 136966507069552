import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from 'ngx-modialog-7';
import { BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { FormCoreModule } from '../gamma/form/form-core.module';
import { L10nService } from '../gamma/l10n/l10n.service';

import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { RouterInitService } from './router-init.service';
import { U2000_CoreModule } from './U2000_core';
import { U2000_CustomPageComponent } from './U2000_custompage/U2000_custompage.component';
import { U2000_CustomPageService } from './U2000_custompage/U2000_custompage.service';
import { U2000_LoginNoBusinessLinkComponent } from './U2000_custompage/U2000_loginnobusinesslink.component';
import { U2000_MajorErrorComponent } from './U2000_custompage/U2000_majorerror.component';
import { U2000_PageNotFoundComponent } from './U2000_custompage/U2000_pagenotfound.component';
import { U2000_SubscriptionComingSoonComponent } from './U2000_custompage/U2000_subscriptioncomingsoon.component';
import { U2000_SharedModule } from './U2000_shared';
import { U2000_WorkspaceRoutedComponents } from './U2000_workspace/U2000_workspace-routing.module';
import { U2000_WorkspaceModule } from './U2000_workspace/U2000_workspace.module';
import { U2000_WorkspaceComponent } from './U2000_workspace/U2000_workspace/U2000_workspace.component';
import { U2011_LoginModule } from './U2011_login/U2011_login.module';
import { U2021_EulaModule } from './U2021_eula/U2021_eula.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        U2000_CoreModule,
        U2000_SharedModule,
        U2000_WorkspaceModule,
        U2011_LoginModule,
        U2021_EulaModule,
        AppRoutingModule,
        ModalModule.forRoot(),
        BootstrapModalModule,
        ToastrModule.forRoot(),
        FormCoreModule,
    ],
    declarations: [
        U2000_WorkspaceRoutedComponents,
        U2000_CustomPageComponent,
        U2000_PageNotFoundComponent,
        U2000_MajorErrorComponent,
        U2000_SubscriptionComingSoonComponent,
        U2000_LoginNoBusinessLinkComponent,
    ],
    providers: [
        AppInitService,
        RouterInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitializerFactory,
            deps: [AppInitService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: LocaleIdFactory,
            deps: [L10nService],
        },
        U2000_CustomPageService,
    ],
    bootstrap: [U2000_WorkspaceComponent],
})
export class AppModule {}

export function AppInitializerFactory(config: AppInitService) {
    return () => config.load();
}

export function LocaleIdFactory(l10nSettingService: L10nService) {
    return l10nSettingService.localeId;
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { RoutingService } from '../../../gamma/routing';
import { ToastService } from '../../../gamma/toast';

export enum U2000_WebNotificationTypeEnum {
    REPORT_READY = 'REPORT_READY',
}

export interface U2000_WebNotificationMessage {
    type: U2000_WebNotificationTypeEnum;
    content: string;
}

@Injectable()
export class U2000_WebSocketParserService {
    constructor(private toastService: ToastService, private routingService: RoutingService, private router: Router, private translateService: TranslateService) {}

    public parseMessage(message: string) {
        const messageObj: U2000_WebNotificationMessage = JSON.parse(message);
        const logicalUnit: string = this.routingService.currentLogicalUnitId;

        if (messageObj.type === U2000_WebNotificationTypeEnum.REPORT_READY) {
            this.toastService.success(this.translateService.instant('lblReportReady'), this.translateService.instant('lblReport'), {}, () => this.onClickReportReady());
        }
    }

    onClickReportReady() {
        this.router.navigate(['U2024']);
    }
}

import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormCheckboxQuestion } from '../../formquestion-checkbox';

@Component({
    selector: 'form-checkbox-bootstrap',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class FormCheckboxBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormCheckboxQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, public el: ElementRef) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }

    get altLabelId() {
        return 'alt-' + this.labelId;
    }

    override get formGroupClass() {
        let formGroupClass = this.question.formGroupClass;
        if (this.hasError) {
            formGroupClass += ' has-error';
        }
        if (this.question.condensed) {
            formGroupClass += ' condensed';
        }
        return formGroupClass;
    }

    onChange(event: Event, question: FormCheckboxQuestion) {
        if (this.question.onChange) {
            this.question.onChange(event, this.question);
        }
    }
}

import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { U2000_SubNavbarService, U2000_TitleService } from '../U2000_core';
import { U2000_CustomPageComponent } from './U2000_custompage.component';
import { U2000_CustomPageService } from './U2000_custompage.service';

@Component({
    selector: 'u2000-loginnobusinesslink',
    templateUrl: 'U2000_custompage.component.html',
    styleUrls: ['U2000_custompage.component.scss', 'U2000_loginnobusinesslink.component.scss'],
})
export class U2000_LoginNoBusinessLinkComponent extends U2000_CustomPageComponent {
    constructor(
        titleService: U2000_TitleService,
        subNavbarService: U2000_SubNavbarService,
        protected override customPageService: U2000_CustomPageService,
        protected override route: ActivatedRoute,
        sanitizer: DomSanitizer,
        translateService: TranslateService,
    ) {
        super(titleService, subNavbarService, customPageService, route);
        // let message = sanitizer.bypassSecurityTrustHtml('lblLoginNoBusinessLink');

        this.pageParams = {
            type: 'warning',
            title: 'lblWarning',
            message: 'lblLoginNoBusinessLink',
        };
    }
}

import { Injectable } from '@angular/core';

/**
 * Available list of question types.
 *
 * @export
 * @enum {number}
 */
export enum FormQuestionType {
    Textbox,
    Textarea,
    Dropdown,
    FormGroup,
    FormArray,
    Checkbox,
    Radio,
    Hidden,
    FileUpload,
    Date,
    Amount,
    RadioButton,
    Typehead,
    ReCaptcha,
}

/** Form service */
@Injectable()
export class FormService {}

import { Injectable } from '@angular/core';

import { CloseGuard } from 'ngx-modialog-7';
import { from, Observable, of } from 'rxjs';
import { every, first, map, mergeMap } from 'rxjs/operators';

import { IOpenModalLogicalUnitParams } from '../logicalunit/logicalunit-modal.interface';
import { RxjsService } from '../utils/rxjs.service';

@Injectable()
export class ModalDismissGuard implements CloseGuard {
    openingParams: IOpenModalLogicalUnitParams<any>;

    constructor(private rxjsService: RxjsService) {}

    beforeDismiss(): boolean | Promise<any> {
        return this.runCanDeactivate(this.openingParams).toPromise();
    }

    beforeClose(): boolean | Promise<any> {
        return this.runCanDeactivate(this.openingParams).toPromise();
    }

    setOpeningParams(params: IOpenModalLogicalUnitParams<any>) {
        this.openingParams = params;
    }

    private runCanDeactivate(params: IOpenModalLogicalUnitParams<any>) {
        const canDeactivate = params.canDeactivate;
        if (!canDeactivate || canDeactivate.length === 0) {
            return of(true);
        }
        const canDeactivate$ = from(canDeactivate).pipe(
            mergeMap(c => {
                const guard = params.injector.get(c);
                let observable: Observable<any>;

                if (guard.canDeactivate) {
                    observable = this.rxjsService.wrapIntoObservable(guard.canDeactivate());
                } else {
                    observable = this.rxjsService.wrapIntoObservable(guard());
                }
                return observable.pipe(first());
            }),
        );
        return canDeactivate$.pipe(
            every(result => result === true),
            map(x => !x),
        );
    }
}

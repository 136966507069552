import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormBaseComponent, IRegistrationFormConfigs } from './form-base.component';
import { IFormValidationMessage } from './formvalidationmessage.service';

/* Mixin implementation (To copy to the file implementing the mixin)
    // Form mixin requirements
    formValidationMessages: IFormValidationMessage[];
    registeredForms: IRegisteredForm[];
    registerForm: (form: FormBaseComponent, configs: IRegistrationFormConfigs) => void;
    unregisterForm: (form: FormBaseComponent) => void;
    hasDirtyForms: () => boolean;
    cleanupForms: () => void;
*/

export interface IRegisteredForm {
    configs: IRegistrationFormConfigs;
    form: UntypedFormGroup;
    formComponent: FormBaseComponent;
}

/**
 * Form service.
 *
 * @export
 * @class FormService
 */
@Injectable()
export abstract class FormMixin {
    formValidationMessages: IFormValidationMessage[];
    registeredForms: IRegisteredForm[];

    registerForm(formComponent: FormBaseComponent, configs: IRegistrationFormConfigs = { skipFormGuardIfDirty: false }) {
        if (this.registeredForms == null) {
            this.registeredForms = [];
        }

        const identifier = (formComponent as any).editionType != null ? (formComponent as any).editionType : formComponent.name;
        const pos = this.registeredForms.findIndex(registeredform => registeredform.formComponent.name === identifier);
        if (pos === -1) {
            this.registeredForms.push({ formComponent, form: formComponent.form, configs });
        }
    }

    unregisterForm(formComponent: FormBaseComponent) {
        if (this.registeredForms == null) {
            this.registeredForms = [];
        }

        const identifier = (formComponent as any).editionType != null ? (formComponent as any).editionType : formComponent.name;
        const pos = this.registeredForms.findIndex(registeredform => registeredform.formComponent.name === identifier);
        if (pos > -1) {
            this.registeredForms.splice(pos, 1);
        }
    }

    hasDirtyForms() {
        if (this.registeredForms == null) {
            this.registeredForms = [];
        }
        for (let i = 0; i < this.registeredForms.length; i++) {
            if (this.registeredForms[i].configs.skipFormGuardIfDirty !== true && this.registeredForms[i].form.dirty) {
                return true;
            }
        }
        return false;
    }

    cleanupForms() {
        if (this.registeredForms == null) {
            this.registeredForms = [];
        }
        this.registeredForms.forEach(registeredForm => {
            registeredForm.form.markAsPristine();
        });
    }
}

import { Injectable } from '@angular/core';

/**
 * Service for Math utils.
 *
 * @export
 * @class MathService
 */
@Injectable()
export class MathService {
    /**
     *  The toFixed() method converts a number into a fixed number, keeping a specified number of digits.
     *  @method toFixed
     *  @param {number} t_Value Value to format.
     *  @param {number} [t_Digits=0] Optional.
     *  The number of digits after the decimal point. Default is 0 (no digits after the decimal point).
     *  @return {number} Formatted value.
     *  @static
     *  @example
     *      // Get the fixed value on 2 decimal.
     *      console.log(fui.MathUtils.toFixed(10.3333, 2)); // Returns "10.33".
     *      console.log(fui.MathUtils.toFixed(10.336, 2));  // Returns "10.34".
     *      console.log(fui.MathUtils.toFixed(10.3, 2));    // Returns "10.3".
     *      - Note that using the browser method 8.655.toFixed(2) will return "8.65".
     *      console.log(fui.MathUtils.toFixed(8.655, 2));   // Returns "8.66".
     *      - Note that using the browser method -8.655.toFixed(2) will return "-8.65".
     *      console.log(fui.MathUtils.toFixed(-8.655, 2));  // Returns "-8.66".
     *
     *      // Round up a number to the nearest one hundred.
     *      console.log(mathUtils.toFixed(1234, -2));   // Returns "1200".
     */
    public toFixed(t_Value: number, t_Digits = 0): number {
        // Inspired by code from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round.
        let arValue: string[];
        let fractionalPart: number;
        let nbDecimals: number;

        t_Digits = t_Digits * -1;

        if (t_Value == null) {
            return null;
        }
        // Split the number in case it have already the 'e' (times ten to the power of).
        arValue = t_Value.toString().split('e');

        if (/\d+\.\d+9{9}\d/.test(arValue[0])) {
            // Validates if the number is like x.xxxxx999999999x.
            // Exception number generates by javascript when a calculation is done and it distorts the result.
            // Adds a tiny number to patch the rounding.
            nbDecimals = /\d*$/.exec(arValue[0])[0].length; // Count the number of decimals to add to the last decimal.
            arValue[0] = (parseFloat(arValue[0]) + Number('9e-' + nbDecimals)).toString();
        }

        // Generates a new string that uses the 'e' to exploit the power of number without the bug with formula
        // t_Value * Math.pow(10, t_Digits).
        t_Value = Number(arValue[0] + 'e' + (arValue[1] ? Number(arValue[1]) - t_Digits : -t_Digits));
        if (/-/.test(arValue[0])) {
            // Negative number. Round the half number down to be symmetric like positive number.
            // Get the positive fraction of the number and clean the number because -865.4 give 0.39999999999997726 instead of 0.4.
            fractionalPart = this.toFixed((t_Value % 1) * -1, 10);
            if (fractionalPart >= 0.5) {
                t_Value = this.floor(t_Value);
            } else {
                t_Value = Math.round(t_Value);
            }
        } else {
            t_Value = Math.round(t_Value);
        }

        arValue = t_Value.toString().split('e');
        // Exploit the same thing, but to do equivalent of t_Value / Math.pow(10, t_Digits).
        return Number(arValue[0] + 'e' + (arValue[1] ? Number(arValue[1]) + t_Digits : t_Digits));
    }

    /**
     *  Returns the nearest float based on a specified digits precision less than or equal to the specified number.
     *  @method floor
     *  @param {number} t_Value Value to format.
     *  @param {number} [t_Digits=0] Optional.
     *  The number of digits after the decimal point. Default is 0 (no digits after the decimal point).
     *  @return {number} Formatted value.
     *  @static
     *  @example
     *      // Get the ceil value on 2 decimal.
     *      fuiDebug.log(fui.MathUtils.floor(1.33633, 2));   // Returns "1.33".
     *      fuiDebug.log(fui.MathUtils.floor(1.33233, 2));   // Returns "1.33".
     */
    public floor(t_Value: number, t_Digits = 0): number {
        const pow: number = Math.pow(10, t_Digits);
        let value: number = this.toFixed(t_Value * pow, 15);
        value = this.toFixed(Math.floor(value) / pow, t_Digits);

        // We need to put in string the put in number to be sure that there's not 0.0000001 trailling number.
        return parseFloat(value.toString());
    }

    /**
     *  Returns the safe numeric value for the given parameter. If the parameter is NaN then this method return 0 (zero).
     *  @method safe
     *  @param {number} aNumber Value to analyse.
     *  @return {number} The safe numeric value that can be used inside arythmetic expression: never NaN.
     *  @static
     *  @example
     *      safe(NaN);    // Returns 0.
     *      safe(null);   // Returns 0.
     *      safe(224);    // Returns 224.
     */
    public safe(aNumber: number): number {
        if (aNumber && !isNaN(aNumber)) {
            return aNumber;
        } else {
            return 0;
        }
    }

    /**
     * Generate a unique integer.
     *
     * @memberof MathService
     */
    public generateUniqueInt(length = 8) {
        // eslint-disable-next-line new-parens
        const timestamp = +new Date();

        const ts = timestamp.toString();
        const parts = ts.split('').reverse();
        let id = '';

        for (let i = 0; i < length; ++i) {
            const index = this.getRandomInt(0, parts.length - 1);
            id += parts[index];
        }

        return parseInt(id, 0);
    }

    /**
     * Generate a randow hexadecimal string.
     *
     * @memberof MathService
     */
    public generateRadomHex(length = 8) {
        let r = '';
        while (r.length < length) {
            const n = Math.floor(Math.random() * 16);
            r = r + n.toString(16);
        }
        return r;
    }

    private getRandomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}

import { Directive, OnInit } from '@angular/core';

import { BaseComponent } from '../core/components/base.component';
import { ExceptionEvent } from '../exception/exception-event';
import { RoutingService } from '../routing/routing.service';
import { ToastService } from '../toast';
import { LogicalUnitBaseService } from './logicalunit-base.service';

/**
 * Base logical unit component class.
 *
 * @export
 * @abstract
 * @class LogicalUnitBaseComponent
 * @extends {BaseComponent<any>}
 * @template T
 */
@Directive()
export abstract class LogicalUnitBaseComponent<T extends LogicalUnitBaseService> extends BaseComponent<any> implements OnInit {
    /**
     * Method used to handle non business errors.
     *
     * @memberof LogicalUnitBaseComponent
     * @example
     *      // Handle javascript errors locally.
     *      this.handleError = (event: ExceptionEvent) => {
     *          if (anyCondition) {
     *              // Do not execute the framework behavior.
     *              event.preventDefault();
     *          }
     *      };
     */
    handleError: (error: ExceptionEvent) => void;

    /**
     * Hook method called when the component receives a parameter.
     *
     * @memberof LogicalUnitBaseComponent
     */
    paramReceived: (param: any) => void;

    needHelpClick: () => void;

    /**
     * Method used to modify the url sent to google analytics.
     *
     *
     * @memberof LogicalUnitBaseComponent
     */
    gaPageMap: (url: string) => string;

    /**
     * Creates an instance of LogicalUnitBaseComponent.
     * @param {T} service
     * @param {ToastService} toastService
     * @param {RoutingService} routingService
     * @memberof LogicalUnitBaseComponent
     */
    constructor(service: T, toastService: ToastService, protected routingService: RoutingService) {
        super(service, toastService);

        if (this instanceof LogicalUnitBaseComponent) {
            this.routingService.currentComponent = this;
        }
    }

    ngOnInit() {
        this.routingService.dispatchNavigationParams();
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { U2000_AuthenticationService, U2000_ConversationService } from '../../../U2000_core';
import { U2000_DevelopmentService } from '../../../U2000_core/U2000_services/U2000_development.service';

@Component({
    selector: 'u2000-developmentbar',
    templateUrl: 'U2000_developmentbar.component.html',
    styleUrls: ['U2000_developmentbar.component.scss'],
})
export class U2000_DevelopmentBarComponent implements OnInit, OnDestroy {
    keyUp: (e: KeyboardEvent) => void;
    securityTagsResponse: string;

    appTimestamp: string;
    appVersion: string;

    constructor(public developmentService: U2000_DevelopmentService, private conversationService: U2000_ConversationService, public authService: U2000_AuthenticationService) {
        // define a handler
        this.keyUp = (e: KeyboardEvent) => {
            const shortcutOptions = this.developmentService.getOptionByKeyShortcut(e);
            if (shortcutOptions != null) {
                shortcutOptions.action();
            }
        };
    }

    ngOnInit() {
        // register the handler
        if (this.showOptions) {
            document.addEventListener('keyup', this.keyUp, false);
        }

        this.appTimestamp = this.developmentService.appTimestamp;
        this.appVersion = this.developmentService.appVersion;
    }

    ngOnDestroy() {
        // register the handler
        if (this.showOptions) {
            document.removeEventListener('keyup', this.keyUp, false);
        }
    }

    get showOptions() {
        return !environment.production;
    }

    get panels() {
        return this.developmentService.panels;
    }

    get conversationId() {
        return this.conversationService.conversationId;
    }

    inputClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }
}

/**
 * @license
 * Copyright ACCEO Solution Inc. All Rights Reserved.
 */
/**
 * @module
 * @description
 * Entry point for all public APIs of the routing package.
 */

export * from './routing.module';
export * from './routing.service';

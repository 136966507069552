import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BootstrapResolutionService } from '../../../../gamma/bootstrap';
import { RoutingService } from '../../../../gamma/routing';

import { AppInitService } from '../../../app-init.service';
import { U2000_AuthenticationService } from '../../../U2000_core';
import { U2000_DevelopmentService } from '../../../U2000_core/U2000_services/U2000_development.service';
import { U2000_WorkspaceService } from '../../U2000_workspace.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'header',
    templateUrl: 'U2000_header.component.html',
    styleUrls: ['U2000_header.component.scss'],
})
export class U2000_HeaderComponent {
    constructor(
        public service: U2000_WorkspaceService,
        public authService: U2000_AuthenticationService,
        private locationService: Location,
        public bootstapResolutionService: BootstrapResolutionService,
        public developmentService: U2000_DevelopmentService,
        private appInitService: AppInitService,
        private routingService: RoutingService,
        private router: Router,
    ) {}

    get isLoggedIn() {
        return this.authService.isLoggedIn;
    }

    logoClick(event: MouseEvent) {
        if (event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            this.developmentService.toggleBar();
        } else {
            if (this.routingService.currentLogicalUnitId !== 'U4401') {
                this.router.navigate(['U2001']);
            }
        }
    }

    needHelpClick() {
        if (this.routingService.currentComponent.needHelpClick != null) {
            this.routingService.currentComponent.needHelpClick();
            return null;
        }
        this.service.needHelp();
    }

    partOfLU(luId: string) {
        const currentLuId = this.locationService.path(true).substr(1, 5);
        if (luId.length === 5) {
            return currentLuId === luId;
        } else if (luId.length === 4 && !isNaN(parseFloat(luId))) {
            return currentLuId.indexOf(luId) === 0 || currentLuId.indexOf(luId) === 1;
        } else {
            return false;
        }
    }

    get supportEmail() {
        return this.appInitService.initData.configs.supportEmail;
    }

    get supportPhoneNumber() {
        return this.appInitService.initData.configs.supportPhoneNumber;
    }
}

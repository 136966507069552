import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';

import { DialogRef, ModalComponent } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

import { BaseComponent } from '../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../gamma/toast';
import { UserAgentService } from '../../../../gamma/utils/user-agent.service';

import { U2000_SecurityService } from '../U2000_security.service';

export interface IU2000_VerifyEmailModalContext extends BSModalContext {
    email: string;
}

@Component({
    selector: 'u2000-verifyemail-modal',
    templateUrl: 'U2000_verifyemail-modal.component.html',
    styleUrls: ['U2000_verifyemail-modal.component.scss'],
})
export class U2000_VerifyEmailModalComponent extends BaseComponent<U2000_SecurityService> implements ModalComponent<BSModalContext>, OnInit {
    context: IU2000_VerifyEmailModalContext;

    constructor(public dialog: DialogRef<BSModalContext>, service: U2000_SecurityService, toastService: ToastService, private el: ElementRef, public userAgentService: UserAgentService) {
        super(service, toastService);
        this.context = dialog.context as IU2000_VerifyEmailModalContext;
        // this.context.size = 'sm';
        this.context.isBlocking = true;
        this.context.showClose = true;
    }

    ngOnInit() {
        const element: HTMLDivElement = this.el.nativeElement;
        const modalDialog = element.parentElement.parentElement;
        const modalContent = element.parentElement;

        modalDialog.className = 'modal-dialog u2000-verifyemail-dialog';
        modalContent.className = 'modal-content u2000-verifyemail-content';
    }

    onValid(securityKey: string) {
        this.dialog.close(securityKey);
    }

    onError(error: HttpErrorResponse) {
        this.dialog.close(error);
    }

    closeClick() {
        this.dialog.dismiss();
    }
}

import { Injectable } from '@angular/core';

/**
 * Localization service.
 *
 * @export
 * @class L10nService
 */
@Injectable()
export class L10nService {
    currentLanguageCode: string;
    localeId: 'fr' | 'en';
    dateFormats: { [format: string]: string };
    decimalSeparator: string;
    thousandSeparator: string;

    constructor() {
        this.localeId = 'fr';
        this.dateFormats = {};
    }
}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { U2000_SubNavbarService, U2000_TitleService } from '../U2000_core';
import { U2000_CustomPageComponent } from './U2000_custompage.component';
import { IU2000_CustomPageParams, U2000_CustomPageService } from './U2000_custompage.service';

@Component({
    selector: 'u2000-pagenotfound',
    templateUrl: 'U2000_custompage.component.html',
    styleUrls: ['U2000_custompage.component.scss'],
})
export class U2000_PageNotFoundComponent extends U2000_CustomPageComponent {
    public override pageParams: IU2000_CustomPageParams;
    constructor(titleService: U2000_TitleService, subNavbarService: U2000_SubNavbarService, protected override customPageService: U2000_CustomPageService, protected override route: ActivatedRoute) {
        super(titleService, subNavbarService, customPageService, route);

        this.pageParams = {
            type: 'danger',
            title: 'lblSorry',
            message: 'lblNonExistingRoute',
        };
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from '../../../gamma/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class U2000_LogService {
    constructor(private http: HttpClient, private httpService: HttpService) {}

    log(message: string, error = false) {
        let request: IU2000_LogDtoRequest = { message: message, error: error };
        this.http.post<Response>(`${environment.apiUrl}U2000/log`, request, this.httpService.optionsWithExtra({ silent: true, globalErrorHandling: false })).subscribe();
    }
}

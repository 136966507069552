import { FormDropdownQuestion, IFormDropdownQuestion } from '../../gamma/form/formquestion/formquestion-dropdown';

export class U2000_CountryQuestion extends FormDropdownQuestion {
    static schema: IFormDropdownQuestion = {
        key: 'countryId',
        label: 'lblCountry',
        maxLength: 40,
    };

    constructor(options: IFormDropdownQuestion = {}) {
        super(options);
        const schema = U2000_CountryQuestion.schema;

        if (String.isNullOrEmpty(this.key)) {
            this.key = schema.key;
        }
        if (String.isNullOrEmpty(this.label)) {
            this.label = schema.label;
        }
        if (this.maxLength == null) {
            this.maxLength = schema.maxLength;
        }
    }
}

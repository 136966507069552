import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route as AngularRoute, NavigationEnd, NavigationExtras, Router, RouterEvent } from '@angular/router';

import { environment } from '../../environments/environment';
import { LogicalUnitBaseComponent, LogicalUnitInitMixin, LogicalUnitModalBaseService } from '../logicalunit';
import { LogicalUnitBaseService } from '../logicalunit/logicalunit-base.service';

/**
 * Interface that defines a routing parameter.
 *
 * @export
 * @interface IRoutingParameters
 */
export interface IRoutingParameters {
    [variableName: string]: any;
}

export declare type Routes = Route[];

export interface Route extends AngularRoute {
    public?: boolean;
    logout?: boolean;
}

export interface ILogicalUnitFlow {
    lu: string;
    action: string;
}

/**
 * Service used to hold all information concerning the logical unit routes.
 * It alors activates and deactives the logical units services.
 *
 * @export
 * @class RoutingService
 */
@Injectable()
export class RoutingService {
    /**
     * Current logical unit id.
     *
     * @readonly
     * @type {string}
     * @memberof RoutingService
     */
    get currentLogicalUnitId(): string {
        if (this.currentLogicalUnitService && this.currentLogicalUnitService.logicalUnitId) {
            return this.currentLogicalUnitService.logicalUnitId;
        }
        return String.empty;
    }

    /**
     * Reference to the current routed component displayed.
     *
     * @type {LogicalUnitBaseComponent}
     * @memberof RoutingService
     */
    get currentComponent() {
        return this._currentComponent;
    }

    set currentComponent(component: LogicalUnitBaseComponent<any>) {
        this.lastComponent = this._currentComponent;
        this._currentComponent = component;
    }
    /**
     * Dictionary of all loaded logical units.
     *
     * @type {{ [logicalUnitId: string]: LogicalUnitBaseService }}
     * @memberof RoutingService
     */
    loadedLogicalUnits: { [logicalUnitId: string]: LogicalUnitBaseService };

    /**
     * Reference to the logical unit service currently in use.
     *
     * @type {LogicalUnitBaseService}
     * @memberof RoutingService
     */
    currentLogicalUnitService: LogicalUnitBaseService;

    /**
     * Event emitter that tells if modal logical unit has been activated.
     *
     * @type {EventEmitter<string>}
     * @memberof RoutingService
     */
    modalLogicalUnitActivated$: EventEmitter<string>;

    logFlows$: EventEmitter<ILogicalUnitFlow[]> = new EventEmitter();

    luFlowLogs: ILogicalUnitFlow[];
    drillDown: boolean;

    private openedModalLogicalUnits: string[];
    private lastRouterLogicalUnit: string;
    private lastRouterComponent: LogicalUnitBaseComponent<any>;
    private lastComponent: LogicalUnitBaseComponent<any>;

    private _currentComponent: LogicalUnitBaseComponent<any>;
    private luParametersAdded$: { [logicalUnitId: string]: EventEmitter<IRoutingParameters> };
    private luParametersAdded: { [logicalUnitId: string]: IRoutingParameters };
    private compParametersAdded: { [paramId: number]: IRoutingParameters };
    public lastRoutesHistory: string[];

    private readonly maxRouteHistory = 10;

    constructor(protected router: Router) {
        this.loadedLogicalUnits = {};
        this.luParametersAdded$ = {};
        this.luParametersAdded = {};
        this.compParametersAdded = {};
        this.modalLogicalUnitActivated$ = new EventEmitter();

        this.openedModalLogicalUnits = [];
        this.luFlowLogs = [];
        this.lastRoutesHistory = [];

        router.events.subscribe((route: RouterEvent) => {
            if (route instanceof NavigationEnd) {
                const logicalUnitRoute = route.url.substr(1, 5);
                if (this.lastRoutesHistory != null) {
                    let componentName = null;
                    if (this.currentComponent != null) {
                        componentName = ' (' + this.currentComponent.constructor.name + ')';
                    }
                    const count = this.lastRoutesHistory.unshift(route.url + componentName);
                    if (count > this.maxRouteHistory) {
                        this.lastRoutesHistory.splice(10, count - this.maxRouteHistory);
                    }
                }

                if (this.currentLogicalUnitService != null && this.currentLogicalUnitService !== this.loadedLogicalUnits[logicalUnitRoute]) {
                    this.drillDown = false;
                    this.deactivateLogicalUnit(this.currentLogicalUnitService, this.loadedLogicalUnits[logicalUnitRoute]);
                }
                if (this.loadedLogicalUnits.hasOwnProperty(logicalUnitRoute)) {
                    this.activateLogicalUnit(this.loadedLogicalUnits[logicalUnitRoute]);
                    this.dispatchNavigationParams();
                }
            }
        });

        // Add a quick access via the console for developpers.
        if (!environment.production) {
            window['routingService'] = this;
        }
    }

    /**
     * Tells if the router can be seen publicly.
     *
     * @param {string} currentLocationHref
     * @returns
     * @memberof RoutingService
     * @example
     *      if (!routingService.isPublicRoute(location.href)) {
     *          location.hash = '#/U2011';
     *      }
     */
    isPublicRoute(currentLocationHref: string) {
        const route = this.router.config.find(x => currentLocationHref.indexOf(x.path) > -1) as Route;

        if (route && route.public) {
            return true;
        }
        return false;
    }

    /**
     * Tells if the router can be seen publicly.
     *
     * @param {string} currentLocationHref
     * @returns
     * @memberof RoutingService
     */
    isLogoutRoute(currentLocationHref: string) {
        const route = this.router.config.find(x => currentLocationHref.indexOf(x.path) > -1) as Route;

        if (route && route.logout && currentLocationHref.indexOf('bundleId=true') === -1) {
            return true;
        }
        return false;
    }

    /**
     * (Internal usage only) Add a logical unit to the list of loaded logical units.
     *
     * @param {LogicalUnitBaseService} logicalUnit
     *
     * @memberof RoutingService
     */
    addLogicalUnit(logicalUnitService: LogicalUnitBaseService) {
        this.luParametersAdded$[logicalUnitService.logicalUnitId] = new EventEmitter<IRoutingParameters>();
        this.luParametersAdded$[logicalUnitService.logicalUnitId].subscribe((x: IRoutingParameters) => {
            if (logicalUnitService.paramReceived) {
                logicalUnitService.paramReceived(x);
            }
        });

        this.loadedLogicalUnits[logicalUnitService.logicalUnitId] = logicalUnitService;

        if (logicalUnitService.luType === 'Page') {
            /* if ((this.currentLogicalUnitService != null)
                && (this.currentLogicalUnitService !== logicalUnitService)) {
                this.deactivateLogicalUnit(this.currentLogicalUnitService, logicalUnitService);
            } */
            /*if (this.currentLogicalUnitService == null) {
                this.activateLogicalUnit(logicalUnitService);
            }*/
        }

        this.luParametersAdded$[logicalUnitService.logicalUnitId].emit(this.luParametersAdded[logicalUnitService.logicalUnitId]);

        delete this.luParametersAdded[logicalUnitService.logicalUnitId];
    }

    /**
     * (Internal usage only) Reset the stored logical unit in cases of delog or a switch of context.
     *
     * @memberof RoutingService
     */
    resetLogicalUnits() {
        this.luParametersAdded = {};
        this.compParametersAdded = {};

        for (const luId in this.loadedLogicalUnits) {
            if (this.loadedLogicalUnits.hasOwnProperty(luId)) {
                const luService = this.loadedLogicalUnits[luId];
                const luInitMixin = luService as any as LogicalUnitInitMixin;
                // Reset all loaded logical units.
                if (luService.reset) {
                    luService.reset();
                }

                // Test if the services implements the LogicalUnitInitMixin to reset the InitLU.
                if (luInitMixin.initLU != null) {
                    if (luInitMixin.initLUParams != null) {
                        luInitMixin.initLUParams.loaded = false;
                    }
                }
            }
        }
    }

    /**
     * (Internal usage only) Open a modal logical unit.
     *
     * @param {string} luId Opened logical unit.
     * @memberof RoutingService
     */
    openModalLogicalUnit(luId: string) {
        if (this.currentLogicalUnitService !== this.loadedLogicalUnits[luId]) {
            if (this.openedModalLogicalUnits.length === 0) {
                this.lastRouterLogicalUnit = this.currentLogicalUnitId;
                this.lastRouterComponent = this.lastComponent;
            }
            this.deactivateLogicalUnit(this.currentLogicalUnitService, this.loadedLogicalUnits[luId]);

            if (this.loadedLogicalUnits.hasOwnProperty(luId)) {
                this.openedModalLogicalUnits.push(luId);
                this.activateLogicalUnit(this.loadedLogicalUnits[luId]);

                this.modalLogicalUnitActivated$.emit(luId);
            }
        }
    }

    /**
     * (Internal usage only) Close a modal logical unit.
     *
     * @param {string} luId Closed logical unit.
     * @memberof RoutingService
     */

    closeModalLogicalUnit(luId: string) {
        if (this.openedModalLogicalUnits.length === 1) {
            // Last modal to close.
            this.openedModalLogicalUnits = [];

            let lastLogicalUnitService = this.currentLogicalUnitService;
            if (this.loadedLogicalUnits.hasOwnProperty(luId)) {
                this.deactivateLogicalUnit(this.loadedLogicalUnits[luId], this.loadedLogicalUnits[this.lastRouterLogicalUnit]);
            }
            this.activateLogicalUnit(this.loadedLogicalUnits[this.lastRouterLogicalUnit]);
            this.currentComponent = this.lastRouterComponent;

            if ((lastLogicalUnitService as LogicalUnitModalBaseService).onRedirectAfterClose != null) {
                (lastLogicalUnitService as LogicalUnitModalBaseService).onRedirectAfterClose();
            }
        } else {
            const pos = this.openedModalLogicalUnits.indexOf(luId);
            this.openedModalLogicalUnits.splice(pos, 1);
            const previousModal = this.openedModalLogicalUnits[this.openedModalLogicalUnits.length - 1];

            // Validate if the previous modal is not part of the same logical unit of the current one.
            if (this.currentLogicalUnitService !== this.loadedLogicalUnits[previousModal]) {
                if (this.loadedLogicalUnits.hasOwnProperty(luId)) {
                    this.deactivateLogicalUnit(this.loadedLogicalUnits[luId], this.loadedLogicalUnits[previousModal]);
                }
                this.activateLogicalUnit(this.loadedLogicalUnits[previousModal]);
            }
        }
    }

    /**
     * Add parameters that may be transfered to another logical unit.
     *
     * @template T
     * @param {string} logicalUnitId Logical unit to which the parameter is destined to.
     * @param {T} parameters Parameters to send.
     *
     * @memberof RoutingService
     * @example
     *      // Add a parameter
     *      this.routingService.addLogicalUnitParams<IU2111_LogicalUnitParams>('U2111', { anyParam: 'anyParamValue' });
     * @example
     *      // Retreive a parameter (From within the service's constructor)
     *
     *      // From the service constructor
     *      this.paramReceived = (x: IU2000_LogicalUnitParams) => {
     *          if (x != null) {
     *              this.anyParam = x.anyParam;
     *          }
     *      };
     */
    addLogicalUnitParams<T>(logicalUnitId: string, parameters: T) {
        if (this.loadedLogicalUnits.hasOwnProperty(logicalUnitId)) {
            this.luParametersAdded$[logicalUnitId].emit(parameters);
        } else {
            this.luParametersAdded[logicalUnitId] = parameters;
        }
    }

    /**
     * Navigate to a route and send parameters to the specified logical unit. (Works has the Router.navigate)
     *
     * @template T
     * @param {any[]} navigationPath
     * @param {string} logicalUnitId
     * @param {T} parameters
     * @param {NavigationExtras} [extra]
     * @returns {Promise<boolean>}
     * @memberof RoutingService
     * @example
     *      // Navigate to a route and send params to the logical units service.
     *      this.routingService.navigateWithLUParams<IU2111_LogicalUnitParams>(['U2111'], U2111', { anyParam: 'anyParamValue' });
     *
     * @example
     *      // Retreive a parameter (From within the service's constructor)
     *
     *      // From the service constructor
     *      this.paramReceived = (x: IU2000_LogicalUnitParams) => {
     *          if (x != null) {
     *              this.anyParam = x.anyParam;
     *          }
     *      };
     */
    navigateWithLUParams<T>(navigationPath: any[], logicalUnitId: string, parameters: T, extra?: NavigationExtras) {
        this.addLogicalUnitParams(logicalUnitId, parameters);
        return this.router.navigate(navigationPath, extra);
    }

    /**
     * Navigate to a route by url and send parameters to the specified logical unit. (Works has the Router.navigateByUrl)
     *
     * @template T
     * @param {any[]} navigationPath
     * @param {string} logicalUnitId
     * @param {T} parameters
     * @param {NavigationExtras} [extra]
     * @returns {Promise<boolean>}
     * @memberof RoutingService
     * @example
     *      // Navigate to a route and send params to the logical units service.
     *      this.routingService.navigateByUrlWithLUParams<IU2111_LogicalUnitParams>(
     *          '/U5101?id=234123',
     *          'U5101',
     *          { anyParam: 'anyParamValue' });
     *
     * @example
     *      // Retreive a parameter (From within the service's constructor)
     *
     *      // From the service constructor
     *      this.paramReceived = (x: IU2000_LogicalUnitParams) => {
     *          if (x != null) {
     *              this.anyParam = x.anyParam;
     *          }
     *      };
     */
    navigateByUrlWithLUParams<T>(navigationUrl: string, logicalUnitId: string, parameters: T, extra?: NavigationExtras) {
        this.addLogicalUnitParams(logicalUnitId, parameters);
        return this.router.navigateByUrl(navigationUrl, extra);
    }

    /**
     * Navigate to a route and send parameters to the component impacted by the navigation. (Works has the Router.navigate)
     * Description of the technique used since Angular does not give that kind of feature yet:
     *  - A random id number is generated.
     *  - The parameter is stored in a local dictonnary with the id number as the key.
     *  - The id is passed to the route in the queryParams under the cpId name.
     *  - The real router.navigate is called with the newly modified NavigationExtras.
     *  - When the route is resolved the routed component base class reads the queryParams and removes the cpId from it.
     *  - The params is then retreived from the dictionnary with the id and removed from it.
     *  - A paramReceived method is then called and the component can retreive is parameter.
     *
     * @template T
     * @param {any[]} navigationPath
     * @param {T} parameters
     * @param {NavigationExtras} [extra]
     * @returns {Promise<boolean>}
     * @memberof RoutingService
     * @example
     *      this.routingService.navigate(['U5101'],
     *          { anyParam: 'anyParamValue' },
     *          { queryParamsHandling: 'merge'});
     *
     * @example
     *      // Retreive a parameter (From within the component's constructor)
     *
     *      // From the component's constructor
     *      this.paramReceived = (x: any) => {
     *          if ((x) && (x.hasOwnProperty('anyParam')) {
     *              this.anyParam = x.anyParam;
     *          }
     *      };
     */
    navigateWithParams<T>(navigationPath: any[], parameters: T, extra?: NavigationExtras) {
        if (parameters != null) {
            if (extra == null) {
                extra = {};
            }
            if (extra.queryParams == null) {
                extra.queryParams = {};
            }

            extra.queryParams['cpId'] = this.storeParams(parameters);
        }
        return this.router.navigate(navigationPath, extra);
    }

    /**
     * Navigate to a URL and send parameters to the component impacted by the navigation. (Works has the Router.navigateByUrl)
     * Description of the technique used since Angular does not give that kind of feature yet:
     *  - A random id number is generated.
     *  - The parameter is stored in a local dictonnary with the id number as the key.
     *  - The id is passed to the route in the queryParams under the cpId name.
     *  - The real router.navigateByUrl is called with the newly modified NavigationExtras.
     *  - When the route is resolved the routed component base class reads the queryParams and removes the cpId from it.
     *  - The params is then retreived from the dictionnary with the id and removed from it.
     *  - A paramReceived method is then called and the component can retreive is parameter.
     *
     * @template T
     * @param {any[]} navigationPath
     * @param {T} parameters
     * @param {NavigationExtras} [extra]
     * @returns {Promise<boolean>}
     * @memberof RoutingService
     * @example
     *      this.routingService.navigateByUrl('/U5101?id=234123',
     *          { anyParam: 'anyParamValue' },
     *          { queryParamsHandling: 'merge' });
     *
     * @example
     *      // Retreive a parameter (From within the component's constructor)
     *
     *      // From the component's constructor
     *      this.paramReceived = (x: any) => {
     *          if ((x) && (x.hasOwnProperty('anyParam')) {
     *              this.anyParam = x.anyParam;
     *          }
     *      };
     */
    navigateByUrlWithParams<T>(navigationUrl: string, parameters: T, extra?: NavigationExtras) {
        if (parameters != null) {
            if (navigationUrl.lastIndexOf('?') === -1) {
                navigationUrl += '?';
            } else {
                if (navigationUrl.lastIndexOf('cpId=') > -1) {
                    const urlSplit = navigationUrl.split('?');
                    const urlParamSplit = urlSplit[1].split('&');

                    navigationUrl = urlSplit[0] + '?';

                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < urlParamSplit.length; i++) {
                        if (urlParamSplit[i].indexOf('cpId') === -1) {
                            navigationUrl += urlParamSplit[i] + '&';
                        }
                    }
                } else {
                    navigationUrl += '&';
                }
            }

            navigationUrl += 'cpId=' + this.storeParams(parameters);
        }
        return this.router.navigateByUrl(navigationUrl, extra);
    }

    /**
     * Tells that the current logical unit is part of a specific logical unit.
     *
     * @param {string} luId Logical unit id to challange.
     * @returns {boolean}
     *
     * @memberof RoutingService
     * @example
     *      // Refer to the spec file to have a complete coverage of how the service may be used.
     */
    partOfLU(luId: string): boolean {
        if (luId.length === 5) {
            return this.currentLogicalUnitId === luId;
        } else if (luId.length === 4 && !isNaN(parseFloat(luId))) {
            return this.currentLogicalUnitId.indexOf(luId) === 0 || this.currentLogicalUnitId.indexOf(luId) === 1;
        } else {
            return false;
        }
    }

    /**
     * Tells that the current logical unit is part of a specific module or sub-module.
     *
     * @param {string} moduleId Module id to challange.
     * @returns {boolean}
     *
     * @memberof RoutingService
     * @example
     *      // Refer to the spec file to have a complete coverage of how the service may be used.
     */
    partOfModule(moduleId: string): boolean {
        if (moduleId.length === 1 && isNaN(parseFloat(moduleId))) {
            return false;
        } else {
            return this.currentLogicalUnitId.indexOf(moduleId) === 0 || this.currentLogicalUnitId.indexOf(moduleId) === 1;
        }
    }

    /**
     * (Internal usage only) Dispatch event to the current component.
     *
     * @memberof RoutingService
     */
    dispatchNavigationParams() {
        const cpId: number = this.router.routerState.snapshot.root.queryParams['cpId'];
        if (cpId !== null && this.compParametersAdded[cpId] != null) {
            if (this._currentComponent.paramReceived != null) {
                this._currentComponent.paramReceived(this.compParametersAdded[cpId]);
                const queryParams: any = {};
                for (const property in this.router.routerState.snapshot.root.queryParams) {
                    if (this.router.routerState.snapshot.root.queryParams.hasOwnProperty(property)) {
                        queryParams[property] = this.router.routerState.snapshot.root.queryParams[property];
                    }
                }
                delete queryParams.cpId;
                this.router.navigate([], { queryParams, replaceUrl: true });
            }
        }
    }

    /**
     * Get the navigation params
     *
     * @returns
     * @memberof RoutingService
     */
    getNavigationParams<T>(route: ActivatedRouteSnapshot) {
        const cpId: number = route.queryParams['cpId'];
        if (cpId !== null && this.compParametersAdded[cpId] != null) {
            return this.compParametersAdded[cpId] as T;
        }
        return null;
    }

    /**
     * Get the navigation LU params
     *
     * @returns
     * @memberof RoutingService
     */
    getNavigationLUParams<T>(route: ActivatedRouteSnapshot, logicalUnitId: string) {
        if (this.luParametersAdded[logicalUnitId] !== null) {
            return this.luParametersAdded[logicalUnitId] as T;
        }
        return null;
    }

    public activateLogicalUnit(logicalUnitService: LogicalUnitBaseService) {
        const lastLogicalUnitService = this.currentLogicalUnitService;
        if (this.currentLogicalUnitService !== logicalUnitService) {
            this.currentLogicalUnitService = logicalUnitService;
            this.luFlowLogs.push({ lu: this.currentLogicalUnitService.logicalUnitId, action: 'Activated' });

            this.currentLogicalUnitService.activatedInternal(lastLogicalUnitService);

            this.logLuFlow();
        }
    }

    public deactivateLogicalUnit(logicalUnitService: LogicalUnitBaseService, nextLogicalUnitService?: LogicalUnitBaseService) {
        if (this.currentLogicalUnitService != null && nextLogicalUnitService != null) {
            if (this.currentLogicalUnitService.logicalUnitId !== nextLogicalUnitService.logicalUnitId) {
                this.luFlowLogs.push({ lu: logicalUnitService.logicalUnitId, action: 'Deactivated' });
                this.currentLogicalUnitService.deactivatedInternal(nextLogicalUnitService);
            }
        }
    }

    public storeParams(parameters: any) {
        const paramId = this.genParamId();
        this.compParametersAdded[paramId] = parameters;
        return paramId;
    }

    private genParamId() {
        let id: number;
        while (id == null) {
            id = Math.floor(Math.random() * 90000) + 10000;
            if (this.compParametersAdded[id] != null) {
                id = null;
            }
        }
        return id;
    }

    private logLuFlow() {
        this.logFlows$.emit(this.luFlowLogs);
        this.luFlowLogs = [];
    }
}

import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { BusyIndicatorService } from '../busyindicator/busyindicator.service';

@Injectable()
export class ModalBusyIndicatorGuard implements CanDeactivate<any> {
    constructor(private busyIndicatorService: BusyIndicatorService) {}

    canDeactivate() {
        return this.busyIndicatorService.hidden;
    }
}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormQuestionBaseComponent } from '../../../../gamma/form/formquestion/formquestion-base.component';
import { FormTextboxQuestion } from '../../../../gamma/form/formquestion/formquestion-textbox';
import { I18nService } from '../../../../gamma/i18n';

import { IU2000_PasswordValidation, U2000_Validators } from '../../../U2000_core';

@Component({
    selector: 'u2000-password-question',
    templateUrl: './U2000_passwordquestion.component.html',
    styleUrls: ['./U2000_passwordquestion.component.scss'],
})
export class U2000_PasswordQuestionComponent extends FormQuestionBaseComponent implements OnInit, AfterViewInit {
    @Input() override question: FormTextboxQuestion;
    @Input() override form: UntypedFormGroup;

    passwordValidity: IU2000_PasswordValidation;
    private inputType: string;

    constructor(i18nService: I18nService) {
        super(i18nService);

        this.passwordValidity = {
            lowerCase: false,
            upperCase: false,
            minChars: false,
            number: false,
            specialChar: false,
            valid: false,
        };
    }

    ngOnInit() {
        this.inputType = this.question.type;
    }

    ngAfterViewInit() {
        this.question.onInput = event => {
            this.passwordValidity = U2000_Validators.validatePassword(this.question.formControlRef);
        };
    }

    getInputType() {
        return this.inputType;
    }

    togglePasswordType() {
        if (this.inputType === 'password') {
            this.inputType = 'text';
        } else {
            this.inputType = 'password';
        }
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import './overloads/array-overload-interface';
import './overloads/string-overload-interface';

import { CoreService } from './core.service';

/**
 * Gamma core module.
 *
 * @export
 * @class CoreModule
 */
@NgModule({
    imports: [TranslateModule, CommonModule],
    exports: [],
    declarations: [],
    providers: [CoreService],
})
export class CoreModule {}

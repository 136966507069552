import { ErrorHandler, Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { Mixin } from '../core';
import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { ExceptionEvent } from './exception-event';

export const ExceptionServiceConfig = new InjectionToken<IServiceProviderConfig<IExceptionProvider>>('EXCEPTION_SERVICE_CONFIG');

/**
 * Provider interface of the exception service.
 *
 * @export
 * @interface IExceptionProvider
 * @extends {IProvider<ExceptionService>}
 */
export interface IExceptionProvider extends IProvider<ExceptionService> {
    handleError(error: any): void;
}

/**
 * Service that extends the Angular ErrorHandler provider to be able to handle javascript errors over the Angular handler.
 *
 * @export
 * @class ExceptionService
 * @extends {ErrorHandler}
 * @implements {ServiceProviderBase<IExceptionProvider, ExceptionService>}
 */
@Injectable()
@Mixin([ServiceProviderBase])
export class ExceptionService extends ErrorHandler implements ServiceProviderBase<IExceptionProvider, ExceptionService> {
    // ServiceProviderBase mixin implementation.
    provider: IExceptionProvider;
    registerProvider: (provider: IExceptionProvider) => void;

    constructor(injector: Injector, @Inject(ExceptionServiceConfig) config: IServiceProviderConfig<IExceptionProvider>) {
        super();
        if (config && config.provider) {
            this.registerProvider(injector.get(config.provider));
        }
    }

    /**
     * Handle javascript errors.
     *
     * @param {*} error
     *
     * @memberof ExceptionService
     */
    override handleError(error: any) {
        const event = new ExceptionEvent(error);

        if (this.provider != null) {
            this.provider.handleError(event);
        }

        if (!event.defaultPrevented()) {
            super.handleError(error);
        }
    }
}

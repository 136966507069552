import { FormQuestionType } from '../form.service';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

type InputType = 'text' | 'textarea' | 'color' | 'email' | 'image' | 'month' | 'number' | 'range' | 'password' | 'reset' | 'search' | 'time' | 'submit' | 'tel' | 'url' | 'week';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormTextboxQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormTextboxQuestion extends IFormQuestionControlInputBase<string> {
    /**
     * Type of input.
     *
     * @type {InputType}
     * @memberof IFormTextboxQuestion
     */
    type?: InputType;

    /**
     * Restrict.
     *
     * @type {RegExp}
     */
    restrict?: RegExp;

    disablePaste?: boolean;

    noteClass?: string;
}

/**
 * Textbox question type.
 *
 * @export
 * @class FormTextboxQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormTextboxQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.Textbox;
    /**
     * Type of input.
     *
     * @type {InputType}
     * @memberof FormTextboxQuestion
     */
    type: InputType;

    /**
     * Restrict.
     *
     * @type {RegExp}
     * @memberof FormTextboxQuestion
     */
    restrict?: RegExp;

    disablePaste: boolean;

    noteClass?: string;

    /**
     * Creates an instance of FormTextboxQuestion.
     * @param {IFormTextboxQuestion} [options={}]  Creation options.
     *
     * @memberof FormTextboxQuestion
     */
    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        this.type = options.type || 'text';
        this.restrict = options.restrict;
        this.disablePaste = options.disablePaste != null ? options.disablePaste : false;
        this.noteClass = options.noteClass;
    }
}

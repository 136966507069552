import { FormQuestionControlBase, IFormQuestionControlBase } from './formquestioncontrol-base';

/**
 * Interface to define a form control question implicating an input.
 *
 * @export
 * @interface IFormQuestionControlInputBase
 * @extends {IFormQuestionControlBase><T>}
 * @template T
 */
export interface IFormQuestionControlInputBase<T> extends IFormQuestionControlBase<T> {
    /**
     * Size of the component (not length).
     *
     * @type {('small' | 'default' | 'large')}
     * @memberof FormQuestionBase
     */
    size?: 'small' | 'default' | 'large';

    /**
     * Input mask.
     *
     * @type {(Array<string | RegExp>)}
     * @memberof IFormTextboxQuestion
     */
    inputMask?: (string | RegExp)[] | ((raw: string) => (string | RegExp)[]) | false;

    /**
     * Tell the component to manage the input as an input group.
     *
     * @type {boolean}
     * @memberof IFormTextboxQuestion
     */
    isInputGroup?: boolean;

    /**
     * Adds a list of old values under the input. (Linked to the HTML5 autocomplete attribute).
     *
     * @type {('on' | 'off')}
     * @memberof IFormTextboxQuestion
     */
    autoComplete?: 'on' | 'off';

    /**
     * Give the focus automatically to the input. (Linked to the HTML5 autofocus attribute).
     *
     * @type {boolean}
     * @memberof IFormDateQuestion
     */
    autoFocus?: boolean;

    /**
     * Text alignment inside the input field.
     *
     * @type {('left' | 'right' | 'center')}
     * @memberof IFormTextboxQuestion
     */
    textAlign?: 'left' | 'right' | 'center';

    /**
     * Event triggered on the enter key is pressed.
     *
     * @memberof IFormQuestionControlBase
     */
    onKeyDownEnter?: (event: Event, question: FormQuestionControlBase<T>) => void;
}

/**
 * Form control type of question.
 *
 * @export
 * @abstract
 * @class FormQuestionControlBase
 * @extends {FormQuestionBase}
 * @template T Type of value.
 */
export abstract class FormQuestionControlInputBase<T = any> extends FormQuestionControlBase<T> {
    /**
     * Size of the component (not length).
     *
     * @type {('small' | 'default' | 'large')}
     * @memberof FormQuestionBase
     */
    size: 'small' | 'default' | 'large';

    /**
     * Input mask.
     *
     * @type {(Array<string | RegExp>)}
     * @memberof IFormTextboxQuestion
     */
    inputMask: (string | RegExp)[] | ((raw: string) => (string | RegExp)[]) | false;

    /**
     * Tell the component to manage the input as an input group.
     *
     * @type {boolean}
     * @memberof IFormTextboxQuestion
     */
    isInputGroup: boolean;

    /**
     * Adds a list of old values under the input. (Linked to the HTML5 autocomplete attribute).
     *
     * @type {('on' | 'off')}
     * @memberof IFormTextboxQuestion
     */
    autoComplete: 'on' | 'off';

    /**
     * Give the focus automatically to the input. (Linked to the HTML5 autofocus attribute).
     *
     * @type {boolean}
     * @memberof IFormDateQuestion
     */
    autoFocus: boolean;

    /**
     * Text alignment inside the input field.
     *
     * @type {('left' | 'right' | 'center')}
     * @memberof IFormTextboxQuestion
     */
    textAlign: 'left' | 'right' | 'center';

    /**
     * Event triggered on the enter key is pressed.
     *
     * @memberof IFormQuestionControlBase
     */
    onKeyDownEnter: (event: Event, question: FormQuestionControlBase<T>) => void;

    constructor(options: IFormQuestionControlInputBase<T> = {}) {
        super(options);

        this.size = options.size || 'default';
        this.inputMask = options.inputMask || false;
        this.isInputGroup = options.isInputGroup || false;
        this.autoComplete = options.autoComplete || 'off';
        this.textAlign = options.textAlign || 'left';
        this.autoFocus = options.autoFocus;
        this.onKeyDownEnter = options.onKeyDownEnter;
    }

    get internalInputGroupClass() {
        let inputGroupClass = '';
        if (this.isInputGroup) {
            inputGroupClass = 'input-group';
            if (this.size === 'small') {
                inputGroupClass += ' input-group-sm';
            } else if (this.size === 'large') {
                inputGroupClass += 'input-group-lg';
            }
        }
        return inputGroupClass;
    }

    get internalInputClass() {
        let classes = '';
        if (this.textAlign === 'right') {
            classes += ' text-right';
        } else if (this.textAlign === 'center') {
            classes += ' text-right';
        }
        if (!this.isInputGroup) {
            if (this.size === 'small') {
                classes += ' input-sm';
            } else if (this.size === 'large') {
                classes += ' input-lg';
            }
        }
        return classes;
    }
}

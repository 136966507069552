import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IHttpOptionExtras, IInterceptor } from '../http/http.service';
import { BusyIndicatorService } from './busyindicator.service';

/**
 * HTTP interceptor to activate and deactivate busy indicators on http calls.
 *
 * @export
 * @class BusyIndicatorInterceptor
 * @implements {IInterceptor}
 */
@Injectable()
export class BusyIndicatorInterceptor implements IInterceptor {
    openedRequests: { [requestId: number]: number };
    constructor(private busyIndicatorService: BusyIndicatorService) {
        this.openedRequests = {};
    }

    request(requestId: number, req: HttpRequest<any>, options: IHttpOptionExtras) {
        this.openedRequests[requestId] = this.busyIndicatorService.show(
            {
                silent: options.silent,
                message: options.busyMessage,
                invisible: options.busyInvisible,
                hideSpinner: options.busyHideSpinner,
            },
            'Request interceptor (' + req.url + ')',
        );
        return req;
    }

    response(requestId: number, res: HttpResponse<any>, options: IHttpOptionExtras) {
        this.busyIndicatorService.hide(this.openedRequests[requestId], 'Request interceptor');
        delete this.openedRequests[requestId];

        return res;
    }

    responseError(requestId: number, res: HttpErrorResponse, options: IHttpOptionExtras) {
        this.busyIndicatorService.hide(this.openedRequests[requestId], 'Request interceptor');
        delete this.openedRequests[requestId];

        return res;
    }
}

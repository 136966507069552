import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import * as introJs from 'intro.js/intro.js';
import { IntroJs } from 'intro.js';
import { LocalStorageService } from '../../../gamma/localstorage';
import { U2000LS_IntroDontShowAgain } from '../U2000_localstorages';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class U2000_IntroJSService {
    constructor(
        private translateService: TranslateService, //
        private localStorageService: LocalStorageService,
    ) {}

    introJS: IntroJs = null;

    start(id: string, steps: introJs.Step[], force = false) {
        return new Observable(subscriber => {
            const lsDontShowAgain = U2000LS_IntroDontShowAgain + '_' + id;
            if (this.localStorageService.get(lsDontShowAgain, false) === false || force) {
                this.introJS = introJs();

                /* this.introJS.onbeforechange(x => {
                    let check = document.getElementById('introjs-dontShowAgain') as HTMLInputElement;
                    if (check != null) {
                        this.localStorageService.set(lsDontShowAgain, check.checked);
                    }
                }); */

                this.introJS.onafterchange(x => {
                    const intervalID = setInterval(() => {
                        let check = document.getElementById('introjs-dontShowAgain') as HTMLInputElement;

                        if (check['checkVisibility']()) {
                            check.checked = this.localStorageService.get(lsDontShowAgain, false) as any;
                            check.addEventListener('change', e => {
                                this.localStorageService.set(lsDontShowAgain, check.checked);
                            });
                            window.clearInterval(intervalID);
                        }
                    }, 100);
                });

                this.introJS.onexit(x => {
                    this.introJS = null;

                    subscriber.next();
                    subscriber.complete();
                    /* document.removeEventListener('keypress', this.stopEscapePropagation); */
                });

                this.introJS.start();

                const modifiedSteps = JSON.parse(JSON.stringify(steps)) as typeof introJs.Step[];
                modifiedSteps.forEach(x => {
                    x.intro =
                        x.intro +
                        `</br></br><div class="introjs-dontShowAgain"><input type="checkbox" id="introjs-dontShowAgain" name="introjs-dontShowAgain"><label for="introjs-dontShowAgain">Ne plus afficher au démarrage</label></div>`;
                });

                this.introJS
                    .setOptions({
                        exitOnEsc: false,
                        exitOnOverlayClick: false,
                        nextLabel: this.translateService.instant('lblNext'),
                        prevLabel: this.translateService.instant('lblPrevious'),
                        skipLabel: '',
                        doneLabel: this.translateService.instant('lblDone'),
                        steps: modifiedSteps,
                    })
                    .start();
            } else {
                subscriber.next();
                subscriber.complete();
            }
        });
    }

    isStarted() {
        return this.introJS != null;
    }

    /* private stopEscapePropagation(event: KeyboardEvent) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    } */
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'u2000-section-title',
    templateUrl: './U2000_sectiontitle.component.html',
    styleUrls: ['./U2000_sectiontitle.component.scss'],
})
export class U2000_SectionTitleComponent {
    @Input() label: string;

    constructor() {}
}

import { EventEmitter, Injectable } from '@angular/core';

import { ISecurityProvider, SecurityParams, SecurityService } from '../../../gamma/security';

@Injectable()
export class U2000_SecurityProvider implements ISecurityProvider {
    changed$: EventEmitter<string[]>;
    service: SecurityService;
    securityTags: string[];
    constructor() {
        this.securityTags = [];
        this.changed$ = new EventEmitter<string[]>();
    }

    addTags(tags: string[] | string) {
        if (typeof tags === 'string') {
            this.securityTags.push(tags);
            this.changed$.emit([tags]);
        } else {
            this.securityTags = this.securityTags.concat(tags);
            this.changed$.emit(tags);
        }
    }

    clearTags() {
        this.securityTags = [];
        this.changed$.emit([]);
    }

    hasAccess(tags: string | string[] | SecurityParams): boolean {
        if (typeof tags === 'string') {
            return this.securityTags.indexOf(tags) !== -1;
        } else if (tags instanceof Array) {
            for (let i = 0; i < tags.length; i++) {
                if (this.securityTags.indexOf(tags[i]) === -1) {
                    return false;
                }
            }
            return true;
        } else {
            const securityParams = tags;
            for (let i = 0; i < securityParams.tags.length; i++) {
                if (securityParams.operator === 'or') {
                    if (this.securityTags.indexOf(securityParams.tags[i]) >= 0) {
                        return true;
                    }
                } else {
                    if (this.securityTags.indexOf(securityParams.tags[i]) === -1) {
                        return false;
                    }
                }
            }
            return securityParams.operator === 'or' ? false : true;
        }
    }
}

import { InjectionToken, NgModule } from '@angular/core';

import { ILogicalUnitModalRoute } from '../../gamma/logicalunit/logicalunit-modal.interface';

import { U2021_EulaModalComponent } from './U2021_eula-modal/U2021_eula-modal.component';
import { U2021_EulaResolver } from './U2021_eula.resolver';
import { U2021_EulaService } from './U2021_eula.service';

export const U2021_EulaRoute: ILogicalUnitModalRoute<U2021_EulaService> = {
    component: U2021_EulaModalComponent,
    resolver: U2021_EulaResolver,
};

export const U2021_Route = new InjectionToken<ILogicalUnitModalRoute<U2021_EulaService>>('U2021_Route');

@NgModule({
    imports: [],
    exports: [],
    providers: [{ provide: U2021_Route, useValue: U2021_EulaRoute }],
})
export class U2021_EulaRoutingModule {}

export const U2021_RoutedComponents = [U2021_EulaModalComponent];

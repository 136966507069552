import { createNumberMask } from '../../mask/mask.service';
import { FormQuestionType } from '../form.service';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

type NumericType = 'amount' | 'integer' | 'float' | 'percent';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormNumericQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormNumericQuestion extends IFormQuestionControlInputBase<string> {
    /**
     * Number type (Mask preset that can be customized by other properties)
     *
     * @type {NumericType}
     * @memberof IFormNumericQuestion
     */
    numericType: NumericType;

    /**
     * The local in which the number will be formatted.
     *
     * @type {'fr' | 'en'}
     * @memberof FormNumericQuestion
     */
    locale: 'fr' | 'en';

    /**
     * The currency code if the type is amount.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    currencyCode?: string;

    /**
     * Mask prefix.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    prefix?: string;

    /**
     * Mask suffix.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    suffix?: string;

    /**
     * Mask include thousands separator.
     *
     * @type {boolean}
     * @memberof IFormNumericQuestion
     */
    includeThousandsSeparator?: boolean;

    /**
     * Mask thousand separator symbol.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    thousandsSeparatorSymbol?: string;

    /**
     * Mask decimal symbol.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    decimalSymbol?: string;

    /**
     * Mask allows decimal.
     *
     * @type {boolean}
     * @memberof IFormNumericQuestion
     */
    allowDecimal?: boolean;

    /**
     * Mask limit of decimal allowed.
     *
     * @type {number}
     * @memberof IFormNumericQuestion
     */
    decimalLimit?: number;

    /**
     * Mask allows negative numbers.
     *
     * @type {boolean}
     * @memberof IFormNumericQuestion
     */
    allowNegative?: boolean;

    /**
     * Mask limit of integer allowed.
     *
     * @type {number}
     * @memberof IFormNumericQuestion
     */
    integerLimit?: number;
}

/**
 * Textbox question type.
 *
 * @export
 * @class IFormNumericQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormNumericQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.Amount;

    /**
     * Number type (Mask preset that can be customized by other properties)
     *
     * @type {NumericType}
     * @memberof FormNumericQuestion
     */
    numericType: NumericType;

    /**
     * The local in which the number will be formatted.
     *
     * @type {'fr' | 'en'}
     * @memberof FormNumericQuestion
     */
    locale: 'fr' | 'en';

    /**
     * The currency code if the type is amount.
     *
     * @type {string}
     * @memberof FormNumericQuestion
     */
    currencyCode: string;

    private inputMaskConfigs: IU2000_NumericMaskConfig;

    /**
     * Creates an instance of IFormNumericQuestion.
     * @param {IFormNumericQuestion} [options={}]  Creation options.
     *
     * @memberof IFormNumericQuestion
     */
    constructor(options: IFormNumericQuestion) {
        super(options);

        this.numericType = options.numericType;
        this.locale = options.locale;

        const defaultMaskConfig: IU2000_NumericMaskConfig = {
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ' ',
            decimalSymbol: ',',
            allowDecimal: true,
            decimalLimit: 2,
            allowNegative: false,
            integerLimit: 8,
        };
        if (this.numericType === 'amount') {
            if (this.locale === 'en') {
                defaultMaskConfig.prefix = '$';
            } else {
                defaultMaskConfig.suffix = String.nbsp + '$';
            }
            defaultMaskConfig.allowDecimal = true;
            defaultMaskConfig.decimalLimit = 2;
            defaultMaskConfig.integerLimit = 8;
            this.textAlign = 'right';
        } else if (this.numericType === 'integer') {
            defaultMaskConfig.suffix = '';
            defaultMaskConfig.allowDecimal = false;
            defaultMaskConfig.decimalLimit = 0;
            defaultMaskConfig.integerLimit = 10;
        } else if (this.numericType === 'float') {
            defaultMaskConfig.suffix = '';
            defaultMaskConfig.allowDecimal = true;
            defaultMaskConfig.decimalLimit = 2;
            defaultMaskConfig.integerLimit = 8;
        } else if (this.numericType === 'percent') {
            defaultMaskConfig.suffix = ' %';
            defaultMaskConfig.allowDecimal = true;
            defaultMaskConfig.decimalLimit = 2;
            defaultMaskConfig.integerLimit = 3;
        }

        if (this.locale === 'en') {
            defaultMaskConfig.thousandsSeparatorSymbol = ',';
            defaultMaskConfig.decimalSymbol = '.';
        }

        this.inputMaskConfigs = {
            prefix: options.prefix || defaultMaskConfig.prefix,
            suffix: options.suffix || defaultMaskConfig.suffix,
            includeThousandsSeparator: options.includeThousandsSeparator || defaultMaskConfig.includeThousandsSeparator,
            thousandsSeparatorSymbol: options.thousandsSeparatorSymbol || defaultMaskConfig.thousandsSeparatorSymbol,
            decimalSymbol: options.decimalSymbol || defaultMaskConfig.decimalSymbol,
            allowDecimal: options.allowDecimal || defaultMaskConfig.allowDecimal,
            decimalLimit: options.decimalLimit || defaultMaskConfig.decimalLimit,
            allowNegative: options.allowNegative || defaultMaskConfig.allowNegative,
            integerLimit: options.integerLimit || defaultMaskConfig.integerLimit,
        };

        this.currencyCode = options.currencyCode || 'CAD';

        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask prefix.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    get prefix(): string {
        return this.inputMaskConfigs.prefix;
    }

    set prefix(value: string) {
        this.inputMaskConfigs.prefix = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask suffix.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    get suffix(): string {
        return this.inputMaskConfigs.suffix;
    }

    set suffix(value: string) {
        this.inputMaskConfigs.suffix = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask include thousands separator.
     *
     * @type {boolean}
     * @memberof IFormNumericQuestion
     */
    get includeThousandsSeparator(): boolean {
        return this.inputMaskConfigs.includeThousandsSeparator;
    }

    set includeThousandsSeparator(value: boolean) {
        this.inputMaskConfigs.includeThousandsSeparator = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask thousand separator symbol.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    get thousandsSeparatorSymbol(): string {
        return this.inputMaskConfigs.thousandsSeparatorSymbol;
    }

    set thousandsSeparatorSymbol(value: string) {
        this.inputMaskConfigs.thousandsSeparatorSymbol = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask decimal symbol.
     *
     * @type {string}
     * @memberof IFormNumericQuestion
     */
    get decimalSymbol(): string {
        return this.inputMaskConfigs.decimalSymbol;
    }

    set decimalSymbol(value: string) {
        this.inputMaskConfigs.decimalSymbol = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask allows decimal.
     *
     * @type {boolean}
     * @memberof IFormNumericQuestion
     */
    get allowDecimal(): boolean {
        return this.inputMaskConfigs.allowDecimal;
    }

    set allowDecimal(value: boolean) {
        this.inputMaskConfigs.allowDecimal = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask limit of decimal allowed.
     *
     * @type {number}
     * @memberof IFormNumericQuestion
     */
    get decimalLimit(): number {
        return this.inputMaskConfigs.decimalLimit;
    }

    set decimalLimit(value: number) {
        this.inputMaskConfigs.decimalLimit = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask allows negative numbers.
     *
     * @type {boolean}
     * @memberof IFormNumericQuestion
     */
    get allowNegative(): boolean {
        return this.inputMaskConfigs.allowNegative;
    }

    set allowNegative(value: boolean) {
        this.inputMaskConfigs.allowNegative = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }

    /**
     * Mask limit of integer allowed.
     *
     * @type {number}
     * @memberof IFormNumericQuestion
     */
    get integerLimit(): number {
        return this.inputMaskConfigs.integerLimit;
    }

    set integerLimit(value: number) {
        this.inputMaskConfigs.integerLimit = value;
        this.inputMask = createNumberMask(this.inputMaskConfigs) as any;
    }
}

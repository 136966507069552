import { HttpService } from '../http';
import { RoutingService } from '../routing';
import { LogicalUnitBaseService } from './logicalunit-base.service';

/**
 * Base logical unit service.
 *
 * @export
 * @abstract
 * @class LogicalUnitBaseService
 */
export abstract class LogicalUnitModalBaseService extends LogicalUnitBaseService {
    public modalOptions: any;

    constructor(public override routingService: RoutingService, httpService: HttpService) {
        super(routingService, httpService);

        this.luType = 'Modal';
    }
    onRedirectAfterClose: () => void;

}

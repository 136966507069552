import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

import { U2000_Validators } from '../U2000_core';

export class U2000_PhoneNumberQuestion extends FormTextboxQuestion {
    static schema: IFormTextboxQuestion = {
        key: 'phoneNumber',
        label: 'lblPhoneNumber',
        width: '12em',
        maxLength: 32,
        inputMask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validators: [U2000_Validators.phoneNumber],
    };

    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        const schema = U2000_PhoneNumberQuestion.schema;
        if (String.isNullOrEmpty(this.key)) {
            this.key = schema.key;
        }
        if (String.isNullOrEmpty(this.label)) {
            this.label = schema.label;
        }
        if (String.isNullOrEmpty(this.width)) {
            this.width = schema.width;
        }
        if (this.maxLength == null) {
            this.maxLength = schema.maxLength;
        }

        if (this.inputMask == null || this.inputMask === false) {
            this.inputMask = schema.inputMask;
        }
        if (Array.isNullOrEmpty(this.validators)) {
            this.validators = schema.validators;
        } else {
            this.validators.push(U2000_Validators.phoneNumber);
        }
    }
}

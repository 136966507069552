import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpService } from '../../gamma/http';
import { I18nService } from '../../gamma/i18n';

import { languageHashValues } from '../../languageshashvalues';
import { U2000_DevelopmentService } from './U2000_services/U2000_development.service';

@Injectable()
export class U2000_TranslatePartialLoader {
    private cachedPart: { [part_Lang: string]: { [variable: string]: string } };

    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private httpService: HttpService,
        private i18nService: I18nService,
        private developmentService: U2000_DevelopmentService,
    ) {
        this.cachedPart = {};
    }

    public getPartialTranslation(part: string): Observable<any> {
        part = part.toLowerCase();

        if (!this.partLoaded(part)) {
            const loadObservable = this.http
                .get<{ [variable: string]: string }>(
                    `assets/i18n/${part}/${this.buildPart(part)}${languageHashValues[part.toUpperCase()]}.json`,
                    this.httpService.optionsWithExtra({
                        silent: true,
                        globalErrorHandling: false,
                    }),
                )
                .pipe(
                    tap(res => {
                        const languages: { [variable: string]: string } = res;
                        this.cachedPart[this.buildPart(part)] = languages;

                        // Just add languages to le translations list to be able to display variables instead.
                        if (!this.developmentService.translationVariablesDisplayed) {
                            this.translateService.setTranslation(this.i18nService.currentLanguageCode, languages, true);
                        }
                    }),
                );
            return loadObservable;
        } else {
            return of(this.cachedPart[this.buildPart(part)]);
        }
    }

    partLoaded(part: string) {
        return this.cachedPart[this.buildPart(part)] != null;
    }

    getPart(part: string) {
        return this.cachedPart[this.buildPart(part)];
    }

    private buildPart(part: string) {
        return part.toLowerCase() + '_' + this.i18nService.currentLanguageCode;
    }
}

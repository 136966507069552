import { Component } from '@angular/core';

import { PopoverDirective } from 'ngx-bootstrap/popover';

import { LocalStorageService } from '../../../../../gamma/localstorage';
import { RoutingService } from '../../../../../gamma/routing';
import { SecurityService } from '../../../../../gamma/security';

import { IU2000_Application, U2000_ApplicationMenuService } from '../../../../U2000_core/U2000_services/U2000_applicationmenu.service';
import { U2000_AuthenticationService } from '../../../../U2000_core/U2000_services/U2000_authentication.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[u2000-applicationsmenu]',
    templateUrl: 'U2000_applicationsmenu.component.html',
    styleUrls: ['U2000_applicationsmenu.component.scss'],
})
export class U2000_ApplicationsMenuComponent {
    popover: PopoverDirective;

    applications: IU2000_Application[];

    constructor(
        public authService: U2000_AuthenticationService,
        public routingService: RoutingService,
        private securityService: SecurityService,
        private localStorageService: LocalStorageService,
        public applicationMenuService: U2000_ApplicationMenuService,
    ) {
        this.applications = this.applicationMenuService.applications;
    }

    showMenu(popover: PopoverDirective) {
        this.popover = popover;
        if (this.popover) {
            if (this.popover.isOpen) {
                this.popover.hide();
            } else {
                this.popover.show();
            }
        }
    }

    onClickOutside() {
        if (this.popover && this.popover.isOpen) {
            this.popover.hide();
        }
    }

    applicationVisible(application: IU2000_Application) {
        if (application.securityFn) {
            return application.securityFn(application);
        } else if (application.securityTags != null) {
            return this.securityService.hasAccess(application.securityTags);
        }
        return true;
    }

    getRouterLink(application: IU2000_Application) {
        let route: string | string[] = this.localStorageService.get(this.applicationMenuService.getLocalStorageName(application.module));

        if (!this.applicationMenuService.haveAccessToRoute(route, application.options) || route == null) {
            if (application.routeFn) {
                route = application.routeFn(application);
            } else {
                route = application.route;
            }
        }
        return route;
    }
}

import { Injectable } from '@angular/core';

import { UserAgentService } from './user-agent.service';

/**
 * Service for Dom utils.
 *
 * @export
 * @class DomService
 */
@Injectable()
export class PrintService {
    private printMediaQuery: MediaQueryList;
    private onPrint: () => void;
    private beforePrint: () => void;
    private afterPrint: () => void;

    constructor(private userAgentService: UserAgentService) {}

    initEvents(onPrint: () => void, beforePrint: () => void, afterPrint: () => void) {
        if (this.userAgentService.isFirefox() || this.userAgentService.isInternetExplorer() || this.userAgentService.isEdge()) {
            window.onafterprint = afterPrint;
            window.onbeforeprint = beforePrint;
        } else {
            this.beforePrint = beforePrint;
            this.afterPrint = afterPrint;
            this.printMediaQuery = window.matchMedia('print');
            this.printMediaQuery.addListener(this.mediaQueryResponse.bind(this));
        }
        this.onPrint = onPrint;
    }

    destroyEvents() {
        if (this.userAgentService.isFirefox() || this.userAgentService.isInternetExplorer() || this.userAgentService.isEdge()) {
            window.onafterprint = null;
            window.onbeforeprint = null;
        } else {
            this.printMediaQuery.removeListener(this.mediaQueryResponse as any);
            this.printMediaQuery = null;
            this.beforePrint = null;
            this.afterPrint = null;
        }
    }

    print() {
        if (this.userAgentService.isSafari() || this.userAgentService.isMobile()) {
            const modalHeader = document.getElementsByClassName('modal-header')[0].getElementsByTagName('h3')[0].outerHTML;
            const modalBody = document.getElementsByClassName('modal-body')[0].outerHTML;
            const html = `
                <html>
                    <head>
                        <link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
                        rel="stylesheet" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
                        crossorigin="anonymous">
                    </head>
                    <body>
                        <div class="modal-header">${modalHeader}</div>
                        ${modalBody}
                    </body>
                </html>`;

            const win = window.open();
            win.document.write(html);
        } else {
            this.onPrint();
            window.print();
        }
    }

    private mediaQueryResponse(mediaQueryList: MediaQueryList) {
        if (mediaQueryList.matches) {
            // if media query matches
            this.beforePrint();
        } else {
            this.afterPrint();
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'postalCode' })
export class U2000_PostalCodePipe implements PipeTransform {
    transform(value: string): string {
        if (value == null) {
            return null;
        }

        if (value.length === 6) {
            value = value.substr(0, 3) + ' ' + value.substr(3, 3);
        }
        return value.toUpperCase();
    }
}

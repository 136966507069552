import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormRadioButtonQuestion } from '../../formquestion-radiobutton';

@Component({
    selector: 'form-radiobutton-bootstrap',
    templateUrl: './radiobutton.component.html',
    styleUrls: ['./radiobutton.component.scss'],
})
export class FormRadioButtonBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormRadioButtonQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, private el: ElementRef) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }

    get altLabelId() {
        return 'alt-' + this.labelId;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { HttpService } from '../../../gamma/http';
import { RoutingService } from '../../../gamma/routing';
import { UserAgentService } from '../../../gamma/utils';

import { environment } from '../../../environments/environment';
import { U2000_DevelopmentService } from './U2000_development.service';

interface IU2000_InstantCaptureParams {
    publicFilename: string;
    domElement?: HTMLElement;
    entity?: { typeId: number; id: number };
    infoText?: string;
    logicalUnit?: string;
}

@Injectable()
export class U2000_InstantCaptureService {
    registeredLu: { [logicalUnitId: string]: boolean };
    pendingCaptures: { [logicalUnitId: string]: IU2000_InstantCaptureParams[] };

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private developmentService: U2000_DevelopmentService,
        private routingService: RoutingService,
        private userAgentService: UserAgentService,
    ) {
        this.registeredLu = {};
        this.pendingCaptures = {};
    }

    public register(logicalUnitId: string): void {
        this.registeredLu[logicalUnitId] = true;
    }

    public addInstantCapture(logicalUnitId: string, options: IU2000_InstantCaptureParams) {
        if (this.pendingCaptures[logicalUnitId] == null) {
            this.pendingCaptures[logicalUnitId] = [];
        }
        this.pendingCaptures[logicalUnitId].push(options);
    }

    public sendAll(logicalUnitId: string) {
        if (!Array.isNullOrEmpty(this.pendingCaptures[logicalUnitId])) {
            this.pendingCaptures[logicalUnitId].forEach(option => {
                this.send(option);
            });
            this.pendingCaptures[logicalUnitId] = null;
        }
    }

    send(options: IU2000_InstantCaptureParams) {
        let htmlText: string;
        let elementToSnapshot: HTMLElement;
        let logicalUnit: string = this.routingService.currentLogicalUnitId;
        if (options.logicalUnit != null) {
            logicalUnit = options.logicalUnit;
        }
        if (this.registeredLu[logicalUnit] === true) {
            if (options.domElement != null) {
                elementToSnapshot = options.domElement;
            } else {
                elementToSnapshot = document.getElementsByTagName('html').item(0) as HTMLElement;
            }
            this.setInputValues(elementToSnapshot);
            this.changeAssetsSrc(elementToSnapshot);
            if (elementToSnapshot.getElementsByTagName('head').length === 0) {
                htmlText = `
            <html>
                ${document.getElementsByTagName('head').item(0).outerHTML}
            <body>
                ${elementToSnapshot.outerHTML}
            </body>
            </html>
            `;
            } else {
                htmlText = elementToSnapshot.outerHTML;
            }

            htmlText = this.addInfoPanel(htmlText, options.infoText);

            this.removeInputValues(elementToSnapshot);

            if (this.developmentService.displayInstantCapture) {
                const win = window.open();
                win.document.write(htmlText);
            }

            const request: IU2000_CreateInstantCaptureDtoRequest = {
                htmlText: htmlText,
                logicalUnit: logicalUnit,
                entityId: options.entity ? options.entity.id : null,
                entityTypeId: options.entity ? options.entity.typeId : null,
                publicFilename: options.publicFilename,
            };
            this.http
                .post<IU2000_CreateInstantCaptureDtoResponse>(environment.apiUrl + 'U2000/instantcapture', request, this.httpService.optionsWithExtra({ silent: true, globalErrorHandling: false }))
                .pipe(map(res => res.result))
                .subscribe();
        }
    }

    private addInfoPanel(htmlText: string, infoText?: string) {
        const infoPanel =
            `
        <script type="text/javascript">
            function togglePanel() {
                var x = document.getElementById('panel-info');
                if (x.style.display === "none") {
                    x.style.display = "block";
                } else {
                    x.style.display = "none";
                }
            }
        </script>
        <div style="position: absolute; top: 0; right: 0; background-color: #ffffff; border: solid 1px #000000;
            padding: 5px; z-index: 9999; max-width: 600px">
            <div id="panel-info">` +
            'Browser: ' +
            this.userAgentService.browser +
            ' ' +
            this.userAgentService.browserVersion +
            '<br/>' +
            'OS: ' +
            this.userAgentService.os +
            ' ' +
            this.userAgentService.osVersion +
            '<br/>' +
            'Resolution: ' +
            window.screen.availWidth +
            ' x ' +
            window.screen.availHeight +
            '<br/>' +
            (!String.isNullOrEmpty(infoText) ? infoText : '') +
            `</div>
            <button onclick="togglePanel()">Show/Hide</button>
        </div>`;
        return htmlText.replace('</body>', infoPanel + '</body>');
    }

    private changeAssetsSrc(domElement: HTMLElement) {
        const elements = domElement.getElementsByTagName('img');
        for (let i = 0; i < elements.length; i++) {
            elements[i].setAttribute('src', elements[i].src);
        }
    }

    private setInputValues(domElement: HTMLElement) {
        const elements = domElement.getElementsByTagName('input');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].type !== 'password') {
                elements[i].setAttribute('value', elements[i].value);
            }
        }
    }

    private removeInputValues(domElement: HTMLElement) {
        const elements = domElement.getElementsByTagName('input');
        for (let i = 0; i < elements.length; i++) {
            elements[i].removeAttribute('value');
        }
    }
}

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { HttpErrorService, HttpService } from '../http';
import { RoutingService } from '../routing';
import { ToastService } from '../toast';

/* Mixin implementation (To copy to the file implementing the mixin)
    // InitLU mixin requirements
    luConfigs: IU4121_InitDto;
    initLU: () => Observable<any>;
    initLUParams: IInitLUParams;
*/

/* Mixin constructor implementation (To copy to the constructor class implementing the mixin)
    // InitLU mixin requirements
    public http: HttpClient,
    public httpService: HttpService,
    public httpErrorService: HttpErrorService,
    public toastService: ToastService,
    public translateService: TranslateService,
*/

/**
 * Interface defining the init logical unit (InitLU) parameters.
 *
 * @export
 * @interface IInitLUParams
 * @example
 *     this.initLUParams = {
 *          cache: false,
 *          before: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
 *              this.currentWorkflowId = route.queryParams['id'];
 *          },
 *          buildParam: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
 *              let urlParams = new HttpParams();
 *              urlParams.set('id', route.queryParams['id']);
 *              return urlParams;
 *          },
 *          response: (res: IXXXX_InitResponse, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
 *              console.log(res);
 *          },
 *          errorResponse: (res: HttpErrorResponse, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
 *              let response: IErrorResponse = res.error;
 *              if (response.errors.length > 0) {
 *                  if (response.errors.find(x => x.code === 'SFWK-B0002')) {
 *                      this.customPageService.display({
 *                          type: 'danger',
 *                          message: 'lblInvoiceNotFound'
 *                      });
 *                  };
 *              }
 *              return res;
 *          }
 *      };
 */
export interface IInitLUParams {
    /**
     * Tells if the InitLU response must cached and be call only the first time.
     * Or not cached and called on every time the logical unit is invoked.
     *
     * @type {boolean}
     * @memberof IInitLUParams
     *  @example
     *      // Refer to the interface example.
     */
    cache?: boolean;

    /**
     * Tells if the InitLU as already been loaded.
     * (A test on a luConfigs == null as been made in the past but a unknown Angular bug let us with this choise)
     *
     * @type {boolean}
     * @memberof IInitLUParams
     */
    loaded?: boolean;

    /**
     * Hook method called before the InitLU call.
     *
     * @param {ActivatedRouteSnapshot} route The activated route.
     * @param {RouterStateSnapshot} state The snapshot of the router state.
     *
     * @memberof IInitLUParams
     * @example
     *      // Refer to the interface example.
     */
    before?(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot);
    /**
     * Hook method called to build parameters to be sent in the search property of the http call.
     *
     * @param {ActivatedRouteSnapshot} route The activated route.
     * @param {RouterStateSnapshot} state The snapshot of the router state.
     *
     * @memberof IInitLUParams
     * @example
     *      // Refer to the interface example.
     */
    buildParam?(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): HttpParams;
    /**
     * Hook method called on a successfull response from the server.
     *
     * @param {*} res Response received from the server
     * @param {ActivatedRouteSnapshot} route The activated route.
     * @param {RouterStateSnapshot} state The snapshot of the router state.
     *
     * @memberof IInitLUParams
     * @example
     *      // Refer to the interface example.
     */
    response?(res: any, route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot);
    /**
     * Hook method called on a response error to handle the error.
     *
     * @param {*} res Response received from the server
     * @param {ActivatedRouteSnapshot} route The activated route.
     * @param {RouterStateSnapshot} state The snapshot of the router state.
     *
     * @memberof IInitLUParams
     * @example
     *      // Refer to the interface example.
     */
    errorResponse?(res: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot);
}

export class LogicalUnitInitMixin {
    /**
     * Configuration retreived from the InitLU call.
     *
     * @type {*}
     * @memberof LogicalUnitInitMixin
     */
    luConfigs: any;
    /**
     * Parameters that allows to customize the InitLU call from within a logical unit service.
     *
     * @type {IInitLUParams}
     * @memberof LogicalUnitInitMixin
     */
    initLUParams?: IInitLUParams;
    /**
     * The default apiUrl built from the environment variable and the logical unit id.
     *
     * @type {string}
     * @memberof LogicalUnitInitMixin
     */
    apiUrl: string;

    http: HttpClient;
    httpService: HttpService;
    httpErrorService: HttpErrorService;
    toastService: ToastService;
    translateService: TranslateService;
    routingService: RoutingService;
    logicalUnitId: string;
    luType: 'Page' | 'Modal';

    addInterceptors: () => void;
    removeInterceptors: () => void;

    /**
     * Method that calls the server init method of the logical unit to retreive information or make validations.
     *
     * @param {ActivatedRouteSnapshot} route The activated route.
     * @param {RouterStateSnapshot} state The snapshot of the router state.
     * @returns {Observable<any>}
     *
     * @memberof LogicalUnitInitMixin
     */
    initLU(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // let changedLogicalUnit = false;
        const currentService = this.routingService.currentLogicalUnitService;

        /* if (currentService) {
            changedLogicalUnit = (state.url.indexOf(currentService.logicalUnitId) === -1);
        } */

        if (!this.initLUParams) {
            this.initLUParams = {};
        }

        if (this.initLUParams.before) {
            this.initLUParams.before(route, state);
        }

        if (this.initLUParams.cache === false || this.initLUParams.loaded !== true) {
            // Add the interceptors stored in the service and remove the one from the current logical unit service.
            this.routingService.luFlowLogs.push({ lu: this.logicalUnitId, action: 'InitLU Start' });
            this.addInterceptors();

            if (currentService) {
                if (!currentService.interceptorOptions.persistThroughModalLogicalUnit || (currentService.interceptorOptions.persistThroughModalLogicalUnit && !(this.luType === 'Modal'))) {
                    currentService.removeInterceptors();
                }
            }

            /* if (currentService && changedLogicalUnit) {
                if ((!(this.luType === 'Modal'))
                    || ((this.luType === 'Modal') && (!currentService.interceptorOptions.persistThroughModalLogicalUnit))) {
                    currentService.removeInterceptors();
                }
            }
 */
            const observable = this.http
                .get<any>(
                    `${this.apiUrl}/init`,
                    this.httpService.optionsWithExtra({
                        silent: true,
                        globalErrorHandling: false,
                        params: this.initLUParams.buildParam ? this.initLUParams.buildParam(route, state) : null,
                    }),
                )
                .pipe(
                    map(res => {
                        this.luConfigs = res.result;
                        return res;
                    }),
                    tap(res => {
                        this.initLUParams.loaded = true;
                        if (this.initLUParams.response) {
                            this.initLUParams.response(res, route, state);
                        }
                        this.routingService.luFlowLogs.push({ lu: this.logicalUnitId, action: 'InitLU Done' });
                    }),
                    catchError((res: HttpErrorResponse) => {
                        this.initLUParams.loaded = false;
                        if (res.status === 401 || res.status === 500 || res.status === 504) {
                            res = this.httpErrorService.handleHttpError(res);
                        } else {
                            if (this.initLUParams.errorResponse) {
                                this.initLUParams.errorResponse(res, route, state);
                            } else {
                                this.toastService.error(this.translateService.instant('lblLogicalUnitInitFailed'));
                            }
                        }
                        this.routingService.luFlowLogs.push({ lu: this.logicalUnitId, action: 'InitLU Error' });
                        return observableThrowError(res);
                    }),
                    finalize(() => {
                        this.routingService.luFlowLogs.push({ lu: this.logicalUnitId, action: 'InitLU Finalized' });
                        // If the InitLU succeeds or crashes, we just restore the logical units interceptors to its original state.
                        // The activation of the logical unit will add the right interceptors.
                        this.removeInterceptors();

                        if (currentService) {
                            if (!currentService.interceptorOptions.persistThroughModalLogicalUnit || (currentService.interceptorOptions.persistThroughModalLogicalUnit && !(this.luType === 'Modal'))) {
                                currentService.addInterceptors();
                            }
                        }
                    }),
                );
            return observable;
        } else {
            return of(this.luConfigs);
        }
    }
}

import { ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../i18n/i18n.service';
import { FormQuestionType } from '../form.service';
import { FormQuestionBase } from './formquestion-base';
import { FormGroupQuestion } from './formquestion-formgroup';
import { FormQuestionControlBase } from './formquestioncontrol-base';

/**
 * Base class used form question component.
 *
 * @export
 * @abstract
 * @class FormQuestionBaseComponent
 */
export abstract class FormQuestionBaseComponent {
    FormQuestionType = FormQuestionType;

    /**
     * Question to be displayed.
     *
     * @type {FormQuestionBase}
     * @memberof FormQuestionBaseComponent
     */
    question: FormQuestionBase;
    /**
     * Reference to the form in which the question is defined .
     *
     * @type {FormGroup}
     * @memberof FormQuestionBaseComponent
     */
    form: UntypedFormGroup;
    /**
     * Id of logical unit.
     *
     * @type {string}
     * @memberof FormQuestionBaseComponent
     */
    logicalUnitId: string;

    /**
     * Creates an instance of FormQuestionBaseComponent.
     * @param {I18nService} i18nService
     *
     * @memberof FormQuestionBaseComponent
     */
    constructor(private i18nService: I18nService) {}

    afterViewInit(componentRef: FormQuestionBaseComponent, el: ElementRef) {
        if (this.question instanceof FormQuestionControlBase) {
            this.question.componentRef = componentRef;
            this.question.domRef = el.nativeElement;
        }
    }

    /**
     * Form the question label with the i18n service.
     *
     * @param {string} label Label to format.
     * @returns {string} The formatted label.
     *
     * @memberof FormQuestionBaseComponent
     */
    formatLabel(label: string): string {
        if (this.question instanceof FormQuestionControlBase) {
            return this.i18nService.formatLabel(label);
        }
        return '';
    }

    /**
     * Tells if the question is valid.
     *
     * @readonly
     *
     * @memberof FormQuestionBaseComponent
     */
    get isValid() {
        if (this.question instanceof FormQuestionControlBase) {
            return this.question.formControlRef.valid;
        } else if (this.question instanceof FormGroupQuestion) {
            return this.question.formGroupRef.valid;
        }
        return true;
    }

    /**
     * Tells if the question is in error.
     *
     * @readonly
     *
     * @memberof FormQuestionBaseComponent
     */
    get hasError() {
        if (this.question instanceof FormQuestionControlBase) {
            if (this.question.formControlRef == null) {
                return false;
            }
            return this.question.formControlRef.invalid && this.question.formControlRef.touched && this.question.formControlRef.dirty;
        }
        return false;
    }

    get componentId() {
        return this.question.componentId;
    }
    get inputId() {
        return 'in-' + this.componentId;
    }

    get formGroupId() {
        return 'fg-' + this.componentId;
    }

    get labelId() {
        return 'lbl-' + this.componentId;
    }

    get errorMessageId() {
        return 'err-' + this.componentId;
    }

    get formGroupClass() {
        if (this.question instanceof FormQuestionControlBase) {
            // let classList: DOMTokenList = new DOMTokenList();
            let formGroupClass = this.question.formGroupClass;
            if (this.hasError) {
                formGroupClass += ' has-error';
            }
            if ((this.question as FormQuestionControlBase).hideRequiredMarker === true) {
                formGroupClass += ' hide-required-marker';
            }
            return formGroupClass;
        } else if (this.question instanceof FormGroupQuestion) {
            if (this.hasError) {
                return ' has-error';
            }
        }
        return null;
    }
}

import { ChangeDetectorRef, Component, HostListener } from '@angular/core';

import { BusyIndicatorService, IBusyIndicatorState } from '../../../../gamma/busyindicator';

@Component({
    selector: 'u2000-busy-indicator',
    templateUrl: 'U2000_busyindicator.component.html',
    styleUrls: ['U2000_busyindicator.component.scss'],
})
export class U2000_BusyIndicatorComponent {
    public displayed = false;
    public invisible = false;
    public hideSpinner = false;
    public message: string;
    constructor(public service: BusyIndicatorService, changeDetector: ChangeDetectorRef) {
        this.service.stateChange$.subscribe((state: IBusyIndicatorState) => {
            this.displayed = state.displayed;
            changeDetector.detectChanges();
            if (this.displayed) {
                if (state.invisible == null) {
                    state.invisible = false;
                }
                if (state.hideSpinner == null) {
                    state.hideSpinner = false;
                }
                this.invisible = state.invisible;
                this.hideSpinner = state.hideSpinner;
            }
        });

        this.service.messageChange$.subscribe(x => {
            this.message = x;
        });
    }

    @HostListener('window:keydown', ['$event'])
    blockTabKey(event: KeyboardEvent) {
        if (this.displayed) {
            if (event.keyCode === 9) {
                event.preventDefault();
            }
        }
    }
}

import { Component } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_ESignatureService } from '../U2000_esignature.service';

@Component({
    selector: 'u2000-choosesendingmethod',
    templateUrl: 'U2000_choosesendingmethod.component.html',
    styleUrls: ['U2000_choosesendingmethod.component.scss'],
})
export class U2000_ChooseSendingMethodComponent extends BaseComponent<U2000_ESignatureService> {
    constructor(service: U2000_ESignatureService, toastService: ToastService) {
        super(service, toastService);
    }
}

import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { IFormCheckboxQuestion } from '../form/formquestion/formquestion-checkbox';
import { IDropdownOptions } from '../form/formquestion/formquestion-dropdown';

export const ReferentialServiceConfig = new InjectionToken<IServiceProviderConfig<IReferentialProvider>>('REFERENTIAL_SERVICE_CONFIG');

/**
 * Interface that defines the default referential structure.
 *
 * @export
 * @interface IReferential
 * @example
 *      // Refer to the spec file to have a complete coverage of how the service may be used.
 */
export interface IReferential {
    /**
     * Referential id.
     *
     * @type {number}
     * @memberof IReferential
     */
    id?: number;

    /**
     * Referential description.
     *
     * @type {string}
     * @memberof IReferential
     */
    description?: string;
}

/**
 * Interface that defines the configuration used to apply a referential to an object.
 *
 * @export
 * @interface IApplyConfig
 * @template T Referential type.
 * @template U Destination type.
 */
export interface IApplyConfig<T, U> {
    /**
     * Configuration of the referential.
     *
     * @type {IReferentialConfig<T>}
     * @memberof IApplyConfig
     */
    referential: IReferentialConfig<T>;
    /**
     * Configuration of the destination.
     *
     * @type {IDestinationConfig<T, U>}
     * @memberof IApplyConfig
     */
    destination: IDestinationConfig<T, U>;
}

/**
 * Interface that defines the referential part of the configuration.
 *
 * @export
 * @interface IReferentialConfig
 * @template T Referential type.
 */
export interface IReferentialConfig<T> {
    /**
     * Custom data or the referential itself.
     *
     * @type {T[]}
     * @memberof IReferentialConfig
     */
    data?: T[];
    /**
     * Referential key used to retreive the referential data.
     *
     * @type {string}
     * @memberof IReferentialConfig
     */
    referentialKey?: string;
    /**
     * Field name of the referential structure used to bind with the object on which the referential will be applied on.
     *
     * @type {string}
     * @default id
     * @memberof IReferentialConfig
     */
    bindingFieldName?: string;
    /**
     *  Field name of the referential structure used to return in the object on which the referential will be applied on.
     *
     * @type {string}
     * @default description // Implying label[0].description
     * @memberof IReferentialConfig
     */
    returnFieldName?: string;
    /**
     * Lambda method that can be used to customized the way to find the record.
     *
     * @param {*} referential
     * @returns {string}
     *
     * @memberof IReferentialConfig
     */
    find?: (referential: T, value: any) => boolean;
    /**
     * Lambda method that can be used to customized the returned value.
     *
     * @param {*} referential
     * @returns {string}
     *
     * @memberof IReferentialConfig
     */
    return?(referential: T): string;
}

/**
 * Interface that defines the destination part of the configuration.
 *
 * @export
 * @interface IDestinationConfig
 * @template T
 */
export interface IDestinationConfig<T, U> {
    /**
     * Data on which to apply the referential
     *
     * @type {(U[] | U)}
     * @memberof IDestinationConfig
     */
    data: U[] | U;
    /**
     * Field name of the destination structure used to bind with the configured referential.
     *
     * @type {string}
     * @memberof IDestinationConfig
     */
    bindingFieldName: string;
    /**
     * Field name of the destination structure used to be affected by the referetial's returned value.
     *
     * @type {string}
     * @memberof IDestinationConfig
     */
    returnFieldName: string;

    /**
     * Lambda method that can be used to customized the returned value.
     *
     * @param {*} referential
     * @returns {string}
     *
     * @memberof IReferentialConfig
     */
    return?: (referential: T, object: U) => void;
}

/**
 * Provider interface for the local storage service.
 *
 * @export
 * @interface IReferentialProvider
 * @extends {IProvider<ReferentialService>}
 */
export interface IReferentialProvider extends IProvider<ReferentialService> {
    /**
     * Hook method called on gets the description used.
     *
     * @param {IReferential} referential
     * @returns {(string | number)}
     * @memberof IReferentialProvider
     */
    getId?(referential: IReferential): string | number;

    /**
     * Hook method called on gets the description used.
     *
     * @param {IReferential} referential
     * @returns {string}
     * @memberof IReferentialProvider
     */
    getDescription?(referential: IReferential): string;
}

/**
 * Service used to manage all referential issues. Also used to store all loaded referentials.
 *
 * @export
 * @class ReferentialService
 */
@Injectable()
export class ReferentialService extends ServiceProviderBase<IReferentialProvider, ReferentialService> {
    private referentials: { [referentialKey: string]: IReferential[] } = {};

    constructor(injector: Injector, @Inject(ReferentialServiceConfig) config: IServiceProviderConfig<IReferentialProvider>) {
        super(injector, config);
    }

    /**
     * Add a referential binded to a key to facilitate referential usage and logical unit scope.
     *
     * @param {string} referentialKey String reference that may contain a logical unit id. (Ex: U2000_XXXXX)
     * @param {IReferential[]} referential Referential data list.
     *
     * @memberof ReferentialService
     */
    add(referentialKey: string, referential: IReferential[]) {
        this.referentials[referentialKey] = referential;
    }

    /**
     * Apply a referential to an object.
     * This is used to easyly retreive referential descriptions when the server only sends the id.
     *
     * @template T The referential type.
     * @template U The object type.
     * @param {IApplyConfig<T, U>} config The configuration.
     * @returns
     *
     * @memberof ReferentialService
     */
    apply<T, U>(config: IApplyConfig<T, U>) {
        if (!config.destination.data) {
            return null; // nothing to do
        }
        if (config.referential.bindingFieldName == null) {
            config.referential.bindingFieldName = 'id';
        }
        if (config.referential.returnFieldName == null && config.referential.return == null) {
            config.referential.returnFieldName = 'description';
        }
        if (config.destination.data instanceof Array) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < config.destination.data.length; i++) {
                this.applyToObject<T, U>(config, config.destination.data[i]);
            }
        } else {
            this.applyToObject<T, U>(config, config.destination.data);
        }
    }

    private applyToObject<T extends IReferential, U>(config: IApplyConfig<T, U>, object: U) {
        let referentials: T[];
        if (config.referential.data != null) {
            referentials = config.referential.data;
        } else if (config.referential.referentialKey != null) {
            referentials = this.referentials[config.referential.referentialKey] as any as T[];
        } else {
            throw new Error(`A datas or const value must be defined.`);
        }
        let referential: T;
        if (config.referential.find) {
            referential = referentials.find((x: T) => config.referential.find(x, object[config.destination.bindingFieldName]));
        } else {
            if (config.referential.bindingFieldName === 'id') {
                referential = referentials.find(x => this.getId(x) === object[config.destination.bindingFieldName]);
            } else {
                referential = referentials.find(x => x[config.referential.bindingFieldName] === object[config.destination.bindingFieldName]);
            }
        }
        if (referential) {
            if (config.referential.returnFieldName === 'description') {
                object[config.destination.returnFieldName] = this.getDescription(referential);
            } else if (config.referential.return) {
                object[config.destination.returnFieldName] = config.referential.return(referential as T);
            } else {
                object[config.destination.returnFieldName] = referential[config.referential.returnFieldName];
            }

            if (config.destination.return) {
                config.destination.return(referential as T, object);
            }
        }
    }

    /**
     * Prepare a referential to be defined as a dropdown options list.
     *
     * @template T Dropdown list type.
     * @param {(string | any[])} referential Whether a referential key or a array.
     * @param {(referential) => T} [customMap] Mapping method to allow custom behavior while building the options.
     * @returns {T[]}
     *
     * @memberof ReferentialService
     * @example
     *      this.qCommunicationLanguage = new FormDropdownQuestion({
     *          ...
     *          options: this.referentialService.dropDownOptions<IDropdownOptions>(U2000RF_Languages),
     *          value: this.referentialService.get<ILanguageDto>(U2000RF_Languages)
     *              .find(x => x.code === this.translateService.currentLang).id
     *          ...
     *      });
     */
    dropDownOptions<T>(referential: string | any[], customMap?: (referential) => T): T[] {
        let dataList: any[];
        if (typeof referential === 'string') {
            dataList = this.referentials[referential];
        } else {
            dataList = referential;
        }
        if (customMap != null) {
            return dataList.map<T>(customMap);
        } else {
            return dataList.map(x => {
                return {
                    key: this.getId(x),
                    value: this.getDescription(x),
                } as IDropdownOptions as any;
            });
        }
    }

    IFormCheckboxQuestion(referential: string | any[]): IFormCheckboxQuestion[] {
        let dataList: any[];
        if (typeof referential === 'string') {
            dataList = this.referentials[referential];
        } else {
            dataList = referential;
        }

        return dataList.map(x => {
            return {
                defaultValue: this.getId(x) as any,
                label: this.getDescription(x),
            } as IFormCheckboxQuestion;
        });
    }

    /**
     * Get a referential via a referential key.
     *
     * @template T Referential type.
     * @param {string} referentialKey Key of referential.
     * @returns {T[]} The referential data.
     *
     * @memberof ReferentialService
     * @example
     *      this.languages = this.referentialService.get<ILanguageDto>(U2000RF_Languages);
     */
    get<T>(referentialKey: string): T[] {
        return this.referentials[referentialKey] as any;
    }

    private getId(referential: IReferential) {
        if (this.provider && this.provider.getId) {
            return this.provider.getId(referential);
        } else {
            return referential.id;
        }
    }

    getDescription(referential: IReferential) {
        if (this.provider && this.provider.getDescription) {
            return this.provider.getDescription(referential);
        } else {
            return referential.description;
        }
    }

    find<T>(referentialKey: string, predicate: (this: void, value: T, index: number, obj: Array<T>) => boolean) {
        return this.get<T>(referentialKey).find(predicate);
    }
}

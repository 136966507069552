import { DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../../i18n';
import { CustomCurrencyPipe } from '../../../../../shared/pipes/customcurrency.pipe';
import { UserAgentService } from '../../../../../utils';
import { FormNumericQuestion } from '../../../formquestion-numeric';

@Component({
    selector: '[form-numeric-input-bootstrap]',
    templateUrl: './numeric-input.component.html',
    styleUrls: ['./numeric-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormNumericInputBootstrapComponent),
            multi: true,
        },
    ],
})
export class FormNumericInputBootstrapComponent implements ControlValueAccessor {
    @Input() question: FormNumericQuestion;
    @Input() form: UntypedFormGroup;

    formattedValue: string;
    value: number;

    // 8 = Backspace,       9 = tab,            33 = page up,           34 = page down,
    // 35 = end,            36 = home,          37 = left arrow,        38 = up arrow,
    // 39 = right arrow,    40 = down arrow,    46 = Delete
    private RESTRICTION_FREE_KEYBOARD_KEYS = [8, 9, 33, 34, 35, 36, 37, 38, 39, 40, 46];

    constructor(private userAgent: UserAgentService, private currencyPipe: CustomCurrencyPipe, private percentPipe: PercentPipe, private decimalPipe: DecimalPipe, private i18nService: I18nService) {
        this.formattedValue = String.empty;
    }

    onBlur(event: Event) {
        const input: HTMLInputElement = event.target as any;

        this.convertValue(input.value);

        if (this.question.numericType === 'amount') {
            if (!String.isNullOrEmpty(input.value)) {
                input.value = this.currencyPipe.transform(this.value, this.question.currencyCode);
            }
        }
        this.onTouched();

        return null;
    }

    onKeyPress(event: KeyboardEvent) {
        const input: HTMLInputElement = event.target as any;

        if (event.charCode === 46) {
            if (String.isNullOrEmpty(input.value)) {
                input.value = '0,';
            } else {
                input.value = input.value + ',';
            }
        }

        if (event.charCode === 8 || event.charCode === 32) {
            this.convertValue(input.value);
        }

        if (this.shoudBypassSpecialCharacters(event)) {
            return null;
        }
    }

    onKeyDownEnter(event: Event) {
        const input: HTMLInputElement = event.target as any;
        this.convertValue(input.value);

        // On mobile, shut the virtual keyboard if a submit is launched.
        if (this.userAgent.isMobile()) {
            // SAIA-9850 - On mobile, we want to shut the soft keyboard on enter key press, but with the mask, it seams that the Submit
            // is not launched when the input is blured to soon if the value is empty.
            if (String.isNullOrEmpty(input.value)) {
                setTimeout(() => {
                    input.blur();
                }, 1000);
            } else {
                input.blur();
            }
        }
    }

    unmaskAmount(amountStr: string) {
        if (amountStr != null) {
            amountStr = amountStr.replace(/\$+/g, '');
            if (this.question.locale === 'fr') {
                amountStr = amountStr.replace(/,+/g, '.');
            } else {
                amountStr = amountStr.replace(/,+/g, '');
            }
            amountStr = amountStr.replace(/\s+/g, '');
        }
        if (this.question.allowDecimal) {
            return parseFloat(amountStr);
        } else {
            return parseInt(amountStr, 0);
        }
    }

    /**
     * This is the fix for certains browsers(firefox and mozilla) where the key pressed of special characters are rejected by the filter.
     * use to filter out specific characters on firefox and mozilla from  the list to restrict.
     *
     * @export
     * @abstract
     * @method FormQuestionBaseComponent
     */
    shoudBypassSpecialCharacters(event: KeyboardEvent): boolean {
        if (this.userAgent.isFirefox() || this.userAgent.isMozilla()) {
            for (const key of this.RESTRICTION_FREE_KEYBOARD_KEYS) {
                if (event.keyCode === key) {
                    return true;
                }
            }
        }
        if (event.key === undefined) {
            return true;
        }

        return false;
    }

    onChange(event: Event, question: FormNumericQuestion) {
        const input: HTMLInputElement = event.target as any;
        this.convertValue(input.value);
    }

    onChangeInternal(event: Event, question: FormNumericQuestion) {
        if (this.question.onChange) {
            this.question.onChange(event, this.question);
        }
    }

    private convertValue(value: string, propagateChange = true) {
        if (!String.isNullOrEmpty(value)) {
            value = value.replace(/ /g, '');
            if (this.question.numericType === 'amount') {
                this.value = this.unmaskAmount(value);
            } else if (this.question.numericType === 'percent') {
                this.value = parseFloat(
                    this.unmaskAmount(value)
                        .toString()
                        .substr(0, value.length - 1),
                );
            } else {
                this.value = parseFloat(this.unmaskAmount(value).toString());
            }
        } else {
            this.value = null;
        }
        if (propagateChange) {
            this.propagateChange(this.value);
        }
    }

    private propagateChange = (_: any) => {
        /* Must be empty */
    };
    onTouched = () => {
        /* Must be empty */
    };

    writeValue(value: string) {
        if (!String.isNullOrEmpty(value) && !isNaN(parseFloat(value))) {
            // this.formattedValue = conformToMask(obj, this.question.inputMask, {});
            if (this.question.numericType === 'amount') {
                this.formattedValue = this.currencyPipe.transform(parseFloat(value), this.question.currencyCode);
            } else if (this.question.numericType === 'percent') {
                this.formattedValue = this.percentPipe.transform(parseFloat(value) / 100, '1.0-2');
            } else {
                this.formattedValue = this.decimalPipe.transform(value, '1.0-2');
            }
        } else {
            this.formattedValue = String.empty;
        }
        this.convertValue(this.formattedValue, false);
    }
    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /**
     * Form the question label with the i18n service.
     *
     * @param {string} label Label to format.
     * @returns {string} The formatted label.
     *
     * @memberof FormQuestionBaseComponent
     */
    formatLabel(label: string): string {
        return this.i18nService.formatLabel(label);
    }

    get componentId() {
        return this.question.idPrefix + '-' + this.question.key.toLowerCase();
    }
}

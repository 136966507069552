import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../../gamma/modal';
import { CustomDatePipe } from '../../../gamma/shared/pipes/customdate.pipe';
import { ToastService } from '../../../gamma/toast';
import { ClipboardService } from '../../../gamma/utils/clipboard.service';

@Injectable()
export class U2000_GridService {
    constructor(
        private clipboardService: ClipboardService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private datePipe: CustomDatePipe,
        private modalService: ModalService,
    ) {}

    copyToClipboard(fields: IU2000_ClipboardConfigField[], data: any[]) {
        let str = '';
        for (const field of fields) {
            str += '"' + this.translateService.instant(field.label) + '"' + '\t';
        }
        str += '\r\n';

        for (let i = 0; i < data.length; i++) {
            for (const field of fields) {
                let value = data[i];
                const split = field.fieldName.split('.');
                for (const prop of split) {
                    if (value == null) {
                        break;
                    }

                    value = value[prop];
                }
                if (value != null) {
                    value = this.formatValue(value, field);
                } else {
                    value = '';
                }
                str += '"' + value + '"' + '\t';
            }
            str += '\r\n';
        }

        this.modalService
            .confirm({
                message: 'lblCopyClipboardConfirm',
            })
            .then(
                result => {
                    if (this.clipboardService.copyToClipboard(str)) {
                        this.toastService.success('lblCopyClipboardCompleted');
                    } else {
                        this.toastService.warning('lblFeatureNotSupportedBrowser');
                    }
                },
                result => {
                    /* Cancel event MUST BE DECLARED */
                },
            );
    }

    formatValue(value: any, field: IU2000_ClipboardConfigField) {
        if (field.type) {
            if (field.type === 'amount') {
                value = value.toLocaleString().replace(/\s+/g, '');
            } else if (field.type === 'date') {
                value = this.datePipe.transform(value, 'medium');
            } else if (field.type === 'datetime') {
                value = this.datePipe.transform(value, 'full');
            }
        }

        return value ? value : '';
    }
}

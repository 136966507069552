import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';

export const SecurityServiceConfig = new InjectionToken<IServiceProviderConfig<ISecurityProvider>>('SECURITY_SERVICE_CONFIG');

export interface SecurityParams {
    tags: string[];
    operator: 'or' | 'and';
}

/**
 * Interface that defines the security provider.
 *
 * @export
 * @interface ISecurityProvider
 * @extends {IProvider<SecurityService>}
 */
export interface ISecurityProvider extends IProvider<SecurityService> {
    readonly securityTags: string[];

    /**
     * Add security tags that reflects the user's permissions.
     *
     * @param {(string[] | string)} tags
     *
     * @memberof ISecurityProvider
     */
    addTags(tags: string[] | string);

    /**
     * Clear all security tags
     *
     * @memberof ISecurityProvider
     */
    clearTags();

    /**
     * Tells if the user has acces to a specific tag.
     *
     * @param {(string | string[] | SecurityParams)} tags Tag,tags or params to validate.
     * @returns {boolean}
     *
     * @memberof ISecurityProvider
     */
    hasAccess(tags: string | string[] | SecurityParams): boolean;
}

@Injectable()
export class SecurityService extends ServiceProviderBase<ISecurityProvider, SecurityService> {
    constructor(injector: Injector, @Inject(SecurityServiceConfig) config: IServiceProviderConfig<ISecurityProvider>) {
        super(injector, config);
    }

    get securityTags(): string[] {
        if (this.provider != null) {
            return this.provider.securityTags;
        } else {
            throw new Error('You must register a provider for the SecurityService.');
        }
    }
    /**
     * Add security tags that reflects the user's permissions.
     *
     * @param {(string[] | string)} tags
     *
     * @memberof SecurityService
     * @example
     *      securityService.addTags('SPMA-236');
     *      securityService.addTags(['SPMA-236', 'SPMA-237']);
     */
    addTags(tags: string[] | string) {
        if (this.provider != null) {
            return this.provider.addTags(tags);
        } else {
            throw new Error('You must register a provider for the SecurityService.');
        }
    }

    /**
     * Clear all security tags
     *
     * @memberof SecurityService
     * @example
     *      securityService.clearTags();
     */
    clearTags() {
        if (this.provider != null) {
            return this.provider.clearTags();
        } else {
            throw new Error('You must register a provider for the SecurityService.');
        }
    }

    /**
     * Tells if the user has acces to a specific tag.
     *
     * @param {string | strinhg[] | SecurityParams} tags Tag, tags or params to validate.
     * @returns {boolean}
     *
     * @memberof SecurityService
     * @example
     *      if (securityService.hasAccess('SPMA-236')) {
     *          // Your code here.
     *      }
     */
    hasAccess(tags: string | string[] | SecurityParams): boolean {
        if (this.provider != null) {
            return this.provider.hasAccess(tags);
        } else {
            throw new Error('You must register a provider for the SecurityService.');
        }
    }
}

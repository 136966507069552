import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { HttpErrorService, IHttpErrorProvider } from '../../../gamma/http';
import { ModalService } from '../../../gamma/modal';
import { RoutingService } from '../../../gamma/routing';

import { U2000_AuthenticationService } from '../U2000_services/U2000_authentication.service';

@Injectable()
export class U2000_HttpErrorProvider implements IHttpErrorProvider {
    service: HttpErrorService;

    private router: Router;
    private routingService: RoutingService;
    private authService: U2000_AuthenticationService;
    private translateService: TranslateService;

    constructor(private injector: Injector, private modalService: ModalService) {}

    private resolveServices() {
        if (this.router == null) {
            this.router = this.injector.get(Router);
            this.routingService = this.injector.get(RoutingService);
            this.authService = this.injector.get(U2000_AuthenticationService);
            this.translateService = this.injector.get(TranslateService);
        }
    }

    handleError(err: HttpErrorResponse): HttpErrorResponse {
        this.resolveServices();
        if (err.status === 401) {
            // Once the error occured and the user has been logged out, we just ignore the error.
            if (this.authService.isLoggedIn) {
                // Close all modal opened.
                this.modalService.closeAll();

                this.routingService.addLogicalUnitParams<IU2011_LogicalUnitParams>('U2011', {
                    returningUrl: this.router.url,
                    alert: { type: 'warning', message: 'lblSessionExpired' },
                    targetTenantId: { tenantId: this.authService.tenantId },
                });
                this.authService.logoutAndRedirect(false);
            }

            return err;
        } else if (err.status === 504) {
            this.router.navigate(['U2000/e/m']);
        } else {
            const error: IErrorResponse = err.error;
            let errorText = `
                <div class="alert alert-danger" style="margin-top: 15px; text-align: center">
                    ${this.translateService.instant('lblUnhandledErrorMessage')}
                </div>`;
            if (error.errors != null) {
                errorText += `<div class='well' style='margin-bottom:0'>`;
                for (let i = 0; i < error.errors.length; i++) {
                    errorText += `<div>
                        <div>${error.errors[i].code}</div>
                        <div>${error.errors[i].message}
                    </div>`;
                }
                errorText += '</div>';
            }

            this.modalService.alert({
                title: this.translateService.instant('lblError'),
                message: errorText,
            });
        }

        return err;
    }
}

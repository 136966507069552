import { HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Interceptor interface.
 *
 * @export
 * @interface IInterceptor
 */
export interface IInterceptor {
    /**
     * Hook method called before the request is built.
     *
     * @param {number} requestId Id of the request.
     * @param {IHttpRequestData} data Request data that will be used for the http call.
     * @returns {IHttpRequestData} The possibly modified request data.
     *
     * @memberof IInterceptor
     */
    request?(requestId: number, data: HttpRequest<any>, options: IHttpOptionExtras): HttpRequest<any>;

    /**
     * Hook method called on a successfull response.
     *
     * @param {number} requestId Id of the request.
     * @param {Response} res Response received from the server.
     * @returns {Response} The possibly modified response.
     *
     * @memberof IInterceptor
     */
    response?(requestId: number, res: HttpResponse<any>, options: IHttpOptionExtras): HttpResponse<any>;

    /**
     * Hook method on an error response.
     *
     * @param {number} requestId Id of the request.
     * @param {Response} res Response received from the server.
     * @returns {Response} The possibly modified response.
     *
     * @memberof IInterceptor
     */
    responseError?(requestId: number, res: HttpErrorResponse, options: IHttpOptionExtras): HttpErrorResponse;
}

export interface IHttpOptionExtras {
    /**
     * Call without starting the busy indicator.
     *
     * @type {boolean}
     * @memberof GammaRequestOptionsArgs
     */
    silent?: boolean;

    /**
     * Message displayed with the busy indicator (Never displayed if silent is true).
     *
     * @type {string}
     * @memberof GammaRequestOptionsArgs
     */
    busyMessage?: string;

    /**
     * Hide the busy indicator's visual but keep its security glass.
     *
     * @type {boolean}
     * @memberof GammaRequestOptionsArgs
     */
    busyInvisible?: boolean;

    /**
     * Tells if the non business global error handling must be called.
     *
     * @type {boolean}
     * @memberof GammaRequestOptionsArgs
     */
    globalErrorHandling?: boolean;

    /**
     * Hide the busy indicator's spinner but keep its security glass.
     *
     * @type {boolean}
     * @memberof GammaRequestOptionsArgs
     */
    busyHideSpinner?: boolean;
}

export interface IHttpOptions extends IHttpOptionExtras {
    headers?: HttpHeaders;
    observe?: any;
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
}

/**
 * Gamma HTTP service that is an extend of the Angular HTTP service with the addition of the interceptor feature.
 *
 * @export
 * @class HttpService
 */
@Injectable()
export class HttpService {
    private storeRequest: IInterceptor[];
    private storeResponse: IInterceptor[];
    private storeResponseError: IInterceptor[];

    constructor() {
        this.storeRequest = [];
        this.storeResponse = [];
        this.storeResponseError = [];
    }

    public interceptRequest(requestId: number, data: HttpRequest<any>, options: IHttpOptionExtras): HttpRequest<any> {
        return this.storeRequest.reduce((d, i) => i.request(requestId, d, options), data);
    }

    public interceptResponse(requestId: number, response: HttpResponse<any>, options: IHttpOptionExtras): HttpResponse<any> {
        return this.storeResponse.reduce((res, i): HttpResponse<any> => {
            return i.response(requestId, res, options);
        }, response);
    }

    public interceptResponseError(requestId: number, res: HttpErrorResponse, options: IHttpOptionExtras): HttpErrorResponse {
        return this.storeResponseError.reduce((d, i) => i.responseError(requestId, d, options), res);
    }

    /**
     * Add an interceptor to the http call pipeline.
     *
     * @param {IInterceptor} interceptor Interceptor to add.
     *
     * @memberof HttpService
     */
    addInterceptor(interceptor: IInterceptor) {
        if (interceptor.request) {
            if (this.storeRequest.indexOf(interceptor) === -1) {
                this.storeRequest.push(interceptor);
            }
        }
        if (interceptor.response) {
            if (this.storeResponse.indexOf(interceptor) === -1) {
                this.storeResponse.push(interceptor);
            }
        }
        if (interceptor.responseError) {
            if (this.storeResponseError.indexOf(interceptor) === -1) {
                this.storeResponseError.push(interceptor);
            }
        }
    }

    /**
     * Remove a specific interceptor from the http call pipeline.
     *
     * @param {IInterceptor} interceptor Interceptor to remove.
     *
     * @memberof HttpService
     */
    removeInterceptor(interceptor: IInterceptor) {
        let pos: number;
        if (interceptor.request) {
            pos = this.storeRequest.indexOf(interceptor);
            if (pos > -1) {
                this.storeRequest.splice(pos, 1);
            }
        }
        if (interceptor.response) {
            pos = this.storeResponse.indexOf(interceptor);
            if (pos > -1) {
                this.storeResponse.splice(pos, 1);
            }
        }
        if (interceptor.responseError) {
            pos = this.storeResponseError.indexOf(interceptor);
            if (pos > -1) {
                this.storeResponseError.splice(pos, 1);
            }
        }
    }

    optionsWithExtra(options: IHttpOptions) {
        if (options && (!options.params || options.params.keys.length)) {
            options.params = new HttpParams();
        }

        // TO BE REFACTORED EVENTUALLY
        if (options && options.silent != null) {
            options.params = options.params.set('__silent', options.silent as any);
        }
        if (options && options.busyMessage != null) {
            options.params = options.params.set('__busyMessage', options.busyMessage as any);
        }
        if (options && options.busyInvisible != null) {
            options.params = options.params.set('__busyInvisible', options.busyInvisible as any);
        }
        if (options && options.globalErrorHandling != null) {
            options.params = options.params.set('__globalErrorHandling', options.globalErrorHandling as any);
        }
        if (options && options.busyHideSpinner != null) {
            options.params = options.params.set('__busyHideSpinner', options.busyHideSpinner as any);
        }

        return options;
    }
}

import { FormQuestionType } from '../form.service';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormTextareaQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormTextareaQuestion extends IFormQuestionControlInputBase<string> {
    /**
     * Number of rows.
     *
     * @type {number}
     * @memberof IFormTextareaQuestion
     */
    rows?: number;
}

/**
 * Textbox question type.
 *
 * @export
 * @class FormTextareaQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormTextareaQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.Textbox;
    /**
     * Number of rows.
     *
     * @type {number}
     * @memberof IFormTextareaQuestion
     */
    rows: number;

    /**
     * Creates an instance of FormTextareaQuestion.
     * @param {IFormTextareaQuestion} [options={}]  Creation options.
     *
     * @memberof FormTextareaQuestion
     */
    constructor(options: IFormTextareaQuestion = {}) {
        super(options);
        this.rows = options.rows || 3;
    }
}

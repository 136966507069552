import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

export class U2000_CityQuestion extends FormTextboxQuestion {
    static schema: IFormTextboxQuestion = {
        key: 'cityName',
        label: 'lblCity',
        width: '50em',
        maxLength: 50,
    };

    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        const schema = U2000_CityQuestion.schema;

        if (String.isNullOrEmpty(this.key)) {
            this.key = schema.key;
        }
        if (String.isNullOrEmpty(this.label)) {
            this.label = schema.label;
        }
        if (String.isNullOrEmpty(this.width)) {
            this.width = schema.width;
        }
        if (this.maxLength == null) {
            this.maxLength = schema.maxLength;
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { HttpErrorService, HttpService } from '../../gamma/http';
import { RoutingService } from '../../gamma/routing';

import { environment } from '../../environments/environment';
import { AppInitService } from '../app-init.service';
import { U2000_AuthenticationService } from '../U2000_core';
import { IU2000_NeedHelpInfo } from './U2000_workspace-interface';

@Injectable()
export class U2000_WorkspaceService {
    // There to avoid using the constructor's name that is not available when minified.
    static className = 'U2000_WorkspaceService';
    displayLanguageButton = true;
    displayHelpButton = true;

    constructor(
        public http: HttpClient,
        public httpService: HttpService,
        public httpErrorService: HttpErrorService,
        public authService: U2000_AuthenticationService,
        private appInitService: AppInitService,
        private routingService: RoutingService,
        private router: Router,
    ) {}

    getNeedHelpInfo(lu: string, clientTenantId: number) {
        const request: IU2000_NeedHelpDtoRequest = { lu: lu, clientTenantId: clientTenantId };
        return this.http.post<IU2000_NeedHelpDtoResponse>(`${environment.apiUrl}U2000/needHelp`, request).pipe(
            map(res => {
                return res.result;
            }),
        );
    }

    logNeedHelpInfo(request: IU2000_LogNeedHelpDtoRequest) {
        return this.http.post<IU2000_NeedHelpDtoResponse>(`${environment.apiUrl}U2000/logneedHelp`, request, this.httpService.optionsWithExtra({ silent: true, globalErrorHandling: false })).pipe(
            map(res => {
                return res.result;
            }),
        );
    }

    public needHelp() {
        let url = this.appInitService.initData.needHelpUrl;
        let needHelpInfo: IU2000_NeedHelpInfo = {
            clientTenantId: this.authService.tenantId,
            logicalUnitId: this.routingService.currentLogicalUnitId,
        };

        if (this.routingService.currentLogicalUnitService && this.routingService.currentLogicalUnitService.getNeedHelpInfo != null) {
            needHelpInfo = this.routingService.currentLogicalUnitService.getNeedHelpInfo(needHelpInfo);
        }

        if (needHelpInfo.skipBackendCall === true) {
            if (needHelpInfo.applicant == null) {
                needHelpInfo.applicant = 'Client';
            }

            const logNeedHelpRequest: IU2000_LogNeedHelpDtoRequest = {
                applicantType: !String.isNullOrEmpty(needHelpInfo.applicant) ? needHelpInfo.applicant : null,
                applicationTypeCode: !String.isNullOrEmpty(needHelpInfo.application) ? needHelpInfo.application : null,
                logicalUnit: !String.isNullOrEmpty(needHelpInfo.logicalUnitId) ? needHelpInfo.logicalUnitId.substr(0, 5) : null,
                clientName: !String.isNullOrEmpty(needHelpInfo.clientName) ? needHelpInfo.clientName : null,
                supplierName: !String.isNullOrEmpty(needHelpInfo.supplierName) ? needHelpInfo.supplierName : null,
            };

            this.logNeedHelpInfo(logNeedHelpRequest).subscribe();

            url = url.replace('{0}', logNeedHelpRequest.applicantType != null ? logNeedHelpRequest.applicantType : '');
            url = url.replace('{1}', logNeedHelpRequest.logicalUnit != null ? logNeedHelpRequest.logicalUnit : '');
            url = url.replace('{2}', logNeedHelpRequest.applicationTypeCode != null ? logNeedHelpRequest.applicationTypeCode : '');
            url = url.replace('{3}', logNeedHelpRequest.clientName != null ? logNeedHelpRequest.clientName : '');
            url = url.replace('{4}', logNeedHelpRequest.supplierName != null ? logNeedHelpRequest.supplierName : '');
            url = url.replace('{5}', '');

            window.open(url, '_blank');
        } else {
            this.getNeedHelpInfo(needHelpInfo.logicalUnitId, needHelpInfo.clientTenantId).subscribe(res => {
                const logNeedHelpRequest: IU2000_LogNeedHelpDtoRequest = {
                    applicantType: needHelpInfo.applicant != null ? needHelpInfo.applicant : !String.isNullOrEmpty(res.applicant) ? res.applicant : null,
                    applicationTypeCode: !String.isNullOrEmpty(res.application) ? res.application : null,
                    logicalUnit: !String.isNullOrEmpty(needHelpInfo.logicalUnitId) ? needHelpInfo.logicalUnitId.substr(0, 5) : null,
                    clientName: !String.isNullOrEmpty(res.clientName) ? res.clientName : null,
                    supplierName: !String.isNullOrEmpty(needHelpInfo.supplierName) ? needHelpInfo.supplierName : null,
                    masterGroupTenantId: res.masterGroupTenantId != null ? res.masterGroupTenantId : null,
                };

                this.logNeedHelpInfo(logNeedHelpRequest).subscribe();

                url = url.replace('{0}', logNeedHelpRequest.applicantType != null ? logNeedHelpRequest.applicantType : '');
                url = url.replace('{1}', logNeedHelpRequest.logicalUnit != null ? logNeedHelpRequest.logicalUnit : '');
                url = url.replace('{2}', logNeedHelpRequest.applicationTypeCode != null ? logNeedHelpRequest.applicationTypeCode : '');
                url = url.replace('{3}', logNeedHelpRequest.clientName != null ? logNeedHelpRequest.clientName : '');
                url = url.replace('{4}', logNeedHelpRequest.supplierName != null ? logNeedHelpRequest.supplierName : '');
                url = url.replace('{5}', logNeedHelpRequest.masterGroupTenantId != null ? logNeedHelpRequest.masterGroupTenantId.toString() : '');

                window.open(url, '_blank');
            });
        }
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';

import { DialogRef, ModalComponent } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

import { BaseComponent } from '../../../../gamma/core/components/base.component';
import { LocalStorageService } from '../../../../gamma/localstorage';
import { ToastService } from '../../../../gamma/toast';
import { UserAgentService } from '../../../../gamma/utils/user-agent.service';

import { U2000_AuthenticationService } from '../../../U2000_core';
import { U2000LS_IdentityVerification } from '../../../U2000_core/U2000_localstorages';
import { U2000_SecurityService } from '../U2000_security.service';
import { IU2000_VerifyIdentityResult } from '../U2000_verifyidentity/U2000_verifyidentity';
import { U2000_VerifyIdentityModalService } from './U2000_verifyidentity-modal.service';

export interface IU2000_VerifyIdentityModalContext extends BSModalContext {
    workflowReference: string;
    uniqueId: string;
    useKeyFromLocalStorage?: boolean;
}

@Component({
    selector: 'u2000-verifyidentity-modal',
    templateUrl: 'U2000_verifyidentity-modal.component.html',
    styleUrls: ['U2000_verifyidentity-modal.component.scss'],
    providers: [U2000_VerifyIdentityModalService],
})
export class U2000_VerifyIdentityModalComponent extends BaseComponent<U2000_SecurityService> implements ModalComponent<BSModalContext>, OnInit {
    context: IU2000_VerifyIdentityModalContext;

    showComponent = false;
    securityKeyValidityInMinutes: number = null;

    constructor(
        public dialog: DialogRef<BSModalContext>,
        service: U2000_SecurityService,
        toastService: ToastService,
        private el: ElementRef,
        public userAgentService: UserAgentService,
        public verifyIdentityModalService: U2000_VerifyIdentityModalService,
        private localStorage: LocalStorageService,
        private authService: U2000_AuthenticationService,
    ) {
        super(service, toastService);
        this.context = dialog.context as IU2000_VerifyIdentityModalContext;
        // this.context.size = 'sm';
        this.context.isBlocking = true;
        this.context.showClose = true;
    }

    ngOnInit() {
        const element: HTMLDivElement = this.el.nativeElement;
        const modalDialog = element.parentElement.parentElement;
        const modalContent = element.parentElement;

        modalDialog.className = 'modal-dialog u2000-verifyidentity-dialog';
        modalContent.className = 'modal-content u2000-verifyidentity-content';

        // If we want to validate the security key from local storage.
        if (this.context.useKeyFromLocalStorage === true) {
            // Get the security key in the local storage
            let identityVerification = this.localStorage.get<string>(this.authService.agentInfo.id + '.' + U2000LS_IdentityVerification, '');

            this.verifyIdentityModalService.validateSecurityKey(this.context.uniqueId, identityVerification).subscribe(
                res => {
                    // If the security key is still valid, nothing to do except return it. Otherwise, we
                    // validate the user identity.
                    if (res.valid) {
                        const verifyIdentityResult: IU2000_VerifyIdentityResult = {
                            securityKey: res.securityKey,
                            id: res.contactMediaControlId,
                            expirationTimeStamp: res.expirationTimeStamp,
                        };
                        this.onValid(verifyIdentityResult);
                    } else {
                        this.showComponent = true;
                        this.securityKeyValidityInMinutes = res.securityKeyValidityInMinutes;
                    }
                },
                err => {
                    // If a error occured while trying to validate the security key, we want to ask for a new security code
                    this.showComponent = true;
                },
            );
        } else {
            // No security key to validate, ask for a new security code
            this.showComponent = true;
        }
    }

    onValid(verifyIdentityResult: IU2000_VerifyIdentityResult) {
        // Save the security key in the local storage
        if (this.context.useKeyFromLocalStorage === true) {
            let identityVerification = this.authService.tokenInfo.access_token;
            identityVerification += '||' + verifyIdentityResult.securityKey;
            identityVerification += '||' + verifyIdentityResult.expirationTimeStamp;
            identityVerification = btoa(identityVerification);

            this.localStorage.set(this.authService.agentInfo.id + '.' + U2000LS_IdentityVerification, identityVerification);
        }

        this.dialog.close(verifyIdentityResult);
    }

    onError(error: HttpErrorResponse) {
        this.dialog.close(error);
    }

    closeClick() {
        this.dialog.dismiss();
    }

    mustShowComponent(): boolean {
        return this.showComponent;
    }
}

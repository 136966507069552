import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { combineLatest, from, Observable } from 'rxjs';

import { BusyIndicatorService } from '../busyindicator/busyindicator.service';
import { LogicalUnitBaseService } from './logicalunit-base.service';
import { LogicalUnitInitMixin } from './logicalunit-init.mixin';

/**
 * Generic logical unit route resolver.
 * Used manages async flows that may resolve in a success for the route to be displayed.
 * May call the InitLU and/or the translations files.
 *
 * @export
 * @class LogicalUnitResolver
 * @implements {Resolve<any>}
 * @template T The logical unit service type.
 */
export class LogicalUnitResolver<T extends LogicalUnitBaseService> implements Resolve<LogicalUnitResolver<T>> {
    /*private busyIndicatorService: BusyIndicatorService;
    private securityService: SecurityService;*/

    /**
     * Creates an instance of LogicalUnitResolver.
     * @param {T} service Reference to the logical unit service.
     * @param {BusyIndicatorService} busyIndicatorService
     *
     * @memberof LogicalUnitResolver
     */
    constructor(public service: T, private busyIndicatorService: BusyIndicatorService) {}

    /**
     * Resolve method call by the Angular router.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     *
     * @memberof LogicalUnitResolver
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this._resolve(route, state);
    }

    /**
     * Resolve method that must be called to load modal logical unit.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<any>}
     * @memberof LogicalUnitResolver
     */
    resolveModal() {
        return this._resolve(null, null);
    }

    private _resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let observable: Observable<any>;
        const service: ITranslateServiceMixin & LogicalUnitInitMixin = this.service as any;

        this.busyIndicatorService.show(null, 'LU resolve');
        const promise = new Promise((resolve, reject) => {
            if (service.initLU && service.initTranslation) {
                observable = combineLatest([service.initLU(route, state), service.initTranslation()]);
            } else if (service.initLU != null) {
                observable = service.initLU(route, state);
            } else if (service.initTranslation != null) {
                observable = service.initTranslation();
            }

            if (observable != null) {
                observable.subscribe(
                    result => {
                        if (this.service.initCompleted) {
                            this.service.initCompleted(route, state);
                        }
                        this.busyIndicatorService.hide(null, 'LU resolve');
                        resolve(true);
                    },
                    error => {
                        if (this.service.initFailed) {
                            this.service.initFailed(route, state);
                        }
                        this.busyIndicatorService.hide(null, 'LU resolve');
                        reject({ ngNavigationCancelingError: true });
                    },
                );
            } else {
                resolve(true);

                this.busyIndicatorService.hide(null, 'LU resolve');
            }
        });

        return from(promise);
    }
}

import { FormQuestionType } from '../form.service';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

/**
 * Interface to define the option structure for the dropdown.
 *
 * @export
 * @interface IDropdownOptions
 */
export interface IDropdownOptions {
    /**
     * Key of the option. Its value is bound to the value of the input.
     *
     * @type {(string | number | boolean)}
     * @memberof IDropdownOptions
     */
    key: string | number | boolean;
    /**
     * Displayed value in the dropdown options list.
     *
     * @type {string}
     * @memberof IDropdownOptions
     */
    value: string;
}

/**
 * Interface to define a dropdown question.
 *
 * @export
 * @interface IFormDropdownQuestion
 * @extends {(IFormQuestionControlBase<string | number>)}
 */
export interface IFormDropdownQuestion extends IFormQuestionControlInputBase<string | number> {
    /**
     * Options displayed in the dropdown.
     *
     * @type {IDropdownOptions[]}
     * @memberof IFormDropdownQuestion
     */
    options?: IDropdownOptions[];

    /**
     * The label in the default value of the dropdown.
     *
     * @type {string}
     * @memberof IFormDropdownQuestion
     */
    defaultLabel?: string;
}

/**
 * Dropdown question type.
 *
 * @export
 * @class FormDropdownQuestion
 * @extends {(FormQuestionControlBase<string | number>)}
 * @example
 *      // Creation of a dropdown question.
 *      this.qDropdown = new FormDropdownQuestion({
 *          key: 'anyDropdown',
 *          label: 'My dropdown',
 *          width: '15em',
 *          validators: [Validators.required],
 *          options: [
 *              { key: 0, value: 'Value 0' },
 *              { key: 1, value: 'Value 1' }
 *          ],
 *          value: 0
 *      });
 *
 *      // Usage of a referential to build then dropdown options.
 *      this.qCommunicationLanguage = new FormDropdownQuestion({
 *          key: 'communicationLanguageId',
 *          label: 'lblEmailSMSLanguage',
 *          width: '15em',
 *          validators: [Validators.required],
 *          options: referentialService.dropDownOptions<IDropdownOptions>(U2000RF_Languages),
 *          value: referentialService.get<ILanguageDto>(U2000RF_Languages)
 *              .find(x => x.code === translateService.currentLang).id
 *      });
 */
export class FormDropdownQuestion extends FormQuestionControlInputBase<string | number> {
    override formQuestionType = FormQuestionType.Dropdown;
    options: IDropdownOptions[] = [];
    defaultLabel: string;

    /**
     * Creates an instance of FormDropdownQuestion.
     * @param {IFormDropdownQuestion} [options={}]  Creation options.
     *
     * @memberof FormDropdownQuestion
     */
    constructor(options: IFormDropdownQuestion = {}) {
        super(options);
        this.options = options.options ? this.options.concat(options.options) : this.options;
        this.defaultLabel = options.defaultLabel || null;
    }
}

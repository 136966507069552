import { Component, Injector } from '@angular/core';

import { PopoverDirective } from 'ngx-bootstrap/popover';

import { IOpenModalComponentParams } from '../../../../../gamma/modal';
import { ModalService } from '../../../../../gamma/modal/modal.service';
import { RoutingService } from '../../../../../gamma/routing';
import { SecurityService } from '../../../../../gamma/security';

import { U2000_AuthenticationService } from '../../../../U2000_core/U2000_services/U2000_authentication.service';
import { U2011_OrganizationSelectionModalComponent } from '../../../../U2011_login/U2011_organizationselection-modal/U2011_organizationselection-modal.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[u2000-profilebutton]',
    templateUrl: 'U2000_profilebutton.component.html',
    styleUrls: ['U2000_profilebutton.component.scss'],
})
export class U2000_ProfileButtonComponent {
    popover: PopoverDirective;
    constructor(
        public authService: U2000_AuthenticationService,
        private modalService: ModalService,
        private routingService: RoutingService,
        private securityService: SecurityService,
        private injector: Injector,
    ) {}

    showProfile(popover: PopoverDirective) {
        this.popover = popover;
        if (this.popover) {
            if (this.popover.isOpen) {
                this.popover.hide();
            } else {
                this.popover.show();
            }
        }
    }

    onClickOutside() {
        if (this.popover && this.popover.isOpen) {
            this.popover.hide();
        }
    }

    disconnectClick() {
        // Send parameters to the routing service for the U2011 to consume.
        this.routingService.addLogicalUnitParams<IU2011_LogicalUnitParams>('U2011', {
            returningUrl: null,
            targetTenantId: null,
        });
        this.authService.logoutAndRedirect();
    }

    selectOrganization() {
        const params: IOpenModalComponentParams = {
            component: U2011_OrganizationSelectionModalComponent,
            injector: this.injector,
        };
        this.modalService.openComponent(params);
    }

    get currentTenant() {
        return this.authService.currentTenant;
    }

    get agentTenants() {
        const list = [];
        let i: number;
        for (i = 0; i < this.authService.agentTenants.length; i++) {
            if (this.authService.agentTenants[i].id !== this.currentTenant.id) {
                list.push(this.authService.agentTenants[i]);
            }
            // Only show the first two tenants available.
            if (list.length === 2) {
                break;
            }
        }
        return list;
    }

    rowClick(organization: ICurrentAgentTenantDto, event: Event) {
        this.authService.increaseClickCounter(organization.id);

        this.authService.selectContext(organization.id).subscribe();
    }

    displayOtherOrganizations() {
        return !Array.isNullOrEmpty(this.authService.agentTenants) && this.authService.agentTenants.length > 3;
    }

    myAccountClick() {
        this.popover.hide();
    }

    myOrganizationClick() {
        this.popover.hide();
    }

    public allowedToViewMyOrganization(): boolean {
        return this.securityService.hasAccess('SPAI-29');
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { FormBaseComponent } from '../../../../../../gamma/form/form-base.component';
import { FormService } from '../../../../../../gamma/form/form.service';
import { FormRadioQuestion } from '../../../../../../gamma/form/formquestion/formquestion-radio';
import { FormValidationMessageService } from '../../../../../../gamma/form/formvalidationmessage.service';
import { RoutingService } from '../../../../../../gamma/routing';
import { ToastService } from '../../../../../../gamma/toast';
import { DomService } from '../../../../../../gamma/utils';

import { U2000_Animations, U2000_AuthenticationService, U2000_Validators } from '../../../../../U2000_core';
import { U2000_EmailQuestion } from '../../../../../U2000_questiondomains';
import { U2010_RadioGroupComponent } from '../../../../../U2010_authentication/U2010_radiogroup/U2010_radiogroup.component';
import { U2000_VerifyIdentityNavigationStepsEnum } from '../../U2000_verifyidentity';
import { U2000_VerifyIdentityService } from '../../U2000_verifyidentity.service';

@Component({
    selector: 'u2000-choosecommunication-form',
    templateUrl: 'U2000_choosecommunication-form.component.html',
    providers: [FormService],
    styleUrls: ['U2000_choosecommunication-form.component.scss'],
    animations: [U2000_Animations.slideInOut],
})
export class U2000_ChooseCommunicationFormComponent extends FormBaseComponent<U2000_VerifyIdentityService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'ChooseCommunication';

    qVerificationCodeTypeId: FormRadioQuestion<number>;
    qEmail: U2000_EmailQuestion;

    constructor(
        service: U2000_VerifyIdentityService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,

        private translateService: TranslateService,
        public authService: U2000_AuthenticationService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.qVerificationCodeTypeId = new FormRadioQuestion<number>({
            key: 'verificationCodeTypeId',
            defaultValue: 0,
            component: U2010_RadioGroupComponent,
            onChange: () => {
                this.setEmailValidators();
            },
        });

        this.qEmail = new U2000_EmailQuestion({
            key: 'confirmedEmail',
            placeholder: 'lblEmail',
            hideRequiredMarker: true,
            width: '100em',
        });
        this.qEmail.label = null;

        this.questions = [this.qVerificationCodeTypeId, this.qEmail];
    }

    ngOnInit() {
        this.qVerificationCodeTypeId.radioButtons = this.buildCommunicationMethodRadioButtons();
        this.initForm();
        this.setEmailValidators();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    buildCommunicationMethodRadioButtons() {
        const radioButtons = [];
        if (!String.isNullOrEmpty(this.service.componentConfigs.securityEmail)) {
            radioButtons.push({
                key: 0,
                value: 0,
                label: '<i class="fa fa-phone md-margin-right sm-margin-left"></i>' + this.translateService.instant('lblEmail') + ': ' + this.service.componentConfigs.securityEmail,
            });
        }
        if (!String.isNullOrEmpty(this.service.componentConfigs.mobilePhoneNumber)) {
            radioButtons.push({
                key: 1,
                value: 1,
                label: '<i class="fa fa-at md-margin-right sm-margin-left"></i>' + this.translateService.instant('lblSMS') + ': ' + this.service.componentConfigs.mobilePhoneNumber,
            });
        }
        return radioButtons;
    }

    onSubmit() {
        this.service.clearAlerts();
        this.showErrors(this.form.controls);
        if (this.form.valid) {
            const request: IU2000_VerifyIdentitySendCodeDtoRequest = this.form.value;

            this.service.sendVerificationCode(request).subscribe(
                res => {
                    this.resetForm();
                    this.service.navigateTo(U2000_VerifyIdentityNavigationStepsEnum.verifyCode);
                },
                (res: HttpErrorResponse) => {
                    this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                        this.service.addAlert({ type: 'danger', message: validationError.message });
                        return true;
                    });
                },
            );
        }
    }

    setEmailValidators() {
        if (this.mustConfirmEmail()) {
            this.qEmail.validators = [Validators.required, U2000_Validators.email];
        } else {
            this.qEmail.validators = [];
        }
    }

    mustConfirmEmail(): boolean {
        return this.qVerificationCodeTypeId.value === 0 && this.service.componentConfigs.mustConfirmEmail;
    }
}

import { FormQuestionType } from '../form.service';
import { FormQuestionControlBase, IFormQuestionControlBase } from './formquestioncontrol-base';

/**
 * Interface to define a checkbox question.
 *
 * @export
 * @interface IFormCheckboxQuestion
 * @extends {IFormQuestionControlBase<boolean>}
 */
export interface IFormCheckboxQuestion extends IFormQuestionControlBase<boolean> {
    /**
     * Alternate label displayed beside the check box.
     *
     * @type {string}
     * @memberof IFormCheckboxQuestion
     */
    alternateLabel?: string;

    /**
     * Display the checkbox in a more condensed way (To use to when you want to regroup )
     *
     * @type {boolean}
     * @memberof IFormCheckboxQuestion
     */
    condensed?: boolean;

    alternateLabelEvents?: { context: any; event: () => void }[];
}

/**
 * Checkbox question type.
 *
 * @export
 * @class FormCheckboxQuestion
 * @extends {FormQuestionControlBase<boolean>}
 */
export class FormCheckboxQuestion extends FormQuestionControlBase<boolean> {
    override formQuestionType = FormQuestionType.Checkbox;

    /**
     * Alternate label displayed beside the check box.
     *
     * @type {string}
     * @memberof IFormCheckboxQuestion
     */
    alternateLabel: string;

    /**
     * Display the checkbox in a more condensed way (To use to when you want to regroup )
     *
     * @type {boolean}
     * @memberof IFormCheckboxQuestion
     */
    condensed: boolean;

    alternateLabelEvents: { context: any; event: () => void }[];

    /**
     * Creates an instance of FormCheckboxQuestion.
     * @param {IFormQuestionControlBase<boolean>} [options={}] Creation options.
     *
     * @memberof FormCheckboxQuestion
     */
    constructor(options: IFormCheckboxQuestion = {}) {
        super(options);
        this.alternateLabel = options.alternateLabel;
        this.condensed = options.condensed || false;
        this.alternateLabelEvents = options.alternateLabelEvents || [];
    }
}

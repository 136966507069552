import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { LogicalUnitBaseService } from '../../../../gamma/logicalunit';

import { U2000_NavWizardStep } from './U2000_navwizardstep';

export interface IU2000_NavWizardStepChange {
    step: U2000_NavWizardStep;
    index: number;
}

@Component({
    selector: 'u2000-navwizard',
    templateUrl: './U2000_navwizard.component.html',
    styleUrls: ['./U2000_navwizard.component.scss'],
})
export class U2000_NavWizardComponent implements OnInit {
    @Input() steps: U2000_NavWizardStep[];
    @Input() format: 'vertical' | 'horizontal' = 'horizontal';
    @Input() error: (step: U2000_NavWizardStep) => boolean;
    @Input() service: LogicalUnitBaseService;
    @Output() stepChange: EventEmitter<IU2000_NavWizardStepChange> = new EventEmitter<IU2000_NavWizardStepChange>();

    constructor(private router: Router, private location: Location) {
        this.steps = [];
    }

    ngOnInit() {
        this.steps.forEach(step => {
            step.navWizard = this;
        });
        this.first();
    }

    getActiveOnRoute() {
        const route = this.location.path();
        this.steps.some(x => {
            if (x.route === route) {
                x.active = true;
                return true;
            } else {
                return false;
            }
        });
    }

    gotoRoute(route: string) {
        this.router.navigate([route]);
    }

    get currentStep() {
        return this.steps.find(x => {
            if (x.active === true) {
                return true;
            } else {
                return false;
            }
        });
    }

    set currentStep(step: U2000_NavWizardStep) {
        step.active = true;
    }

    get currentStepIndex() {
        return this.steps.findIndex(x => this.currentStep === x);
    }

    gotoIndex(ind: number) {
        if (ind >= 0 && ind < this.steps.length) {
            this.steps[ind].active = true;
        } else {
            console.error(`gotoStepIndex cannot got to index ${ind} because it does not exist.`);
        }
    }

    first() {
        if (!Array.isNullOrEmpty(this.steps)) {
            this.steps[0].active = true;
        }
    }

    last() {
        this.steps[this.steps.length - 1].active = true;
    }

    previous() {
        const step = this.prevStep;
        if (step) {
            step.active = true;
        }
    }

    next() {
        const step = this.nextStep;
        if (step) {
            step.active = true;
        }
    }

    noStep() {
        this.steps.forEach(x => {
            x.active = false;
        });
    }

    get prevStep() {
        let i: number;
        const step = this.currentStep;
        if (step == null) {
            return null;
        }
        i = this.steps.findIndex(x => step === x);
        for (i = i - 1; i >= 0; i--) {
            if (this.steps[i].visible === true) {
                return this.steps[i];
            }
        }
        return null;
    }

    get nextStep() {
        let i: number;
        const step: U2000_NavWizardStep = this.currentStep;
        if (step == null) {
            return null;
        }
        i = this.steps.findIndex(x => step === x);
        for (i = i + 1; i < this.steps.length; i++) {
            if (this.steps[i].visible === true) {
                return this.steps[i];
            }
        }
        return null;
    }
}

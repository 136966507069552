import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { PopoverDirective } from 'ngx-bootstrap/popover';

import { I18nService } from '../../../../../i18n';
import { L10nService } from '../../../../../l10n';
import { DateService } from '../../../../../utils';
import { CustomDates, FormDateRangeQuestion } from '../../../formquestion-daterange';

@Component({
    selector: 'form-daterange-input-bootstrap',
    templateUrl: './daterange-input.component.html',
    styleUrls: ['./daterange-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormDateRangeInputBootstrapComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormDateRangeInputBootstrapComponent),
            multi: true,
        },
    ],
})
export class FormDateRangeInputBootstrapComponent implements ControlValueAccessor, Validator, AfterViewInit {
    @ViewChild('dp', { static: true }) dateRangePicker: PopoverDirective;
    @Input() question: FormDateRangeQuestion;
    @Input() form: UntypedFormGroup;

    bsConfig: Partial<BsDaterangepickerConfig>;
    value: any;
    dateError = false;

    constructor(
        //
        private i18nService: I18nService,
        protected datePipe: DatePipe,
        private l10nService: L10nService,
        private localeService: BsLocaleService,
        private translateService: TranslateService,
        private dateService: DateService,
    ) {
        this.value = null;

        this.translateService.setTranslation(
            'fr',
            {
                sysTo: 'au',
                sysToday: `Aujourd'hui`,
                sysYesterday: 'Hier',
                sysLast7Days: '7 derniers jours',
                sysLast30Days: '30 derniers jours',
                sysLast90Days: '90 derniers jours',
            },
            true,
        );
        this.translateService.setTranslation(
            'en',
            {
                sysTo: 'to',
                sysToday: `Today`,
                sysYesterday: 'Yesterday',
                sysLast7Days: 'Last 7 days',
                sysLast30Days: 'Last 30 days',
                sysLast90Days: 'Last 90 days',
            },
            true,
        );
        this.bsConfig = {
            dateInputFormat: 'YYYY-MM-DD,h:mm:ss a',
            showWeekNumbers: false,
            containerClass: 'theme-acceo',
            todayPosition: 'left',
            customTodayClass: 'bg-light',
        };

        this.localeService.use(this.l10nService.localeId);
    }

    ngAfterViewInit() {
        let ranges: CustomDates[] = [];
        const today = this.dateService.getTodayDateWithoutTime();
        ranges = [
            {
                key: 'today',
                value: [today, today],
                label: this.translateService.instant('sysToday'),
            },
            {
                key: 'yesterday',
                value: [new Date(new Date().setDate(today.getDate() - 1)), new Date(new Date().setDate(today.getDate() - 1))],
                label: this.translateService.instant('sysYesterday'),
            },
            {
                key: 'last7Days',
                value: [new Date(new Date().setDate(today.getDate() - 7)), today],
                label: this.translateService.instant('sysLast7Days'),
            },
            {
                key: 'last30Days',
                value: [new Date(new Date().setDate(today.getDate() - 30)), today],
                label: this.translateService.instant('sysLast30Days'),
            },
            {
                key: 'last90Days',
                value: [new Date(new Date().setDate(today.getDate() - 90)), today],
                label: this.translateService.instant('sysLast90Days'),
            },
        ];
        if (this.question.ranges != null) {
            ranges = ranges.concat(this.question.ranges);
        }

        this.bsConfig.ranges = ranges;
        this.bsConfig.adaptivePosition = true;
    }

    writeValue(value: any): void {
        // Fix a bug introduced in the ngx-boostrap. The value was overriden by the affectation of the bsConfig initialization.
        setTimeout(() => {
            if (String.isNullOrEmpty(value)) {
                value = null;
            }

            if (value != null && Object.keys(value).length > 0) {
                this.value = { dateFrom: value[0], dateTo: value[1] };
            } else {
                this.value = null;
            }
        }, 0);
    }
    setDisabledState?(isDisabled: boolean): void {}

    onDatePickerChange(value: any) {
        this.convertValue(value);
        this.onTouched();

        setTimeout(() => {
            const evt = document.createEvent('HTMLEvents');

            evt.initEvent('change', false, true);
            if (this.question.onChange != null) {
                this.question.onChange(evt, this.question);
            }
        }, 0);
    }

    onQuestionChange(event: Event) {
        if (this.question.onChange != null) {
            this.question.onChange(event, this.question);
        }
    }

    onClearButtonClick() {
        this.onDatePickerChange(null);
        if (this.dateRangePicker.isOpen) {
            this.dateRangePicker.hide();
        }
    }

    onSearchButtonClick() {
        this.toggleVisibility();
    }

    toggleVisibility() {
        this.dateRangePicker.show();
    }

    hideDatePicker() {
        if (this.dateRangePicker.isOpen) {
            this.dateRangePicker.hide();
        }
    }

    private convertValue(value: Date[], propagateChange = true) {
        try {
            if (value != null) {
                this.value = { dateFrom: value[0], dateTo: value[1] };
            } else {
                this.value = null;
            }
            this.dateError = false;
        } catch (e) {
            this.value = null;
            this.dateError = true;
        }
        if (propagateChange) {
            this.propagateChange(value);
        }
    }

    formattedValue() {
        if (this.value != null && this.value.dateFrom != null && this.value.dateTo != null) {
            return this.datePipe.transform(this.value.dateFrom, 'yyyy-MM-dd') + ' ' + this.translateService.instant('sysTo') + ' ' + this.datePipe.transform(this.value.dateTo, 'yyyy-MM-dd');
        }
        return null;
    }

    private propagateChange = (_: any) => {
        /* Must be empty */
    };

    onTouched = () => {
        /* Must be empty */
    };

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /**
     * Form the question label with the i18n service.
     *
     * @param {string} label Label to format.
     * @returns {string} The formatted label.
     *
     * @memberof FormQuestionBaseComponent
     */
    formatLabel(label: string): string {
        return this.i18nService.formatLabel(label);
    }

    get componentId() {
        return this.question.idPrefix + '-' + this.question.key.toLowerCase();
    }

    validate(c: UntypedFormControl) {
        return !this.dateError ? null : { validDate: false };
    }
}

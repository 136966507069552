import { Directive, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { LogicalUnitBaseService } from '../../logicalunit/logicalunit-base.service';
import { ToastService } from '../../toast/toast.service';

export interface IFromControlValidationError {
    [code: string]: true;
}

/**
 * Base class user for form components.
 *
 * @export
 * @class BaseComponent
 * @template T
 */
@Directive()
export abstract class BaseComponent<T = any> implements OnDestroy {
    // Subject used to clean all subscriptions on destroy.
    protected ngUnsubscribe = new Subject();

    onDestroy: () => void;

    constructor(public service: T, protected toastService: ToastService) {}

    ngOnDestroy(): void {
        // Clean all subscriptions.
        this.ngUnsubscribe.next(0);
        this.ngUnsubscribe.complete();
    }

    /**
     * Automatic handle of servers errors. All non handle errors are going to be display in a warning toast.
     *
     * @param {IErrorResponse} response Response received from the server.
     * @param {(serverError: IErrorElement) => boolean} [handle]
     *
     * @memberof BaseComponent
     * @example
     *      // Way to handle business errors comming from server.
     *      this.service.callServerAPI(U9999_AnyDto).subscribe(res => {
     *           // Your success code here.
     *      }, res => {
     *          this.handleServerBusinessError(res,
     *              (serverError: IErrorElement): boolean => {
     *                  if (validationError.code === 'U9999-0001') {
     *                      this.toastService.error('U9999-0001');
     *
     *                      // Tells that this error is handled and may not pop the warning toast.
     *                      return true;
     *                  }
     *
     *          // Tells that this error is not handled and may pop the warning toast.
     *          return false;
     *      });
     */
    public handleServerBusinessError<U extends { errors: IErrorElement[] }>(response: U, handle?: (serverError: IErrorElement) => boolean) {
        if (response && response.errors) {
            let i: number;
            let max: number = response.errors.length;
            if (handle != null) {
                for (i = max - 1; i >= 0; i--) {
                    if (handle(response.errors[i]) === true) {
                        response.errors.splice(i, 1);
                    }
                }
            }

            max = response.errors.length;
            for (i = max - 1; i >= 0; i--) {
                this.toastService.error(response.errors[i].message);
            }
        }
    }

    /**
     * Automatic handle of servers warning. All non handle warning are going to be display in a warning toast.
     *
     * @param {IErrorResponse} response Response received from the server.
     * @param {(serverError: IErrorElement) => boolean} [handle]
     *
     * @memberof BaseComponent
     * @example
     *      // Way to handle warning coming from server.
     *      this.service.callServerAPI(U9999_AnyDto).subscribe(res => {
     *           // Your success code here.
     *      }, res => {
     *          this.handleServerWarning(res,
     *              (serverWarning: any): boolean => {
     *                  if (serverWarning.code === 'U9999-0001') {
     *                      this.toastService.warning('U9999-0001');
     *
     *                      // Tells that this warning is handled and may not pop the warning toast.
     *                      return true;
     *                  }
     *
     *          // Tells that this error is not handled and may pop the warning toast.
     *          return false;
     *      });
     */
    public handleServerWarning<U extends { warnings?: any[] }>(response: U, handle?: (serverWarning: any) => boolean) {
        if (response && response.warnings) {
            let i: number;
            let max: number = response.warnings.length;
            if (handle != null) {
                for (i = max - 1; i >= 0; i--) {
                    if (handle(response.warnings[i]) === true) {
                        response.warnings.splice(i, 1);
                    }
                }
            }

            max = response.warnings.length;
            for (i = max - 1; i >= 0; i--) {
                this.toastService.warning(response.warnings[i].message);
            }
        }
    }

    /**
     * Logical unit id of the currently used logical unit.
     *
     * @readonly
     *
     * @memberof BaseComponent
     */
    get logicalUnitId() {
        if (this.service instanceof LogicalUnitBaseService) {
            return this.service.logicalUnitId;
        } else {
            return '';
        }
    }
}

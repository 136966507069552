import { Component, Input } from '@angular/core';

@Component({
    selector: 'u2000-separator',
    templateUrl: './U2000_separator.component.html',
    styleUrls: ['./U2000_separator.component.scss'],
})
export class U2000_SeparatorComponent {
    @Input() label: string;
    @Input() backgroundColor: string;

    constructor() {}
}

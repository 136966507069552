import { Component, OnInit } from '@angular/core';

import { DialogRef, ModalComponent } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

import { BaseComponent } from '../../../gamma/core/components/base.component';
import { ToastService } from '../../../gamma/toast';

import { U2000_Animations } from '../../U2000_core';
import { U2000_AuthenticationService } from '../../U2000_core/U2000_services/U2000_authentication.service';
import { U2011_LoginService } from '../U2011_login.service';

@Component({
    selector: 'u2011-organizationselection-modal',
    templateUrl: 'U2011_organizationselection-modal.component.html',
    styleUrls: ['U2011_organizationselection-modal.component.scss'],
    animations: [U2000_Animations.slideInOut],
})
export class U2011_OrganizationSelectionModalComponent extends BaseComponent<U2011_LoginService> implements ModalComponent<BSModalContext>, OnInit {
    MAX_ORGANIZATIONS = 15;
    organizations: ICurrentAgentTenantDto[];
    filteredOrganizations: ICurrentAgentTenantDto[];
    collapsed = true;

    constructor(public dialog: DialogRef<BSModalContext>, service: U2011_LoginService, toastService: ToastService, private authService: U2000_AuthenticationService) {
        super(service, toastService);
        dialog.context.isBlocking = false;
        dialog.context.showClose = true;
        dialog.context.dialogClass = 'modal-dialog u2111-select-organization-modal';
    }

    ngOnInit() {
        this.organizations = this.getOrganizations();
        this.filteredOrganizations = this.organizations;
    }

    rowClick(organization: ICurrentAgentTenantDto, event: Event) {
        this.authService.increaseClickCounter(organization.id);

        this.authService.selectContext(organization.id).subscribe({
            complete: () => {
                this.dialog.close(true);
            },
        });
    }

    closeClick() {
        this.dialog.close(true);
    }

    getOrganizations() {
        if (this.authService.currentTenant != null) {
            const list = [];
            let i: number;
            for (i = 0; i < this.authService.agentTenants.length; i++) {
                if (this.authService.agentTenants[i].id !== this.authService.currentTenant.id) {
                    list.push(this.authService.agentTenants[i]);
                }
            }
            return list;
        } else {
            return this.authService.agentTenants;
        }
    }

    getFilteredOrganizations(filter = '') {
        const list = [];
        let i: number;
        for (i = 0; i < this.organizations.length; i++) {
            if (!String.isNullOrEmpty(filter)) {
                if (this.organizations[i].name.toLocaleLowerCase().replace(/ /g, '').indexOf(filter.toLocaleLowerCase().replace(/ /g, '')) !== -1) {
                    list.push(this.organizations[i]);
                }
            } else {
                list.push(this.organizations[i]);
            }
        }
        return list;
    }

    getOrganizationNames() {
        return this.organizations.map(x => x.name);
    }

    onFilterChange(filter = '') {
        this.filteredOrganizations = this.getFilteredOrganizations(filter);
    }

    showMoreOrganizations() {
        this.collapsed = !this.collapsed;
    }
}

import { FormQuestionType } from '../form.service';
import { FormQuestionControlBase, IFormQuestionControlBase } from './formquestioncontrol-base';

/**
 * Interface to define an hidden question.
 *
 * @export
 * @interface IFormHiddenQuestion
 * @extends {IFormQuestionControlBase<T>}
 * @template T Type of the value.
 */
export type IFormHiddenQuestion<T> = IFormQuestionControlBase<T>;

/**
 * Hidden question type.
 *
 * @export
 * @class FormHiddenQuestion
 * @extends {FormQuestionControlBase<T>}
 * @template T
 */
export class FormHiddenQuestion<T> extends FormQuestionControlBase<T> {
    override formQuestionType = FormQuestionType.Hidden;

    /**
     * Creates an instance of FormHiddenQuestion.
     * @param {IFormHiddenQuestion<T>} [options={}]  Creation options.
     *
     * @memberof FormHiddenQuestion
     */
    constructor(options: IFormHiddenQuestion<T> = {}) {
        super(options);
    }
}

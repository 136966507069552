import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { I18nService } from '../../../../i18n';
import { UserAgentService } from '../../../../utils/user-agent.service';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormTypeheadQuestion } from '../../formquestion-typehead';

/*
this.qSearch = new FormTypeheadQuestion({
            key: 'search',
            placeholder: 'lblSearch',
            getDataAsObservableFn: (token) => {
                return of(this.organizations);
            },
            getDataAsObservableSrc: this,
            typeaheadOptionField: 'name',
            onInput: (x => {
                this.filterChange.emit(this.qSearch.value);
            }),
            onChange: (x => {
                this.filterChange.emit(this.qSearch.value);
            }),
            onKeyPress: (x => {
                this.filterChange.emit(this.qSearch.value);
            })

        });
*/

@Component({
    selector: 'form-typehead-bootstrap',
    templateUrl: './typehead.component.html',
    styleUrls: ['./typehead.component.scss'],
})
export class FormTypeheadBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormTypeheadQuestion;
    @Input() override form: UntypedFormGroup;

    asyncSelected: string;
    typeaheadLoading: boolean;
    typeaheadNoResults: boolean;
    dataSource: Observable<any>;

    // 8 = Backspace,       9 = tab,            33 = page up,           34 = page down,
    // 35 = end,            36 = home,          37 = left arrow,        38 = up arrow,
    // 39 = right arrow,    40 = down arrow,    46 = Delete
    private RESTRICTION_FREE_KEYBOARD_KEYS = [8, 9, 33, 34, 35, 36, 37, 38, 39, 40, 46];

    constructor(i18nService: I18nService, private el: ElementRef, private userAgent: UserAgentService) {
        super(i18nService);

        this.dataSource = Observable.create((observer: any) => {
            // Runs on every search
            observer.next(this.question.formControlRef.value);
        }).pipe(
            mergeMap((token: string) => {
                const boundFunction = this.question.getDataAsObservableFn.bind(this.question.getDataAsObservableSrc);
                return boundFunction(token);
            }),
        );
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    changeTypeaheadNoResults(e: boolean): void {
        this.typeaheadNoResults = e;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        if (this.question.selected) {
            this.question.selected(e.item);
        }
    }

    onKeyPress(event: KeyboardEvent) {
        if (this.shoudBypassSpecialCharacters(event)) {
            return null;
        }

        if (this.question.restrict !== null) {
            if (!event.key.match(this.question.restrict)) {
                event.preventDefault();
            }
        }
    }

    onKeyDownEnter(event: Event) {
        const input: HTMLInputElement = event.target as any;
        // On mobile, shut the virtual keyboard if a submit is launched.
        if (this.userAgent.isMobile()) {
            input.blur();
        }

        this.question.onKeyDownEnter();
    }

    /**
     * This is the fix for certains browsers(firefox and mozilla) where the key pressed of special characters are rejected by the filter.
     * use to filter out specific characters on firefox and mozilla from  the list to restrict.
     *
     * @export
     * @abstract
     * @method FormQuestionBaseComponent
     */
    shoudBypassSpecialCharacters(event: KeyboardEvent): boolean {
        if (this.userAgent.isFirefox() || this.userAgent.isMozilla()) {
            for (const key of this.RESTRICTION_FREE_KEYBOARD_KEYS) {
                if (event.keyCode === key) {
                    return true;
                }
            }
        }
        if (event.key === undefined) {
            return true;
        }

        return false;
    }
}

import { Component } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_VerifyIdentityService } from '../U2000_verifyidentity.service';

@Component({
    selector: 'u2000-completed',
    templateUrl: 'U2000_completed.component.html',
    styleUrls: ['U2000_completed.component.scss'],
})
export class U2000_CompletedComponent extends BaseComponent<U2000_VerifyIdentityService> {
    constructor(
        public override service: U2000_VerifyIdentityService,
        // Super requirements
        toastService: ToastService,
    ) {
        super(service, toastService);
    }
    done() {
        /*let completeRequest: IU2014_CompleteDtoRequest = {};
        completeRequest.id = this.service.individual.id;
        completeRequest.versionNo = this.service.individual.versionNo;*/
        /* this.service.complete(completeRequest).subscribe(res => {
            this.onCancel();
        }); */
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { HttpErrorService, HttpErrorServiceConfig, IHttpErrorProvider } from './http-error.service';
import { HttpService } from './http.service';

/**
 * Gamma http module.
 *
 * @export
 * @class GammaHttpModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [HttpErrorService, HttpService],
})
export class GammaHttpModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof GammaHttpModule
     */
    static withConfig(config: IServiceProviderConfig<IHttpErrorProvider> = {}): ModuleWithProviders<GammaHttpModule> {
        return {
            ngModule: GammaHttpModule,
            providers: [{ provide: HttpErrorServiceConfig, useValue: config }],
        };
    }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { U2000_AuthenticationService } from '../../../../U2000_core/U2000_services/U2000_authentication.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[u2000-reportmenu]',
    templateUrl: 'U2000_reportmenu.component.html',
    styleUrls: ['U2000_reportmenu.component.scss'],
})
export class U2000_ReportMenuComponent {
    constructor(public authService: U2000_AuthenticationService, public router: Router) {}

    showReports() {
        this.router.navigate(['U2024']);
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { FormBaseComponent } from '../../../../../../gamma/form/form-base.component';
import { FormService } from '../../../../../../gamma/form/form.service';
import { FormTextboxQuestion } from '../../../../../../gamma/form/formquestion/formquestion-textbox';
import { FormValidationMessageService } from '../../../../../../gamma/form/formvalidationmessage.service';
import { ReferentialService } from '../../../../../../gamma/referential';
import { RoutingService } from '../../../../../../gamma/routing';
import { ToastService } from '../../../../../../gamma/toast';
import { DomService } from '../../../../../../gamma/utils';

import { U2000RF_SecurityQuestions, U2000_AuthenticationService } from '../../../../../U2000_core';
import { U2000_VerifyIdentityService } from '../../U2000_verifyidentity.service';

@Component({
    selector: 'u2000-securityquestion-form',
    templateUrl: 'U2000_securityquestion-form.component.html',
    providers: [FormService],
    styleUrls: ['U2000_securityquestion-form.component.scss'],
})
export class U2000_SecurityQuestionFormComponent extends FormBaseComponent<U2000_VerifyIdentityService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'SecurityQuestion';

    visibleSecurityQuestionId = 1;

    qSecurityAnswer1: FormTextboxQuestion;
    qSecurityAnswer2: FormTextboxQuestion;

    constructor(
        service: U2000_VerifyIdentityService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,

        public authService: U2000_AuthenticationService,
        private referentialService: ReferentialService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.qSecurityAnswer1 = new FormTextboxQuestion({
            key: 'securityAnswer1',
            placeholder: 'lblSecQuestionAnswer',
            maxLength: 128,
            validators: [Validators.required],
            hideRequiredMarker: true,
            autoFocus: true,
        });

        this.qSecurityAnswer2 = new FormTextboxQuestion({
            key: 'securityAnswer2',
            placeholder: 'lblSecQuestionAnswer',
            maxLength: 128,
            hideRequiredMarker: true,
        });

        this.questions = [this.qSecurityAnswer1, this.qSecurityAnswer2];
    }

    ngOnInit() {
        this.qSecurityAnswer1.label = this.referentialService.get<ILanguageDto>(U2000RF_SecurityQuestions).find(x => x.id === this.service.componentConfigs.securityQuestion1Id).labels[0].description;
        this.qSecurityAnswer2.label = this.referentialService.get<ILanguageDto>(U2000RF_SecurityQuestions).find(x => x.id === this.service.componentConfigs.securityQuestion2Id).labels[0].description;
        this.initForm();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    onSubmit() {
        this.service.clearAlerts();
        this.showErrors(this.form.controls);
        if (this.form.valid) {
            const validateSecurityQuestionRequest: IU2014_ValidateSecurityQuestionDtoRequest = this.form.value;
            validateSecurityQuestionRequest.securityQuestion1Id = this.service.componentConfigs.securityQuestion1Id;
            validateSecurityQuestionRequest.securityQuestion2Id = this.service.componentConfigs.securityQuestion2Id;
            this.service.validateSecurityQuestion(validateSecurityQuestionRequest).subscribe(
                res => {
                    this.resetForm();
                    this.service.onValid();
                    // this.service.navigateTo(U2000_VerifyIdentityNavigationStepsEnum.completed);
                },
                (res: HttpErrorResponse) => {
                    this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                        if (validationError.code === 'U2000-0021' || validationError.code === 'U2000-0022') {
                            this.service.error.emit(res);
                        } else {
                            this.service.addAlert({ type: 'danger', message: validationError.message });
                        }
                        return true;
                    });
                },
            );
        }
    }

    toggleSecurityQuestion() {
        if (this.visibleSecurityQuestionId === 1) {
            this.qSecurityAnswer1.validators = [];
            this.qSecurityAnswer1.formControlRef.setValue(null);
            this.qSecurityAnswer2.validators = [Validators.required];
            this.visibleSecurityQuestionId = 2;
        } else {
            this.qSecurityAnswer2.validators = [];
            this.qSecurityAnswer2.formControlRef.setValue(null);
            this.qSecurityAnswer1.validators = [Validators.required];
            this.visibleSecurityQuestionId = 1;
        }
    }
}

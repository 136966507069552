import { forkJoin } from 'rxjs';

import { LogicalUnitBaseService } from '../../../gamma/logicalunit';

import { U2000_TranslatePartialLoader } from '../../U2000_core';

/* Mixin implementation (To copy to the file implementing the mixin)
    // Translate mixin requirements
    initTranslation: () => Observable<any>;
*/

/* Mixin constructor implementation (To copy to the constructor class implementing the mixin)
    // Translate mixin requirements
    public translatePartialLoader: U2000_TranslatePartialLoader,
*/

export class U2000_TranslateServiceMixin implements ITranslateServiceMixin {
    translatePartialLoader: U2000_TranslatePartialLoader;

    /*initTranslation(): Observable<any> {
        let service = (<LogicalUnitBaseService><any>this);
        return this.translatePartialLoader.getPartialTranslation(service.logicalUnitName);
    }*/

    initTranslation() {
        const service = this as any as LogicalUnitBaseService;
        const translationsObs = [];

        service.translationParts.forEach(part => {
            translationsObs.push(this.translatePartialLoader.getPartialTranslation(part));
        });

        return forkJoin(translationsObs);
    }
}

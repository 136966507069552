import { NgModule } from '@angular/core';

import { BootstrapResolutionService } from './bootstrapresolution.service';

/**
 * Bootstrap module.
 *
 * @export
 * @class BootstrapModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [BootstrapResolutionService],
    bootstrap: [],
})
export class BootstrapModule {}

import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { I18nService } from '../i18n';

export const ToastServiceConfig = new InjectionToken<IServiceProviderConfig<IToastProvider>>('TOAST_SERVICE_CONFIG');

/**
 * Interface that defines the toast provider.
 *
 * @export
 * @interface IToastProvider
 * @extends {IProvider<ToastService>}
 */
export interface IToastProvider extends IProvider<ToastService> {
    /**
     * Success toast (Green);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof IToastProvider
     */
    success(message: string, title?: string, options?: any, clickHandler?: any): void;
    /**
     * Error toast (Red);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof IToastProvider
     */
    error(message: string, title?: string, options?: any, clickHandler?: any): void;
    /**
     * Warning toast (Orange);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof IToastProvider
     */
    warning(message: string, title?: string, options?: any, clickHandler?: any): void;
    /**
     * Information toast (Bleu);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof IToastProvider
     */
    info(message: string, title?: string, options?: any, clickHandler?: any): void;
    /**
     * Custom toast;
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof IToastProvider
     */
    custom(message: string, title?: string, options?: any, clickHandler?: any): void;
}

@Injectable()
export class ToastService extends ServiceProviderBase<IToastProvider, ToastService> {
    i18nService: I18nService;

    constructor(private injector: Injector, @Inject(ToastServiceConfig) config: IServiceProviderConfig<IToastProvider>) {
        super(injector, config);

        this.i18nService = this.injector.get(I18nService);
    }
    /**
     * Success toast (Green);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof ToastService
     */
    success(message: string, title?: string, options?: any, clickHandler?: any) {
        if (this.provider != null) {
            message = this.i18nService.formatLabel(message);
            title = this.i18nService.formatLabel(title);
            this.provider.success(message, title, options, clickHandler);
        } else {
            throw new Error('You must register a provider for the ToastService.');
        }
    }
    /**
     * Error toast (Red);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof ToastService
     */
    error(message: string, title?: string, options?: any, clickHandler?: any) {
        if (this.provider != null) {
            message = this.i18nService.formatLabel(message);
            title = this.i18nService.formatLabel(title);
            this.provider.error(message, title, options, clickHandler);
        } else {
            throw new Error('You must register a provider for the ToastService.');
        }
    }
    /**
     * Information toast (Bleu);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof ToastService
     */
    warning(message: string, title?: string, options?: any, clickHandler?: any) {
        if (this.provider != null) {
            message = this.i18nService.formatLabel(message);
            title = this.i18nService.formatLabel(title);
            this.provider.warning(message, title, options, clickHandler);
        } else {
            throw new Error('You must register a provider for the ToastService.');
        }
    }
    /**
     * Information toast (Bleu);
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof ToastService
     */
    info(message: string, title?: string, options?: any, clickHandler?: any) {
        if (this.provider != null) {
            message = this.i18nService.formatLabel(message);
            title = this.i18nService.formatLabel(title);
            this.provider.info(message, title, options, clickHandler);
        } else {
            throw new Error('You must register a provider for the ToastService.');
        }
    }
    /**
     * Custom toast;
     *
     * @param {string} message Message of the toast.
     * @param {string} [title] Title of the toast.
     * @param {*} [options] Options of the toast.
     *
     * @memberof ToastService
     */
    custom(message: string, title?: string, options?: any, clickHandler?: any) {
        if (this.provider != null) {
            message = this.i18nService.formatLabel(message);
            title = this.i18nService.formatLabel(title);
            this.provider.custom(message, title, options, clickHandler);
        } else {
            throw new Error('You must register a provider for the ToastService.');
        }
    }
}

import { Injectable } from '@angular/core';

import { I18nService } from '../../../gamma/i18n';

const FINANCIAL_INSTITUTION_LOGO_PATH = 'assets/images/financial_institutions/';
const CREDIT_CARD_LOGO_PATH = 'assets/images/credit_cards/';
const APPLICATION_TYPE_LOGO_PATH = 'assets/images/application_types/';

@Injectable()
export class U2000_AssetsService {
    constructor(private i18nService: I18nService) {}

    getFinancialInstitutionLogo(financialInstitutionCode: string): string {
        return this._getFinancialInstitutionLogo(financialInstitutionCode, 'normal');
    }

    getFinancialInstitutionLogoSquare(financialInstitutionCode: string): string {
        return this._getFinancialInstitutionLogo(financialInstitutionCode, 'square');
    }

    getFinancialInstitutionLogoWhite(financialInstitutionCode: string): string {
        return this._getFinancialInstitutionLogo(financialInstitutionCode, 'white');
    }

    private _getFinancialInstitutionLogo(financialInstitutionCode: string, type: 'normal' | 'square' | 'white'): string {
        let path: string;
        let code = financialInstitutionCode;

        if (String.isNullOrEmpty(code)) {
            path = `${FINANCIAL_INSTITUTION_LOGO_PATH}logo-unknown.svg`;
        } else {
            if (code.toUpperCase() === 'FLINKSCAPITAL') {
                code = 'ACCEOBANK';
            }
            path = FINANCIAL_INSTITUTION_LOGO_PATH;
            if (type === 'normal') {
                path = path + `logo-${code.toLowerCase()}-${this.i18nService.currentLanguageCode}.svg`;
            } else if (type === 'square') {
                path = path + `logo-${code.toLowerCase()}-${type}.svg`;
            } else if (type === 'white') {
                path = path + `logo-${code.toLowerCase()}-${type}-${this.i18nService.currentLanguageCode}.svg`;
            }
        }
        return path;
    }

    getCreditCardLogo(paymentMode: IPaymentModeEnum) {
        if (String.isNullOrEmpty(paymentMode)) {
            paymentMode = 'UNKNOWN';
        }
        return `${CREDIT_CARD_LOGO_PATH}${paymentMode.toLowerCase()}.svg`;
    }

    getApplicationTypeLogoSquare(applicationTypeId: string) {
        return `${APPLICATION_TYPE_LOGO_PATH}logo-${applicationTypeId.toLowerCase()}-square.svg`;
    }

    getApplicationTypeLogo(applicationTypeId: string) {
        return `${APPLICATION_TYPE_LOGO_PATH}logo-${applicationTypeId.toLowerCase()}.svg`;
    }
}

import { Observable } from 'rxjs';

import { FormQuestionType } from '../form.service';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormTypeheadQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormTypeheadQuestion extends IFormQuestionControlInputBase<string> {
    getDataAsObservableFn: (token: string) => Observable<any>;
    getDataAsObservableSrc: any;
    typeaheadOptionField?: string;
    typeaheadGroupField?: string;
    typeaheadOptionsLimit?: number;
    typeaheadMinLength?: number;
    typeaheadScrollable?: boolean;
    typeaheadOptionsInScrollableView?: number;
    selected?: (item: any) => void;
    onKeyDownEnter?: () => void;

    /**
     * Restrict.
     *
     * @type {RegExp}
     */
    restrict?: RegExp;
}

/**
 * Typehead question type.
 *
 * @export
 * @class FormTypeheadQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormTypeheadQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.Typehead;

    /**
     * Restrict.
     *
     * @type {RegExp}
     * @memberof FormTextboxQuestion
     */
    restrict?: RegExp;

    getDataAsObservableFn: (token: string) => Observable<any>;
    getDataAsObservableSrc: any;
    typeaheadOptionField: string;
    typeaheadGroupField: string;
    typeaheadOptionsLimit: number;
    typeaheadMinLength: number;
    typeaheadScrollable: boolean;
    typeaheadOptionsInScrollableView: number;
    selected?: (item: any) => void;
    override onKeyDownEnter: () => void;

    /**
     * Creates an instance of FormTextboxQuestion.
     * @param {IFormTypeheadQuestion} [options={}]  Creation options.
     *
     * @memberof FormTypeheadQuestion
     */
    constructor(options: IFormTypeheadQuestion) {
        super(options);

        this.getDataAsObservableFn = options.getDataAsObservableFn;
        this.getDataAsObservableSrc = options.getDataAsObservableSrc;
        this.typeaheadOptionField = options.typeaheadOptionField;
        this.typeaheadGroupField = options.typeaheadGroupField;
        this.typeaheadOptionsLimit = options.typeaheadOptionsLimit != null ? options.typeaheadOptionsLimit : 7;
        this.typeaheadMinLength = options.typeaheadMinLength != null ? options.typeaheadMinLength : 2;
        this.typeaheadScrollable = options.typeaheadScrollable === true;
        this.typeaheadOptionsInScrollableView = options.typeaheadOptionsInScrollableView;
        this.restrict = options.restrict;
        this.selected = options.selected;
        this.onKeyDownEnter = options.onKeyDownEnter;
    }
}

import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';

import { HttpService, IInterceptor } from '../../../gamma/http/http.service';
import { RoutingService } from '../../../gamma/routing/routing.service';
import { SecurityService } from '../../../gamma/security';
import { MathService } from '../../../gamma/utils';

import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../../gamma/localstorage';
import { U2000LS_LastLoggedUsername, U2000LS_MappingKey, U2000LS_OrganizationClickCount, U2000LS_TenantId, U2000LS_TokenInfo } from '../U2000_localstorages';

export interface IU2000_LoginInfo {
    username: string;
    password: string;
    rememberMe?: boolean;
    grantType?: string;
    authorizationCode?: string;
}

export interface IU2000_TokenInfo {
    access_token: string;
    token_type: string;
}

export interface IU2000_AutoLoginInfo {
    accessToken: string;
    tenantId: number;

    agentInfo: ICurrentAgentInfoDto;
    agentTenants: ICurrentAgentTenantDto[];
    userProfile: ICurrentUserProfileDto;
    securityTags: string[];
}

/**
 * List of route exception that may be avoid returning to after a context modification.
 */
const changeContextRerouteException = ['U510'];

@Injectable()
export class U2000_AuthenticationService {
    private _tokenInfo: IU2000_TokenInfo;
    private authenticationInterceptor: IInterceptor;
    private _tenantId: number;

    authenticated$: EventEmitter<boolean>;
    agentInfo: ICurrentAgentInfoDto;
    agentTenants: ICurrentAgentTenantDto[];
    userProfile: ICurrentUserProfileDto;
    returningUrl: string;
    defaultReturningUrl: string;
    hideNavigation: boolean;
    rememberMe: boolean;
    mappingWorkflowReference: string;
    hasBundleId = false;
    workflowReference2fa: string;
    loginInfo: IU2000_LoginInfo;

    organizationClickCount: IU2000_OrganizationClickCount;

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private securityService: SecurityService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private routingService: RoutingService,
        private mathService: MathService,
    ) {
        this.defaultReturningUrl = 'U2001';
        this.hideNavigation = false;

        this.authenticationInterceptor = {
            request: (requestId: number, req: HttpRequest<any>) => {
                let newHeaders = req.headers ? req.headers : new HttpHeaders();
                if (!req.headers.has('Authorization')) {
                    newHeaders = newHeaders.set('Authorization', `${this._tokenInfo.token_type} ${this._tokenInfo.access_token}`);
                }
                if (!req.headers.has('X-SAIA-Tenant-Id') && this.tenantId != null) {
                    newHeaders = newHeaders.set('X-SAIA-Tenant-Id', this.tenantId.toString());
                }
                return req.clone({ headers: newHeaders });
            },
        };
        // Validate if there's a bundleId because the token and tenant are provided in the bundleId so we must not use the local storage.
        if (window.location.hash.indexOf('bundleId') === -1) {
            if (localStorageService.get<IU2000_TokenInfo>(U2000LS_TokenInfo) != null) {
                this.tokenInfo = localStorageService.get<IU2000_TokenInfo>(U2000LS_TokenInfo);
            }
            if (localStorageService.get<number>(U2000LS_TenantId) != null) {
                this.tenantId = localStorageService.get<number>(U2000LS_TenantId);
            }
        } else {
            this.hasBundleId = true;
        }

        this.organizationClickCount = this.localStorageService.get<IU2000_OrganizationClickCount>(U2000LS_OrganizationClickCount, {
            counter: {},
        });

        this.authenticated$ = new EventEmitter<boolean>();

        // Add a quick access via the console for developpers.
        if (!environment.production) {
            window['authenticationService'] = this;
        }
    }

    set accessToken(token: string) {
        this.tokenInfo = { access_token: token, token_type: 'Bearer' };
    }

    get accessToken() {
        return this._tokenInfo.access_token;
    }

    get tokenInfo(): IU2000_TokenInfo {
        return this._tokenInfo;
    }
    set tokenInfo(tokenInfo: IU2000_TokenInfo) {
        this.setToken(tokenInfo);

        if (tokenInfo == null) {
            this.securityService.clearTags();
            this.agentInfo = null;
            this.userProfile = null;
            this.tenantId = null;
            this.agentTenants = null;
        }
    }

    get tenantId() {
        return this._tenantId;
    }

    set tenantId(value: number) {
        this._tenantId = value;
        if (value == null) {
            this.localStorageService.remove(U2000LS_TenantId);
        } else {
            this.localStorageService.set(U2000LS_TenantId, value);
        }
    }

    get isLoggedIn(): boolean {
        return this._tokenInfo != null && this.userProfile != null;
    }

    getToken(loginInfo: IU2000_LoginInfo) {
        this.rememberMe = loginInfo.rememberMe;
        const preparedParams = new HttpParams({
            encoder: new CustomQueryEncoderHelper(),
            fromObject: {
                username: loginInfo.username.replace(/ /g, ''),
                password: loginInfo.password,
                grant_type: loginInfo.grantType != null ? loginInfo.grantType : 'password',
                authorization_code: loginInfo.authorizationCode != null ? loginInfo.authorizationCode : '',
            },
        });

        const tokenObs: Observable<IU2000_TokenInfo> = this.http.post<IU2000_TokenInfo>(environment.apiUrl + 'U2000/token', preparedParams);

        const chainedObs: Observable<IU2000_CoreCurrentAgentConsolidatedInfoDto> = tokenObs.pipe(
            flatMap(res => {
                if (res.token_type == 'Code') {
                    this.workflowReference2fa = res.access_token;
                    this.loginInfo = loginInfo;
                    this.router.navigate(['/U2011/2fa'], { skipLocationChange: true });
                    return of();
                } else {
                    this.setToken(res as any);

                    return this.getUserAgentInfo();
                }
            }),
        );
        return chainedObs;
    }

    clean2faInfo() {
        this.workflowReference2fa = null;
        this.loginInfo = null;
    }

    cleanLocalStorage() {
        this.localStorageService.remove(U2000LS_TokenInfo);
        this.localStorageService.remove(U2000LS_TenantId);
    }

    cleanLoginInfo() {
        this.cleanLocalStorage();

        this.tokenInfo = null;
    }

    getUserAgentInfo() {
        return this.http.get<IU2000_CoreCurrentAgentConsolidatedInfoDtoResponse>(environment.apiUrl + 'U2000/currentagentconsolidatedinfo').pipe(
            map(res => res.result),
            tap((res: IU2000_CoreCurrentAgentConsolidatedInfoDto) => {
                this.agentInfo = res.info;
                if (res.tenants) {
                    this.agentTenants = this.createTenantList(res.tenants);
                }
            }),
        );
    }

    getUserInfo() {
        this.securityService.clearTags();
        const request: IU2000_CoreCurrentUserConsolidatedInfoDtoRequest = {};
        if (this.rememberMe != null) {
            request.rememberMe = this.rememberMe;
        }
        if (this.mappingWorkflowReference != null) {
            request.mappingWorkflowReference = this.mappingWorkflowReference;
        }

        const httpParams = new HttpParams({ fromObject: request as any });
        return this.http.get<IU2000_CoreCurrentUserConsolidatedInfoDtoResponse>(environment.apiUrl + 'U2000/currentuserconsolidatedinfo', { params: httpParams }).pipe(
            map(res => res.result),
            tap(res => {
                this.userProfile = res.profile;
                this.securityService.addTags(res.securityTags);

                if (res.mappingKey) {
                    this.addAppMapping(res.mappingKey, res.mappingApplicationId);
                }
            }),
        );
    }

    selectContext(tenantId: number) {
        const changingContext = this.tenantId != null;
        this.tenantId = tenantId;
        this.authenticated$.emit(false);
        return this.getUserInfo().pipe(
            tap(res => {
                if (changingContext) {
                    this.authCompleted();
                    this.changeContext();
                } else {
                    if (this.userProfile.promptSecurityInfo) {
                        this.authCompleted();
                        // Redirect the user to the security wizard, then he will be redirected to the original url.
                        this.routingService.navigateWithLUParams<IU2014_LogicalUnitParams>(['U2014'], 'U2014', { returningUrl: this.returningUrl ? this.returningUrl : this.defaultReturningUrl });
                        this.returningUrl = null;
                    } else {
                        this.authCompleted();
                        this.navigateAfterLogin(this.returningUrl);
                    }
                }
            }),
        );
    }

    logoutAndRedirect(postToServer = true) {
        this.router.navigate(['U2011/lo'], { skipLocationChange: true }).then(() => {
            this.logout(true, postToServer);
        });
    }

    logoutWithoutRedirect(postToServer = true) {
        this.logout(false, postToServer);
    }

    private logout(redirect = true, postToServer = true) {
        // When the app is loaded from the bundle id we do not invalidate the token on the backend side to let the caller working.
        if (this.hasBundleId) {
            postToServer = false;
        }

        // Clean the UI infos as soon as possible
        // The effective logout to the server is asynchronous
        if (!this.isLoggedIn) {
            this.cleanLoginInfo();
            return null;
        }

        if (postToServer) {
            // Even if the logout crashes, we want to continue the normal course.
            this.http.post(environment.apiUrl + 'U2000/logout', {}, this.httpService.optionsWithExtra({ silent: true, globalErrorHandling: false })).subscribe();
        }

        this.hasBundleId = false;
        this.cleanLoginInfo();

        this.routingService.resetLogicalUnits();
        if (redirect) {
            this.router.navigate(['U2011']);
        }
    }

    autoLogin(loginData: IU2000_AutoLoginInfo) {
        this.accessToken = loginData.accessToken;
        this.tenantId = loginData.tenantId;

        this.agentInfo = loginData.agentInfo;
        if (!Array.isNullOrEmpty(loginData.agentTenants)) {
            this.agentTenants = this.createTenantList(loginData.agentTenants);
        }
        this.userProfile = loginData.userProfile;
        this.securityService.addTags(loginData.securityTags);

        this.authCompleted();
    }

    autoLoginWithNavigation(loginData: IU2000_AutoLoginInfo) {
        this.autoLogin(loginData);

        // Give time to the app initializer to complete it's initialisation to proceed with the navigation.
        // It was causing some unfoundable primary outlet for some components.
        setTimeout(() => {
            this.navigateAfterLogin(location.hash.substr(1));
        }, 0);
    }

    authCompleted() {
        this.localStorageService.set(U2000LS_LastLoggedUsername, this.userProfile.username);
        this.localStorageService.set(U2000LS_TokenInfo, this.tokenInfo);
        this.authenticated$.emit(true);
    }

    get tenantName() {
        const tenant = this.currentTenant;
        if (tenant != null) {
            return tenant.name;
        }
        return String.empty;
    }

    get currentTenant(): ICurrentAgentTenant {
        if (this.agentTenants != null) {
            return this.agentTenants.find(x => x.id === this.tenantId);
        }
        return null;
    }

    increaseClickCounter(tenantId: number) {
        const agentId = this.agentInfo.id;
        this.initOrganizationClickCounter(agentId, tenantId);
        this.organizationClickCount.counter[agentId][tenantId] += 1;
        this.localStorageService.set(U2000LS_OrganizationClickCount, this.organizationClickCount);
    }

    // TODO: Move this feature to the routing service (Be able to configure de routing service first)
    navigateToDefault() {
        this.routingService.navigateWithLUParams([this.defaultReturningUrl], this.defaultReturningUrl, { calledFromAuth: true } as IU5100_LogicalUnitParams);
    }

    // Private method to be able to stub.
    private setToken(tokenInfo: IU2000_TokenInfo): void {
        this._tokenInfo = tokenInfo;
        this.localStorageService.remove(U2000LS_TokenInfo);

        if (this._tokenInfo == null) {
            this.httpService.removeInterceptor(this.authenticationInterceptor);
            this.authenticated$.emit(false);
        } else {
            this.httpService.addInterceptor(this.authenticationInterceptor);
        }
    }

    private changeContext() {
        this.hasBundleId = false;
        let currentUrl: string;
        this.routingService.resetLogicalUnits();

        this.router.navigate(['U2011/lo'], { skipLocationChange: true }).then(() => {
            this.navigateAfterLogin('U2001');
        });
    }

    /**
     * Scan the rerouting exceptions to determine the URL that will be used after the context modification.
     *
     * @private
     * @param {string} url
     * @returns
     * @memberof U2000_AuthenticationService
     */
    private findReroutingUrl(url: string) {
        for (let i = 0; i < changeContextRerouteException.length; i++) {
            if (this.routingService.partOfModule(changeContextRerouteException[i])) {
                return null;
            }
        }
        return url;
    }

    private initOrganizationClickCounter(agentId: number, tenantId: number) {
        if (this.organizationClickCount.counter[agentId] == null) {
            this.organizationClickCount.counter[agentId] = {};
        }
        if (this.organizationClickCount.counter[agentId][tenantId] == null) {
            this.organizationClickCount.counter[agentId][tenantId] = 0;
        }
    }

    private createTenantList(tenantList: ICurrentAgentInfoDto[]): ICurrentAgentInfoDto[] {
        const list: ICurrentAgentInfoDto[] = [];
        let i: number;
        for (i = 0; i < tenantList.length; i++) {
            list.push(tenantList[i]);
            list[i].counter = this.getOrganizationClickCount(this.agentInfo.id, list[i].id);
        }
        // Descending sort on the counter value.
        return list.sort((a, b) => {
            return b.counter - a.counter;
        });
    }

    private getOrganizationClickCount(agentId: number, tenantId: number) {
        this.initOrganizationClickCounter(agentId, tenantId);

        return this.organizationClickCount.counter[agentId][tenantId];
    }

    private navigateAfterLogin(currentUrl?: string) {
        if (currentUrl != null && currentUrl.indexOf('U2011') === -1 && currentUrl.indexOf('U2000') === -1) {
            this.router.navigateByUrl(currentUrl);
            this.returningUrl = null;
        } else {
            this.navigateToDefault();
        }
    }

    addAppMapping(mappingKey: string, applicationId: number) {
        const keysToAdd: { key: string; value: string[] }[] = [];
        let key = btoa(`${U2000LS_MappingKey}_${applicationId}`);
        key = key.substr(0, key.length - 2);

        const appMappings = this.localStorageService.get<string[]>(key, []);

        const i = appMappings.findIndex(x => x === mappingKey);
        if (i === -1) {
            appMappings.push(mappingKey);
        }
        let realKey = btoa(`${U2000LS_MappingKey}_${applicationId}`);
        realKey = realKey.substr(0, realKey.length - 2);
        keysToAdd.push({ key: realKey, value: appMappings });

        // Adding some fake values to make some noise.
        let fakeAppMappings = [this.mathService.generateRadomHex(97)];
        if (Math.round(Math.random() * 2) === 1) {
            fakeAppMappings.push(this.mathService.generateRadomHex(97));
        }
        let mixedUpFakeKey = realKey.substr(17, 5) + realKey.substr(6, 5) + realKey.substr(0, 6) + realKey.substr(11, 6);
        keysToAdd.push({ key: mixedUpFakeKey, value: fakeAppMappings });

        fakeAppMappings = [this.mathService.generateRadomHex(97)];
        if (Math.round(Math.random() * 2) === 1) {
            fakeAppMappings.push(this.mathService.generateRadomHex(97));
        }
        mixedUpFakeKey = realKey.substr(6, 5) + realKey.substr(11, 6) + realKey.substr(17, 5) + realKey.substr(0, 6);
        keysToAdd.push({ key: mixedUpFakeKey, value: fakeAppMappings });

        keysToAdd.sort((a, b) => {
            const keyA = a.key;
            const keyB = b.key;
            if (keyA < keyB) {
                return -1;
            }
            if (keyA > keyB) {
                return 1;
            }
            return 0;
        });

        keysToAdd.forEach(x => {
            this.localStorageService.set(x.key, x.value);
        });
    }
}

export class CustomQueryEncoderHelper implements HttpParameterCodec {
    encodeKey(k: string): string {
        return encodeURIComponent(k);
    }

    encodeValue(v: string): string {
        return encodeURIComponent(v);
    }

    decodeKey(k: string): string {
        return decodeURIComponent(k);
    }

    decodeValue(v: string): string {
        return decodeURIComponent(v);
    }
}

import { Component, Inject, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ILogicalUnitModalRoute } from '../../../../gamma/logicalunit/logicalunit-modal.interface';
import { ModalService } from '../../../../gamma/modal/modal.service';

import { I18nService } from '../../../../gamma/i18n';
import { U2021_Route } from '../../../U2021_eula/U2021_eula-routing.module';
import { U2021_EulaService } from '../../../U2021_eula/U2021_eula.service';
import { U2000_WorkspaceService } from '../../U2000_workspace.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'footer',
    templateUrl: 'U2000_footer.component.html',
    styleUrls: ['U2000_footer.component.scss'],
})
export class U2000_FooterComponent {
    currentYear: number;
    constructor(
        private service: U2000_WorkspaceService,
        public translateService: TranslateService,
        private modalService: ModalService,
        private injector: Injector,
        public i18nService: I18nService,
        @Inject(U2021_Route) private u2021_Route: ILogicalUnitModalRoute<U2021_EulaService>,
    ) {
        this.currentYear = new Date().getFullYear();
    }

    get acceoSiteLanguage() {
        const lang = this.translateService.currentLang;
        if (lang === 'en') {
            return '/en/';
        }
        return '';
    }

    eulaClick() {
        const params = this.modalService.routeToParams(this.u2021_Route, this.injector);
        this.modalService.openLogicalUnit<U2021_EulaService, number>(params);
    }

    displayLanguageButton() {
        return this.service.displayLanguageButton;
    }
}

import { AfterViewInit, Directive } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { BaseComponent } from '../core/components/base.component';
import { RoutingService } from '../routing/routing.service';
import { ToastService } from '../toast';
import { DomService } from '../utils';
import { FormMixin } from './form.mixin';
import { FormCheckboxBootstrapComponent } from './formquestion/bootstrap/checkbox/checkbox.component';
import { FormDateBootstrapComponent } from './formquestion/bootstrap/date/date.component';
import { FormDropdownBootstrapComponent } from './formquestion/bootstrap/dropdown/dropdown.component';
import { FormFileUploadBootstrapComponent } from './formquestion/bootstrap/fileupload/fileupload.component';
import { FormGroupBootstrapComponent } from './formquestion/bootstrap/formgroup/formgroup.component';
import { FormHiddenBootstrapComponent } from './formquestion/bootstrap/hidden/hidden.component';
import { FormNumericBootstrapComponent } from './formquestion/bootstrap/numeric/numeric.component';
import { FormRadioBootstrapComponent } from './formquestion/bootstrap/radio/radio.component';
import { FormRadioButtonBootstrapComponent } from './formquestion/bootstrap/radiobutton/radiobutton.component';
import { FormTextareaBootstrapComponent } from './formquestion/bootstrap/textarea/textarea.component';
import { FormTextboxBootstrapComponent } from './formquestion/bootstrap/textbox/textbox.component';
import { FormTypeheadBootstrapComponent } from './formquestion/bootstrap/typehead/typehead.component';
import { FormQuestionBase } from './formquestion/formquestion-base';
import { FormCheckboxQuestion } from './formquestion/formquestion-checkbox';
import { FormDateQuestion } from './formquestion/formquestion-date';
import { FormDropdownQuestion } from './formquestion/formquestion-dropdown';
import { FormFileUploadQuestion } from './formquestion/formquestion-fileupload';
import { FormArrayQuestion } from './formquestion/formquestion-formarray';
import { FormGroupQuestion } from './formquestion/formquestion-formgroup';
import { FormHiddenQuestion } from './formquestion/formquestion-hidden';
import { FormNumericQuestion } from './formquestion/formquestion-numeric';
import { FormRadioQuestion } from './formquestion/formquestion-radio';
import { FormRadioButtonQuestion } from './formquestion/formquestion-radiobutton';
import { FormReCaptchaQuestion } from './formquestion/formquestion-recaptcha';
import { FormTextareaQuestion } from './formquestion/formquestion-textarea';
import { FormTextboxQuestion } from './formquestion/formquestion-textbox';
import { FormTypeheadQuestion } from './formquestion/formquestion-typehead';
import { FormQuestionControlBase } from './formquestion/formquestioncontrol-base';
import { FormQuestionControlInputBase } from './formquestion/formquestioncontrol-input-base';
import { FormReCaptchaComponent } from './formquestion/recaptcha/recaptcha.component';
import { FormValidationMessageService, IFormValidationMessage } from './formvalidationmessage.service';

export interface IFromControlValidationError {
    [code: string]: true;
}

export interface IFormChanges {
    changedFormValue: { [key: string]: any };
    hasChanged: boolean;
}

export interface IRegistrationFormConfigs {
    /**
     * If the FormGuard is registered on the route, the dirtyness of that form will not be validated on the canDeactivate method.
     *
     * @type {boolean}
     * @memberof IFormInitConfigs
     */
    skipFormGuardIfDirty?: boolean;
}

export interface ICheckChangesOptions {
    keysToIgnore?: string[];
}
/**
 * Base class user for form components.
 *
 * @export
 * @class FormBaseComponent
 * @template T
 */
@Directive()
export abstract class FormBaseComponent<T extends FormMixin = FormMixin> extends BaseComponent implements AfterViewInit {
    static className = 'FormBaseComponent';

    /**
     * List of validation messages.
     *
     * @type {IFormValidationMessage[]}
     * @memberof FormBaseComponent
     */
    formValidationMessages: IFormValidationMessage[];
    /**
     * List of questions used to buils the form groups.
     *
     * @type {FormQuestionBase[]}
     * @memberof FormBaseComponent
     */
    questions: FormQuestionBase[];
    /**
     * Form object used by the component.
     *
     * @type {FormGroup}
     * @memberof FormBaseComponent
     */
    form: UntypedFormGroup;

    /**
     * Default CSS class inserted into label html tag of the generic question template.
     *
     * @type {string}
     * @memberof FormBaseComponent
     */
    defaultLabelClass: string = String.empty;
    /**
     * Default CSS class inserted into label html tag of the generic question template.
     *
     * @type {string}
     * @memberof FormBaseComponent
     */
    defaultInputContainerClass: string = String.empty;

    protected _name: string = String.empty;
    protected defaultValue: any = {};
    protected formArrayQuestions: FormArrayQuestion[] = [];

    /**
     * Creates an instance of FormBaseComponent.
     * @param {ToastService} toastService
     * @param {RoutingService} routingService
     * @param {FormValidationMessageService} validationMessageService
     * @memberof FormBaseComponent
     */
    constructor(
        public override service: T,
        protected override toastService: ToastService,
        protected routingService: RoutingService,
        protected validationMessageService: FormValidationMessageService,
        public domService: DomService,
    ) {
        super(service, toastService);
    }

    ngAfterViewInit() {
        if (this.questions) {
            const question = this.questions.find(x => (x as FormQuestionControlInputBase<any>).autoFocus);
            if (question) {
                (question as FormQuestionControlInputBase<any>).domRef.getElementsByTagName('input')[0].focus();
            }
        }
    }

    toFormGroup(questions: FormQuestionBase[], parentQuestion?: FormGroupQuestion, defaultValue?: any, validator?: ValidatorFn, asyncValidator?: AsyncValidatorFn) {
        const group: { [key: string]: AbstractControl } = {};
        questions.forEach(question => {
            question.parentQuestion = parentQuestion;

            if (question instanceof FormGroupQuestion) {
                defaultValue[question.key] = {};
                group[question.key] = this.toFormGroup(question.questions, question, defaultValue[question.key], question.validator);
                question.formGroupRef = group[question.key] as UntypedFormGroup;
            } else if (question instanceof FormArrayQuestion) {
                group[question.key] = this.toFormArray(question);
                question.formArrayRef = group[question.key] as UntypedFormArray;
                this.formArrayQuestions.push(question);
            } else if (question instanceof FormQuestionControlBase) {
                if (question.labelClass == null) {
                    question.labelClass = this.defaultLabelClass;
                }
                if (question.inputContainerClass == null) {
                    question.inputContainerClass = this.defaultInputContainerClass;
                }
                if (question.inputClass == null) {
                    question.inputClass = String.empty;
                }

                group[question.key] = new UntypedFormControl(question.defaultValue || '', question.validators);
                question.formControlRef = group[question.key] as UntypedFormControl;
                // TODO: Manage default value for form arrays
                if (defaultValue) {
                    defaultValue[question.key] = question.defaultValue;
                }
            }

            if (question.idPrefix == null) {
                question.idPrefix = this.idPrefix;
            }

            this.initQuestionDefaultComponent(question);
        });
        return new UntypedFormGroup(group, validator, asyncValidator);
    }

    private toFormArray(question: FormArrayQuestion) {
        const qGroup = question.createQuestionGroup();

        const control = this.toFormGroup(qGroup.questions, qGroup, {}, question.validator);
        qGroup.formGroupRef = control;
        return new UntypedFormArray([control]);
    }

    setFormValue(data: any) {
        this.formArrayQuestions.forEach(formArrayQuestion => {
            const formArrayData = this.getData(formArrayQuestion, data);
            if (!Array.isNullOrEmpty(formArrayData)) {
                for (let i = 1; i < formArrayData.length; i++) {
                    formArrayQuestion.addGroup(this);
                }
            }
        });
        // If some groups are added, we must let the time to Angular to process the form group and then set the value.
        setTimeout(() => {
            this.form.patchValue(data);
        }, 0);
    }

    private getData(question: FormQuestionBase, dataToSearch: any) {
        const varPath = this.buildVariablePath(question);
        let data = dataToSearch;
        varPath.forEach(key => {
            data = data[key];
        });

        return data;
    }

    private buildVariablePath(question: FormQuestionBase) {
        const result = [question.key];
        let parent = question.parentQuestion;
        while (parent != null) {
            result.unshift(question.key);
            parent = parent.parentQuestion;
        }
        return result;
    }

    private initQuestionDefaultComponent(question: FormQuestionBase) {
        // There because of a webpack error that is raised when defined in the question's constructor.
        if (question.component == null) {
            if (question instanceof FormCheckboxQuestion) {
                question.component = FormCheckboxBootstrapComponent;
            } else if (question instanceof FormDateQuestion) {
                question.component = FormDateBootstrapComponent;
            } else if (question instanceof FormDropdownQuestion) {
                question.component = FormDropdownBootstrapComponent;
            } else if (question instanceof FormFileUploadQuestion) {
                question.component = FormFileUploadBootstrapComponent;
            } else if (question instanceof FormGroupQuestion) {
                question.component = FormGroupBootstrapComponent;
            } else if (question instanceof FormHiddenQuestion) {
                question.component = FormHiddenBootstrapComponent;
            } else if (question instanceof FormNumericQuestion) {
                question.component = FormNumericBootstrapComponent;
            } else if (question instanceof FormRadioQuestion) {
                question.component = FormRadioBootstrapComponent;
            } else if (question instanceof FormTextboxQuestion) {
                question.component = FormTextboxBootstrapComponent;
            } else if (question instanceof FormTextareaQuestion) {
                question.component = FormTextareaBootstrapComponent;
            } else if (question instanceof FormRadioButtonQuestion) {
                question.component = FormRadioButtonBootstrapComponent;
            } else if (question instanceof FormTypeheadQuestion) {
                question.component = FormTypeheadBootstrapComponent;
            } else if (question instanceof FormReCaptchaQuestion) {
                question.component = FormReCaptchaComponent;
            }
        }
    }

    /**
     * Initialize the form.
     * Populates the form object by converting the questions list to a form group with a list of form control (one for each question).
     * It also registers the validation messages of the logical unit service to the validation message service.
     *
     * @protected
     *
     * @memberof FormBaseComponent
     */
    public initForm(configs?: IRegistrationFormConfigs) {
        this.registerValidationMessage(this.service.formValidationMessages);
        this.form = this.toFormGroup(this.questions, null, this.defaultValue);
        this.service.registerForm(this, configs);
        this.resetForm();
    }

    protected destroyForm() {
        this.service.unregisterForm(this);
    }

    /**
     * Reset the form value with all the default values defined in the questions. It alse reset the form state. (Pristine, Untouched)
     *
     * @protected
     * @memberof FormBaseComponent
     */
    protected resetForm() {
        this.form.reset(this.defaultValue);

        this.scanQuestions(this.questions, question => {
            if (question.onReset) {
                question.onReset();
            }
        });

        this.resetFormState();
    }

    /**
     * Reset all form to "Pristine" and "Untouched" to be able to change the route without being prompt.
     *
     * @protected
     * @memberof FormBaseComponent
     */
    protected resetFormState() {
        this.form.markAsPristine();
        this.form.markAsUntouched();
    }

    /**
     * Register some validation message manually within a specific component.
     *
     * @protected
     * @param {IFormValidationMessage[]} validationMessages
     *
     * @memberof FormBaseComponent
     */
    protected registerValidationMessage(validationMessages: IFormValidationMessage[]) {
        if (validationMessages == null) {
            throw new Error('The form ValidationMessages must be defined in the service');
        }
        this.formValidationMessages = validationMessages;
        validationMessages.forEach(x => {
            if (x.serverError == null) {
                this.validationMessageService.addMessages(x, this.routingService.currentLogicalUnitId);
            } else {
                this.validationMessageService.addServerMessages(x, this.routingService.currentLogicalUnitId);
            }
        });
    }

    /**
     * Automatic handle of servers validation errors. All non handle errors are going to be display in a toast.
     *
     * @protected
     * @param {IErrorResponse} response Response received from the server.
     * @param {(validationError: IErrorElement, formControlError: { [key: string]: true }) => boolean} handle Handle the error locally.
     *
     * @memberof FormBaseComponent
     * @example
     *      // Way to handle errors comming from server.
     *      this.service.callServerAPI(U9999_AnyDto).subscribe(res => {
     *           // Your success code here.
     *      }, res => {
     *          this.handleServerValidationError(res.error,
     *              (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
     *                  if (validationError.code === 'U9999-0001') {
     *                      // Sets this particular error message under the input.
     *                      this.qPassword.formControlRef.setErrors(formControlErr);
     *
     *                      // Tells that this error is handled and may not pop the warning toast.
     *                      return true;
     *                  }
     *
     *              // Tells that this error is not handled and may pop the warning toast.
     *          return false;
     *      });
     */
    public handleServerValidationError(response: any, handle: (validationError: any, formControlError: IFromControlValidationError) => boolean) {
        if (response && response.errors) {
            let i: number;
            let max: number = response.errors.length;
            for (i = max - 1; i >= 0; i--) {
                const validationMessage: IFormValidationMessage = {
                    code: response.errors[i].code,
                    message: response.errors[i].message,
                    serverError: response.errors[i],
                };

                this.registerValidationMessage([validationMessage]);

                if (handle(response.errors[i], this.validationMessageToFormControlError(validationMessage)) === true) {
                    response.errors.splice(i, 1);
                }
            }

            max = response.errors.length;
            for (i = max - 1; i >= 0; i--) {
                this.toastService.warning(response.errors[i].message);
            }
            setTimeout(() => {
                this.domService.scrollToError();
            }, 1);
        }
    }

    /**
     * Convert a validation to a form control error.
     *
     * @param {IFormValidationMessage} validationMessage The validation message to convert.
     * @returns {IFromControlValidationError} The form control error.
     *
     * @memberof FormBaseComponent
     * @example
     *      this.qPassword.formControlRef.setErrors(
     *          this.validationMessageToFormControlError(U2000_ValidationMessages.invalidUsernamePassword_0010)
     *      );
     */
    validationMessageToFormControlError(validationMessage: IFormValidationMessage): IFromControlValidationError {
        const formControlError: IFromControlValidationError = {};
        formControlError[validationMessage.code] = true;
        return formControlError;
    }

    /**
     * Display errors of all the form controls. Usually used on the submit method.
     *
     * @param {{ [key: string]: AbstractControl }} formControls
     *
     * @memberof FormBaseComponent
     * @example
     *      onSubmit() {
     *          this.showErrors(this.form.controls);
     *      }
     */
    showErrors(formControls: { [key: string]: AbstractControl }) {
        for (const controlKey in formControls) {
            if (formControls.hasOwnProperty(controlKey)) {
                const formControl = formControls[controlKey];

                if (formControl instanceof UntypedFormGroup) {
                    this.showErrors(formControl.controls);
                } else if (formControl instanceof UntypedFormArray) {
                    formControl.controls.forEach((x: UntypedFormGroup) => {
                        this.showErrors(x.controls);
                    });
                }

                if (!(formControl instanceof UntypedFormArray)) {
                    this.clearNoMesageErrors(formControl);
                    formControl.markAsDirty();
                    formControl.markAsTouched();
                }
            }
        }
        // Must wait for the DOM to write before scrolling to the component in error.
        setTimeout(() => {
            this.domService.scrollToError();
        }, 1);
    }

    clearNoMesageErrors(formControl: AbstractControl) {
        if (formControl.hasError('noMessage')) {
            formControl.setErrors(null);
        }
    }

    checkChanges(formControls: { [key: string]: AbstractControl }, oldFormValue: any, options: ICheckChangesOptions = {}): IFormChanges {
        const changes: IFormChanges = { changedFormValue: {}, hasChanged: false };
        for (const controlKey in formControls) {
            if (formControls.hasOwnProperty(controlKey)) {
                const formControl = formControls[controlKey];
                if (formControl instanceof UntypedFormGroup) {
                    changes.changedFormValue[controlKey] = {};
                    this.checkChangesForChildren(changes, formControl.controls, oldFormValue[controlKey], options, changes.changedFormValue[controlKey]);
                } else {
                    // Ignoring unwanted fields from the changed form value
                    if (this.isAChangedKeyToIgnore(controlKey, options)) {
                        continue;
                    }

                    const oldValue = oldFormValue[controlKey];
                    const newValue = formControl.value;

                    if (String.isBlank(newValue) && String.isBlank(oldValue)) {
                        continue;
                    }

                    if (oldValue instanceof Object) {
                        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                            changes.hasChanged = true;
                            changes.changedFormValue[controlKey] = formControl.value;
                        }
                    } else {
                        if (String(oldValue).trim() !== String(newValue).trim()) {
                            changes.hasChanged = true;
                            changes.changedFormValue[controlKey] = formControl.value;
                        }
                    }
                }
            }
        }
        return changes;
    }

    private checkChangesForChildren(changes: IFormChanges, formControls: { [key: string]: AbstractControl }, oldFormValue: any, options: ICheckChangesOptions, changedFormValue: any): void {
        for (const controlKey in formControls) {
            if (formControls.hasOwnProperty(controlKey)) {
                const formControl = formControls[controlKey];
                if (formControl instanceof UntypedFormGroup) {
                    changedFormValue[controlKey] = {};
                    this.checkChangesForChildren(changes, formControl.controls, oldFormValue, options, changedFormValue[controlKey]);
                } else {
                    // Ignoring unwanted fields from the changed form value
                    if (this.isAChangedKeyToIgnore(controlKey, options)) {
                        continue;
                    }

                    const oldValue = oldFormValue ? oldFormValue[controlKey] : {};
                    const newValue = formControl.value;
                    if (String.isBlank(newValue) && String.isBlank(oldValue)) {
                        continue;
                    }
                    if (oldValue instanceof Object) {
                        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                            changes.hasChanged = true;
                            changedFormValue[controlKey] = formControl.value;
                        }
                    } else {
                        if (String(oldValue).trim() !== String(newValue).trim()) {
                            changes.hasChanged = true;
                            changedFormValue[controlKey] = formControl.value;
                        }
                    }
                }
            }
        }
    }

    private isAChangedKeyToIgnore(controlKey: string, options: ICheckChangesOptions): boolean {
        const keysToIgnore = options.keysToIgnore || [];
        for (const key of keysToIgnore) {
            if (String(key).trim() === String(controlKey).trim()) {
                return true;
            }
        }
        return false;
    }

    /**
     * Name of the form (Used to uniquely identify the forms)
     *
     * @type {string}
     * @memberof FormBaseComponent
     */
    get name() {
        return (this.constructor as typeof FormBaseComponent).className;
    }

    /**
     * Id prefix of the form (Used to uniquely identify all its components)
     *
     * @type {string}
     * @memberof FormBaseComponent
     */
    get idPrefix() {
        let idPrefix = this.name.match(/[A-Z]/g).join(String.empty).toLowerCase();
        if (idPrefix.length < 2) {
            idPrefix = this.name.substr(0, 3).toLowerCase();
        }
        return idPrefix;
    }

    get formId() {
        return this.idPrefix + '-form';
    }

    get submitButtonId() {
        return 'btn-' + this.idPrefix + '-submit';
    }

    scanQuestions(questions: FormQuestionBase | FormQuestionBase[], execute: (question: FormQuestionBase) => void) {
        if (questions == null) {
            return null;
        }

        let questionsToScan = [];
        if (questions instanceof FormQuestionBase) {
            questionsToScan.push(questions);
        } else {
            questionsToScan = questions;
        }

        questionsToScan.forEach(question => {
            if (question instanceof FormGroupQuestion) {
                execute(question);
                this.scanQuestions(question.questions, execute);
            } else if (question instanceof FormArrayQuestion) {
                execute(question);
                this.formArrayQuestions.forEach(x => {
                    x.questionGroups.forEach(y => {
                        this.scanQuestions(y, execute);
                    });
                });
            } else if (question instanceof FormQuestionControlBase) {
                execute(question);
            }
        });
    }

    scanFormControls(formControls: { [key: string]: AbstractControl }, execute: (key: string, formControl: AbstractControl) => void) {
        for (const controlKey in formControls) {
            if (formControls[controlKey] instanceof UntypedFormGroup) {
                this.scanFormControls((formControls[controlKey] as UntypedFormGroup).controls, execute);
            } else if (formControls[controlKey] instanceof UntypedFormArray) {
                const formArray = formControls[controlKey] as UntypedFormArray;
                for (let i = 0; i < formArray.length; i++) {
                    this.scanFormControls((formArray.at(i) as UntypedFormGroup).controls, execute);
                }
            } else {
                execute(controlKey, formControls[controlKey]);
            }
        }
    }
}

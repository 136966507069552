import { Component } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_VerifyIdentityService } from '../U2000_verifyidentity.service';

@Component({
    selector: 'u2000-verifycode',
    templateUrl: 'U2000_verifycode.component.html',
    styleUrls: ['U2000_verifycode.component.scss'],
})
export class U2000_VerifyCodeComponent extends BaseComponent<U2000_VerifyIdentityService> {
    constructor(
        service: U2000_VerifyIdentityService,
        // Super requirements
        toastService: ToastService,
    ) {
        super(service, toastService);
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Mixin } from '../../gamma/core';
import { FormBaseComponent, IRegistrationFormConfigs } from '../../gamma/form/form-base.component';
import { FormMixin, IRegisteredForm } from '../../gamma/form/form.mixin';
import { IFormValidationMessage } from '../../gamma/form/formvalidationmessage.service';
import { HttpErrorService, HttpService } from '../../gamma/http';
import { I18nService } from '../../gamma/i18n';
import { IInitLUParams, LogicalUnitBaseService, LogicalUnitInitMixin } from '../../gamma/logicalunit';
import { ModalService } from '../../gamma/modal';
import { RoutingService } from '../../gamma/routing';
import { ToastService } from '../../gamma/toast';

import { U2000_ConversationService, U2000_TranslatePartialLoader, U2000_TranslateServiceMixin } from '../U2000_core';
import { U2000_AuthenticationService } from '../U2000_core/U2000_services/U2000_authentication.service';
import { U2000_ValidationMessages } from '../U2000_core/U2000_validators/U2000_validationmessages';
import { IU2000_NeedHelpInfo } from '../U2000_workspace/U2000_workspace-interface';
import { U2011_OrganizationSelectionModalComponent } from './U2011_organizationselection-modal/U2011_organizationselection-modal.component';

export interface IU2011_Alert {
    type: 'success' | 'danger' | 'info' | 'warning';
    message: string;
}

@Injectable()
@Mixin([U2000_TranslateServiceMixin, LogicalUnitInitMixin, FormMixin])
export class U2011_LoginService extends LogicalUnitBaseService implements U2000_TranslateServiceMixin, LogicalUnitInitMixin, FormMixin {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'U2011_LoginService';

    //#region Mixin requirements
    // InitLU mixin requirements
    luConfigs: IU2011_InitDto;
    initLU: () => Observable<any>;
    initLUParams: IInitLUParams;
    // Translate mixin requirements
    initTranslation: () => Observable<any>;
    // Form mixin requirements
    formValidationMessages: IFormValidationMessage[];
    registeredForms: IRegisteredForm[];
    registerForm: (form: FormBaseComponent, configs: IRegistrationFormConfigs) => void;
    unregisterForm: (form: FormBaseComponent) => void;
    hasDirtyForms: () => boolean;
    cleanupForms: () => void;
    //#endregion

    targetTenantId: IU2011_TargetTenantId;
    targetTenantForValidation: IU2011_TargetTenantId;
    contextualMessage: string;
    alertAdded$: EventEmitter<IU2011_Alert>;
    alertsAdded: IU2011_Alert[];
    alertsCleared$: EventEmitter<any>;

    identitySecurityKey: string;
    identityError = false;
    logForTenantError = false;
    logForTenantErrorMessage: string;

    constructor(
        public override routingService: RoutingService,
        // InitLU mixin requirements
        public http: HttpClient,
        public override httpService: HttpService,
        public httpErrorService: HttpErrorService,
        public toastService: ToastService,
        public translateService: TranslateService,
        // Translate mixin requirements
        public translatePartialLoader: U2000_TranslatePartialLoader,

        private injector: Injector,
        private modalService: ModalService,

        private conversationService: U2000_ConversationService,
        private authService: U2000_AuthenticationService,
        private i18nService: I18nService,
    ) {
        super(routingService, httpService);
        this.formValidationMessages = [U2000_ValidationMessages.invalidUsernamePassword_0010];
        this.alertAdded$ = new EventEmitter<IU2011_Alert>();
        this.alertsAdded = [];
        this.alertsCleared$ = new EventEmitter<any>();

        this.paramReceived = (x: IU2011_LogicalUnitParams) => {
            if (x != null) {
                if (x.hasOwnProperty('returningUrl')) {
                    this.returningUrl = x.returningUrl;
                }
                if (x.hasOwnProperty('targetTenantId')) {
                    this.targetTenantId = x.targetTenantId;

                    if (this.targetTenantForValidation == null) {
                        // SAIA-16509: Secure a problem where the target tenant changes between the init and the login and could not been reproduced.
                        this.targetTenantForValidation = Object.assign({}, x.targetTenantId);
                    }
                }
                if (x.hasOwnProperty('contextualMessage')) {
                    this.contextualMessage = x.contextualMessage;
                }
                if (x.hasOwnProperty('alert')) {
                    this.clearAlerts();
                    this.addAlert(x.alert);
                }
            }
        };
        this.initLUParams = {
            cache: false,
            buildParam: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                if (this.targetTenantId != null) {
                    let urlParams = new HttpParams();
                    urlParams = urlParams.set('targetTenantId', this.targetTenantId.tenantId.toString());
                    return urlParams;
                }
                return null;
            },
        };

        this.initCompleted = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
            if (this.luConfigs.targetTenantName != null) {
                if (this.alertsAdded.length === 0) {
                    let alert: IU2000_Alert = {
                        type: 'warning',
                        message: this.authService.returningUrl.match(/U\d\d\d\d/)[0] + '-TargetTenantWarning' + (this.luConfigs.targetTenantType === 'INDIVIDUAL' ? '_Perso' : ''),
                        translationOptions: {
                            targetTenantName: this.luConfigs.targetTenantName,
                        },
                    };
                    this.addAlert(alert);
                }
            }
        };

        this.getNeedHelpInfo = (needHelpInfo: IU2000_NeedHelpInfo) => {
            if (this.targetTenantId == null || this.targetTenantId.clientTenantId == null) {
                needHelpInfo.skipBackendCall = true;
            } else {
                needHelpInfo.skipBackendCall = false;
                needHelpInfo.clientTenantId = this.targetTenantId.clientTenantId;
            }

            needHelpInfo.applicant = '';

            return needHelpInfo;
        };

        // Tells the framework that everything is ready for activation.
        super.logicalUnitCreated();
    }

    validateTargetedTenantId(agentTenants: ICurrentAgentTenantDto[]) {
        if (this.targetTenantId != null && this.targetTenantId.tenantId != null) {
            const targetedTenant = agentTenants.find(x => x.id === this.targetTenantId.tenantId);
            if (targetedTenant == null && this.targetTenantId.required !== false) {
                if (this.targetTenantId.errorMessage == null) {
                    this.targetTenantId.errorMessage = 'U2011-0001';
                }

                this.addAlert({ type: 'danger', message: this.i18nService.formatLabel(this.targetTenantId.errorMessage) });

                this.authService.logoutWithoutRedirect();
                return false;
            } else if (targetedTenant == null) {
                this.targetTenantId = null;
            }
        }
        return true;
    }

    addAlert(alert: IU2011_Alert) {
        if (this.alertAdded$.observers.length > 0) {
            this.alertAdded$.emit(alert);
        } else {
            this.alertsAdded.push(alert);
        }
    }

    clearAlerts() {
        this.alertsCleared$.emit();
    }

    set returningUrl(url: string) {
        this.authService.returningUrl = url;
    }
    get returningUrl(): string {
        return this.authService.returningUrl;
    }

    onGetToken(tokenRes: IU2000_CoreCurrentAgentConsolidatedInfoDto) {
        if (!this.validateTargetedTenantId(this.authService.agentTenants)) {
            return false;
        }

        if (!Array.isNullOrEmpty(tokenRes.tenants)) {
            if (tokenRes.tenants.length === 1) {
                // Terminate with the only tenant available.
                this.terminateLogin(this.authService.agentTenants[0].id);
            } else if (tokenRes.tenants.length > 1) {
                // Terminate with a target tenant.
                if (this.targetTenantId != null && this.targetTenantId.tenantId != null) {
                    // SAIA-16509: Secure a problem where the target tenant changes between the init and the login and could not been reproduced.
                    if (this.targetTenantForValidation != null && this.targetTenantForValidation.tenantId != null && this.targetTenantForValidation.tenantId !== this.targetTenantId.tenantId) {
                        this.logForTenantError = true;

                        this.logForTenantErrorMessage = 'First tenant: ' + this.targetTenantForValidation.tenantId + ', \n';
                        this.logForTenantErrorMessage = this.logForTenantErrorMessage + 'Modified tenant: ' + this.targetTenantId.tenantId + ', \n';
                        this.logForTenantErrorMessage = this.logForTenantErrorMessage + 'Conversation id: ' + this.conversationService.conversationId + ', \n';
                        this.logForTenantErrorMessage = this.logForTenantErrorMessage + 'Loaded logical unit: ' + Object.keys(this.routingService.loadedLogicalUnits).join(',') + ', \n';
                        this.logForTenantErrorMessage = this.logForTenantErrorMessage + 'Route: ' + this.routingService.lastRoutesHistory.join(', ');

                        this.targetTenantId = Object.assign({}, this.targetTenantForValidation);
                        this.targetTenantForValidation = null;
                    }

                    this.terminateLogin(this.targetTenantId.tenantId);
                } else {
                    this.modalService.openComponent({
                        component: U2011_OrganizationSelectionModalComponent,
                        injector: this.injector,
                    });
                }
            }
        } else {
            // There's not tenant, the process is not completed.
            this.addAlert({ type: 'danger', message: 'U2011-0002' });
        }
        return true;
    }

    private terminateLogin(tenantId: number) {
        this.authService.selectContext(tenantId).subscribe(() => {
            /* this.form.disable();

            if (this.logForTenantError) {
                this.logService.log('U2011 - The target tenant has been switched before the login :\n' + this.logForTenantErrorMessage, true);
                this.logForTenantError = false;
            } */
        });
    }
}

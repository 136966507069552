import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormBaseComponent } from '../../../../../../gamma/form/form-base.component';
import { FormService } from '../../../../../../gamma/form/form.service';
import { FormTextboxQuestion } from '../../../../../../gamma/form/formquestion/formquestion-textbox';
import { FormValidationMessageService } from '../../../../../../gamma/form/formvalidationmessage.service';
import { RoutingService } from '../../../../../../gamma/routing';
import { ToastService } from '../../../../../../gamma/toast';
import { DomService } from '../../../../../../gamma/utils';

import { AppInitService } from '../../../../../app-init.service';
import { U2000_AuthenticationService } from '../../../../../U2000_core';
import { U2000_ESignatureNavigationStepsEnum } from '../../U2000_esignature';
import { U2000_ESignatureService } from '../../U2000_esignature.service';

@Component({
    selector: 'u2000-esignatureverifycode-form',
    templateUrl: 'U2000_esignatureverifycode-form.component.html',
    providers: [FormService],
    styleUrls: ['U2000_esignatureverifycode-form.component.scss'],
})
export class U2000_ESignatureVerifyCodeFormComponent extends FormBaseComponent<U2000_ESignatureService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'ESignatureVerifyCode';

    qVerificationCode: FormTextboxQuestion;

    constructor(
        public override service: U2000_ESignatureService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,

        public authService: U2000_AuthenticationService,
        private translateService: TranslateService,
        private appInitService: AppInitService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.qVerificationCode = new FormTextboxQuestion({
            key: 'verificationCode',
            placeholder: 'lblESignatureCode',
            maxLength: 6,
            validators: [Validators.required],
            hideRequiredMarker: true,
            width: '12em',
            formGroupClass: 'display-inline-block',
            autoFocus: true,
            restrict: /[0-9]/,
            onChange: () => {
                this.service.verificationCode = this.qVerificationCode.value;
            },
        });

        this.questions = [this.qVerificationCode];
    }

    ngOnInit() {
        this.initForm();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    submit() {
        this.service.clearAlerts();
        this.showErrors(this.form.controls);
        if (this.form.valid) {
            const verifyIdentityCodeRequest: IU2000_ESignatureVerifyCodeDtoRequest = this.form.value;
            const obsVerifIndentityCode = this.service.verifyIdentityCode(verifyIdentityCodeRequest);

            obsVerifIndentityCode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                res => {
                    this.resetForm();
                    this.service.onValid();
                },
                (res: HttpErrorResponse) => {
                    this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                        if (validationError.code === 'SPAI-B0008') {
                            validationError.message = this.translateService.instant('lblVerificationCodeLocked', {
                                email: this.appInitService.initData.configs.supportEmail,
                                phoneNumber: this.appInitService.initData.configs.supportPhoneNumber,
                            });
                        }

                        this.service.addAlert({ type: 'danger', message: validationError.message });
                        return true;
                    });
                },
            );

            return obsVerifIndentityCode;
        } else {
            return throwError('error');
        }
    }

    resendVerificationCode() {
        this.service.clearAlerts();
        const resendCodeRequest: IU2000_VerifyIdentityResendCodeDtoRequest = {
            contactMediaControlId: this.service.sendCodeResult.contactMediaControlId,
            securityKey: this.service.sendCodeResult.securityKey,
        };
        /*if ()
        resendCodeRequest.individualId = this.service.loginResult.id;*/
        this.service.resendVerificationCode(resendCodeRequest).subscribe(
            res => {
                this.resetForm();
                this.service.addAlert({ type: 'success', message: 'U2000-0027' });
                this.service.sendCodeResult.contactMediaControlId = res.contactMediaControlId;
                this.service.sendCodeResult.securityKey = res.securityKey;
            },
            (res: HttpErrorResponse) => {
                this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                    this.service.addAlert({ type: 'danger', message: validationError.message });
                    return true;
                });
            },
        );
    }

    changeSendingCodeMethod() {
        this.service.navigateTo(U2000_ESignatureNavigationStepsEnum.chooseSendingMethod);
    }
}

import { Component } from '@angular/core';

/**
 * This component empty and is only used to be a transition to the logout flow.
 *   - 1: Disconnect
 *   - 2: Move to U2011/lo (this route)
 *   - 3: When the route is completely resolved, the logout is performed
 *   - 4: Move to U2011 (login) whith can now be displayed.
 * It prevents the current screen to display elements that are only displayed if not logged in. Ex: U5101 - The connection panel.
 * It also allow to disconnect before rerouting to the login screen that is guarded to be display if logged in.
 *
 * @export
 * @class U2011_LogoutComponent
 */
@Component({
    selector: 'u2011-logout',
    template: ``,
})
export class U2011_LogoutComponent {}

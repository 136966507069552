import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

export interface I2000_TitleSuffix {
    label: string;
    params?: any;
}

@Injectable()
export class U2000_TitleService {
    changed$: EventEmitter<string>;
    defaultTitleLabel = 'lblDefaultBrowserTitle';
    private _currentTitle: string[];

    constructor(private titleService: Title, private translateService: TranslateService) {
        this.changed$ = new EventEmitter<string>();

        // Default language defined here to ensure that even if the globalXX.json is not done loading, the browswer has a title.
        translateService.setTranslation('fr', { lblDefaultBrowserTitle: 'ACCEO Transphere' }, true);
        translateService.setTranslation('en', { lblDefaultBrowserTitle: 'ACCEO Transphere' }, true);
    }

    setTitleSuffix(titleSuffix: I2000_TitleSuffix) {
        const defaultBrowserTitle: string = this.translateService.instant(this.defaultTitleLabel);
        if (titleSuffix) {
            this.titleService.setTitle(`${defaultBrowserTitle} - ${this.translateService.instant(titleSuffix.label, titleSuffix.params)}`);
        } else {
            this.titleService.setTitle(defaultBrowserTitle);
        }
        this.changed$.emit(this.titleService.getTitle());
    }

    reset() {
        const defaultBrowserTitle: string = this.translateService.instant(this.defaultTitleLabel);
        this.titleService.setTitle(defaultBrowserTitle);
        this.changed$.emit(this.titleService.getTitle());
    }

    get currentTitle() {
        return this._currentTitle;
    }
}

import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { I18nService } from '../../../../i18n';
import { FormValidationMessageService } from '../../../formvalidationmessage.service';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormGroupQuestion } from '../../formquestion-formgroup';

@Component({
    selector: 'form-formgroup-bootstrap',
    templateUrl: './formgroup.component.html',
    styleUrls: ['./formgroup.component.scss'],
})
export class FormGroupBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit, OnDestroy {
    @Input() override question: FormGroupQuestion;
    @Input() override form: UntypedFormGroup;

    groupInError = false;
    statusChangeSubs: Subscription;

    constructor(i18nService: I18nService, private el: ElementRef, private formValidationMessageService: FormValidationMessageService) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);

        this.statusChangeSubs = this.question.formGroupRef.statusChanges.subscribe(status => {
            if (this.question.formGroupRef == null) {
                return null;
            }
            if (this.question.formGroupRef.invalid && this.question.formGroupRef.touched && this.question.formGroupRef.dirty) {
                for (const error in this.question.formGroupRef.errors) {
                    if (this.question.formGroupRef.errors.hasOwnProperty(error)) {
                        const validationMessage = this.formValidationMessageService.getValidationMessageByName(error);
                        if (validationMessage.groupMessage === true) {
                            this.groupInError = true;
                            return true;
                        }
                    }
                }
            }
            this.groupInError = false;
            return false;
        });
    }

    ngOnDestroy() {
        // When autoDetail is true, ngAfterViewInit is not called so statusChangeSubs is undefined
        if (this.statusChangeSubs != null) {
            this.statusChangeSubs.unsubscribe();
        }
    }

    override get hasError() {
        if (this.question.handleGroupError) {
            if (this.question.formGroupRef == null) {
                return this.groupInError;
            }

            return this.groupInError || (this.question.formGroupRef.invalid && this.question.formGroupRef.touched && this.question.formGroupRef.dirty);
        } else {
            return false;
        }
    }

    get hasData() {
        return (this.el.nativeElement as HTMLElement).getElementsByClassName('data').length > 0;
    }
}

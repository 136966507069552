export const languageHashValues= {
  "GLOBAL": ".5c617640018b11a37916",
  "U4302_TRANSACTIONSDETAIL": ".b056299f5c43b3ffb1de",
  "U4400_PAYMENT": ".57d828748a900f5ef690",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".01c748efbeb460d9b41d",
  "U2117_PADAGREEMENTINVITATION": ".9f9a885b3815f9d3e5c5",
  "U2000_PAD": ".ab747059aef5eef9dc9b",
  "U4402_INVOICEPORTALPAYMENT": ".2a0a9946e83837c11457",
  "U2000_BANKACCOUNTCREATION": ".ec1cb527aba52e3e15e7",
  "U2011_LOGIN": ".19c413f4ecd8179d2a9e",
  "U2012_LOGINHELP": ".8b23a273c5eae73c49b8",
  "U2014_SECURITY": ".c442ceb341434d01c77d",
  "U2086_EFTPENDINGPAYMENTS": ".38b014f2fead4b013815",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".c60322da485776762165",
  "U2110_ACCOUNTCREATION": ".bdd1b47462a823c701bf",
  "U2121_INDIVIDUALPROFILE": ".4ec0c3ee8d6dea176e83",
  "U2131_ORGANIZATIONPROFILE": ".7816237eba5097e59cbc",
  "U2112_ERPBINDING": ".e63ae92f759a71383d51",
  "U2113_SUPPLIERINVITATION": ".d8f3eeced2da6a8b4222",
  "U2118_RBBINDING": ".da5730e3b0bc01ee87e6",
  "U2132_AFFILIATION": ".b8e000af20a7a4adfee8",
  "U2133_BUSINESSLINKS": ".254b1f95cae3c5ce1f8f",
  "U2181": ".afe7f44d70f352f0044c",
  "U2211_SUBSCRIPTIONOFFER": ".84bce731e6bd664ab3cb",
  "U2212_SUBSCRIPTIONFILTER": ".837afbf9ddf640bd49c9",
  "U2213_SUBSCRIPTION": ".0a7f9e12f1b909dcab87",
  "U4111_CREDITCARDADDITION": ".1ec2facf4bcfa7f94b44",
  "U4112_CREDITCARDDETAIL": ".05a2ddf58a72ee6ae7c8",
  "U4121_BANKACCOUNTADDITION": ".1943cd7cd1d350912f8c",
  "U4122_BANKACCOUNTDETAIL": ".f6ed3857d4fb69ce6c3d",
  "U4303_ANONYMOUSTRANSACTION": ".5238158a5042474d855c",
  "U4401_PAYMENTGATEWAY": ".282a1fe9744e2e98bda1",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".8c372abdb3775bd6d664",
  "U5000_RECEIVABLE": ".6bb285b92219d37a952e",
  "U5101_STATEMENTOFACCOUNT": ".e96b685af48ee44526f9",
  "U5102_INVOICE": ".f2c3972f9021dbc4da06",
  "U5103_PADAGREEMENT": ".2a60d41bb5420e724bc2",
  "U5121_SENTINVOICE": ".8269f55fb93762f01f7d",
  "U5124_PGRECEIVEDPAYMENT": ".f210462377efddf4a7d6",
  "U5120_INVOICES": ".4ce49db03670278f734c",
  "U5123_PADAGREEMENTCONSOLE": ".e4e952864cb3db15383e",
  "U5132_PAYMENTRECONCILIATION": ".7e5ceaecbd133bc45460",
  "U5133_BANKRECONCILIATION": ".4bdb928b84a5459cd307",
  "U6112_BUSINESSLINK": ".213c80e34ab84e6074f2",
  "U6113_SUPPLIERRECORD": ".3a088a25bfb48881ab4c",
  "U6211_PAYLOT": ".b61ca55462d59ddde5ad",
  "U6311_PURCHASE": ".b97bcd9a286242713cf2",
  "U2115_AFFILIATIONINVITATION": ".a4ae6258e49f5c27363e",
  "U6100_PAYABLE": ".2304da03ff51dcbf8b28",
  "U6131_SENTPAYMENT": ".0a03ffded1b18c543913",
  "U6191_SENTPAYMENTREPORT": ".1f59e36677ed816eb7ff",
  "U6221_SENTPAYMENT": ".2090645fc3c4bc48b782",
  "U6422_INTERACSENTPAYMENT": ".f5029088be876d5a78c1",
  "U6531_TRANSACTIONPAYMENT": ".58f5d3ab54da2addd1ea",
  "U5131_RECEIVEDPAYMENT": ".8c49ca1e8ce80c352c8f",
  "U5191_RECEIVEDPAYMENTREPORT": ".c3d85f23f25c54c51907",
  "U2001_HOME": ".4303f0c99b5d1ea0a4c3",
  "U2024_REPORTLOG": ".cfd26f7279b62f594ad2",
  "U2111_INVOICEPORTALBINDING": ".d1a8e2dd223fd9518594",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".710034ab9e1f0f1ad184",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".7c6f08d8276d1888a711",
  "U6511_TRANSACTIONPARTY": ".573d29287136a9ca7ba7",
  "U6212_PAYROLLPARTY": ".4bee19d9664c9a6945a9",
  "U6121_EFTLOT": ".6f09b21c238594e66c71",
  "U6412_INTERACFILE": ".feae2409396255f95852",
  "U6122_EFTAPPROVAL": ".70b1a17a2e2d7db125b8",
  "U6512_TRANSACTIONLOT": ".32fbdc148e932c97d9b8",
  "U6123_EFTAPPROVAL": ".6cf53bd8208f7247c749",
  "U6123_EFTAPPROVALMOBILE": ".abe9ec5e09ba3d852dfc",
  "U2012_LOGIN": ".b39344715e43c61487dd",
  "U2000_PAYMENT": ".6bb00e7909b310ed91f0",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".2f2a27d8e635891e30fa",
  "U2000_PAYMENTMODEUPDATE": ".1658f663b7da8645d1da",
  "U6114_TELUSBILLER": ".6dac58cc9b1c466e8c35",
  "U4403_CREDITCARDTERMINAL": ".3956e4d06b6f25906ebb",
  "U4404_INTERACTERMINAL": ".498be6337626d8ccec34",
  "U2000_SAMPLECHEQUESELECTION": ".78b4adb00ddd1b85fac7",
  "U2023_TUTORIALVIDEO": ".4057b71e695cbd4392f7",
  "U2134_ORGANIZATIONBILLING": ".10527ad40b9d403f81eb",
  "U6414_BANKACCOUNTWALLET": ".d95009874757f3374155",
  "U2022_INSTANTACCOUNTVERIFICATION": ".c559e69cea78efd9142f",
  "U6532_PENDINGTRANSACTION": ".a2243566b0a556f8dd92",
  "U4301_TRANSACTIONSSUMMARY": ".47f9cbeb2248bbae4971",
  "U5111_SENTINVOICESTRACKING": ".774218e7548986237e9c",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".f59ccfed82379a1cb59c",
  "U2000_INTRO": ".651cb3c77154fec35f68",
  "U2000_ELECTRONICSIGNATURE": ".ada6996e2efe493c59da",
  "U2000_VERIFYEMAIL": ".9bddec0f8c154a0c9825",
  "U2200_SUBSCRIPTION": ".8309a7de6cdeae63c948",
  "U2000_VERIFYIDENTITY": ".43659a3ff9f9352c5aa4",
  "U2000_ATTACHMENTVIEWER": ".28a42eb89fc2b10d6c0f",
  "U2212_PLANLIST": ".29382fce2c534217bc42",
  "U3002_TAXBILLSEARCHRESULT": ".c655c2f082aa1dbcd4cc"
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
    transform(seconds: number) {
        return new Date(seconds * 1000)
            .toISOString()
            .substr(11, 8)
            .replace(/^[0:]+/, '');
    }
}

import { Injectable } from '@angular/core';

/**
 * Service for Object utils.
 *
 * @export
 * @class ObjectService
 */
@Injectable()
export class ObjectService {
    isObject(item: any) {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    mergeDeep(target: any, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (Array.isArray(source[key])) {
                    if (target[key] == null) {
                        target[key] = source[key];
                    }

                    for (let i = 0; i < source[key].length; i++) {
                        this.mergeDeep(target[key][i], source[key][i]);
                    }
                } else if (this.isObject(source[key]) && !(source[key] instanceof Date)) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    this.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return this.mergeDeep(target, ...sources);
    }
}

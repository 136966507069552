import { Injectable } from '@angular/core';

import { RoutingService } from '../../../gamma/routing';

import { environment } from '../../../environments/environment';

@Injectable()
export class U2000_SeleniumService {
    constructor(private routingService: RoutingService) {
        // Add a quick access via the console for developpers.
        if (!environment.production) {
            window['seleniumService'] = this;
        }
    }

    public generateSeleniumTestBase(node?: string, template?: string): void {
        let list = '';
        let fillForm = '';
        let firstFillForm = false;
        let fillFormObject = '';
        let fillFormSubmit = '';
        let formFiller = '';
        let clickEvents = '';
        let lastFormName = '';
        let lastFormClassName = '';

        let nodeToScan = node != null ? node : 'main';
        let classSuffix = node != null ? 'Component' : 'Page';
        if (document.querySelector('modal-overlay') != null) {
            nodeToScan = 'modal-overlay';
            classSuffix = 'Modal';
        }
        let extendsName = 'Abstract';
        if (template != null) {
            classSuffix = 'Page';
            if (template === 'search') {
                extendsName = 'U2000_Search';
            } else if (template === 'detail') {
                extendsName = 'U2000_Detail';
            }
        }
        let componentName = '';
        if (node != null) {
            const nameSplit = node.split('-');
            for (let i = 0; i < nameSplit.length; i++) {
                nameSplit[i] = nameSplit[i].charAt(0).toUpperCase() + nameSplit[i].slice(1);
                if (i === 0) {
                    nameSplit[i] = nameSplit[i] + '_';
                }
            }
            componentName = nameSplit.join('');
        } else {
            componentName = this.routingService.currentComponent.constructor.name;
            componentName = componentName.substr(0, componentName.length - 9);
        }

        document.querySelectorAll(nodeToScan + ' [id]').forEach(x => {
            const id: string = x.attributes['id'].value;
            let variableName: string = id.trim().replace(/_/g, '-');
            variableName = variableName.trim().replace(/\b\w/g, c => c.toUpperCase());
            variableName = variableName.trim().replace(/-/g, '');
            variableName = variableName.trim().replace(/\b\w/g, c => c.toLowerCase());

            if (x.tagName === 'FORM') {
                if (fillForm !== '') {
                    fillForm += '\n\treturn self();\n';
                    fillForm += '}\n\n';
                    fillFormObject += '}\n\n';
                    formFiller += '\n\n';
                }
                const className = variableName.trim().replace(/\b\w/g, c => c.toUpperCase());

                lastFormName = variableName;
                lastFormClassName = className;

                fillForm += 'public THE_TYPEActController fill' + className + '(' + className + 'Param param) {\n';
                firstFillForm = true;
                fillFormObject += 'public class ' + this.routingService.currentLogicalUnitId + '_' + className + 'Param {\n';
                formFiller += className + 'Param ' + variableName + 'Param = new ' + className + 'Param();\n';
            } else if (x.tagName === 'BUTTON' && x.attributes['type'] != null && x.attributes['type'].value === 'submit') {
                if (fillForm !== '') {
                    fillFormSubmit += 'public THE_TYPEActController submit' + lastFormName + '() {\n';
                    fillFormSubmit += '\tthis.getWait().until(ExpectedConditions.presenceOfElementLocated(this.parentComponent.' + variableName + '));\n\n';
                    fillFormSubmit += '\tthis.findElement(this.parentComponent.' + variableName + ').click();\n\n';
                    fillFormSubmit += '\tthis.waitNotBusy();\n';
                    fillFormSubmit += '\n\treturn self();\n';
                    fillFormSubmit += '}\n\n';
                }
                list += 'public By ' + variableName + ' = By.id("' + id + '");\n';
            } else {
                if (x.tagName === 'INPUT' && x.attributes['ng-reflect-name'] != null) {
                    const name = x.attributes['ng-reflect-name'].value;
                    if (firstFillForm) {
                        fillForm += '\tthis.getWait().until(ExpectedConditions.presenceOfElementLocated(this.parentComponent.' + variableName + '));\n\n';
                    }

                    if (x.attributes['type'] != null && x.attributes['type'].value === 'checkbox') {
                        fillForm += '\tif ((param.' + name + ' != null) && (param.' + name + ')) {\n';
                        fillForm += '\t\tthis.findElement(this.parentComponent.' + variableName + ').click();\n';
                        fillForm += '\t}\n';
                        fillFormObject += '\tpublic Boolean ' + name + ';\n';
                        formFiller += lastFormName + 'Param.' + name + ' = ' + (x as HTMLInputElement).checked + ';\n';
                    } else {
                        fillForm += '\tthis.findElement(this.parentComponent.' + variableName + ').clear();\n';
                        fillForm += '\tthis.findElement(this.parentComponent.' + variableName + ').sendKeys(param.' + name + ');\n';
                        fillFormObject += '\tpublic String ' + name + ';\n';
                        formFiller += lastFormName + 'Param.' + name + ' = "' + (x as HTMLInputElement).value + '";\n';
                    }
                } else if (x.tagName === 'SELECT' && x.attributes['ng-reflect-name'] != null) {
                    const name = x.attributes['ng-reflect-name'].value;
                    fillForm += '\tnew Select(this.findElement(this.parentComponent.' + variableName + ')).selectByValue(param.' + name + '.toString());\n';
                    fillFormObject += '\tpublic Long ' + name + ';\n';
                    formFiller += lastFormName + 'Param.' + name + ' = ' + (x as HTMLInputElement).value + 'L;\n';
                }

                if (id.lastIndexOf('-0') === -1) {
                    list += 'public By ' + variableName + ' = By.id("' + id + '");\n';
                } else {
                    list += 'public String ' + variableName.substring(0, variableName.length - 1) + ' = By.id("' + id.substring(0, id.length - 2) + '");\n';
                }
            }

            if ((x.tagName === 'BUTTON' && (x.attributes['type'] == null || x.attributes['type'].value === 'button')) || x.tagName === 'A') {
                clickEvents += 'public THE_TYPEActController ' + variableName + 'Click() {\n';
                clickEvents += '\tthis.getWait().until(ExpectedConditions.elementToBeClickable(this.parentComponent.' + variableName + '));\n\n';
                clickEvents += '\tthis.findElement(this.parentComponent.' + variableName + ').click();\n';
                clickEvents += '\treturn this;\n';
                clickEvents += '}\n';
            }
        });

        if (fillForm !== '') {
            fillForm += '\n\treturn self();\n';
            fillForm += '}\n\n';
            fillFormObject += '}\n\n';
            formFiller += '\n\n';
        }

        this.logPage(list, fillForm, fillFormSubmit, clickEvents, fillFormObject, classSuffix, componentName, extendsName);

        console.log(formFiller);
    }

    logPage(list: string, fillForm: string, fillFormSubmit: string, clickEvents: string, fillFormObject: string, classSuffix: string, componentName: string, extendsName: string) {
        let pageCode = 'package com.acceo.saia.test.THE_PACKAGE;\n\n';

        pageCode += 'import com.acceo.saia.test.library.ui.' + classSuffix.toLowerCase() + 's.Abstract' + classSuffix + ';\n\n';

        pageCode += 'import org.openqa.selenium.By;\n';
        pageCode += 'import org.springframework.beans.factory.annotation.Autowired;\n';
        pageCode += 'import org.springframework.stereotype.Component;\n\n';

        pageCode += '@Component\n';
        pageCode += 'public class THE_TYPE' + classSuffix + ' extends\n';
        pageCode += '\t' + extendsName + classSuffix + '<THE_TYPE' + classSuffix + ' , THE_TYPEActController, THE_TYPEVerifyController> {\n\n';

        pageCode += list + '\n\n';

        pageCode += '\t@Autowired\n';
        pageCode += '\tpublic THE_TYPE' + classSuffix + ' (THE_TYPEActController act,\n';
        pageCode += '\t\tTHE_TYPEVerifyController verify) {\n';
        if (classSuffix === 'Page') {
            pageCode += '\t\tsuper(act, verify, "THE_LOGICAL_UNIT");\n\n';
        } else {
            pageCode += '\t\tsuper(act, verify);\n\n';
        }

        pageCode += '\t\tthis.act().parentComponent = this;\n';
        pageCode += '\t\tthis.verify().parentComponent = this;\n';
        pageCode += '\t}\n\n';

        pageCode += '\t@Override\n';
        pageCode += '\tprotected THE_TYPE' + classSuffix + '  self() {\n';
        pageCode += '\t\treturn this;\n';
        pageCode += '\t}\n';
        pageCode += '}\n';

        pageCode = pageCode.split('THE_TYPE').join(componentName);
        // pageCode = pageCode.split('THE_PACKAGE').join(window['SeleniumPackage']);
        console.log(pageCode);

        let actCode = 'package com.acceo.saia.test.THE_PACKAGE.controllers;\n\n';

        actCode += 'import com.acceo.saia.test.library.ui.' + classSuffix.toLowerCase() + 's.Abstract' + classSuffix + 'ActController;\n\n';

        actCode += 'import org.openqa.selenium.support.ui.ExpectedConditions;\n';
        actCode += 'import org.springframework.stereotype.Component;\n\n';

        actCode += '@Component\n';
        actCode += 'public class THE_TYPEActController extends ' + extendsName + classSuffix + 'ActController<THE_TYPE' + classSuffix + ' , THE_TYPEActController> {\n\n';

        actCode += fillFormObject + '\n\n';

        actCode += fillForm + '\n\n';
        actCode += fillFormSubmit + '\n\n';
        actCode += clickEvents + '\n\n';

        actCode += '\t@Override\n';
        actCode += '\tprotected THE_TYPEActController self() {\n';
        actCode += '\t\treturn this;\n';
        actCode += '\t}\n';

        actCode += '}\n';

        actCode = actCode.split('THE_TYPE').join(componentName);
        // actCode = actCode.split('THE_PACKAGE').join(window['SeleniumPackage']);
        console.log(actCode);

        let actVerif = 'package com.acceo.saia.test.THE_PACKAGE.controllers;\n\n';

        actVerif += 'import com.acceo.saia.test.library.ui.' + classSuffix.toLowerCase() + 's.Abstract' + classSuffix + 'VerifyController;\n\n';

        actVerif += 'import org.springframework.stereotype.Component;\n\n';

        actVerif += '@Component\n';
        actVerif += 'public class THE_TYPEVerifyController extends ' + extendsName + classSuffix + 'VerifyController<THE_TYPE' + classSuffix + ' , THE_TYPEVerifyController> {\n\n';

        actVerif += '\t@Override\n';
        actVerif += '\tprotected THE_TYPEVerifyController self() {\n';
        actVerif += '\t\treturn this;\n';
        actVerif += '\t}\n';
        actVerif += '}\n';

        actVerif = actVerif.split('THE_TYPE').join(componentName);
        // actVerif = actVerif.split('THE_PACKAGE').join(window['SeleniumPackage']);
        console.log(actVerif);
    }
}

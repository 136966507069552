import { Injectable } from '@angular/core';

import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { IToastProvider, ToastService } from '../../../gamma/toast';

@Injectable()
export class U2000_ToastProvider implements IToastProvider {
    service: ToastService;
    constructor(public toastr: ToastrService) {}

    success(message: string, title?: string, options: IndividualConfig = {} as any, onClick?: any) {
        const toastOptions: IndividualConfig = options;

        toastOptions.enableHtml = true;
        this.toastr
            .success(message, title, toastOptions)
            .onTap.pipe(take(1))
            .subscribe(() => {
                if (onClick) {
                    onClick();
                }
            });
    }

    error(message: string, title?: string, options: IndividualConfig = {} as any, onClick?: any) {
        const toastOptions: IndividualConfig = options;

        toastOptions.enableHtml = true;
        this.toastr
            .error(message, title, toastOptions)
            .onTap.pipe(take(1))
            .subscribe(() => {
                if (onClick) {
                    onClick();
                }
            });
    }

    warning(message: string, title?: string, options: IndividualConfig = {} as any, onClick?: any) {
        const toastOptions: IndividualConfig = options;

        toastOptions.enableHtml = true;
        this.toastr
            .warning(message, title, toastOptions)
            .onTap.pipe(take(1))
            .subscribe(() => {
                if (onClick) {
                    onClick();
                }
            });
    }

    info(message: string, title?: string, options: IndividualConfig = {} as any, onClick?: any) {
        const toastOptions: IndividualConfig = options;

        toastOptions.enableHtml = true;
        this.toastr
            .info(message, title, toastOptions)
            .onTap.pipe(take(1))
            .subscribe(() => {
                if (onClick) {
                    onClick();
                }
            });
    }

    custom(message: string, title?: string, options: IndividualConfig = {} as any, onClick?: any) {
        const toastOptions: IndividualConfig = options;

        toastOptions.enableHtml = true;
        this.toastr
            .show(message, title, toastOptions)
            .onTap.pipe(take(1))
            .subscribe(() => {
                if (onClick) {
                    onClick();
                }
            });
    }
}

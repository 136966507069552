import { Component, EventEmitter, Input, Output } from '@angular/core';

import { I18nService } from '../../../i18n';

@Component({
    selector: 'file-upload-button',
    templateUrl: './fileuploadbutton.component.html',
    styleUrls: ['./fileuploadbutton.component.scss'],
})
export class FileUploadButtonComponent {
    @Input() label: string;
    @Input() btnClass: string;
    @Output() onChange: EventEmitter<any>;

    data: string | ArrayBuffer;

    constructor(private i18nService: I18nService) {
        this.onChange = new EventEmitter<any>();
    }

    onFileChange(event: Event) {
        if ((event.target as HTMLInputElement).files[0] == null) {
            this.data = null;
        } else {
            const reader: FileReader = new FileReader();
            reader.onload = (readerEvent: Event) => {
                this.data = (readerEvent.target as FileReader).result;
                if (this.onChange) {
                    this.onChange.emit(this.data);
                }
            };
            reader.readAsDataURL((event.target as HTMLInputElement).files[0]);
        }
    }

    formatLabel(): string {
        return this.i18nService.formatLabel(this.label);
    }

    get buttonClass() {
        if (this.btnClass) {
            return this.btnClass;
        }
        return 'btn';
    }
}

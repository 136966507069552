import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { ModalService } from '../modal/modal.service';

export const HttpErrorServiceConfig = new InjectionToken<IServiceProviderConfig<IHttpErrorProvider>>('HTTP_ERROR_SERVICE_CONFIG');

/**
 *
 *
 * @export
 * @interface IHttpErrorProvider
 * @extends {IProvider<HttpErrorService>}
 */
export interface IHttpErrorProvider extends IProvider<HttpErrorService> {
    handleError(err: HttpErrorResponse): HttpErrorResponse;
}

/**
 * Service that manages all non business http errors.
 *
 * @export
 * @class HttpErrorService
 * @extends {ServiceProviderBase<IHttpErrorProvider, HttpErrorService>}
 */
@Injectable()
export class HttpErrorService extends ServiceProviderBase<IHttpErrorProvider, HttpErrorService> {
    /**
     * Creates an instance of HttpErrorService.
     * @param {Injector} injector
     * @param {ModalService} modalService
     * @param {IServiceProviderConfig<IHttpErrorProvider>} config
     *
     * @memberof HttpErrorService
     */
    constructor(private injector: Injector, private modalService: ModalService, @Inject(HttpErrorServiceConfig) config: IServiceProviderConfig<IHttpErrorProvider>) {
        super(injector, config);
    }

    handleHttpError(err: HttpErrorResponse): HttpErrorResponse {
        let errorText: string;
        const translate = this.injector.get(TranslateService);

        if (this.provider != null) {
            return this.provider.handleError(err);
        } else {
            errorText = `
                <div class="alert alert-danger" style="margin-top: 15px; text-align: center">
                    ${translate.instant('lblUnhandledErrorMessage')}
                </div>
                <div class='well' style='margin-bottom:0'>
                    ${err.statusText}
                </div>`;

            this.modalService.alert({
                title: translate.instant('lblError'),
                message: errorText,
            });
            return null;
        }
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { BusyIndicatorInterceptor } from './busyindicator.interceptor';
import { BusyIndicatorService, BusyIndicatorServiceConfig, IBusyIndicatorProvider } from './busyindicator.service';

/**
 * Busy indicator module.
 *
 * @export
 * @class BusyIndicatorModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [BusyIndicatorService, BusyIndicatorInterceptor],
    bootstrap: [],
})
export class BusyIndicatorModule {
    /**
     * Configure the service
     *
     * @static
     * @param {IServiceProviderConfig<IBusyIndicatorProvider>} [config={}] Configuration of the service
     * @returns {ModuleWithProviders}
     *
     * @memberof BusyIndicatorModule
     */
    static withConfig(config: IServiceProviderConfig<IBusyIndicatorProvider> = {}): ModuleWithProviders<BusyIndicatorModule> {
        return {
            ngModule: BusyIndicatorModule,
            providers: [{ provide: BusyIndicatorServiceConfig, useValue: config }],
        };
    }
}

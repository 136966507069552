import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BusyIndicatorService } from '../../../gamma/busyindicator';
import { ExceptionEvent, ExceptionService, IExceptionProvider } from '../../../gamma/exception';
import { ModalService } from '../../../gamma/modal';
import { RoutingService } from '../../../gamma/routing';

import { environment } from '../../../environments/environment';
import { U2000_AuthenticationService } from '../U2000_services/U2000_authentication.service';
import { U2000_LogService } from '../U2000_services/U2000_log.service';

@Injectable()
export class U2000_ExceptionProvider implements IExceptionProvider {
    service: ExceptionService;
    private alreadyDisplayed = false;

    constructor(
        private busyIndicatorService: BusyIndicatorService,
        private translateService: TranslateService,
        private injector: Injector,
        private routingService: RoutingService,
        private logService: U2000_LogService,
        private authService: U2000_AuthenticationService,
        private location: Location,
    ) {}

    handleError(event: ExceptionEvent) {
        // In case the error is displayed during a http flow.
        this.busyIndicatorService.hide();

        // Never display http errors, or rebound of InitLU failures.
        if (event.error != null && (event.error['url'] != null || event.error.message.indexOf('/init') > -1)) {
            return null;
        }
        const routingService = this.injector.get(RoutingService);
        if (routingService.currentComponent && routingService.currentComponent.handleError != null) {
            routingService.currentComponent.handleError(event);
        }
        if (!event.defaultPrevented() && !this.alreadyDisplayed) {
            this.alreadyDisplayed = true;
            let body = `
                <div>${this.translateService.instant('lblUnhandledErrorMessage')}</div>
            `;

            if (!environment.production) {
                body =
                    body +
                    `
                    <hr>
                    <small>
                        ${event.error}
                    </small>
                    <hr>
                    <small class="error-stack-trace">
                        ${event.error.stack}
                    </small>
                    `;
            }

            if (this.authService.isLoggedIn) {
                let errorPath = this.routingService.currentLogicalUnitId;
                if (this.routingService.currentComponent != null) {
                    errorPath = errorPath + ' - Component: ' + this.routingService.currentComponent.constructor.name;
                    errorPath = errorPath + ' - Service: ' + this.routingService.currentLogicalUnitService.constructor.name;
                }
                errorPath = errorPath + ` - Route: ${this.location.path()}`;
                errorPath = errorPath + ` - Tenant: ${this.authService.currentTenant?.id}`;
                this.logService.log(`JS - ${errorPath}\n${event.error}\n${event.error.stack}`, true);
            }

            const modalService = this.injector.get(ModalService);

            modalService
                .alert({
                    message: body,
                    closeButton: true,
                    title: 'lblError',
                })
                .then(x => {
                    x.result.then(() => {
                        this.alreadyDisplayed = false;
                    });
                });
        }
    }
}

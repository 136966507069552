import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { FormBaseComponent } from '../../../../../../gamma/form/form-base.component';
import { FormService } from '../../../../../../gamma/form/form.service';
import { FormTextboxQuestion } from '../../../../../../gamma/form/formquestion/formquestion-textbox';
import { FormValidationMessageService } from '../../../../../../gamma/form/formvalidationmessage.service';
import { RoutingService } from '../../../../../../gamma/routing';
import { ToastService } from '../../../../../../gamma/toast';
import { DomService } from '../../../../../../gamma/utils';

import { AppInitService } from '../../../../../app-init.service';
import { U2000_AuthenticationService } from '../../../../../U2000_core';
import { U2000_VerifyIdentityNavigationStepsEnum } from '../../U2000_verifyidentity';
import { U2000_VerifyIdentityService } from '../../U2000_verifyidentity.service';

@Component({
    selector: 'u2000-verifycode-form',
    templateUrl: 'U2000_verifycode-form.component.html',
    providers: [FormService],
    styleUrls: ['U2000_verifycode-form.component.scss'],
})
export class U2000_VerifyCodeFormComponent extends FormBaseComponent<U2000_VerifyIdentityService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'U2000_VerifyCodeFormComponent';

    qVerificationCode: FormTextboxQuestion;

    constructor(
        public override service: U2000_VerifyIdentityService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,

        public authService: U2000_AuthenticationService,
        private translateService: TranslateService,
        private appInitService: AppInitService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.qVerificationCode = new FormTextboxQuestion({
            key: 'verificationCode',
            placeholder: 'lblVerificationCode',
            maxLength: 6,
            validators: [Validators.required],
            hideRequiredMarker: true,
            width: '12em',
            formGroupClass: 'display-inline-block',
            autoFocus: true,
            restrict: /[0-9]/,
        });

        this.questions = [this.qVerificationCode];
    }

    ngOnInit() {
        this.initForm();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    onSubmit() {
        this.service.clearAlerts();
        this.showErrors(this.form.controls);
        if (this.form.valid) {
            const verifyIdentityCodeRequest: IU2000_VerifyIdentityCodeDtoRequest = this.form.value;
            this.service.verifyIdentityCode(verifyIdentityCodeRequest).subscribe(
                res => {
                    this.resetForm();

                    if (this.service.codeSendingMode === 'EMAIL' && this.service.componentConfigs.securityQuestion1Id != null) {
                        this.service.navigateTo(U2000_VerifyIdentityNavigationStepsEnum.securityQuestion);
                    } else {
                        this.service.onValid();
                        // this.service.navigateTo(U2000_VerifyIdentityNavigationStepsEnum.completed);
                    }
                },
                (res: HttpErrorResponse) => {
                    this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                        if (validationError.code === 'SPAI-B0008') {
                            validationError.message = this.translateService.instant('lblVerificationCodeLocked', {
                                email: this.appInitService.initData.configs.supportEmail,
                                phoneNumber: this.appInitService.initData.configs.supportPhoneNumber,
                            });
                        }

                        this.service.addAlert({ type: 'danger', message: validationError.message });
                        return true;
                    });
                },
            );
        }
    }

    resendVerificationCode() {
        this.service.clearAlerts();
        const resendCodeRequest: IU2000_VerifyIdentityResendCodeDtoRequest = {
            contactMediaControlId: this.service.sendCodeResult.contactMediaControlId,
            securityKey: this.service.sendCodeResult.securityKey,
        };
        /*if ()
        resendCodeRequest.individualId = this.service.loginResult.id;*/
        this.service.resendVerificationCode(resendCodeRequest).subscribe(
            res => {
                this.resetForm();
                this.service.addAlert({ type: 'success', message: 'U2000-0027' });
                this.service.sendCodeResult.contactMediaControlId = res.contactMediaControlId;
                this.service.sendCodeResult.securityKey = res.securityKey;
            },
            (res: HttpErrorResponse) => {
                this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                    this.service.addAlert({ type: 'danger', message: validationError.message });
                    return true;
                });
            },
        );
    }
}

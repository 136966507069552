import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormNumericQuestion } from '../../formquestion-numeric';

@Component({
    selector: 'form-numeric-bootstrap',
    templateUrl: './numeric.component.html',
    styleUrls: ['./numeric.component.scss'],
})
export class FormNumericBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormNumericQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, private el: ElementRef) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }
}

import { UntypedFormArray, ValidatorFn } from '@angular/forms';

import { FormBaseComponent } from '../form-base.component';
import { FormQuestionType } from '../form.service';
import { FormQuestionBase, IFormQuestionBase } from './formquestion-base';
import { FormGroupQuestion } from './formquestion-formgroup';

/**
 * Interface to define a group of questions.
 *
 * @export
 * @interface IFormArrayQuestion
 * @extends {IFormQuestionBase}
 */
export interface IFormArrayQuestion extends IFormQuestionBase {
    /**
     * Validator used within the form group.
     *
     * @type {ValidatorFn}
     * @memberof IFormGroupQuestion
     */
    validator?: ValidatorFn;

    /**
     * Form questions constructors and schemas that will defines the object of the array.
     *
     * @type {{ (): FormQuestionBase<any> }[]}
     * @memberof IFormArrayQuestion
     */
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    questionsCtor: { (group: FormGroupQuestion, index: number): FormQuestionBase }[];
}

/**
 * Form group question type used to regroup questions.
 *
 * @export
 * @class FormArrayQuestion
 * @extends {FormQuestionBase}
 */
export class FormArrayQuestion<T extends FormGroupQuestion = FormGroupQuestion> extends FormQuestionBase {
    override formQuestionType = FormQuestionType.FormArray;

    /**
     * Validator used within the form group.
     *
     * @type {ValidatorFn}
     * @memberof IFormGroupQuestion
     */
    validator: ValidatorFn;

    /**
     * Form questions constructors and schemas that will defines the object of the array.
     *
     * @type {{ (): FormQuestionBase<any> }[]}
     * @memberof IFormArrayQuestion
     */
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    questionsCtor: { (group: T, index: number): FormQuestionBase }[];

    /**
     * Reference to the created form array.
     *
     * @type {FormArray}
     * @memberof FormArrayQuestion
     */
    formArrayRef: UntypedFormArray;

    questionGroups: T[] = [];

    /**
     * Creates an instance of FormArrayQuestion.
     * @param {IFormArrayQuestion} [options={ questions: []}]  Creation options.
     *
     * @memberof FormArrayQuestion
     */
    constructor(options: IFormArrayQuestion) {
        super(options);
        this.questionsCtor = options.questionsCtor;
        this.validator = options.validator;
    }

    createQuestionGroup() {
        const qGroup = new FormGroupQuestion({
            questions: [],
        }) as T;

        qGroup.idPrefix = 'fa' + this.questionGroups.length;

        this.questionsCtor.forEach((questionCtor, index) => {
            const question = questionCtor(qGroup, this.questionGroups.length);
            qGroup.questions.push(question);
        });
        this.questionGroups.push(qGroup);
        return qGroup;
    }

    addGroup(formComponent: FormBaseComponent) {
        const qGroup = this.createQuestionGroup();

        const control = formComponent.toFormGroup(qGroup.questions, qGroup, this.validator);
        qGroup.formGroupRef = control;

        this.formArrayRef.push(control);
    }

    removeGroupAt(ind: number) {
        this.questionGroups.splice(ind, 1);
        this.formArrayRef.removeAt(ind);
    }
}

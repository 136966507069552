import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { IToastProvider, ToastService, ToastServiceConfig } from './toast.service';

/**
 * Toast module.
 *
 * @export
 * @class ToastModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ToastService],
})
export class ToastModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof ToastModule
     */
    static withConfig(config: IServiceProviderConfig<IToastProvider> = {}): ModuleWithProviders<ToastModule> {
        return {
            ngModule: ToastModule,
            providers: [{ provide: ToastServiceConfig, useValue: config }],
        };
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { ModalDismissGuard } from './modal-dismiss.guard';
import { IModalProvider, ModalService, ModalServiceConfig } from './modal.service';
import { ModalBusyIndicatorGuard } from './modalbusyindicator.guard';

/**
 * Modal module.
 *
 * @export
 * @class ModalModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ModalService, ModalBusyIndicatorGuard, ModalDismissGuard],
})
export class GammaModalModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof ModalModule
     */
    static withConfig(config: IServiceProviderConfig<IModalProvider> = {}): ModuleWithProviders<GammaModalModule> {
        return {
            ngModule: GammaModalModule,
            providers: [{ provide: ModalServiceConfig, useValue: config }],
        };
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Observable, Subscription, throwError } from 'rxjs';

import { Mixin } from '../../../../gamma/core';
import { HttpService } from '../../../../gamma/http';
import { I18nService } from '../../../../gamma/i18n';
import { DomService } from '../../../../gamma/utils';

import { U2000_AlertComponentMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-component.mixin';
import { U2000_AlertServiceMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-service.mixin';
import { U2000_ChooseSendingMethodComponent } from './U2000_choosesendingmethod/U2000_choosesendingmethod.component';
import { U2000_ESignatureNavigationStepsEnum } from './U2000_esignature';
import { U2000_ESignatureService } from './U2000_esignature.service';
import { U2000_ESignatureVerifyCodeComponent } from './U2000_esignatureverifycode/U2000_esignatureverifycode.component';

@Component({
    selector: 'u2000-esignature',
    templateUrl: 'U2000_esignature.component.html',
    styleUrls: ['U2000_esignature.component.scss'],
    providers: [U2000_ESignatureService],
})
@Mixin([U2000_AlertComponentMixin])
export class U2000_ESignatureComponent implements OnInit, OnDestroy, U2000_AlertComponentMixin {
    @Input() workflowReference: string;
    @Output() valid = new EventEmitter<string>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() error = new EventEmitter<HttpErrorResponse>();
    @Output() changeNavigationStepId = new EventEmitter<number>();
    @Input() validationBeforeSendingCode: () => boolean;

    @ViewChild(U2000_ESignatureVerifyCodeComponent)
    eSignatureVerifyCodeComponent: U2000_ESignatureVerifyCodeComponent;

    private defaultEmail: string;
    @Input()
    set email(email: string) {
        this.defaultEmail = email;
        this.service.email = email;
    }
    get email() {
        return this.defaultEmail;
    }

    //#region Mixin requirements
    // Alert mixin requirements
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    initAlertWithoutService: () => void;
    linkAlertWithService: (service: U2000_AlertServiceMixin) => void;
    destroyAlertComponent: () => void;
    alertClosed: (alert: IU2000_Alert) => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    alerts: IU2000_Alert[];
    //#endregion

    loadingObs: Observable<any>;

    constructor(
        public service: U2000_ESignatureService,
        public injector: Injector,
        // Alert mixin requirements
        public i18nService: I18nService,
        public domService: DomService,

        private httpService: HttpService,
    ) {
        this.service.valid = this.valid;
        this.service.error = this.error;
        this.service.email = this.email;

        this.service.changeNavigationStepId = this.changeNavigationStepId;

        this.linkAlertWithService(service);
    }

    ngOnDestroy() {
        this.httpService.removeInterceptor(this.service.workflowInterceptor);
        this.destroyAlertComponent();
    }

    ngOnInit() {
        this.service.validationBeforeSendingCode = this.validationBeforeSendingCode;
        this.httpService.addInterceptor(this.service.workflowInterceptor);
        this.loadingObs = this.service.resolve(this.workflowReference);
    }

    get currentNavigationComponent() {
        if (this.service.currentNavigationComponentId === U2000_ESignatureNavigationStepsEnum.chooseSendingMethod) {
            return U2000_ChooseSendingMethodComponent;
        } else if (this.service.currentNavigationComponentId === U2000_ESignatureNavigationStepsEnum.verifyCode) {
            return U2000_ESignatureVerifyCodeComponent;
        }
        return null;
    }

    verifyCode(): Observable<any> {
        if (this.currentNavigationComponent === U2000_ESignatureVerifyCodeComponent) {
            return this.eSignatureVerifyCodeComponent.submitForm();
        } else {
            this.service.addAlert({ type: 'danger', message: 'lblIdentityVerificationRequired' });
            return throwError('error');
        }
    }
}

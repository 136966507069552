import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../i18n';
import { FormQuestionBaseComponent } from '../formquestion-base.component';
import { FormReCaptchaQuestion } from '../formquestion-recaptcha';
import { FormReCaptchaControlComponent } from './recaptcha-control/recaptcha-control.component';

@Component({
    selector: 'form-recaptcha',
    templateUrl: './recaptcha.component.html',
    styleUrls: ['./recaptcha.component.scss'],
})
export class FormReCaptchaComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormReCaptchaQuestion;
    @Input() override form: UntypedFormGroup;
    @ViewChild(FormReCaptchaControlComponent, { static: true }) recaptchaControl: FormReCaptchaControlComponent;

    constructor(i18nService: I18nService, private el: ElementRef) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }
}

import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { DialogRef, ModalComponent } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

import { LogicalUnitModalBaseComponent } from '../../../gamma/logicalunit/logicalunit-modal-base.component';
import { ModalDismissGuard } from '../../../gamma/modal/modal-dismiss.guard';
import { RoutingService } from '../../../gamma/routing';
import { ToastService } from '../../../gamma/toast';
import { UserAgentService } from '../../../gamma/utils';

import { U2021_EulaService } from '../../U2021_eula/U2021_eula.service';

type IU2021_ModalContext = BSModalContext;

@Component({
    selector: 'u2021-eula-modal',
    templateUrl: 'U2021_eula-modal.component.html',
    styleUrls: ['U2021_eula-modal.component.scss'],
})
export class U2021_EulaModalComponent extends LogicalUnitModalBaseComponent<U2021_EulaService> implements ModalComponent<BSModalContext>, OnInit, OnDestroy {
    context: IU2021_ModalContext;

    private iframe: HTMLIFrameElement;

    constructor(
        service: U2021_EulaService,
        // ModalComponent requirements
        public dialog: DialogRef<BSModalContext>,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        modalDismissGuard: ModalDismissGuard,

        public userAgentService: UserAgentService,
        private el: ElementRef,
    ) {
        super(service, dialog, toastService, routingService, modalDismissGuard);

        this.context = dialog.context as IU2021_ModalContext;
        this.context.size = 'lg';
        this.context.isBlocking = true;
        this.context.showClose = true;
    }

    override ngOnInit() {
        super.ngOnInit();
        const element: HTMLDivElement = this.el.nativeElement;
        this.iframe = element.getElementsByTagName('iframe')[0];
        const modalDialog = element.parentElement.parentElement;
        const modalContent = element.parentElement;

        modalDialog.classList.add('u2021-eula-dialog');
        modalContent.classList.add('u2021-eula-content');

        this.iframe.src = this.service.luConfigs.url;
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    closeClick() {
        this.dialog.close(true);
    }
}

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { U2000_AuthenticationService } from './U2000_authentication.service';
import { U2000_WebSocketParserService } from './U2000_websocketparser.service';

@Injectable()
export class U2000_WebSocketService {
    private socket: WebSocket;

    constructor(private authService: U2000_AuthenticationService, private webSocketParser: U2000_WebSocketParserService) {
        this.authService.authenticated$.subscribe(loggedIn => {
            if (loggedIn) {
                this.connect();
            } else {
                this.deconnect();
            }
        });
    }

    public connect() {
        try {
            this.socket = new WebSocket(this.webSocketPath() + '/notifications', ['access_token', this.authService.tokenInfo.access_token, this.authService.tenantId.toString()]);

            this.socket.onerror = error => {
                console.error('Web socket error', error);
            };

            this.socket.onopen = event => {
                console.log('Web socket connection established.');
            };

            this.socket.onclose = event => {
                console.log('Web socket connection ended.');
            };

            this.socket.onmessage = event => {
                this.webSocketParser.parseMessage(event.data);
            };
        } catch (exception) {
            console.error('Could not connect to the web socket', exception);
        }
    }

    public deconnect() {
        if (this.socket) {
            this.socket.close();
        }
    }

    private webSocketPath(): string {
        let webSocketUrl = environment.webSocketUrl;
        const apiUrl = location.href;
        if (String.isNullOrEmpty(webSocketUrl)) {
            // Dynamically build the web socket url based on the current context
            if (apiUrl.indexOf('https://') !== -1) {
                webSocketUrl = 'wss://';
            } else {
                webSocketUrl = 'ws://';
            }
            let endIndex = apiUrl.length;
            if (apiUrl.indexOf('/#') !== -1) {
                endIndex = apiUrl.indexOf('/#');
            }
            const path = apiUrl.substring(apiUrl.indexOf('-'), endIndex);
            webSocketUrl += 'notify' + path;
        }
        return webSocketUrl;
    }
}

import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { FormQuestionType } from '../form.service';
import { FormQuestionBase, IFormQuestionBase } from './formquestion-base';

/**
 * Interface to define a group of questions.
 *
 * @export
 * @interface IFormGroupQuestion
 * @extends {IFormQuestionBase}
 */
export interface IFormGroupQuestion extends IFormQuestionBase {
    /**
     * List of questions grouped within the form group.
     *
     * @type {FormQuestionBase[]}
     * @memberof IFormGroupQuestion
     */
    questions: FormQuestionBase[];

    /**
     * Validator used within the form group.
     *
     * @type {ValidatorFn}
     * @memberof IFormGroupQuestion
     */
    validator?: ValidatorFn;

    handleGroupError?: boolean;
}

/**
 * Form group question type used to regroup questions.
 *
 * @export
 * @class FormGroupQuestion
 * @extends {FormQuestionBase}
 */
export class FormGroupQuestion extends FormQuestionBase {
    override formQuestionType = FormQuestionType.FormGroup;
    /**
     * Validator used within the form group.
     *
     * @type {ValidatorFn}
     * @memberof IFormGroupQuestion
     */
    validator: ValidatorFn;
    /**
     * List of questions grouped within the form group.
     *
     * @type {FormQuestionBase[]}
     * @memberof IFormGroupQuestion
     */
    questions: FormQuestionBase[];

    /**
     * Reference to the created form group.
     *
     * @type {FormGroup}
     * @memberof FormGroupQuestion
     */
    formGroupRef: UntypedFormGroup;
    handleGroupError: boolean;

    /**
     * Creates an instance of FormGroupQuestion.
     * @param {IFormGroupQuestion} [options={ questions: []}]  Creation options.
     *
     * @memberof FormGroupQuestion
     */
    constructor(options: IFormGroupQuestion = { questions: [] }) {
        super(options);
        this.questions = options.questions || [];
        this.validator = options.validator;
        this.handleGroupError = options.handleGroupError || true;
    }
}

import { Injectable } from '@angular/core';

/**
 * Service for Uuid.
 *
 * @export
 * @class UuidService
 */
@Injectable()
export class UuidService {
    /**
     * This method is based on Math.random, so it is not possible to garanty the uniqueness of the UUID.
     * To use only on cases that it a 100% unique id is not require.
     * @returns An unsafe uuid.
     */
    public generateUnsafeUuid() {
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    }
}

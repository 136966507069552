import { NgModule } from '@angular/core';

import { FormValidationMessageService } from './formvalidationmessage.service';

/**
 * Form core module.
 *
 * @export
 * @class FormModule
 */
@NgModule({
    imports: [],
    exports: [],
    providers: [FormValidationMessageService],
})
export class FormCoreModule {}

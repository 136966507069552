import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { I18nService } from '../../../gamma/i18n';
import { LocalStorageService } from '../../../gamma/localstorage';
import { LogicalUnitBaseComponent } from '../../../gamma/logicalunit';
import { RoutingService } from '../../../gamma/routing';
import { ToastService } from '../../../gamma/toast';

import { U2000LS_TokenExpiredRedirection } from '../../U2000_core/U2000_localstorages';
import { U2000_TitleService } from '../../U2000_core/U2000_services/U2000_title.service';
import { IU2011_Alert, U2011_LoginService } from '../U2011_login.service';

@Component({
    selector: 'u2011-login',
    templateUrl: 'U2011_login.component.html',
    styleUrls: ['U2011_login.component.scss'],
})
export class U2011_LoginComponent extends LogicalUnitBaseComponent<U2011_LoginService> implements OnDestroy {
    alerts: IU2011_Alert[];

    private addAlertSubscription: Subscription;
    private addClearSubscription: Subscription;

    constructor(
        public override service: U2011_LoginService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,

        private location: Location,
        private i18nService: I18nService,
        titleService: U2000_TitleService,
        localStorageService: LocalStorageService,
    ) {
        super(service, toastService, routingService);

        this.alerts = [];

        titleService.setTitleSuffix({ label: 'lblLogin' });

        this.addAlertSubscription = this.service.alertAdded$.subscribe(alert => {
            this.addAlert(alert);
        });
        this.addClearSubscription = this.service.alertsCleared$.subscribe(() => {
            this.clearAlerts();
        });

        this.service.alertsAdded.forEach(x => {
            this.addAlert(x);
        });
        this.service.alertsAdded = [];

        if (this.service.targetTenantId == null) {
            const tokenExpireRedirection = localStorageService.get<{ url: string; tenant: number }>(U2000LS_TokenExpiredRedirection);
            if (tokenExpireRedirection != null) {
                // If another message is already displayed, just don't display this one.
                if (this.alerts.length === 0) {
                    this.addAlert({ type: 'warning', message: 'lblSessionExpired' });
                }

                this.service.targetTenantId = { tenantId: tokenExpireRedirection.tenant, required: false };
            }
        }
        localStorageService.remove(U2000LS_TokenExpiredRedirection);

        this.paramReceived = (x: IU2011_LogicalUnitParams) => {
            if (x != null) {
                if (x.hasOwnProperty('alert')) {
                    this.addAlert(x.alert);
                }
            }
        };
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.addAlertSubscription.unsubscribe();
        this.addClearSubscription.unsubscribe();
    }

    get forgottenPasswordLink() {
        return this.service.luConfigs.configs.forgottenPasswordLink;
    }

    get forgottenUsernameLink() {
        return this.service.luConfigs.configs.forgottenUsernameLink;
    }

    alertClosed(alert: IU2000_Alert) {
        const alertPos = this.alerts.indexOf(alert);
        if (alertPos !== -1) {
            this.alerts.splice(alertPos, 1);
        }
    }

    addAlert(alert: IU2000_Alert) {
        if (alert.translationOptions == null) {
            alert.translationOptions = {
                targetTenantName: this.service.luConfigs.targetTenantName,
                targetTenantContactName: this.service.luConfigs.targetTenantContactName,
                targetTenantContactEmail: this.service.luConfigs.targetTenantContactEmail
            }
        }

        alert.message = this.i18nService.formatLabel(alert.message, alert.translationOptions);
        const alertAlreadyExists = this.alerts.find(x => x.message === alert.message);
        if (!alertAlreadyExists) {
            this.alerts.push(alert);
        }
    }

    clearAlerts() {
        this.alerts = [];
    }

    needHelp() {
        this.routingService.navigateWithLUParams<IU2012_LogicalUnitParams>(['U2012'], 'U2012', { returningUrl: this.location.path() });
    }
}

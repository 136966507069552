import { ModuleWithProviders, NgModule } from '@angular/core';

import { ILocalStorageServiceConfig } from './localstorage.interface';
import { LocalStorageService, LocalStorageServiceConfig } from './localstorage.service';

/**
 * Local storage module.
 *
 * @export
 * @class LocalStorageModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [LocalStorageService],
})
export class LocalStorageModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof LocalStorageModule
     */
    static withConfig(config: ILocalStorageServiceConfig = {}): ModuleWithProviders<LocalStorageModule> {
        return {
            ngModule: LocalStorageModule,
            providers: [{ provide: LocalStorageServiceConfig, useValue: config }],
        };
    }
}

import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormDateQuestion } from '../../formquestion-date';
import { FormDateInputBootstrapComponent } from './date-input/date-input.component';

@Component({
    selector: 'form-date-bootstrap',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
})
export class FormDateBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @ViewChild(FormDateInputBootstrapComponent, { static: true }) dateInput: FormDateInputBootstrapComponent;
    @Input() override question: FormDateQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, public el: ElementRef, protected datePipe: DatePipe) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }
}

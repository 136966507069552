import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { ExceptionService, ExceptionServiceConfig, IExceptionProvider } from './exception.service';

/**
 * Exception handler module.
 *
 * @export
 * @class ExceptionModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ExceptionService,
        {
            provide: ErrorHandler,
            useExisting: ExceptionService,
        },
    ],
})
export class ExceptionModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof ExceptionModule
     */
    static withConfig(config: IServiceProviderConfig<IExceptionProvider> = {}): ModuleWithProviders<ExceptionModule> {
        return {
            ngModule: ExceptionModule,
            providers: [{ provide: ExceptionServiceConfig, useValue: config }],
        };
    }
}

import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { Subscriber } from 'rxjs';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider } from '../core/serviceprovider/serviceprovider.interface';
import { ISessionStorageServiceConfig } from './sessionstorage.interface';

export const SessionStorageServiceConfig = new InjectionToken<ISessionStorageServiceConfig>('SESSION_STORAGE_SERVICE_CONFIG');

/**
 * Provider interface for the session storage service.
 *
 * @export
 * @interface ISessionStorageProvider
 * @extends {IProvider<SessionStorageService>}
 */
export interface ISessionStorageProvider extends IProvider<SessionStorageService> {
    /**
     * Hook method called on all gets.
     *
     * @param {string} key Obtained key.
     * @param {*} value Obtained value.
     *
     * @memberof ISessionStorageProvider
     */
    onGet?(key: string, value: any): void;

    /**
     * Hook method called on all sets.
     *
     * @param {string} key Defined key.
     * @param {*} value Defined value.
     *
     * @memberof ISessionStorageProvider
     */
    onSet?(key: string, value: any): void;

    /**
     * Hook method called on all removals.
     *
     * @param {string} key removed key.
     *
     * @memberof ISessionStorageProvider
     */
    onRemove?(key: string): void;
}

const SESSION_STORAGE_NOT_SUPPORTED = 'SESSION_STORAGE_NOT_SUPPORTED';

/**
 * Service used to manage the session storage of the browser.
 *
 * @export
 * @class SessionStorageService
 * @extends {ServiceProviderBase<ISessionStorageProvider, SessionStorageService>}
 */
@Injectable()
export class SessionStorageService extends ServiceProviderBase<ISessionStorageProvider, SessionStorageService> {
    /**
     * Tells if the local storage is supported by the browser.
     *
     * @memberof SessionStorageService
     */
    public isSupported = true;

    private prefix = 'gamma';
    private webStorage: Storage;
    private warnings: Subscriber<string> = new Subscriber<string>();
    private errors: Subscriber<string> = new Subscriber<string>();

    /**
     * Creates an instance of SessionStorageService.
     * @param {Injector} injector
     * @param {ISessionStorageServiceConfig} config
     *
     * @memberof SessionStorageService
     */
    constructor(injector: Injector, @Inject(SessionStorageServiceConfig) config: ISessionStorageServiceConfig) {
        super(injector, config);

        const { prefix } = config;

        if (prefix != null) {
            this.setPrefix(prefix);
        }

        this.isSupported = this.checkSupport();
    }

    /**
     * Clear all variable corresponding to the regular expression.
     *
     * @param {string} [regularExpression]
     * @returns {boolean}
     *
     * @memberof SessionStorageService
     */
    public clearAll(regularExpression?: string): boolean {
        // Setting both regular expressions independently
        // Empty strings result in catchall RegExp
        const prefixRegex = this.prefix ? new RegExp('^' + this.prefix) : new RegExp('');
        const testRegex = regularExpression ? new RegExp(regularExpression) : new RegExp('');

        if (!this.isSupported) {
            this.warnings.next(SESSION_STORAGE_NOT_SUPPORTED);
            return false;
        }

        const prefixLength = this.prefix.length;

        for (const key in this.webStorage) {
            // Only remove items that are for this app and match the regular expression
            if (prefixRegex.test(key) && testRegex.test(key.substr(prefixLength))) {
                try {
                    this.remove(key.substr(prefixLength));
                } catch (e) {
                    this.errors.next(e.message);
                    return false;
                }
            }
        }
        return true;
    }

    private deriveKey(key: string): string {
        return `${this.prefix}${key}`;
    }

    /**
     * Get a specific key from the browser local storage.
     *
     * @template T The type of value to retreive.
     * @param {string} key The key to retreive.
     * @param {T} [defaultValue=null] Default value if the key is not found in the browser local storage.
     * @returns {T} The value.
     *
     * @memberof SessionStorageService
     */
    public get<T>(key: string, defaultValue: T = null): T {
        if (!this.isSupported) {
            this.warnings.next(SESSION_STORAGE_NOT_SUPPORTED);
            return defaultValue;
        }

        const item = this.webStorage ? this.webStorage.getItem(this.deriveKey(key)) : null;

        // FIXME: not a perfect solution, since a valid 'null' string can't be stored
        if (!item || item === 'null') {
            if (this.provider && this.provider.onGet) {
                this.provider.onGet(key, null);
            }
            return defaultValue;
        }

        try {
            const jsonItem = JSON.parse(item);
            if (this.provider && this.provider.onGet) {
                this.provider.onGet(key, jsonItem);
            }
            return jsonItem;
        } catch (e) {
            if (this.provider && this.provider.onGet) {
                this.provider.onGet(key, null);
            }
            return defaultValue;
        }
    }

    /**
     * List of all keys currently stored in the browser local storage.
     *
     * @returns {Array<string>}
     *
     * @memberof SessionStorageService
     */
    public keys(): Array<string> {
        if (!this.isSupported) {
            this.warnings.next(SESSION_STORAGE_NOT_SUPPORTED);
            return [];
        }

        const prefixLength = this.prefix.length;
        const keys: Array<string> = [];
        for (const key in this.webStorage) {
            // Only return keys that are for this app
            if (key.substr(0, prefixLength) === this.prefix) {
                try {
                    keys.push(key.substr(prefixLength));
                } catch (e) {
                    this.errors.next(e.message);
                    return [];
                }
            }
        }
        return keys;
    }

    /**
     * Number of keys stored in the browser local storage.
     *
     * @returns {number}
     *
     * @memberof SessionStorageService
     */
    public length(): number {
        let count = 0;
        const storage = this.webStorage;
        for (let i = 0; i < storage.length; i++) {
            if (storage.key(i).indexOf(this.prefix) === 0) {
                count += 1;
            }
        }
        return count;
    }

    /**
     * Remove keys from the browser local storage.
     *
     * @param {...Array<string>} keys
     * @returns {boolean}
     *
     * @memberof SessionStorageService
     */
    public remove(...keys: Array<string>): boolean {
        let result = true;
        keys.forEach((key: string) => {
            if (!this.isSupported) {
                this.warnings.next(SESSION_STORAGE_NOT_SUPPORTED);
                result = false;
            }

            try {
                this.webStorage.removeItem(this.deriveKey(key));
                if (this.provider && this.provider.onRemove) {
                    this.provider.onRemove(key);
                }
            } catch (e) {
                this.errors.next(e.message);
                result = false;
            }
        });
        return result;
    }

    /**
     * Set a key/value to the browser local storage.
     *
     * @param {string} key Key to set.
     * @param {*} value Value to set.
     * @returns {boolean}
     *
     * @memberof SessionStorageService
     */
    public set(key: string, value: any): boolean {
        // Let's convert `undefined` values to `null` to get the value consistent
        if (value === undefined) {
            value = null;
        } else {
            value = JSON.stringify(value);
        }

        if (!this.isSupported) {
            this.warnings.next(SESSION_STORAGE_NOT_SUPPORTED);
            return false;
        }

        try {
            if (this.webStorage) {
                this.webStorage.setItem(this.deriveKey(key), value);
                if (this.provider && this.provider.onSet) {
                    this.provider.onSet(key, value);
                }
            }
        } catch (e) {
            this.errors.next(e.message);
            return false;
        }
        return true;
    }

    private checkSupport(): boolean {
        try {
            const supported = 'sessionStorage' in window && window.sessionStorage !== null;

            if (supported) {
                this.webStorage = window.sessionStorage;

                // When Safari (OS X or iOS) is in private browsing mode, it
                // appears as though SessionStorage is available, but trying to
                // call .setItem throws an exception.
                //
                // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
                // to add something to storage that exceeded the quota."
                const key = this.deriveKey(`__${Math.round(Math.random() * 1e7)}`);
                this.webStorage.setItem(key, '');
                this.webStorage.removeItem(key);
            }

            return supported;
        } catch (e) {
            this.errors.next(e.message);
            return false;
        }
    }

    /**
     * Set the variables prefix.
     *
     * @private
     * @param {string} prefix
     *
     * @memberof SessionStorageService
     */
    private setPrefix(prefix: string): void {
        this.prefix = prefix;

        // If there is a prefix set in the config let's use that with an appended
        // period for readability:
        const PERIOD = '.';
        if (this.prefix && !this.prefix.endsWith(PERIOD)) {
            this.prefix = this.prefix ? `${this.prefix}${PERIOD}` : '';
        }
    }
}

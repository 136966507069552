import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { U2011_ActivationGuard } from './U2011_activation.guard';
import { U2011_LoginResolver } from './U2011_login.resolver';
import { U2011_LoginFormComponent } from './U2011_login/U2011_login-form/U2011_login-form.component';
import { U2011_LoginComponent } from './U2011_login/U2011_login.component';
import { U2011_LogoutComponent } from './U2011_logout/U2011_logout.component';
import { U2011_OrganizationSelectionFormComponent } from './U2011_organizationselection-modal/U2011_organizationselection-form/U2011_organizationselection-form.component';
import { U2011_OrganizationSelectionModalComponent } from './U2011_organizationselection-modal/U2011_organizationselection-modal.component';
import { U2011_VerifyIdentityComponent } from './U2011_verifyidentity/U2011_verifyidentity.component';

const routes: Routes = [
    {
        path: 'U2011',
        component: U2011_LoginComponent,
        resolve: {
            init: U2011_LoginResolver,
        },
        canActivate: [U2011_ActivationGuard],
    },
    {
        path: 'U2011/2fa',
        component: U2011_VerifyIdentityComponent,
    },
    {
        path: 'U2011/lo',
        component: U2011_LogoutComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],
})
export class U2011_LoginRoutingModule {}

export const U2011_RoutedComponents = [U2011_LoginComponent, U2011_LoginFormComponent, U2011_LogoutComponent, U2011_OrganizationSelectionModalComponent, U2011_OrganizationSelectionFormComponent, U2011_VerifyIdentityComponent];

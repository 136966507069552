import { NgModule } from '@angular/core';

import { L10nService } from './l10n.service';

/**
 * Localization (l10n) module.
 *
 * @export
 * @class I18nModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [L10nService],
})
export class L10nModule {}

export const U2000ST_CreateApplicationTask = 'SPAI-4';
export const U2000ST_UpdateMyApplicationsTask = 'SPAI-26';
export const U2000ST_BillerTask = 'SPAI-38';
export const U2000ST_ActiveSupplierTask = 'SPAI-162';
export const U2000ST_AccountingSystemPayerTask = 'SPAI-165';
export const U2000ST_SearchMyApplicationChannelTask = 'SPAI-169';
export const U2000ST_ViewMyApplicationChannelAsClientTask = 'SPAI-173';
export const U2000ST_CreateApplicationChannelTask = 'SPAI-174';
export const U2000ST_RegenerateConfigurationKeyTask = 'SPAI-184';
export const U2000ST_AffiliationTask = 'SPAI-209';
export const U2000ST_CreateAffiliationTask = 'SPAI-213';
export const U2000ST_SearchMyEBusinessLinkInvitationsTask = 'SPAI-221';
export const U2000ST_SendEBusinessLinkInvitationToSupplierTask = 'SPAI-223';
export const U2000ST_CreateCreditCardProfileTask = 'SPAI-257';
export const U2000ST_UpdateOneOfMyCreditCardProfilesTask = 'SPAI-258';
export const U2000ST_CreateBankAccountTask = 'SPAI-270';
export const U2000ST_UpdateOneOfMyBankAccountTask = 'SPAI-271';
export const U2000ST_UploadFileToInviteSuppliersToBePaidViaMyAccountingSystemTask = 'SPAI-277';
export const U2000ST_DownloadMyActiveEBusinessLinksTask = 'SPAI-278';
export const U2000ST_PayablesElectronicChequesSubscriberTask = 'SPAI-290';
export const U2000ST_ReceivablesElectronicInvoicesSubscriberTask = 'SPAI-291';
export const U2000ST_ActiveInvoiceRecipientTask = 'SPAI-295';
export const U2000ST_ManagePadAgreementInvitationsTask = 'SPAI-323';
export const U2000ST_ManagePadAgreementAsClientTask = 'SPAI-324';
export const U2000ST_ManageMyPaymentModesTask = 'SPAI-303';
export const U2000ST_ManageSuppliersTask = 'SPAI-344';
export const U2000ST_ManageBankAccountsToSynchronizeTask = 'SPAI-353';
export const U2000ST_ManageBillingTask = 'SPAI-362';

export const U2000ST_SubscriberToAccountsPayableTask = 'SPIC-276';
export const U2000ST_SubscriberToElectronicInvoicesTask = 'SPIC-308';
export const U2000ST_SubscriberToPaymentTask = 'SPIC-343';

export const U2000ST_SearchMessagesSubmittedByMeViaThePlatformTask = 'SPMA-296';
export const U2000ST_ViewMyInvoices = 'SPMA-297';
export const U2000ST_UpdateMyInvoices = 'SPMA-298';
export const U2000ST_SearchAndViewMyPurchasesTask = 'SPMA-318';
export const U2000ST_ViewThePayerPaymentGatewayTask = 'SPMA-319';
export const U2000ST_ViewPaymentGatewayPaymentTransactionTask = 'SPMA-342';

export const U2000ST_SearchMyElectonicFundsTransferPaymentTransactionTask = 'SPPR-154';
export const U2000ST_UploadFileContainingEftTransactionsTask = 'SPPR-155';
export const U2000ST_SearchMyElectronicTransactionFilesTask = 'SPPR-158';
export const U2000ST_SubmitPaymentTransactionForMyFileTransactionTask = 'SPPR-160';
export const U2000ST_SearchMyReceivedElectonicFundsTransferPaymentTransactionTask = 'SPPR-190';
export const U2000ST_CancelAPaymentTransactionOfOneOfMyLotsTask = 'SPPR-204';
export const U2000ST_SearchEFTFileTransactionsForApprovalTask = 'SPPR-217';
export const U2000ST_SearchAndViewThePayrollTransactionsThatIPaidTask = 'SPPR-311';
export const U2000ST_SearchAndViewMyPayrollFilesTask = 'SPPR-313';
export const U2000ST_SubmitATransactionOfOneOfMyPayrollFilesTask = 'SPPR-314';
export const U2000ST_CancelATransactionOfOneOfMyPayrollFilesTask = 'SPPR-315';
export const U2000ST_SubscriberToPayrollTask = 'SPPR-317';
export const U2000ST_RefundAPaymentTask = 'SPPR-330';
export const U2000ST_ViewMyReceivedPaymentsTask = 'SPPR-333';
export const U2000ST_ReconciliatePaymentsTask = 'SPPR-338';
export const U2000ST_ManageBankingReconciliationTask = 'SPPR-352';
export const U2000ST_ResetPasswordOfAnAffiliationOfMyOrganizationTask = 'SPAI-340';
export const U2000ST_ViewReceivedPaymentsReportTask = 'SPPR-350';
export const U2000ST_ViewSentPaymentsReportTask = 'SPPR-359';
export const U2000ST_ViewPaymentLotsPmtProcessorTask = 'SPPR-398';
export const U2000ST_ViewSentPaymentsPmtProcessorTask = 'SPPR-399';
export const U2000ST_SubscriberToPaymentProcessorTask = 'SPPR-400';
export const U2000ST_BankAccountVerificationTask = 'SPPR-403';
export const U2000ST_ConfirmAnonymousSupplierBankAccountTask = 'SPAI-404';

import { IDynamicLabelEvents } from '../../shared/components/dynamiclabel/dynamiclabel.component';
import { FormQuestionType } from '../form.service';
import { FormQuestionControlBase, IFormQuestionControlBase } from './formquestioncontrol-base';

/**
 * Interface to define a checkbox question.
 *
 * @export
 * @interface IFormRadioQuestion
 * @extends {IFormQuestionControlBase<T>}
 * @template T Value type
 */
export interface IFormRadioQuestion<T> extends IFormQuestionControlBase<T> {
    /**
     * Alternate label displayed beside the check box.
     *
     * @type {string}
     * @memberof IFormRadioQuestion
     */
    radioButtons?: IFormRadioButton<T>[];

    condensed?: boolean;
}

/**
 * Interface to define radio buttons
 *
 * @export
 * @interface IFormRadioButton
 * @template T Value type
 */
export interface IFormRadioButton<T> {
    key?: string;
    label: string;
    labelEvents?: IDynamicLabelEvents[];
    value: T;
    disabled?: boolean;
    holder?: any;
    class?: string;
    note?: string;
    noteClass?: string;
}

/**
 * Radio question type.
 *
 * @export
 * @class FormRadioQuestion
 * @extends {FormQuestionControlBase<any>}
 * @template T Value type
 */
export class FormRadioQuestion<T> extends FormQuestionControlBase<T> {
    override formQuestionType = FormQuestionType.Radio;
    radioButtons: IFormRadioButton<T>[];
    condensed: boolean;

    /**
     * Creates an instance of FormRadioQuestion. Creation options.
     * @param {IFormQuestionControlBase<any>} [options={}]
     *
     * @memberof FormRadioQuestion
     */
    constructor(options: IFormRadioQuestion<T> = {}) {
        super(options);
        this.radioButtons = options.radioButtons;
        this.condensed = options.condensed;
    }
}

import { FormQuestionType } from '../form.service';
import { FormQuestionControlInputBase, IFormQuestionControlInputBase } from './formquestioncontrol-input-base';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormDateQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormDateQuestion extends IFormQuestionControlInputBase<string> {
    datePickerPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

/**
 * Textbox question type.
 *
 * @export
 * @class FormDateQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormDateQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.Date;
    datePickerPlacement: 'top' | 'bottom' | 'left' | 'right';

    /**
     * Creates an instance of FormDateQuestion.
     * @param {IFormDateQuestion} [options={}]  Creation options.
     *
     * @memberof FormDateQuestion
     */
    constructor(options: IFormDateQuestion = {}) {
        super(options);
        this.datePickerPlacement = String.isNullOrEmpty(options.datePickerPlacement) ? 'bottom' : options.datePickerPlacement;
        if (options.isInputGroup == null) {
            this.isInputGroup = true;
        }
    }
}

import { FormQuestionType } from '../form.service';
import { IFormQuestionControlBase } from './formquestioncontrol-base';
import { FormQuestionControlInputBase } from './formquestioncontrol-input-base';
import { ReCaptchaConfig } from './recaptcha/recaptcha-control/recaptcha-control.component';
import { FormReCaptchaComponent } from './recaptcha/recaptcha.component';

/**
 * Interface to define a textbox question.
 *
 * @export
 * @interface IFormReCaptchaQuestion
 * @extends {IFormQuestionControlBase<string>}
 */
export interface IFormReCaptchaQuestion extends IFormQuestionControlBase<string> {
    publicKey: string;
    config: ReCaptchaConfig;
}

/**
 * ReCaptcha question type.
 *
 * @export
 * @class FormReCaptchaQuestion
 * @extends {FormQuestionControlBase<string>}
 */
export class FormReCaptchaQuestion extends FormQuestionControlInputBase<string> {
    override formQuestionType = FormQuestionType.ReCaptcha;

    publicKey: string;
    config: ReCaptchaConfig;

    /**
     * Creates an instance of FormTextboxQuestion.
     * @param {IFormReCaptchaQuestion} [options={}]  Creation options.
     *
     * @memberof FormReCaptchaQuestion
     */
    constructor(options: IFormReCaptchaQuestion) {
        super(options);

        this.publicKey = options.publicKey;
        this.config = options.config;
    }

    reset() {
        if (this.componentRef != null) {
            (this.componentRef as FormReCaptchaComponent).recaptchaControl.reset();
        }
    }
}

import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { CustomDatePipe } from '../../../../shared/pipes/customdate.pipe';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormDateRangeQuestion } from '../../formquestion-daterange';
import { FormDateRangeInputBootstrapComponent } from './daterange-input/daterange-input.component';

@Component({
    selector: 'form-daterange-bootstrap',
    templateUrl: './daterange.component.html',
    styleUrls: ['./daterange.component.scss'],
})
export class FormDateRangeBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @ViewChild(FormDateRangeInputBootstrapComponent, { static: true }) dateInput: FormDateRangeInputBootstrapComponent;
    @Input() override question: FormDateRangeQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, public el: ElementRef, public datePipe: CustomDatePipe) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }
}

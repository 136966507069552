import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { RoutingService } from '../../routing';
import { FormValidationMessageService } from '../formvalidationmessage.service';

@Component({
    selector: 'form-question-bootstrap-error',
    template: `<div class="help-block" *ngIf="errorMessage !== null">{{ errorMessage }}</div>`,
})
export class FormQuestionErrorBootstrapComponent {
    @Input() control: AbstractControl;

    constructor(private formValidationService: FormValidationMessageService, private routingService: RoutingService) {}

    get errorMessage() {
        for (const propertyName in this.control.errors) {
            if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched && this.control.dirty) {
                return this.formValidationService.getValidatorErrorMessage(propertyName, this.routingService.currentLogicalUnitId, this.control, this.control.errors[propertyName]);
            }
        }

        return null;
    }
}

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[required]',
})
export class FormQuestionRequiredDirective implements OnChanges {
    @Input() required: string;
    private requiredEl: HTMLElement;
    private formGroupEl: HTMLElement;
    private labelEl: HTMLLabelElement;
    private inputEl: HTMLInputElement;
    private selectEl: HTMLSelectElement;

    constructor(private el: ElementRef) {
        this.el.nativeElement.style.position = 'relative';
    }

    ngOnChanges(changes: SimpleChanges) {
        this.formGroupEl = this.el.nativeElement.parentElement;
        while (!this.formGroupEl.classList.contains('form-group')) {
            this.formGroupEl = this.formGroupEl.parentElement;
        }
        if (changes['required'].currentValue != null) {
            this.requiredEl = document.createElement('span');
            this.requiredEl.className = 'required-marker';

            this.formGroupEl.classList.add('required');

            this.labelEl = this.formGroupEl.getElementsByTagName('label')[0];
            if (this.labelEl.classList.contains('sr-only')) {
                this.formGroupEl.classList.add('required-no-label');
            }
            this.inputEl = this.formGroupEl.getElementsByTagName('input')[0];
            if (this.inputEl && this.inputEl.classList.contains('input-sm')) {
                this.formGroupEl.classList.add('required-sm');
            }
            this.selectEl = this.formGroupEl.getElementsByTagName('select')[0];
            if (this.selectEl && this.selectEl.classList.contains('input-sm')) {
                this.formGroupEl.classList.add('required-sm');
            }

            this.formGroupEl.insertBefore(this.requiredEl, this.formGroupEl.firstChild);
        } else {
            if (this.requiredEl) {
                this.requiredEl.remove();
            }
            if (this.formGroupEl) {
                this.formGroupEl.classList.remove('required');
            }
        }
    }
}

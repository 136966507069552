import { Validators } from '@angular/forms';

import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

export class U2000_LoginPasswordQuestion extends FormTextboxQuestion {
    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        if (String.isNullOrEmpty(this.key)) {
            this.key = 'password';
        }
        if (String.isNullOrEmpty(this.placeholder)) {
            this.placeholder = 'lblPassword';
        }

        this.type = 'password';
        this.size = 'small';

        if (String.isNullOrEmpty(this.width)) {
            this.width = '100%';
        }
        if (this.maxLength == null) {
            this.maxLength = 128;
        }
        if (this.validators != null) {
            if (this.validators.find(x => x === Validators.required) == null) {
                this.validators.push(Validators.required);
            }
        } else {
            this.validators = [Validators.required];
        }

        if (String.isNullOrEmpty(this.label) && this.placeholder !== '') {
            this.labelClass += ' sr-only';
        }
        this.isInputGroup = true;
        this.hideRequiredMarker = true;
    }
}

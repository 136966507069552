import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Mixin } from '../../../../gamma/core';
import { HttpService } from '../../../../gamma/http';
import { I18nService } from '../../../../gamma/i18n';
import { DomService } from '../../../../gamma/utils';

import { U2000_AlertComponentMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-component.mixin';
import { U2000_AlertServiceMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-service.mixin';
import { U2000_ChooseCommunicationComponent } from './U2000_choosecommunication/U2000_choosecommunication.component';
import { U2000_CompletedComponent } from './U2000_completed/U2000_completed.component';
import { U2000_SecurityQuestionComponent } from './U2000_securityquestion/U2000_securityquestion.component';
import { U2000_VerifyCodeComponent } from './U2000_verifycode/U2000_verifycode.component';
import { IU2000_VerifyIdentityResult, U2000_VerifyIdentityNavigationStepsEnum } from './U2000_verifyidentity';
import { U2000_VerifyIdentityService } from './U2000_verifyidentity.service';

@Component({
    selector: 'u2000-verifyidentity',
    templateUrl: 'U2000_verifyidentity.component.html',
    styleUrls: ['U2000_verifyidentity.component.scss'],
    providers: [U2000_VerifyIdentityService],
})
@Mixin([U2000_AlertComponentMixin])
export class U2000_VerifyIdentityComponent implements OnInit, OnDestroy, U2000_AlertComponentMixin {
    @Input() workflowReference: string;
    @Input() uniqueId: string;
    @Output() valid = new EventEmitter<IU2000_VerifyIdentityResult>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() error = new EventEmitter<HttpErrorResponse>();

    //#region Mixin requirements
    // Alert mixin requirements
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    initAlertWithoutService: () => void;
    linkAlertWithService: (service: U2000_AlertServiceMixin) => void;
    destroyAlertComponent: () => void;
    alertClosed: (alert: IU2000_Alert) => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    alerts: IU2000_Alert[];
    //#endregion

    loadingObs: Observable<any>;

    constructor(
        private service: U2000_VerifyIdentityService,
        public injector: Injector,
        // Alert mixin requirements
        public i18nService: I18nService,
        public domService: DomService,

        private httpService: HttpService,
    ) {
        this.service.valid = this.valid;
        this.service.error = this.error;

        this.linkAlertWithService(service);
    }

    ngOnDestroy() {
        this.httpService.removeInterceptor(this.service.workflowInterceptor);
        this.destroyAlertComponent();
    }

    ngOnInit() {
        this.httpService.addInterceptor(this.service.workflowInterceptor);
        this.loadingObs = this.service.resolve(this.workflowReference, this.uniqueId);
    }

    get currentNavigationComponent() {
        if (this.service.currentNavigationComponentId === U2000_VerifyIdentityNavigationStepsEnum.chooseCommunication) {
            return U2000_ChooseCommunicationComponent;
        } else if (this.service.currentNavigationComponentId === U2000_VerifyIdentityNavigationStepsEnum.verifyCode) {
            return U2000_VerifyCodeComponent;
        } else if (this.service.currentNavigationComponentId === U2000_VerifyIdentityNavigationStepsEnum.securityQuestion) {
            return U2000_SecurityQuestionComponent;
        } else if (this.service.currentNavigationComponentId === U2000_VerifyIdentityNavigationStepsEnum.completed) {
            return U2000_CompletedComponent;
        }
        return null;
    }
}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { U2000_SubNavbarService, U2000_TitleService } from '../U2000_core';
import { U2000_CustomPageComponent } from './U2000_custompage.component';
import { U2000_CustomPageService } from './U2000_custompage.service';

@Component({
    selector: 'u2000-major-error',
    templateUrl: 'U2000_custompage.component.html',
    styleUrls: ['U2000_custompage.component.scss'],
})
export class U2000_MajorErrorComponent extends U2000_CustomPageComponent {
    constructor(titleService: U2000_TitleService, subNavbarService: U2000_SubNavbarService, protected override customPageService: U2000_CustomPageService, protected override route: ActivatedRoute) {
        super(titleService, subNavbarService, customPageService, route);

        if (navigator.language || navigator['userLanguage'] === 'fr') {
            this.pageParams = {
                type: 'danger',
                title: 'Service temporairement indisponible',
                message: `Nous travaillons présentement à rétablir la situation.
                        Nous nous excusons des inconvénients qui ont pu vous être occasionnés.`,
            };
        } else {
            this.pageParams = {
                type: 'danger',
                title: 'Service temporarily unavailable',
                message: `We are currently working on fixing the problem.
                        We apologize for any inconvenient caused by this error.`,
            };
        }

        document.getElementsByTagName('footer')[0].classList.add('hidden');
    }
}

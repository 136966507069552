import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { I18nService } from '../../gamma/i18n';

export interface IU2000_CustomPageParams {
    title?: string;
    message: string;
    substitutionObj?: any;
    type: IU2000_CustomPageType;
}

@Injectable()
export class U2000_CustomPageService {
    public customHistory: { [id: number]: IU2000_CustomPageParams };

    constructor(private router: Router, private i18nService: I18nService) {
        this.customHistory = {};
    }

    public display(customPageParams?: IU2000_CustomPageParams) {
        const id = this.genId();

        if (customPageParams != null) {
            customPageParams.message = this.i18nService.formatLabel(customPageParams.message, customPageParams.substitutionObj);
            this.customHistory[id] = customPageParams;
        }

        this.router.navigate([`/U2000/c/${id}/`]);
    }

    private genId() {
        let id: number;
        while (id == null) {
            id = Math.floor(Math.random() * 90000) + 10000;
            if (this.customHistory[id] != null) {
                id = null;
            }
        }
        return id;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

@Injectable()
export class U2000_VerifyIdentityModalService {
    // There to avoid using the constructor's name that is not available when minified.
    static className = 'U2000_VerifyIdentityModalService';

    constructor(public http: HttpClient) {}

    validateSecurityKey(uniqueId: string, identityVerificationKey: string) {
        let url = environment.apiUrl + 'U2000/verifyIdentity/validatesecuritykey';
        url += '?uniqueId=' + uniqueId;
        url += '&identityVerificationKey=' + identityVerificationKey;

        return this.http.get<IU2000_VerifyIdentitySecurityKeyDtoResponse>(url).pipe(
            map(res => {
                return res.result;
            }),
        );
    }
}

import { Injectable } from '@angular/core';

import { I18nService } from '../../../gamma/i18n';
import { IReferential, IReferentialProvider, ReferentialService } from '../../../gamma/referential/referential.service';

declare module '../../../gamma/referential/referential.service' {
    interface IReferential {
        labels?: ILabelDto[];
    }
}

@Injectable()
export class U2000_ReferentialProvider implements IReferentialProvider {
    service: ReferentialService;

    constructor(private i18nService: I18nService) {}

    getDescription(referential: IReferential) {
        if (referential.labels.length === 1 || referential.labels[0].inLanguageId == null) {
            return referential.labels[0].description;
        } else {
            if (this.i18nService.currentLanguageCode === 'en') {
                return referential.labels.find(x => x.inLanguageId === 1).description;
            } else {
                return referential.labels.find(x => x.inLanguageId === 0).description;
            }
        }
    }
}

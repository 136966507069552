import { U2000_BusyIndicatorComponent } from './U2000_workspace/U2000_busyindicator/U2000_busyindicator.component';
import { U2000_DevelopmentBarComponent } from './U2000_workspace/U2000_developmentbar/U2000_developmentbar.component';
import { U2000_FooterComponent } from './U2000_workspace/U2000_footer/U2000_footer.component';
import { U2000_ApplicationsMenuComponent } from './U2000_workspace/U2000_header/U2000_applicationsmenu/U2000_applicationsmenu.component';
import { U2000_HeaderComponent } from './U2000_workspace/U2000_header/U2000_header.component';
import { U2000_ProfileButtonComponent } from './U2000_workspace/U2000_header/U2000_profilebutton/U2000_profilebutton.component';
import { U2000_ReportMenuComponent } from './U2000_workspace/U2000_header/U2000_reportmenu/U2000_reportmenu.component';
import { U2000_LanguageComponent } from './U2000_workspace/U2000_language/U2000_language.component';
import { U2000_SubNavBarComponent } from './U2000_workspace/U2000_subnavbar/U2000_subnavbar.component';
import { U2000_WorkspaceComponent } from './U2000_workspace/U2000_workspace.component';

export const U2000_WorkspaceRoutedComponents = [
    U2000_WorkspaceComponent,
    U2000_BusyIndicatorComponent,
    U2000_HeaderComponent,
    U2000_FooterComponent,
    U2000_SubNavBarComponent,
    U2000_LanguageComponent,
    U2000_ProfileButtonComponent,
    U2000_ApplicationsMenuComponent,
    U2000_ReportMenuComponent,
    U2000_DevelopmentBarComponent,
];

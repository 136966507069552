import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

export class U2000_WebsiteQuestion extends FormTextboxQuestion {
    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        if (String.isNullOrEmpty(this.key)) {
            this.key = 'webSite';
        }
        if (String.isNullOrEmpty(this.label)) {
            this.label = 'lblWebsite';
        }
        if (this.maxLength == null) {
            this.maxLength = 100;
        }
        if (String.isNullOrEmpty(this.width)) {
            this.width = '40em';
        }
    }
}

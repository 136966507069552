import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { I18nService } from '../../../../i18n';
import { UserAgentService } from '../../../../utils/user-agent.service';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormDropdownQuestion } from '../../formquestion-dropdown';

@Component({
    selector: 'form-dropdown-bootstrap',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class FormDropdownBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormDropdownQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, private el: ElementRef, private userAgentService: UserAgentService, private translateService: TranslateService) {
        super(i18nService);

        this.translateService.setTranslation(
            'fr',
            {
                sysSelect: 'Sélectionner',
            },
            true,
        );
        this.translateService.setTranslation(
            'en',
            {
                sysSelect: 'Select',
            },
            true,
        );
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);

        /* this.question.formControlRef.valueChanges.subscribe(x => {
            if (this.question.onChange) {
                this.question.onChange(null, this.question);
            }
        }); */

        // SAIA-9224: Some browsers auto select the first option of a dropdown when the value of this field is set to null.
        // To bypass this, we add a timeout that waits a frame before directly modifying the selected index to -1 when the value is null.
        if (this.userAgentService.isInternetExplorer() || this.userAgentService.isEdge() || this.userAgentService.isSafari() || this.userAgentService.isMobile()) {
            this.question.formControlRef.valueChanges.subscribe(x => {
                if (x == null) {
                    setTimeout(() => {
                        if (document.getElementById(this.question.key)) {
                            (document.getElementById(this.question.key) as HTMLSelectElement).selectedIndex = -1;
                        }
                    }, 0);
                }
            });
        }
    }

    onChange(event: Event) {
        if (this.question.onChange) {
            this.question.onChange(event, this.question);
        }
    }

    getDefaultLabel() {
        if (this.question.defaultLabel) {
            return this.formatLabel(this.question.defaultLabel);
        }
        return this.translateService.instant('sysSelect');
    }
}

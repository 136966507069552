import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FormBaseComponent } from '../../../../gamma/form/form-base.component';
import { FormService } from '../../../../gamma/form/form.service';
import { FormTextboxQuestion } from '../../../../gamma/form/formquestion/formquestion-textbox';
import { FormValidationMessageService } from '../../../../gamma/form/formvalidationmessage.service';
import { RoutingService } from '../../../../gamma/routing';
import { ToastService } from '../../../../gamma/toast';
import { DomService } from '../../../../gamma/utils/dom.service';

import { U2011_LoginService } from '../../U2011_login.service';

@Component({
    selector: 'u2011-organizationselection-form',
    templateUrl: 'U2011_organizationselection-form.component.html',
    providers: [FormService],
    styleUrls: ['U2011_organizationselection-form.component.scss'],
})
export class U2011_OrganizationSelectionFormComponent extends FormBaseComponent<U2011_LoginService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'OrganizationSelection';

    @Input() organizations: ICurrentAgentTenantDto[];
    @Output() filterChange: EventEmitter<string>;
    qSearch: FormTextboxQuestion;
    constructor(
        service: U2011_LoginService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.filterChange = new EventEmitter();
        this.qSearch = new FormTextboxQuestion({
            key: 'search',
            placeholder: 'lblSearch',
            autoFocus: true,
            onInput: x => {
                this.filterChange.emit(this.qSearch.value);
            },
        });

        this.questions = [this.qSearch];
    }

    ngOnInit() {
        this.initForm({ skipFormGuardIfDirty: true });
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    onSubmit() {
        this.service.clearAlerts();
        this.showErrors(this.form.controls);

        this.filterChange.emit(this.qSearch.value);
    }
}

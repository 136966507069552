import { Injector } from '@angular/core';

import { IProvider, IServiceProviderConfig } from './serviceprovider.interface';

/**
 * Class that a service needs to extend from when a provider is needed.
 *
 * @export
 * @abstract
 * @class ServiceProviderBase
 * @template T The provider's constructor.
 * @template U The
 */
export abstract class ServiceProviderBase<T extends IProvider<U>, U> {
    /**
     * Provider for the service.
     * @property provider
     * @type {T}
     * @protected
     * @memberof ServiceProviderBase
     */
    public provider: T;

    constructor(injector: Injector, config: IServiceProviderConfig<T> = {}) {
        if (config && config.provider) {
            this.registerProvider(injector.get(config.provider));
        }
    }

    /**
     * Register the provider to the service.
     * @method registerProvider
     * @param {T} provider The provider to register.
     * @memberof ServiceProviderBase
     */
    public registerProvider(provider: T): void {
        this.provider = provider;
        this.provider.service = this as any;
        if (this.provider.registered) {
            this.provider.registered();
        }
    }
}

import { Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { FormReCaptchaQuestion } from '../../formquestion-recaptcha';

declare const grecaptcha: any;

declare global {
    interface Window {
        grecaptcha: any;
        reCaptchaOnLoad: () => void;
    }
}

export interface ReCaptchaConfig {
    theme?: 'dark' | 'light';
    type?: 'audio' | 'image';
    size?: 'compact' | 'normal';
    tabindex?: number;
}

@Component({
    selector: 'form-control-recaptcha',
    templateUrl: './recaptcha-control.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormReCaptchaControlComponent),
            multi: true,
        },
    ],
})
export class FormReCaptchaControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() question: FormReCaptchaQuestion;
    @Input() form: UntypedFormGroup;

    @Output() captchaResponse = new EventEmitter<string>();
    @Output() captchaExpired = new EventEmitter();

    private widgetId: number;
    private captchaElement: any;

    private onChange: (value: string) => void;
    private onTouched: (value: string) => void;

    constructor(private element: ElementRef, private ngZone: NgZone, private i18nService: I18nService) {}

    ngOnInit() {
        this.registerReCaptchaCallback();
        this.addScript();
    }

    ngOnDestroy() {
        this.reset();
    }

    registerReCaptchaCallback() {
        window.reCaptchaOnLoad = () => {
            const config = {
                ...this.question.config,
                sitekey: this.question.publicKey,
                callback: this.onSuccess.bind(this),
                'expired-callback': this.onExpired.bind(this),
            };
            this.captchaElement = this.element.nativeElement;

            this.widgetId = this.render(this.element.nativeElement, config);
        };
    }

    /**
     * Useful for multiple captcha
     * @returns {number}
     */
    getId() {
        return this.widgetId;
    }

    writeValue(obj: any): void {
        /* Must be empty */
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * onExpired
     */
    onExpired() {
        this.ngZone.run(() => {
            this.captchaExpired.emit();
            this.onChange(null);
            this.onTouched(null);
        });
    }

    /**
     *
     * @param response
     */
    onSuccess(token: string) {
        this.ngZone.run(() => {
            // this.verifyToken(token);
            this.captchaResponse.next(token);
            this.onChange(token);
            this.onTouched(token);
        });
    }

    /**
     *
     * @param token
     */
    /*verifyToken(token: string) {
        this.control.setAsyncValidators(this.reCaptchaAsyncValidator.validateToken(token))
        this.control.updateValueAndValidity();
    }*/

    /**
     * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
     * @param element
     * @param config
     * @returns {number}
     */
    private render(element: HTMLElement, config): number {
        return grecaptcha.render(element, config);
    }

    /**
     * Resets the reCAPTCHA widget.
     */
    reset(): void {
        if (this.widgetId === undefined) {
            return null;
        }

        while (this.captchaElement.firstChild) {
            this.captchaElement.removeChild(this.captchaElement.firstChild);
        }

        // To avoid a non-fixed zone timeout error when the recaptcha is reset.
        this.ngZone.runOutsideAngular(() => {
            grecaptcha.reset(this.widgetId);
        });
        this.onChange(null);
    }

    /**
     * Gets the response for the reCAPTCHA widget.
     * @returns {string}
     */
    getResponse(): string {
        if (!this.widgetId) {
            return grecaptcha.getResponse(this.widgetId);
        }
        return '';
    }

    /**
     * Add the script
     */
    addScript() {
        if (!window.grecaptcha) {
            const script = document.createElement('script');
            const lang = this.i18nService.currentLanguageCode;
            script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaOnLoad&render=explicit&hl=${lang}`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        } else {
            window.reCaptchaOnLoad();
        }
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { RoutingService } from '../../../gamma/routing';

import { U2000_AuthenticationService } from '../U2000_services/U2000_authentication.service';

@Injectable()
export class U2000_AuthenticationGuard implements CanActivate {
    constructor(private authService: U2000_AuthenticationService, private routingService: RoutingService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let luId = '';
        let targetTenant: IU2011_TargetTenantId = null;
        if (state != null) {
            // Route non modale.
            targetTenant = this.queryParamsTargetTenant(state.root.queryParams);
            if (targetTenant) {
                // Extract the logical unit id from the url.
                luId = state.url.match(/U\d\d\d\d/)[0];
                targetTenant.errorMessage = luId + '-TargetTenantError' + (state.root.queryParams['targetTenantType'] === '0' ? '_Perso' : '');

                if (this.authService.tenantId !== targetTenant.tenantId) {
                    this.authService.logoutWithoutRedirect();
                }
            }
        }

        if (this.authService.isLoggedIn) {
            return true;
        }

        const params: IU2011_LogicalUnitParams = {
            returningUrl: state.url,
            targetTenantId: targetTenant,
        };

        if (state != null) {
            // Route non modale.
            if (targetTenant) {
                params.alert = {
                    type: 'warning',
                    message: luId + '-TargetTenantWarning' + (state.root.queryParams['targetTenantType'] === '0' ? '_Perso' : ''),
                    translationOptions: {
                        targetTenantName: state.root.queryParams['targetTenantName'],
                    },
                };
            }
        }

        this.routingService.navigateWithLUParams<IU2011_LogicalUnitParams>(['/U2011'], 'U2011', params);

        return false;
    }

    private queryParamsTargetTenant(queryParams: any) {
        if (queryParams.hasOwnProperty('targetTenantId')) {
            if (!String.isNullOrEmpty(queryParams.targetTenantId)) {
                const target: IU2011_TargetTenantId = {
                    tenantId: parseInt(queryParams.targetTenantId, 0),
                };
                return target;
            }
        }
        return null;
    }
}

import { EventEmitter, Inject, Injectable, InjectionToken, Injector, Optional, SkipSelf } from '@angular/core';

import { environment } from '../../environments/environment';
import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { I18nService } from '../i18n/i18n.service';

export const BusyIndicatorServiceConfig = new InjectionToken<IServiceProviderConfig<IBusyIndicatorProvider>>('BUSY_INDICATOR_SERVICE_CONFIG');

/**
 * Busy indicator state interface
 *
 * @export
 * @interface IBusyIndicatorState
 */
export interface IBusyIndicatorState {
    /**
     * State displayed or not.
     *
     * @type {boolean}
     * @memberof IBusyIndicatorState
     */
    displayed: boolean;

    /**
     * Message displayed with the busy indicator.
     *
     * @type {string}
     * @memberof IBusyIndicatorState
     */
    message?: string;

    /**
     *  Hide the busy indicator's visual but keep its security glass.
     *
     * @type {string}
     * @memberof IBusyIndicatorState
     */
    invisible?: boolean;

    /**
     * Spinner hidden or not.
     *
     * @type {boolean}
     * @memberof IBusyIndicatorState
     */
    hideSpinner?: boolean;
}

/**
 * Busy indicator show parameters
 *
 * @export
 * @interface IBusyIndicatorParams
 */
export interface IBusyIndicatorParams {
    /**
     * Dont show the busy indicator.
     *
     * @type {boolean}
     * @memberof BusyIndicatorState
     */
    silent?: boolean;

    /**
     * Message displayed with the busy indicator.
     *
     * @type {string}
     * @memberof BusyIndicatorState
     */
    message?: string;

    /**
     * Hide the busy indicator's visual but keep its security glass.
     *
     * @type {string}
     * @memberof BusyIndicatorState
     */
    invisible?: boolean;

    /**
     * Dont show the spinner.
     *
     * @type {boolean}
     * @memberof BusyIndicatorState
     */
    hideSpinner?: boolean;
}

/**
 * Provider interface of the busy indicator.
 *
 * @export
 * @interface IBusyIndicatorProvider
 * @extends {IProvider<BusyIndicatorService>}
 */
export interface IBusyIndicatorProvider extends IProvider<BusyIndicatorService> {
    /**
     * Track the state change.
     *
     * @param {boolean} displayed
     *
     * @memberof IBusyIndicatorProvider
     */
    onStateChange(displayed: boolean): void;
}

/**
 * Service used to manage the visibility of the busy indicator (Indicator thaht the application is currently working or waiting).
 *
 * @export
 * @class BusyIndicatorService
 * @extends {ServiceProviderBase<IBusyIndicatorProvider, BusyIndicatorService>}
 */
@Injectable()
export class BusyIndicatorService extends ServiceProviderBase<IBusyIndicatorProvider, BusyIndicatorService> {
    private count: number;

    private _hidden: boolean;

    /**
     * The event is broadcast when the state of the busy indicator changes.
     *
     * @type {EventEmitter<IBusyIndicatorState>}
     * @memberof BusyIndicatorService
     * @example
     *      busyIndicatorService.stateChange$.subscribe(state => {
     *          if (state.displayed) {
     *              // Your code here
     *          }
     *      });
     */
    public stateChange$: EventEmitter<IBusyIndicatorState>;

    /**
     * The event is broadcast when the visibility of the busy indicator changes.
     *
     * @type {EventEmitter<boolean>}
     * @memberof BusyIndicatorService
     * @example
     *      busyIndicatorService.displayChange$.subscribe(visible => {
     *          if (visible) {
     *              // Your code here
     *          }
     *      });
     */
    public displayChange$: EventEmitter<boolean>;

    /**
     * The event is broadcast when the message of the busy indicator changes.
     *
     * @type {EventEmitter<string>}
     * @memberof BusyIndicatorService
     * @example
     *      busyIndicatorService.messageChange$.subscribe(msg => {
     *          // Your code here
     *      });
     */
    public messageChange$: EventEmitter<string>;

    /**
     * Creates an instance of BusyIndicatorService.
     * @param {BusyIndicatorService} prior
     * @param {Injector} injector
     * @param {IServiceProviderConfig<IBusyIndicatorProvider>} config
     *
     * @memberof BusyIndicatorService
     */

    private id: number;
    private messages: { id: number; message: string }[];
    logShowHide = false;

    constructor(
        @Optional() @SkipSelf() prior: BusyIndicatorService,
        private i18nService: I18nService,
        injector: Injector,
        @Inject(BusyIndicatorServiceConfig) config: IServiceProviderConfig<IBusyIndicatorProvider>,
    ) {
        super(injector, config);

        if (prior) {
            return prior;
        }
        this.count = 0;
        this.id = 0;
        this.messages = [];

        this.stateChange$ = new EventEmitter<IBusyIndicatorState>();
        this.displayChange$ = new EventEmitter<boolean>();
        this.messageChange$ = new EventEmitter<string>();

        // Add a quick access via the console for developpers.
        if (!environment.production) {
            window['busyIndicatorService'] = this;
        }
    }

    /**
     * Display the busy indicator.
     *
     * @param {boolean} [silent=false] Count as a show without displaying it.
     * @param {string} [meassage=null] Message displayed with the busy indicator. Whether a string or a variable.
     *
     * @memberof BusyIndicatorService
     * @example
     *      busyIndicatorService.show();
     */
    show(params: IBusyIndicatorParams, trackingComment?: string) {
        if (params == null) {
            params = {};
        }
        const id = this.id++;
        if (params.message != null) {
            this.messages.push({ id, message: this.i18nService.formatLabel(params.message) });
            this.messageChange$.emit(this.message);
        }
        if (this.count === 0 && !params.silent) {
            this.stateChange$.emit({ displayed: true, message: params.message, invisible: params.invisible, hideSpinner: params.hideSpinner });
            this.displayChange$.emit(true);
        }

        this.count = this.count + 1;
        this._hidden = false;
        if (this.logShowHide) {
            console.log('BusyIndicator - Show = ' + id + ': ' + trackingComment);
        }
        return id;
    }

    /**
     * Hide the busy indicator
     *
     * @memberof BusyIndicatorService
     * @example
     *      busyIndicatorService.hide();
     */
    hide(id?: number, trackingComment?: string) {
        this.count = this.count - 1;
        if (this.logShowHide) {
            console.log('BusyIndicator - Hide = ' + id + ': ' + trackingComment);
        }

        if (id != null) {
            for (let i = 0; i < this.messages.length; i++) {
                if (this.messages[i].id === id) {
                    this.messages.splice(i, 1);
                    this.messageChange$.emit(this.message);
                    break;
                }
            }
        }

        if (this.count <= 0) {
            this.stateChange$.emit({ displayed: false, message: this.message });
            this.displayChange$.emit(false);
            this.messageChange$.emit(null);
            this.count = 0;
            this._hidden = true;
        }
    }

    get message() {
        if (this.messages.length > 0) {
            return this.messages[this.messages.length - 1].message;
        } else {
            return null;
        }
    }

    get hidden() {
        return this._hidden;
    }
}

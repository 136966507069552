import { IFormValidationMessage } from '../../../gamma/form/formvalidationmessage.service';

export class U2000_ValidationMessages {
    static required_0001: IFormValidationMessage = {
        code: 'required',
        message: 'U2000-0001',
    };

    static validEmail_0002: IFormValidationMessage = {
        code: 'validEmail',
        message: 'U2000-0002',
    };

    static validPassword_0003: IFormValidationMessage = {
        code: 'validPassword',
        message: 'U2000-0003',
    };

    static validUsername_0004: IFormValidationMessage = {
        code: 'validUsername',
        message: 'U2000-0004',
    };

    static validMinLength_0009: IFormValidationMessage = {
        code: 'minlength',
        message: 'U2000-0009',
    };
    static invalidUsernamePassword_0010: IFormValidationMessage = {
        code: 'invalidUsernamePassword',
        message: 'U2000-0010',
    };

    static validPhoneNumber_0011: IFormValidationMessage = {
        code: 'validPhoneNumber',
        message: 'U2000-0011',
    };

    static validPostalCode_0012: IFormValidationMessage = {
        code: 'validPostalCode',
        message: 'U2000-0012',
    };

    static matchEmail_0014: IFormValidationMessage = {
        code: 'matchEmail',
        message: 'U2000-0014',
        groupMessage: true,
    };

    static validDate_0015: IFormValidationMessage = {
        code: 'validDate',
        message: 'U2000-0015',
    };

    static startDateBeforeEndDate_0016: IFormValidationMessage = {
        code: 'startDateBeforeEndDate',
        message: 'U2000-0016',
        groupMessage: true,
    };

    static matchPassword_0018: IFormValidationMessage = {
        code: 'matchPassword',
        message: 'U2000-0018',
    };

    static minValue_0025: IFormValidationMessage = {
        code: 'min',
        message: 'U2000-0025',
    };

    static maxValue_0026: IFormValidationMessage = {
        code: 'max',
        message: 'U2000-0026',
    };

    static validWebsite_0042: IFormValidationMessage = {
        code: 'validWebsite',
        message: 'U2000-0042',
    };
}

import { FormQuestionType } from '../form.service';
import { FormQuestionControlBase, IFormQuestionControlBase } from './formquestioncontrol-base';

/**
 * Interface to define a checkbox question.
 *
 * @export
 * @interface IFormRadioButtonQuestion
 * @extends {IFormQuestionControlBase<boolean>}
 */
export interface IFormRadioButtonQuestion extends IFormQuestionControlBase<string> {
    /**
     * Alternate label displayed beside the check box.
     *
     * @type {string}
     * @memberof IFormRadioButtonQuestion
     */
    alternateLabel?: string;

    /**
     * Display the RadioButton in a more condensed way (To use to when you want to regroup )
     *
     * @type {boolean}
     * @memberof IFormRadioButtonQuestion
     */
    condensed?: boolean;

    alternateLabelEvents?: { context: any; event: () => void }[];
}

/**
 * RadioButton question type.
 *
 * @export
 * @class FormRadioButtonQuestion
 * @extends {FormQuestionControlBase<boolean>}
 */
export class FormRadioButtonQuestion extends FormQuestionControlBase<string> {
    override formQuestionType = FormQuestionType.RadioButton;

    /**
     * Alternate label displayed beside the check box.
     *
     * @type {string}
     * @memberof IFormRadioButtonQuestion
     */
    alternateLabel: string;

    /**
     * Display the RadioButton in a more condensed way (To use to when you want to regroup )
     *
     * @type {boolean}
     * @memberof IFormRadioButtonQuestion
     */
    condensed: boolean;

    alternateLabelEvents: { context: any; event: () => void }[];

    /**
     * Creates an instance of FormRadioButtonQuestion.
     * @param {IFormQuestionControlBase<string>} [options={}] Creation options.
     *
     * @memberof FormRadioButtonQuestion
     */
    constructor(options: IFormRadioButtonQuestion) {
        super(options);
        this.alternateLabel = options.alternateLabel;
        this.condensed = options.condensed || false;
        this.alternateLabelEvents = options.alternateLabelEvents || [];
    }
}

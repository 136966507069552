import { Injectable } from '@angular/core';

/**
 * Service for Date utils.
 *
 * @export
 * @class DateService
 */
@Injectable()
export class DateService {
    /**
     * The milliseconds per second
     *
     * @static
     * @memberof DateService
     */
    static MILLISECONDS_PER_SECOND = 1000;

    /**
     *  The DateDiff function returns the number of intervals between two dates.
     *  The interval value can take the following values:
     *  - "d" Day
     *  - "h" Hour
     *  - "n" Minute
     *  - "s" Second
     *  @method dateDiff
     *  @static
     *  @param {string} t_Interval The interval you want to use to calculate the differences between date1 and date2.
     *  @param {Date} t_Date1 Date expressions one.
     *  @param {Date} t_Date2 Date expressions two.
     *  @return {number} Returns the number of intervals between two dates.
     *  @since 1.3.0
     *  @example
     *      let my_Date1: Date = fui.DateUtils.strToDate("2015-01-03');
     *      let my_Date2: Date = fui.DateUtils.strToDate("2015-01-01');
     *      dateService.dateDiff("d", my_Date1, my_Date2)); // Returns "2".
     */
    public dateDiff(t_Interval: string, t_Date1: Date, t_Date2: Date): number {
        let my_Diff: number;
        let my_Number: number;
        let my_Date: Date;

        t_Interval = t_Interval.toLowerCase();
        if (t_Interval === 'd') {
            my_Diff = t_Date1.valueOf() - t_Date2.valueOf();
            return Math.round(my_Diff / 86400000);
        } else if (t_Interval === 'm') {
            my_Date = new Date(t_Date1.getTime());
            my_Diff = 0;
            while (my_Date > t_Date2) {
                my_Date.setMonth(my_Date.getMonth() - 1);
                my_Diff++;
            }
            return my_Diff;
        } else {
            if (t_Interval === 'h') {
                // Hour.
                my_Diff = 3600000; // Value = 1000 miliseconds * 60 sec * 60 * minutes.
            } else if (t_Interval === 'n') {
                // Minute.
                my_Diff = 60000; // Value = 1000 miliseconds * 60 sec.
            } else if (t_Interval === 's') {
                // Seconde.
                my_Diff = 1000;
            } else {
                // Day.
                my_Diff = 86400000; // Value = 1000 miliseconds * 60 sec * 60 * minutes * 24 hours.
            }
            my_Number = (t_Date1.valueOf() - t_Date2.valueOf()) % my_Diff;
            return (t_Date1.valueOf() - t_Date2.valueOf() - my_Number) / my_Diff;
        }
    }

    public isOver18(dateOfBirth: Date) {
        // find the date 18 years ago
        const date18YrsAgo = new Date();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
        // check if the date of birth is before that date
        return dateOfBirth <= date18YrsAgo;
    }

    public getTodayDateWithoutTime() {
        const t = new Date();
        t.setHours(0, 0, 0, 0);
        return t;
    }
}

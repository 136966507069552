import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

import { U2000_Validators } from '../U2000_core/U2000_validators/U2000_validators';

export class U2000_PostalCodeQuestion extends FormTextboxQuestion {
    static schema: IFormTextboxQuestion = {
        key: 'postalCode',
        label: 'lblPostalCode',
        maxLength: 8,
        inputMask: [/[a-zA-Z]/, /\d/, /[a-zA-Z]/, /\d/, /[a-zA-Z]/, /\d/],
        validators: [U2000_Validators.postalCode],
    };

    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        const schema = U2000_PostalCodeQuestion.schema;

        if (String.isNullOrEmpty(this.key)) {
            this.key = schema.key;
        }
        if (String.isNullOrEmpty(this.label)) {
            this.label = schema.label;
        }
        if (this.maxLength == null) {
            this.maxLength = schema.maxLength;
        }

        if (this.inputMask == null || this.inputMask === false) {
            this.inputMask = schema.inputMask;
        }
        if (Array.isNullOrEmpty(this.validators)) {
            this.validators = schema.validators;
        }

        this.onBlur = (event: Event) => {
            if (!String.isNullOrEmpty(this.formControlRef.value)) {
                this.formControlRef.setValue((this.formControlRef.value as string).toUpperCase());
            }
        };
    }
}

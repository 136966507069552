import { Directive, OnDestroy, OnInit } from '@angular/core';

import { ExceptionEvent } from '../exception';
import { ModalDismissGuard } from '../modal/modal-dismiss.guard';
import { ModalBusyIndicatorGuard } from '../modal/modalbusyindicator.guard';
import { RoutingService } from '../routing/routing.service';
import { ToastService } from '../toast';
import { LogicalUnitBaseComponent } from './logicalunit-base.component';
import { LogicalUnitBaseService } from './logicalunit-base.service';
import { IOpenModalLogicalUnitParams } from './logicalunit-modal.interface';

/**
 * Base logical unit component class
 *
 * @export
 * @abstract
 * @class LogicalUnitBaseComponent
 */
@Directive()
export abstract class LogicalUnitModalBaseComponent<T extends LogicalUnitBaseService> extends LogicalUnitBaseComponent<T> implements OnDestroy, OnInit {
    /**
     * Method used to handle non business errors.
     *
     * @memberof LogicalUnitBaseComponent
     * @example
     *      // Handle javascript errors locally.
     *      this.handleError = (event: ExceptionEvent) => {
     *          if (anyCondition) {
     *              // Do not execute the framework behavior.
     *              event.preventDefault();
     *          }
     *      };
     */
    public override handleError: (error: ExceptionEvent) => void;

    /**
     * Method used to modify the url sent to google analytics.
     *
     *
     * @memberof LogicalUnitBaseComponent
     */
    public override gaPageMap: (url: string) => string;

    /**
     * Hook method called when the logical unit is activated.
     *
     * @memberof LogicalUnitBaseService
     */
    public activated: () => void;

    protected override routingService: RoutingService;

    /**
     * Creates an instance of LogicalUnitModalBaseComponent.
     * @param {T} service
     * @param {*} dialog
     * @param {ToastService} toastService
     * @param {RoutingService} routingService
     * @param {ModalDismissGuard} modalDismissGuard
     * @memberof LogicalUnitModalBaseComponent
     */
    constructor(public override service: T, dialog: any, toastService: ToastService, routingService: RoutingService, modalDismissGuard: ModalDismissGuard) {
        super(service, toastService, routingService);

        const openingParams = dialog.context.openingParams as IOpenModalLogicalUnitParams<any>;

        if (Array.isNullOrEmpty(openingParams.canDeactivate)) {
            openingParams.canDeactivate = [];
        }
        if (openingParams.canDeactivate.indexOf(ModalBusyIndicatorGuard) === -1) {
            openingParams.canDeactivate.push(ModalBusyIndicatorGuard);
        }

        modalDismissGuard.setOpeningParams(openingParams);

        dialog.setCloseGuard(modalDismissGuard);
    }

    override ngOnInit() {
        super.ngOnInit();
        this.routingService.openModalLogicalUnit(this.service.logicalUnitId);
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.routingService.closeModalLogicalUnit(this.service.logicalUnitId);
    }
}

import { Injectable } from '@angular/core';

@Injectable()
export class ClipboardService {
    isClipboardEnabled: boolean;

    constructor() {
        this.isClipboardEnabled = false;

        if (window['clipboardData'] && window['clipboardData'].setData) {
            // IE support only.
            this.isClipboardEnabled = true;
        } else if (document.queryCommandSupported('copy')) {
            this.isClipboardEnabled = true;
        }
    }

    copyToClipboard(data: string) {
        if (window['clipboardData'] && window['clipboardData'].setData) {
            // IE only

            return window['clipboardData'].setData('text', data);
        } else {
            const element = document.createElement('textarea');
            element.value = data;
            element.textContent = data;
            document.body.appendChild(element);
            element.select();
            const success = document.execCommand('copy');
            document.body.removeChild(element);

            return success;
        }
    }
}

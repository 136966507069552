import { Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';
import { BSModalContext, MessageModalPreset, MessageModalPresetBuilder, Modal } from 'ngx-modialog-7/plugins/bootstrap';

import { I18nService } from '../../../gamma/i18n';
import { IModalAlertOptions, IModalConfirmOptions, IModalDialogOptions, IModalOptions, IModalPromptOptions, IModalProvider, ModalService } from '../../../gamma/modal';
import { IOpenModalComponentParams } from '../../../gamma/modal/modal.service';
import { UserAgentService } from '../../../gamma/utils';

declare module '../../../gamma/modal/modal.service' {
    interface IOpenModalComponentParams {
        options?: MessageModalPreset | { [key: string]: any };
    }
}

@Injectable()
export class U2000_ModalProvider implements IModalProvider {
    service: ModalService;

    private i18nService: I18nService;
    private translateService: TranslateService;
    private openedDialogs: DialogRef<any>[];

    constructor(public injector: Injector, public modal: Modal, private userAgentService: UserAgentService) {
        this.openedDialogs = [];
    }

    initInjectedElement() {
        if (this.i18nService == null) {
            this.i18nService = this.injector.get(I18nService);
        }
        if (this.translateService == null) {
            this.translateService = this.injector.get(TranslateService);
        }
    }

    prompt(options: IModalPromptOptions): Promise<any> {
        const prompt = this.modal.prompt();

        this.applyModalOptions(prompt, options);

        prompt.cancelBtnClass('btn btn-default btn-acceo btn-acceo-adjust');
        if (options && options.cancelText) {
            prompt.cancelBtn(this.i18nService.formatLabel(options.cancelText));
        } else {
            prompt.cancelBtn(this.translateService.instant('lblCancel'));
        }

        return prompt.open().result;
    }

    alert(options: IModalAlertOptions): Promise<any> {
        const alert = this.modal.alert();

        this.applyModalOptions(alert, options);

        return alert.open().result;
    }

    confirm(options: IModalConfirmOptions): Promise<any> {
        this.initInjectedElement();
        const confirm = this.modal.confirm();

        if (!options || !options.title) {
            confirm.title(this.translateService.instant('lblConfirm'));
        }

        confirm.cancelBtnClass('btn btn-default btn-acceo btn-acceo-adjust');
        if (options && options.cancelButtonText) {
            confirm.cancelBtn(this.i18nService.formatLabel(options.cancelButtonText));
        } else {
            confirm.cancelBtn(this.translateService.instant('lblCancel'));
        }

        this.applyModalOptions(confirm, options);

        return confirm.open().result;
    }

    dialog(options: IModalDialogOptions): Promise<any> {
        const overlayConfig = this.createModalOptions(options);

        return this.modal.open('', overlayConfigFactory(overlayConfig, BSModalContext)).result;
    }

    openComponent<U>(params: IOpenModalComponentParams, callback?: (result: U) => void, cancelCallback?: () => void) {
        const dialogRef = this.modal.open(params.component, overlayConfigFactory(params.options, BSModalContext, { injector: params.injector }));
        /* iOS hack, must deactivate/reactivate the overflow prop.,
        otherwise the modal cannot scroll in portrait resolution in some cases.*/
        if (this.userAgentService.isMobile()) {
            setTimeout(() => {
                document.getElementsByTagName('bs-modal-container')[0]['style'].overflowY = 'hidden';
            }, 1);

            setTimeout(() => {
                document.getElementsByTagName('bs-modal-container')[0]['style'].overflowY = 'auto';
            }, 100);
        }
        this.openedDialogs.push(dialogRef);

        dialogRef.result.then(
            (result: U) => {
                this.openedDialogs.pop();
                if (callback) {
                    callback(result);
                }
            },
            () => {
                this.openedDialogs.pop();
                if (cancelCallback) {
                    cancelCallback();
                }
            },
        );
    }

    closeAll() {
        this.openedDialogs.forEach(x => {
            x.setCloseGuard(null);
            x.close();
        });
        this.openedDialogs = [];
    }

    hideAll(): void {
        throw new Error('Dialog not supported by the provider');
    }

    createModalOptions(options: IModalOptions) {
        this.initInjectedElement();
        const overlayConfig: MessageModalPreset = {} as any;

        if (options && options.size) {
            if (options.size === 'small') {
                overlayConfig.size = 'sm';
            } else if (options.size === 'large') {
                overlayConfig.size = 'lg';
            }
        }
        if (options && options.title) {
            overlayConfig.title = this.i18nService.formatLabel(options.title);
        }
        if (options && options.backdrop) {
            overlayConfig.isBlocking = true;
        }
        if (options && options.closeButton) {
            overlayConfig.showClose = true;
        }
        if (options && options.dialogClass) {
            overlayConfig.dialogClass = options.dialogClass;
        }
        overlayConfig.buttons[0].cssClass = 'btn btn-primary btn-acceo btn-acceo-adjust';

        return overlayConfig;
    }

    applyModalOptions(presetBuilder: MessageModalPresetBuilder<any>, options: IModalOptions) {
        this.initInjectedElement();
        if (options && options.size) {
            if (options.size === 'small') {
                presetBuilder.size('sm');
            } else if (options.size === 'large') {
                presetBuilder.size('lg');
            }
        }
        if (options && options.title) {
            presetBuilder.title(this.i18nService.formatLabel(options.title));
        }
        if (options && options.backdrop) {
            presetBuilder.isBlocking(true);
        }
        if (options && options.closeButton) {
            presetBuilder.showClose(true);
        }
        if (options && options.dialogClass) {
            presetBuilder.dialogClass(options.dialogClass);
        }
        presetBuilder.body(this.i18nService.formatLabel(options.message));

        // For an unknown reason, the translate service is null when injected.
        const translateService = this.injector.get(TranslateService);
        if (presetBuilder['okBtn'] != null) {
            presetBuilder['okBtnClass']('btn btn-primary btn-acceo btn-acceo-adjust');
            if (options && options.okButtonText) {
                presetBuilder['okBtn'](this.i18nService.formatLabel(options.okButtonText));
            } else {
                presetBuilder['okBtn'](translateService.instant('lblOK'));
            }
        }
    }

    scrollTop() {
        document.getElementsByTagName('bs-modal-container')[0].scrollTop = 0;
    }
}

import { EventEmitter, Injectable } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';

import { ISubNavbarParams, ISubNavbarService } from '../../../gamma/subnavbar/subnavbar.interface';

@Injectable()
export class U2000_SubNavbarService implements ISubNavbarService {
    // private _currentSubNavbar: Type<U2000_SubNavbarBaseComponent>
    private defaultSubNavbar: ISubNavbarParams;
    private _currentSubNavbarParams: ISubNavbarParams;
    public subNavbarParamsChanged$: EventEmitter<any> = new EventEmitter();

    constructor(router: Router) {
        this.defaultSubNavbar = { options: [] };

        router.events.subscribe(event => {
            if (event instanceof ResolveEnd) {
                this.reset();
            }
        });
    }

    reset() {
        this.setCurrentSubNavbarParams(this.defaultSubNavbar);
    }

    setCurrentSubNavbarParams(subNavbarParam: ISubNavbarParams) {
        this._currentSubNavbarParams = subNavbarParam;
        this.subNavbarParamsChanged$.emit();
    }

    get currentSubNavbarParams() {
        return this._currentSubNavbarParams;
    }
}

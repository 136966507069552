import { Injectable } from '@angular/core';

import { LocalStorageService } from '../gamma/localstorage';
import { RoutingService } from '../gamma/routing';

import { AppInitService } from './app-init.service';
import { IU2000_AutoLoginInfo, U2000_AuthenticationService } from './U2000_core';

/**
 * Kind of an extention to the AppInitService but called from the Workspace component.
 * (It is called there because we are sure that the router is well initialized
 * and only since that time, we can use services using the rouer).
 *
 * @export
 * @class RouterInitService
 */
@Injectable()
export class RouterInitService {
    constructor(private appInitService: AppInitService, private routingService: RoutingService, private localStorageService: LocalStorageService, private authService: U2000_AuthenticationService) {}

    initialize() {
        if (this.appInitService.initData) {
            if (this.routingService.isLogoutRoute(location.href)) {
                this.authService.logoutWithoutRedirect();
            } else {
                if (this.appInitService.initData.accessToken) {
                    this.authService.autoLoginWithNavigation(this.appInitService.initData as IU2000_AutoLoginInfo);
                }
            }
        }
    }
}

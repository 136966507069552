import { Injectable } from '@angular/core';

/**
 * The RGB color.
 *
 * @export
 * @interface IColorRGB
 */
export interface IColorRGB {
    r: number;
    g: number;
    b: number;
}

/**
 * Service for Color utils.
 *
 * @export
 * @class ColorService
 */
@Injectable()
export class ColorService {
    /**
     * Get the label color
     *
     * @param {string} color
     * @memberof ColorService
     */
    public labelColorAgainstBackground(backgroundColor: string, darkColor?: string, lightColor?: string) {
        if (backgroundColor) {
            if (this.perceivedBrightness(this.hexToRgb(backgroundColor)) > 136) {
                return darkColor ? darkColor : '#000000';
            } else {
                return lightColor ? lightColor : '#FFFFFF';
            }
        }
        return '#000000';
    }

    /**
     * Perceived the brightness of a color
     *
     * @param {IColorRGB} color
     * @returns {number}
     * @memberof ColorService
     */
    public perceivedBrightness(color: IColorRGB): number {
        let result: number = Math.sqrt(color.r * color.r * 0.299 + color.g * color.g * 0.587 + color.b * color.b * 0.114);
        result = Math.round(result);
        return result;
    }

    /**
     * Convert an hexadecimal string to an RGB color format.
     *
     * @param {string} hex
     * @returns {IColorRGB}
     * @memberof ColorService
     */
    public hexToRgb(hex: string): IColorRGB {
        const result: RegExpExecArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }
}

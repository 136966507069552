import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { BusyIndicatorService } from '../../gamma/busyindicator/busyindicator.service';
import { LogicalUnitResolver } from '../../gamma/logicalunit';

import { U2021_EulaService } from './U2021_eula.service';

@Injectable()
export class U2021_EulaResolver extends LogicalUnitResolver<U2021_EulaService> implements Resolve<LogicalUnitResolver<U2021_EulaService>> {
    constructor(service: U2021_EulaService, busyIndicatorService: BusyIndicatorService) {
        super(service, busyIndicatorService);
    }
}

import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[busyIndicatorOverlay], [busyIndicatorOverlayObs]',
})
export class U2000_BusyIndicatorOverlayDirective implements AfterViewInit, OnChanges {
    @Input() busyIndicatorOverlay: boolean;
    @Input() busyIndicatorOverlayObs: Observable<any>;
    @Input() hideContent: boolean;

    subscription: Subscription;

    overlayEl: HTMLElement;
    creationDisplay: string;

    constructor(private el: ElementRef) {
        this.el.nativeElement.style.position = 'relative';
    }

    ngOnChanges() {
        if (this.busyIndicatorOverlayObs != null) {
            if (this.overlayEl) {
                this.overlayEl.style.display = '';
            } else {
                this.creationDisplay = '';
            }

            if (this.busyIndicatorOverlayObs != null) {
                this.subscription = this.busyIndicatorOverlayObs.subscribe(
                    res => {
                        this.handleResponse();
                    },
                    err => {
                        this.handleResponse();
                    },
                );
            }
        } else {
            if (this.overlayEl) {
                if (this.busyIndicatorOverlay) {
                    this.overlayEl.style.display = '';
                } else {
                    this.overlayEl.style.display = 'none';
                    if (this.hideContent) {
                        this.el.nativeElement.children[0].style.visibility = '';
                    }
                }
            }
        }
    }

    private handleResponse() {
        if (this.overlayEl) {
            this.overlayEl.style.display = 'none';
        }

        if (this.hideContent) {
            this.el.nativeElement.children[0].style.visibility = '';
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.overlayEl = document.createElement('busyindicator-overlay');
        this.overlayEl.innerHTML = `<div class="overlay"></div><div class="busyindicator"></div>`;

        if (this.creationDisplay) {
            this.overlayEl.style.display = this.creationDisplay;
            this.creationDisplay = null;
        }

        if (this.hideContent && this.el.nativeElement.children.length > 0) {
            this.el.nativeElement.children[0].style.visibility = 'hidden';
        }

        this.el.nativeElement.parentElement.insertBefore(this.overlayEl, this.el.nativeElement.nextSibling);
        this.el.nativeElement.appendChild(this.overlayEl);

        this.ngOnChanges();
    }
}

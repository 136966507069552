import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { BootstrapModule } from '../../gamma/bootstrap';
import { BusyIndicatorModule } from '../../gamma/busyindicator';
import { CoreModule } from '../../gamma/core';
import { ExceptionModule } from '../../gamma/exception';
import { GammaHttpModule } from '../../gamma/http';
import { BaseInterceptor } from '../../gamma/http/base.interceptor';
import { I18nModule } from '../../gamma/i18n';
import { L10nModule } from '../../gamma/l10n';
import { LocalStorageModule, LocalStorageService } from '../../gamma/localstorage';
import { GammaModalModule } from '../../gamma/modal';
import { ReferentialModule } from '../../gamma/referential';
import { RoutingModule } from '../../gamma/routing';
import { SecurityModule } from '../../gamma/security';
import { ToastModule } from '../../gamma/toast';
import { UtilsModule } from '../../gamma/utils';

import { SessionStorageModule } from '../../gamma/sessionstorage/sessionstorage.module';
import { U2000_BaseHttpInterceptor } from './U2000_basehttp.interceptor';
import { U2000_AuthenticationGuard } from './U2000_guards/U2000_authentication.guard';
import { throwIfAlreadyLoaded } from './U2000_guards/U2000_moduleimport.guard';
import { U2000_ExceptionProvider } from './U2000_providers/U2000_exception.provider';
import { U2000_FormValidationProvider } from './U2000_providers/U2000_formvalidation.provider';
import { U2000_HttpErrorProvider } from './U2000_providers/U2000_httperror.provider';
import { U2000_LocalStorageProvider } from './U2000_providers/U2000_localstorage.provider';
import { U2000_ModalProvider } from './U2000_providers/U2000_modal.provider';
import { U2000_ReferentialProvider } from './U2000_providers/U2000_referential.provider';
import { U2000_SecurityProvider } from './U2000_providers/U2000_security.provider';
import { U2000_SessionStorageProvider } from './U2000_providers/U2000_sessionstorage.provider';
import { U2000_ToastProvider } from './U2000_providers/U2000_toast.provider';
import { U2000_ApplicationMenuService } from './U2000_services/U2000_applicationmenu.service';
import { U2000_AssetsService } from './U2000_services/U2000_assets.service';
import { U2000_AuthenticationService } from './U2000_services/U2000_authentication.service';
import { U2000_BamboraCustomCheckoutService } from './U2000_services/U2000_bambora.customcheckout.service';
import { U2000_ConversationService } from './U2000_services/U2000_conversation.service';
import { U2000_DevelopmentService } from './U2000_services/U2000_development.service';
import { U2000_GridService } from './U2000_services/U2000_grid.service';
import { U2000_InstantCaptureService } from './U2000_services/U2000_instantcapture.service';
import { U2000_IntroJSService } from './U2000_services/U2000_introjs.service';
import { U2000_LogService } from './U2000_services/U2000_log.service';
import { U2000_SeleniumService } from './U2000_services/U2000_selenium.service';
import { U2000_SubNavbarService } from './U2000_services/U2000_subnavbar.service';
import { U2000_TitleService } from './U2000_services/U2000_title.service';
import { U2000_WebSocketService } from './U2000_services/U2000_websocket.service';
import { U2000_WebSocketParserService } from './U2000_services/U2000_websocketparser.service';
import { U2000_TranslateInitialLoader } from './U2000_translateinitialloader';
import { U2000_TranslatePartialLoader } from './U2000_translatepartialloader';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: U2000_TranslateInitialLoader,
                deps: [HttpClient, LocalStorageService],
            },
        }),
        CoreModule,
        BusyIndicatorModule.withConfig(),
        HttpClientModule,
        GammaHttpModule.withConfig({
            provider: U2000_HttpErrorProvider,
        }),
        LocalStorageModule.withConfig({
            provider: U2000_LocalStorageProvider,
            prefix: 'saia2',
            storageType: 'localStorage',
        }),
        SessionStorageModule.withConfig({
            provider: U2000_SessionStorageProvider,
            prefix: 'saia2',
        }),
        ExceptionModule.withConfig({
            provider: U2000_ExceptionProvider,
        }),
        GammaModalModule.withConfig({
            provider: U2000_ModalProvider,
        }),
        ToastModule.withConfig({
            provider: U2000_ToastProvider,
        }),
        SecurityModule.withConfig({
            provider: U2000_SecurityProvider,
        }),
        I18nModule.withConfig({}),
        L10nModule,
        ReferentialModule.withConfig({
            provider: U2000_ReferentialProvider,
        }),
        BootstrapModule,
        UtilsModule,
        RoutingModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        NgxMaskModule,
        CoreModule,
        GammaHttpModule,
        BusyIndicatorModule,
        LocalStorageModule,
        SessionStorageModule,
        ExceptionModule,
        GammaModalModule,
        ToastModule,
        ReferentialModule,
        UtilsModule,
    ],
    declarations: [],
    providers: [
        U2000_ApplicationMenuService,
        U2000_DevelopmentService,
        U2000_SeleniumService,
        U2000_SubNavbarService,
        U2000_TitleService,
        U2000_AuthenticationService,
        U2000_InstantCaptureService,
        U2000_IntroJSService,
        U2000_AuthenticationGuard,
        U2000_ConversationService,
        U2000_BaseHttpInterceptor,
        U2000_ModalProvider,
        U2000_ExceptionProvider,
        U2000_TranslatePartialLoader,
        U2000_ToastProvider,
        U2000_SecurityProvider,
        U2000_FormValidationProvider,
        U2000_LocalStorageProvider,
        U2000_SessionStorageProvider,
        U2000_HttpErrorProvider,
        U2000_ReferentialProvider,
        U2000_AssetsService,
        U2000_GridService,
        U2000_BamboraCustomCheckoutService,
        U2000_WebSocketService,
        U2000_WebSocketParserService,
        U2000_LogService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseInterceptor,
            multi: true,
        },
    ],
})
export class U2000_CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: U2000_CoreModule, conversationService: U2000_ConversationService) {
        throwIfAlreadyLoaded(parentModule, 'U2000_CoreModule');
    }
}

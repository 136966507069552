import { Component } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_VerifyEmailService } from '../U2000_verifyemail.service';

@Component({
    selector: 'u2000-emailcompleted',
    templateUrl: 'U2000_emailcompleted.component.html',
    styleUrls: ['U2000_emailcompleted.component.scss'],
})
export class U2000_EmailCompletedComponent extends BaseComponent<U2000_VerifyEmailService> {
    constructor(
        public override service: U2000_VerifyEmailService,
        // Super requirements
        toastService: ToastService,
    ) {
        super(service, toastService);
    }

    done() {
        /*let completeRequest: IU2014_CompleteDtoRequest = {};
        completeRequest.id = this.service.individual.id;
        completeRequest.versionNo = this.service.individual.versionNo;*/
        /* this.service.complete(completeRequest).subscribe(res => {
            this.onCancel();
        }); */
    }
}

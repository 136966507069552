import { Inject, Injectable, InjectionToken, Injector, Type } from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { BaseComponent } from '../core/components/base.component';
import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { I18nService } from '../i18n';
import { LogicalUnitModalBaseService } from '../logicalunit/logicalunit-modal-base.service';
import { ILogicalUnitModalRoute, IOpenModalLogicalUnitParams } from '../logicalunit/logicalunit-modal.interface';
import { RxjsService } from '../utils/rxjs.service';

export const ModalServiceConfig = new InjectionToken<IServiceProviderConfig<IModalProvider>>('MODAL_SERVICE_CONFIG');

export type ModalSize = 'small' | 'large';

export type ModalPromptInputType = 'text' | 'textarea' | 'email' | 'select' | 'checkbox' | 'date' | 'time' | 'number' | 'password';

export interface IOpenModalComponentParams {
    component: Type<BaseComponent<any>>;
    injector: Injector;
}

/**
 * Interface that defines the modal options.
 *
 * @export
 * @interface IModalOptions
 */
export interface IModalOptions {
    /**
     * Size of the modal (Small or Large)
     *
     * @type {ModalSize}
     * @memberof IModalOptions
     */
    size?: ModalSize;
    /**
     * Message displayed in the modal.
     *
     * @type {string}
     * @memberof IModalOptions
     */
    message?: string;
    /**
     * Title displayed in the modal.
     *
     * @type {string}
     * @memberof IModalOptions
     */
    title?: string;
    /**
     * Display the close button.
     *
     * @type {boolean}
     * @memberof IModalOptions
     */
    closeButton?: boolean;
    /**
     * Display the backdrop.
     *
     * @type {boolean}
     * @memberof IModalOptions
     */
    backdrop?: boolean;
    /**
     * Text displayed in the OK button.
     *
     * @type {string}
     * @memberof IModalOptions
     */
    okButtonText?: string;

    /**
     * Text displayed in the Cancel button.
     *
     * @type {string}
     * @memberof IModalOptions
     */
    cancelText?: string;
    /**
     * CSS class added to the dialog html tag.
     *
     * @type {string}
     * @memberof IModalOptions
     */
    dialogClass?: string;
}

/**
 * Interface that defines the options for an alert.
 *
 * @export
 * @interface IModalAlertOptions
 * @extends {IModalOptions}
 */
export type IModalAlertOptions = IModalOptions;

/**
 * Interface that defines the options for a confirm.
 *
 * @export
 * @interface IModalConfirmOptions
 * @extends {IModalOptions}
 */
export interface IModalConfirmOptions extends IModalOptions {
    okButtonText?: string;
    cancelButtonText?: string;
}

/**
 * Interface that defines the options for an prompt.
 *
 * @export
 * @interface IModalPromptOptions
 * @extends {IModalOptions}
 */
export type IModalPromptOptions = IModalOptions;

/**
 * Interface that defines the options for a dialog.
 *
 * @export
 * @interface IModalDialogOptions
 * @extends {IModalOptions}
 */
export type IModalDialogOptions = IModalOptions;

/**
 * Interface that defines the modal service's provider.
 *
 * @export
 * @interface IModalProvider
 * @extends {IProvider<ModalService>}
 */
export interface IModalProvider extends IProvider<ModalService> {
    /**
     * Display a modal with a field that me be input by the user.
     *
     * @param {IModalPromptOptions} options
     * @returns {Promise<any>}
     *
     * @memberof IModalProvider
     */
    prompt(options: IModalPromptOptions): Promise<any>;
    /**
     * Display a modal message with an OK button.
     *
     * @param {IModalAlertOptions} options
     * @returns {Promise<any>}
     *
     * @memberof IModalProvider
     */
    alert(options: IModalAlertOptions): Promise<any>;
    /**
     * Display a modal confirmation message with an OK and cancel button.
     *
     * @param {IModalConfirmOptions} options
     * @returns {Promise<any>}
     *
     * @memberof IModalProvider
     */
    confirm(options: IModalConfirmOptions): Promise<any>;
    /**
     * Display a custom modal message.
     *
     * @param {IModalDialogOptions} options
     * @returns {Promise<any>}
     *
     * @memberof IModalProvider
     */
    dialog(options: IModalDialogOptions): Promise<any>;

    openComponent<U>(params: IOpenModalComponentParams, callback?: (result: U) => void, cancelCallback?: () => void);

    // openLogicalUnit<T extends LogicalUnitModalBaseService, U>(params: IOpenModalLogicalUnitParams<T>, callback?: (result: U) => void);

    closeAll();
    /**
     * Scroll the modal container to top.
     *
     * @memberof IModalProvider
     */
    scrollTop(): void;
}

/**
 * Modal service that allows to diplay all sorts of modal screens.
 *
 * @export
 * @class ModalService
 * @extends {ServiceProviderBase<IModalProvider, ModalService>}
 */
@Injectable()
export class ModalService extends ServiceProviderBase<IModalProvider, ModalService> {
    public activated: boolean;

    constructor(private injector: Injector, private rxjsService: RxjsService, @Inject(ModalServiceConfig) config: IServiceProviderConfig<IModalProvider>) {
        super(injector, config);
        this.activated = false;
    }

    /**
     * Display a modal with a field that me be input by the user.
     *
     * @param {IModalPromptOptions} options Modal options.
     * @returns {Promise<any>}
     *
     * @memberof ModalService
     * @example
     *      // TODO When an concrete case is made.
     */
    prompt(options: IModalPromptOptions): Promise<any> {
        if (this.activated) {
            if (this.provider != null) {
                this.translateOptions(options);
                return this.provider.prompt(options);
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    /**
     * Display a modal message with an OK button.
     *
     * @param {IModalAlertOptions} options Modal options.
     * @returns {Promise<any>}
     *
     * @memberof ModalService
     * @example
     *      this.modalService.alert({
     *          size: 'small',
     *          title: 'Any title'),
     *          message: 'Any messsage'
     *       });
     */
    alert(options: IModalAlertOptions): Promise<any> {
        if (this.activated) {
            if (this.provider != null) {
                this.translateOptions(options);
                return this.provider.alert(options);
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    /**
     * Display a modal confirmation message with an OK and cancel button.
     *
     * @param {IModalConfirmOptions} options Modal options.
     * @returns {Promise<any>}
     *
     * @memberof ModalService
     * @example
     *      // TODO When an concrete case is made.
     */
    confirm(options: IModalConfirmOptions): Promise<any> {
        if (this.activated) {
            if (this.provider != null) {
                this.translateOptions(options);
                return this.provider.confirm(options);
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    /**
     * Display a custom modal message.
     *
     * @param {IModalDialogOptions} options Modal options.
     * @returns {Promise<any>}
     *
     * @memberof ModalService
     * @example
     *      // TODO When an concrete case is made.
     */
    dialog(options: IModalDialogOptions): Promise<any> {
        if (this.activated) {
            if (this.provider != null) {
                this.translateOptions(options);
                return this.provider.dialog(options);
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    openComponent<T>(params: IOpenModalComponentParams, callback?: (result: T) => void, cancelCallback?: () => void) {
        if (this.activated) {
            if (this.provider != null) {
                return this.provider.openComponent<T>(params, callback, cancelCallback);
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    /**
     * Open a modal logical unit with its own InitLU and languages loaded before opening.
     *
     * @template T The service type used within the logical unit.
     * @template U The type of the value returned when the modal is closed.
     * @param {IU2000_OpenLogicalUnitParams<T>} params Parameters to configure the opening.
     * @param {(result: U) => void} [callback] Callback method called when the modal is closed.
     * @returns A promise of the resolver.
     * @memberof ModalService
     * @example
     *      constructor(
     *          ...
     *          private resolver: U2001_TestResolver
     *          ...) { }
     *
     *      anyMethod() {
     *          let params: IOpenLogicalUnitParams<U2001_TestService> = {
     *              resolver: this.resolver,
     *              component: U2001_TestModalComponent,
     *              injector: this.injector,
     *              options: {
     *                  anyParam: 'anyValue' // Retreivable from the context in the modal component.
     *              }
     *          };
     *          this.modalService.openLogicalUnit<U2001_TestService, boolean>(params, result => {
     *              // Your close callback code here and result is defined as a boolean.
     *          });
     *      }
     */
    openLogicalUnit<T extends LogicalUnitModalBaseService, U>(params: IOpenModalLogicalUnitParams<T>, callback?: (result: U) => void) {
        const resolver = params.injector.get(params.resolver);
        resolver.service.routingService.addLogicalUnitParams(resolver.service.logicalUnitId, params.options);
        const resolvePromise = resolver.resolveModal().toPromise();
        if (params.options == null) {
            params.options = {};
        }

        params.options['openingParams'] = params;
        resolvePromise
            .then(x => {
                this.runCanActivate(params).subscribe(
                    y => {
                        this.openComponent(params, callback);
                    },
                    err => {
                        /* Must be declared */
                    },
                );
            })
            .catch(err => {
                /* Must be declared */
            });
        return resolvePromise;
    }

    closeAll() {
        if (this.activated) {
            if (this.provider != null) {
                return this.provider.closeAll();
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    routeToParams<T extends LogicalUnitModalBaseService>(route: ILogicalUnitModalRoute<T>, injector: Injector) {
        const params: IOpenModalLogicalUnitParams<T> = {
            component: route.component,
            resolver: route.resolver,
            canActivate: route.canActivate,
            canDeactivate: route.canDeactivate,
            injector,
        };
        return params;
    }

    private runCanActivate(params: IOpenModalLogicalUnitParams<any>) {
        const canActivate = params.canActivate;
        if (!canActivate || canActivate.length === 0) {
            return of(true);
        }
        const obs = from(canActivate).pipe(
            map(c => {
                const guard = params.injector.get(c);
                let observable: Observable<any>;
                if (guard.canActivate) {
                    observable = this.rxjsService.wrapIntoObservable(guard.canActivate());
                } else {
                    observable = this.rxjsService.wrapIntoObservable(guard());
                }
                return observable.pipe(first());
            }),
        );
        return this.rxjsService.andObservables(obs);
    }

    scrollTop() {
        if (this.activated) {
            if (this.provider != null) {
                return this.provider.scrollTop();
            } else {
                throw new Error('You must register a provider for the ModalService.');
            }
        } else {
            return new Promise((resolve, reject) => {
                reject('Not still bootstrapped');
            });
        }
    }

    runCanDeactivate(params: any) {
        const canDeactivate = params.canActivate;
        if (!canDeactivate || canDeactivate.length === 0) {
            return of(true);
        }
        const obs = map.call(from(canDeactivate), c => {
            const guard = params.injector.get(c);
            let observable;
            if (guard.canActivate) {
                observable = this.rxjsService.wrapIntoObservable(guard.canDeactivate());
            }
            return first.call(observable);
        });
        return this.rxjsService.andObservables(obs);
    }

    private translateOptions(options: IModalDialogOptions) {
        const i18nService = this.injector.get(I18nService);
        options.title = i18nService.formatLabel(options.title);
        options.message = i18nService.formatLabel(options.message);
    }
}

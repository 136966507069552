export enum U2000_VerifyIdentityNavigationStepsEnum {
    chooseCommunication,
    verifyCode,
    securityQuestion,
    completed,
}

export interface IU2000_VerifyIdentityResult {
    securityKey: string;
    id: number;
    expirationTimeStamp: number;
}

import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from '../../../gamma/http/http.service';

import { U2000_AuthenticationService } from './U2000_authentication.service';

@Injectable()
export class U2000_ConversationService {
    conversationId: string;

    constructor(private httpService: HttpService, private authService: U2000_AuthenticationService) {
        this.generateConversationId(false);

        this.httpService.addInterceptor({
            request: (requestId: number, req: HttpRequest<any>) => {
                let newHeaders = req.headers ? req.headers : new HttpHeaders();
                if (!req.headers.has('X-SAIA-Conversation-Id') && this.conversationId != null) {
                    newHeaders = newHeaders.set('X-SAIA-Conversation-Id', this.conversationId);
                }
                return req.clone({ headers: newHeaders });
            },
        });
    }

    /**
     *  Generate the application conversation id.
     *
     *  Format detail:
     *      ABCCCCCCCCCCCCCCCDDDDDDDD
     *      A = Auto generated format.
     *      B = Application identifier (0 = ANON, 1 = MAIN, 2 = ADSU)
     *      C = Date/Time of the session initialization (YYMMDDHHMISSTTT)
     *      D = If not logged, 'R' followed by a 7 digit random. Of logged, 8 characters of the token before the '-',
     *          left padded with zeroes.
     *  @method _generateConversationId
     *  @param {boolean} t_IsLogged If the user is logged or not.
     *  @return {string} ConversionId
     *  @private
     */
    generateConversationId(t_IsLogged: boolean) {
        const my_Random = Math.floor(Math.random() * 9999999);
        const my_Date = new Date();
        let my_Str = 'A1';

        my_Str +=
            my_Date.getFullYear().toString().substr(2, 2) +
            String.padLeft((my_Date.getMonth() + 1).toString(), 2) +
            String.padLeft(my_Date.getDate().toString(), 2) +
            String.padLeft(my_Date.getHours().toString(), 2) +
            String.padLeft(my_Date.getMinutes().toString(), 2) +
            String.padLeft(my_Date.getSeconds().toString(), 2) +
            String.padLeft(my_Date.getMilliseconds().toString(), 3);

        if (t_IsLogged) {
            my_Str += String.padLeft(this.authService.tokenInfo.access_token.split('-')[0].toUpperCase(), 8);
        } else {
            my_Str += 'R' + String.padLeft(my_Random.toString(), 7);
        }

        this.conversationId = my_Str;
    }
}

export const U2000RF_Languages = 'U2000_Languages';
export const U2000RF_LanguagesInverted = 'U2000_LanguagesInverted';
export const U2000RF_Countries = 'U2000_Countries';
export const U2000RF_SupportedCountries = 'U2000_SupportedCountries';
export const U2000RF_MessagePaymentStatuses = 'U2000_MessagePaymentStatuses';
export const U2000RF_PaymentModeFamilies = 'U2000_PaymentModeFamilies';
export const U2000RF_SecurityQuestions = 'U2000_SecurityQuestions';
export const U2000RF_NotificationTypes = 'U2000_NotificationTypes';
export const U2000RF_FinancialInstitutionWebsites = 'U2000_FinancialInstitutionWebsites';
export const U2000RF_RejectCodes = 'U2000_RejectCodes';
export const U2000RF_PaymentModes = 'U2000_PaymentModes';
export const U2000RF_PaymentStatuses = 'U2000_PaymentStatuses';
export const U2000RF_PaymentTypes = 'U2000_PaymentTypes';
export const U2000RF_FinancialInstitutions = 'U2000_FinancialInstitutions';
export const U2000RF_IavFinancialInstitutions = 'U2000_IavFinancialInstitutions';
export const U2000RF_NoticeSendRules = 'U2000_NoticeSendRules';
export const U2000RF_SendEmailEBusinessLinkInvitations = 'U2000_SendEmailEBusinessLinkInvitations';
export const U2000RF_BusinessTypes = 'U2000RF_BusinessTypes';
export const U2000RF_CategoryTypes = 'U2000RF_CategoryTypes';
export const U2000RF_DocumentTypes = 'U2000RF_DocumentTypes';
export const U2000RF_IntendedMarketTypes = 'U2000RF_IntendedMarketTypes';
export const U2000RF_ProductTypes = 'U2000RF_ProductTypes';
export const U2000RF_ApplicationChannelStatuses = 'U2000RF_ApplicationChannelStatuses';
export const U2000RF_Frequencies = 'U2000RF_Frequencies';
export const U2000RF_OrganizationRoles = 'U2000RF_OrganizationRoles';
export const U2000RF_ApplicationChannelInvitationStatuses = 'U2000RF_ApplicationChannelInvitationStatuses';
export const U2000RF_ApplicationChannelInvitationSendStatuses = 'U2000RF_ApplicationChannelInvitationSendStatuses';
export const U2000RF_TutorialModules = 'U2000RF_TutorialModules';
export const U2000RF_ApplicationTypes = 'U2000RF_ApplicationTypes';
export const U2000RF_PadFrequencies = 'U2000RF_PadFrequencies';
export const U2000RF_PadAgreementInvitationStatuses = 'U2000RF_PadAgreementInvitationStatuses';
export const U2000RF_PadAgreementStatuses = 'U2000RF_PadAgreementStatuses';
export const U2000RF_SupplierRecordStatuses = 'U2000RF_SupplierRecordStatuses';
export const U2000RF_ReportTypes = 'U2000RF_ReportTypes';
export const U2000RF_EftSupplierConfirmationEmailSendRules = 'U2000RF_EftSupplierConfirmationEmailSendRules';
export const U2000RF_DaysOfWeek = 'U2000RF_DaysOfWeek';
export const U2000RF_TelusCategories = 'U2000RF_TelusCategories';
export const U2000RF_Modules = 'U2000_Modules';
export const U2000RF_NotificationGroups = 'U2000_NotificationGroups';
export const U2000RF_Currencies = 'U2000_Currencies';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { U2000_AuthenticationService } from '../U2000_core/U2000_services/U2000_authentication.service';

@Injectable()
export class U2011_ActivationGuard implements CanActivate {
    constructor(private authService: U2000_AuthenticationService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn) {
            this.authService.navigateToDefault();
            return false;
        }
        return true;
    }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpErrorService } from './http-error.service';
import { HttpService, IHttpOptions } from './http.service';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    constructor(private httpService: HttpService, private httpErrorService: HttpErrorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const options: IHttpOptions = {};
        const requestId = Math.floor(Math.random() * 9999999);
        let inteceptedReq: HttpRequest<any>;

        // TO BE REFACTORED EVENTUALLY
        if (req.params.has('__silent')) {
            options.silent = eval(req.params.get('__silent')) as any;
        }
        if (req.params.has('__busyMessage')) {
            options.busyMessage = req.params.get('__busyMessage') as any;
        }
        if (req.params.has('__busyInvisible')) {
            options.busyInvisible = eval(req.params.get('__busyInvisible')) as any;
        }
        if (req.params.has('__globalErrorHandling')) {
            options.globalErrorHandling = eval(req.params.get('__globalErrorHandling')) as any;
        }
        if (req.params.has('__busyHideSpinner')) {
            options.busyHideSpinner = eval(req.params.get('__busyHideSpinner')) as any;
        }

        inteceptedReq = this.httpService.interceptRequest(requestId, req, options);

        // TO BE REFACTORED EVENTUALLY
        if (inteceptedReq.params.has('__silent')) {
            inteceptedReq = inteceptedReq.clone({ params: inteceptedReq.params.delete('__silent') });
        }
        if (inteceptedReq.params.has('__busyMessage')) {
            inteceptedReq = inteceptedReq.clone({ params: inteceptedReq.params.delete('__busyMessage') });
        }
        if (inteceptedReq.params.has('__busyInvisible')) {
            inteceptedReq = inteceptedReq.clone({ params: inteceptedReq.params.delete('__busyInvisible') });
        }
        if (inteceptedReq.params.has('__globalErrorHandling')) {
            inteceptedReq = inteceptedReq.clone({ params: inteceptedReq.params.delete('__globalErrorHandling') });
        }
        if (inteceptedReq.params.has('__busyHideSpinner')) {
            inteceptedReq = inteceptedReq.clone({ params: inteceptedReq.params.delete('__busyHideSpinner') });
        }

        // Clone the request to add the new header.
        const baseReq = req.clone(inteceptedReq);

        // Pass on the cloned request instead of the original request.
        return next.handle(baseReq).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.httpService.interceptResponse(requestId, event, options);
                }
            }),
            catchError((err: any, caught) => {
                if (err instanceof HttpErrorResponse) {
                    this.httpService.interceptResponseError(requestId, err, options);
                    if (err.status < 400 || err.status > 499 || err.status === 401 || err.status === 404 || err.status === 405) {
                        if (options == null || (options != null && options.globalErrorHandling !== false)) {
                            this.httpErrorService.handleHttpError(err);
                            return EMPTY;
                        } else {
                            return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                }
                return null;
            }),
        );
    }
}

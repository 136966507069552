import { Component } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_VerifyIdentityService } from '../U2000_verifyidentity.service';

@Component({
    selector: 'u2000-choosecommunication',
    templateUrl: 'U2000_choosecommunication.component.html',
    styleUrls: ['U2000_choosecommunication.component.scss'],
})
export class U2000_ChooseCommunicationComponent extends BaseComponent<U2000_VerifyIdentityService> {
    constructor(service: U2000_VerifyIdentityService, toastService: ToastService) {
        super(service, toastService);
    }
}

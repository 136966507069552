import { Component, OnDestroy, ViewChild } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_ESignatureService } from '../U2000_esignature.service';
import { U2000_ESignatureVerifyCodeFormComponent } from './U2000_esignatureverifycode-form/U2000_esignatureverifycode-form.component';

@Component({
    selector: 'u2000-esignatureverifycode',
    templateUrl: 'U2000_esignatureverifycode.component.html',
    styleUrls: ['U2000_esignatureverifycode.component.scss'],
})
export class U2000_ESignatureVerifyCodeComponent extends BaseComponent<U2000_ESignatureService> implements OnDestroy {
    @ViewChild('formComponent') formComponent: U2000_ESignatureVerifyCodeFormComponent;

    constructor(
        service: U2000_ESignatureService,
        // Super requirements
        toastService: ToastService,
    ) {
        super(service, toastService);
    }

    submitForm() {
        return this.formComponent.submit();
    }
}

/**
 * Mixin decorator.
 *
 * @export
 * @param {Function[]} baseCtors
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function Mixin(baseCtors: Function[]) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (derivedCtor: Function) => {
        baseCtors.forEach(baseCtor => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                if (name !== 'constructor') {
                    derivedCtor.prototype[name] = baseCtor.prototype[name];
                }
            });
        });
    };
}

// Function copied from the Typescript Mixin example.
/*export function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
    });
}*/

export const U2000LS_LastLoggedUsername = 'lastLoggedUsername';
export const U2000LS_TokenInfo = 'tokenInfo';
export const U2000LS_TenantId = 'tenantId';
export const U2000LS_OrganizationClickCount = 'organizationClickCount';
export const U2000LS_TokenExpiredRedirection = 'tokenExpiredRedirection';
export const U2000LS_SearchSortOrder = 'searchSortOrder';
export const U2000LS_MappingKey = 'mappingKey';
export const U2000LS_ModuleDefaultRoute = 'moduleDefaultRoute';
export const U2000LS_ReconciliationGroupBy = 'reconciliationGroupBy';
export const U2000LS_ReconciliationPaymentModeFamily = 'reconciliationPaymentModeFamily';
export const U2000LS_IdentityVerification = 'identityVerification';
export const U2000LS_IntroDontShowAgain = 'introDontShowAgain';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';

import { FormBaseComponent } from '../../../../../../gamma/form/form-base.component';
import { FormService } from '../../../../../../gamma/form/form.service';
import { FormRadioQuestion } from '../../../../../../gamma/form/formquestion/formquestion-radio';
import { FormValidationMessageService } from '../../../../../../gamma/form/formvalidationmessage.service';
import { RoutingService } from '../../../../../../gamma/routing';
import { ToastService } from '../../../../../../gamma/toast';
import { DomService } from '../../../../../../gamma/utils';

import { U2000_Animations, U2000_AuthenticationService, U2000_Validators } from '../../../../../U2000_core';
import { U2000_EmailQuestion, U2000_PhoneNumberQuestion } from '../../../../../U2000_questiondomains';
import { U2000_ESignatureNavigationStepsEnum } from '../../U2000_esignature';
import { U2000_ESignatureService } from '../../U2000_esignature.service';

@Component({
    selector: 'u2000-choosesendingmethod-form',
    templateUrl: 'U2000_choosesendingmethod-form.component.html',
    styleUrls: ['U2000_choosesendingmethod-form.component.scss'],
    providers: [FormService],
    animations: [U2000_Animations.slideInOut],
})
export class U2000_ChooseSendingMethodFormComponent extends FormBaseComponent<U2000_ESignatureService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'ChooseCommunication';

    qSendingCodeMethod: FormRadioQuestion<number>;
    qEmail: U2000_EmailQuestion;
    qPhoneNumber: U2000_PhoneNumberQuestion;

    constructor(
        service: U2000_ESignatureService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,

        public authService: U2000_AuthenticationService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.qSendingCodeMethod = new FormRadioQuestion<number>({
            key: 'verificationCodeTypeId',
            formGroupClass: 'no-margin-bottom',
            inputContainerClass: 'sending-code-method',
            inputClass: 'sending-code-method-input',
            defaultValue: 0,
            radioButtons: [
                {
                    key: 'email',
                    label: 'lblByEmail',
                    value: 0,
                },
                {
                    key: 'sms',
                    label: 'lblBySMS',
                    value: 1,
                    class: 'sending-code-sms',
                },
            ],
            onChange: () => {
                if (this.qSendingCodeMethod.value === 0) {
                    this.qPhoneNumber.disabled = true;
                    this.qPhoneNumber.validators = [U2000_Validators.phoneNumber];
                } else {
                    this.qPhoneNumber.disabled = false;
                    this.qPhoneNumber.validators = [Validators.required, U2000_Validators.phoneNumber];
                }
            },
        });

        this.qEmail = new U2000_EmailQuestion({
            key: 'email',
            placeholder: 'lblEmail',
            disabled: true,
            defaultValue: this.service.email,
            hideRequiredMarker: true,
        });
        this.qEmail.label = null;

        this.qPhoneNumber = new U2000_PhoneNumberQuestion({
            key: 'phoneNumber',
            placeholder: 'lblPhoneNumber',
            disabled: true,
            hideRequiredMarker: true,
        });
        this.qPhoneNumber.label = null;

        this.questions = [this.qSendingCodeMethod, this.qPhoneNumber, this.qEmail];
    }
    ngOnInit() {
        this.service.$emailChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((email: string) => {
            this.qEmail.value = email;
        });

        this.initForm();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    onSubmit() {
        if (this.service.validationBeforeSendingCode()) {
            this.service.clearAlerts();
            this.showErrors(this.form.controls);
            if (this.form.valid) {
                const request: IU2000_ESignatureSendCodeDtoRequest = this.form.value;

                this.service.sendVerificationCode(request).subscribe(
                    res => {
                        this.resetForm();
                        this.service.navigateTo(U2000_ESignatureNavigationStepsEnum.verifyCode);
                    },
                    (res: HttpErrorResponse) => {
                        this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: { [error: string]: true }): boolean => {
                            this.service.addAlert({ type: 'danger', message: validationError.message });
                            return true;
                        });
                    },
                );
            }
        }
    }
}

import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Mixin } from '../../../gamma/core';
import { IFromControlValidationError } from '../../../gamma/form/form-base.component';
import { I18nService } from '../../../gamma/i18n';
import { LogicalUnitBaseComponent } from '../../../gamma/logicalunit';
import { RoutingService } from '../../../gamma/routing';
import { ToastService } from '../../../gamma/toast';
import { DomService } from '../../../gamma/utils';
import { IU2000_LoginInfo, U2000_AuthenticationService, U2000_ValidationMessages } from '../../U2000_core';

import { U2000_AlertComponentMixin } from '../../U2000_core/U2000_mixins/U2000_alert-component.mixin';
import { U2000_AlertServiceMixin } from '../../U2000_core/U2000_mixins/U2000_alert-service.mixin';
import { IU2000_VerifyIdentityResult } from '../../U2000_sharedmodules/U2000_security/U2000_verifyidentity/U2000_verifyidentity';
import { U2011_LoginService } from '../U2011_login.service';

export interface IU2011_VerifyIdentityInfo {
    workflowReference: string;
    loginInfo: IU2000_LoginInfo;
}

@Component({
    selector: 'u2011-verifyidentity-info',
    templateUrl: 'U2011_verifyidentity.component.html',
    styleUrls: ['U2011_verifyidentity.component.scss'],
})
@Mixin([U2000_AlertComponentMixin])
export class U2011_VerifyIdentityComponent extends LogicalUnitBaseComponent<U2011_LoginService> implements OnInit, OnDestroy, U2000_AlertComponentMixin {
    //#region Mixin requirements
    // Alert mixin requirements
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    initAlertWithoutService: () => void;
    linkAlertWithService: (service: U2000_AlertServiceMixin) => void;
    destroyAlertComponent: () => void;
    alertClosed: (alert: IU2000_Alert) => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    alerts: IU2000_Alert[];

    workflowReference: string;
    loginInfo: IU2000_LoginInfo;

    //#endregion
    constructor(
        service: U2011_LoginService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        private location: Location,

        // Alert mixin requirements
        public i18nService: I18nService,
        public domService: DomService,
        private router: Router,
        private authService: U2000_AuthenticationService
    ) {
        super(service, toastService, routingService);
        this.workflowReference = this.authService.workflowReference2fa;
        this.loginInfo = this.authService.loginInfo;
        this.authService.clean2faInfo();
    }

    override ngOnInit() {
        super.ngOnInit();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyAlertComponent();
    }

    onValid(verifyIdentityResult: IU2000_VerifyIdentityResult) {
        this.service.identitySecurityKey = verifyIdentityResult.securityKey;

        let request: IU2000_LoginInfo = this.loginInfo;
        request.grantType = 'authorization_code';
        request.authorizationCode = this.service.identitySecurityKey;

        this.authService.getToken(request).subscribe(
            (tokenRes: IU2000_CoreCurrentAgentConsolidatedInfoDto) => {
                if (!this.service.onGetToken(tokenRes)) {
                    this.onCancel();
                    return false;
                }
                return true;
            },
            (res: HttpErrorResponse) => {
                if (res.error.error === 'invalid_grant') {
                    let message = U2000_ValidationMessages.invalidUsernamePassword_0010.message;
                    if (res.error.error_description) {
                        message = res.error.error_description;
                    }
                    this.service.addAlert({ type: 'danger', message: message });
                } else {
                    this.service.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: IFromControlValidationError): boolean => {
                        this.service.addAlert({ type: 'danger', message: validationError.message });
                        return true;
                    });
                }
            },
        );
    }

    onError(error: HttpErrorResponse) {
        this.service.identityError = true;

        this.service.addAlert({ type: 'danger', message: error.error.errors[0].message });
    }

    cleanWorkflow() {
        this.workflowReference = null;
        this.loginInfo = null;
    }

    onCancel() {
        this.cleanWorkflow();
        this.router.navigate(['U2011']);
    }
}

import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseComponent } from '../../../../gamma/core/components/base.component';
import { DomService, UserAgentService } from '../../../../gamma/utils';

import { U2000_Animations } from '../../../U2000_core';

@Component({
    selector: 'u2000-grid',
    templateUrl: './U2000_grid.component.html',
    styleUrls: ['./U2000_grid.component.scss'],
    animations: [U2000_Animations.slideInOut],
})
export class U2000_GridComponent {
    private _records: any[];
    @Input() activeRow: number;
    @Input() component: BaseComponent;
    @Input() getDetail: (record: any) => Observable<any>;
    @Input() allowClick: (record: any) => boolean;
    @Input() uniqueDetailField: string;
    @Input() maxNbRecords: number;
    @Input() fieldName? = '';
    @Input() toggleDetailClick? = true;
    @Input() lineClass: (record: any) => any;
    @Output() rowClick: EventEmitter<any>;
    @ContentChild('gridRow') itemTemplate: TemplateRef<any>;
    @ContentChild('gridDetail') detailTemplate: TemplateRef<any>;

    resultCollapsed: { [id: number]: boolean };
    collapsed: boolean;
    detailObs: Observable<any>;

    moreRecordsCollapsed = true;

    constructor(private userAgentService: UserAgentService, public domService: DomService) {
        this.records = [];
        this.resultCollapsed = {};
        this.collapsed = true;

        if (this.uniqueDetailField == null) {
            this.uniqueDetailField = 'id';
        }

        this.rowClick = new EventEmitter();
    }

    @Input()
    get records() {
        return this._records;
    }
    set records(list: any[]) {
        if (list == null) {
            list = [];
        }

        this.resultCollapsed = [];
        // Because the this code was now called before the uniqueDetailField was set, we need to postpone the affectation to
        // ensure that the uniqueDetailField is defined.
        setTimeout(() => {
            list.forEach(x => {
                this.resultCollapsed[x[this.uniqueDetailField]] = true;
            });
        }, 1);

        this._records = list;
    }

    rowClickInternal(record: any) {
        if (this.allowClick == null || this.allowClick(record)) {
            if (this.rowClick.observers.length > 0 && !this.toggleDetailClick) {
                this.rowClick.emit(record);
            } else {
                this.toggleDetail(record);
            }
        }
    }

    toggleDetail(record: any) {
        if (this.domService.isTextSelected()) {
            return null;
        }
        this.records.forEach(x => {
            if (record[this.uniqueDetailField] !== x[this.uniqueDetailField]) {
                this.resultCollapsed[x[this.uniqueDetailField]] = true;
            }
        });
        this.resultCollapsed[record[this.uniqueDetailField]] = !this.resultCollapsed[record[this.uniqueDetailField]];

        if (record.detail == null && this.getDetail != null) {
            if (this.resultCollapsed[record[this.uniqueDetailField]] === false) {
                record.detailObs = this.getDetail.call(this.component, record[this.uniqueDetailField]);
                record.detailObs.subscribe(res => {
                    record.detail = res;
                });
            }
        }
    }

    isCollapsed(result: any) {
        return this.resultCollapsed[result[this.uniqueDetailField]] != null ? this.resultCollapsed[result[this.uniqueDetailField]] : true;
    }

    hideDetailForIE(result: any) {
        if (this.userAgentService.isInternetExplorer()) {
            return this.resultCollapsed[result[this.uniqueDetailField]];
        }
        return false;
    }

    isExpendable(record: any) {
        if (this.hasDetail) {
            if (this.allowClick == null || this.allowClick(record)) {
                if (this.toggleDetailClick) {
                    return true;
                }
            }
        }
        return false;
    }

    get isGridClickable() {
        return this.isOneRowExpendable || (this.rowClick != null && this.rowClick.observers.length > 0);
    }

    get isOneRowExpendable() {
        for (let i = 0; i < this.records.length; i++) {
            const element = this.records[i];
            if (this.isExpendable(element)) {
                return true;
            }
        }
        return false;
    }

    get hasDetail() {
        return this.detailTemplate != null;
    }

    showMoreRecords() {
        this.moreRecordsCollapsed = !this.moreRecordsCollapsed;
    }

    exceedsMaxRecords(): boolean {
        if (this.maxNbRecords) {
            return this._records.length > this.maxNbRecords;
        }
        return false;
    }

    getLineClasses(i: number, record: any) {
        let classes = { active: i === this.activeRow, expandable: this.isOneRowExpendable };

        if (this.lineClass != null) {
            classes = Object.assign(classes, this.lineClass(record));
        }
        return classes;
    }
}

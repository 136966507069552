import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { BootstrapResolutionService } from '../../../../gamma/bootstrap';
import { RoutingService } from '../../../../gamma/routing';
import { SecurityParams, SecurityService } from '../../../../gamma/security';
import { ISubNavbarParamsOption } from '../../../../gamma/subnavbar/subnavbar.interface';

import { U2000_AuthenticationService, U2000_SubNavbarService } from '../../../U2000_core';
import { U2000_WorkspaceService } from '../../U2000_workspace.service';

@Component({
    selector: 'sub-navbar',
    templateUrl: 'U2000_subnavbar.component.html',
    styleUrls: ['U2000_subnavbar.component.scss'],
})
export class U2000_SubNavBarComponent implements AfterViewChecked {
    filteredOptions: any;
    constructor(
        public service: U2000_WorkspaceService,
        public authService: U2000_AuthenticationService,
        private subNavbarService: U2000_SubNavbarService,
        private routingService: RoutingService,
        private securityService: SecurityService,
        private router: Router,
        public bootstrapResolutionService: BootstrapResolutionService,
        private cdRef: ChangeDetectorRef,
    ) {
        subNavbarService.subNavbarParamsChanged$.subscribe(() => {
            if (this.currentSubNavbarParams != null) {
                for (let i = 0; i < this.currentSubNavbarParams.options.length; i++) {
                    const option = this.currentSubNavbarParams.options[i];
                    if (!Array.isNullOrEmpty(option.options)) {
                        for (let y = 0; y < option.options.length; y++) {
                            option.options[y].activated = this.isActive(option.options[y].activationCondition);
                        }
                    } else {
                        option.activated = this.isActive(this.currentSubNavbarParams.options[i].activationCondition);
                    }
                }
                this.filteredOptions = this.getFilteredOptions();
            }
        });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    get isLoggedIn() {
        return this.authService.isLoggedIn;
    }

    get agentName() {
        if (this.authService.agentInfo != null) {
            return this.authService.agentInfo.agentName;
        }
        return '';
    }

    hasAccess(securityTags: string | string[] | SecurityParams) {
        if (securityTags) {
            return this.securityService.hasAccess(securityTags);
        }

        return true;
    }

    get tenantName() {
        if (this.authService.currentTenant != null) {
            if (this.authService.currentTenant.tenantTypeId !== 0) {
                return this.authService.currentTenant.name;
            }
        }
        return '';
    }

    get currentSubNavbarParams() {
        return this.subNavbarService.currentSubNavbarParams;
    }

    private isActive(conditions: string[]) {
        let active = false;
        if (conditions != null) {
            for (let i = 0; i < conditions.length; i++) {
                if (this.routingService.partOfModule(conditions[i])) {
                    active = true;
                }
            }
        }
        return active;
    }

    isActivated(option: ISubNavbarParamsOption) {
        if (Array.isNullOrEmpty(option.options)) {
            return option.activated;
        } else {
            for (let i = 0; i < option.options.length; i++) {
                if (option.options[i].activated) {
                    return true;
                }
            }
        }
        return false;
    }

    showTutorial() {
        this.router.navigate(['U2023']);
    }

    getFilteredOptions() {
        const filteredOptions = [];
        if (this.currentSubNavbarParams) {
            for (let i = 0; i < this.currentSubNavbarParams.options.length; i++) {
                const option: ISubNavbarParamsOption = this.currentSubNavbarParams.options[i];

                const filteredOption: ISubNavbarParamsOption = {
                    activated: option.activated,
                    activationCondition: option.activationCondition,
                    label: option.label,
                    onClick: option.onClick,
                    route: option.route,
                    securityTags: option.securityTags,
                    options: [],
                    queryParams: option.queryParams,
                };

                if (!Array.isNullOrEmpty(option.options)) {
                    const subOptions = [];
                    option.options.forEach(x => {
                        let subOption: any = {};
                        subOption = Object.assign(subOption, x);
                        if (x.securityTags != null) {
                            if (this.hasAccess(x.securityTags)) {
                                subOptions.push(subOption);
                            }
                        } else if (x.securityFn != null) {
                            if (x.securityFn()) {
                                subOptions.push(subOption);
                            }
                        }
                    });

                    filteredOption.options = filteredOption.options.concat(subOptions);

                    filteredOptions.push(filteredOption);
                } else {
                    if (filteredOption.securityTags) {
                        if (this.hasAccess(filteredOption.securityTags)) {
                            filteredOptions.push(filteredOption);
                        }
                    } else if (filteredOption.securityFn != null) {
                        if (filteredOption.securityFn()) {
                            filteredOptions.push(filteredOption);
                        }
                    } else {
                        filteredOptions.push(filteredOption);
                    }
                }
            }
        }
        return filteredOptions;
    }

    hasSubOptions(option) {
        return option.options != null && option.options.length > 0;
    }

    needHelpClick() {
        this.service.needHelp();
    }
}

import { NgModule } from '@angular/core';

import { FormModule } from '../../gamma/form/form.module';

import { U2000_SharedModule } from '../U2000_shared';
import { U2000_SecurityModule } from '../U2000_sharedmodules/U2000_security/U2000_security.module';
import { U2011_ActivationGuard } from './U2011_activation.guard';
import { U2011_LoginRoutingModule, U2011_RoutedComponents } from './U2011_login-routing.module';
import { U2011_LoginResolver } from './U2011_login.resolver';
import { U2011_LoginService } from './U2011_login.service';

@NgModule({
    imports: [U2011_LoginRoutingModule, U2000_SharedModule, FormModule, U2000_SecurityModule],
    exports: [],
    declarations: [U2011_RoutedComponents],
    providers: [U2011_LoginService, U2011_LoginResolver, U2011_ActivationGuard],
})
export class U2011_LoginModule {}

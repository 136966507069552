import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { RoutingService } from '../routing';

export const FormValidationServiceConfig = new InjectionToken<IServiceProviderConfig<IFormValidationProvider>>('FORM_VALIDATION_SERVICE_CONFIG');

/**
 * Interface for the provider of the service.
 *
 * @export
 * @interface IFormValidationProvider
 * @extends {IProvider<FormValidationMessageService>}
 */
export interface IFormValidationProvider extends IProvider<FormValidationMessageService> {
    /**
     * Method used to format the message to be resolved, like applying the translation if the message is a language variable.
     *
     * @param {string} message Message to format.
     * @param {{[param: string]: any}} params Params of the message to format.
     * @returns {string}
     *
     * @memberof IFormValidationProvider
     */
    formatMessage?(message: string, params?: { [param: string]: any }): string;
}

/**
 * Interface for validation messages.
 *
 * @export
 * @interface IFormValidationMessage
 */
export interface IFormValidationMessage {
    code: string;
    message?: string;
    messageFn?: (error: any) => string;
    serverError?: any;
    groupMessage?: boolean;
}

/**
 * Service used to manage all validation messages used in the forms.
 *
 * @export
 * @class FormValidationMessageService
 * @extends {ServiceProviderBase<IFormValidationProvider, FormValidationMessageService>}
 */
@Injectable()
export class FormValidationMessageService extends ServiceProviderBase<IFormValidationProvider, FormValidationMessageService> {
    private formValidationMessages: { [logicalUnit: string]: { [validatorName: string]: IFormValidationMessage } };

    constructor(injector: Injector, @Inject(FormValidationServiceConfig) config: IServiceProviderConfig<IFormValidationProvider>, private routingService: RoutingService) {
        super(injector, config);
    }

    /**
     * Add a validation messages to the service. It may be stored via a precise logical unit or globally via the U2000.
     *
     * @param {(IFormValidationMessage | IFormValidationMessage[])} validationMessages Validation message to add.
     * @param {string} [logicalUnitId='U2000'] Logical unit in which the validation message may be stored.
     *
     * @memberof FormValidationMessageService
     */
    addMessages(validationMessages: IFormValidationMessage | IFormValidationMessage[], logicalUnitId = 'GAMMA') {
        let i: number;
        let messages: IFormValidationMessage[] | IFormValidationMessage;
        if (this.formValidationMessages == null) {
            this.formValidationMessages = {};
        }

        if (this.formValidationMessages[logicalUnitId] == null) {
            this.formValidationMessages[logicalUnitId] = {};
        }

        if (validationMessages.constructor === Array) {
            messages = validationMessages as IFormValidationMessage[];
            for (i = 0; i < messages.length; i++) {
                this.formValidationMessages[logicalUnitId][messages[i].code] = messages[i];
            }
        } else {
            messages = validationMessages as IFormValidationMessage;
            this.formValidationMessages[logicalUnitId][messages.code] = messages;
        }
    }

    /**
     * Add validation messages received specifically by the server.
     *
     * @param {IFormValidationMessage} validationMessage Validation message to add.
     * @param {string} [logicalUnitId='U2000'] Logical unit in which the validation message may be stored.
     *
     * @memberof FormValidationMessageService
     */
    addServerMessages(validationMessage: IFormValidationMessage, logicalUnitId = 'GAMMA') {
        if (this.formValidationMessages == null) {
            this.formValidationMessages = {};
        }

        if (this.formValidationMessages[logicalUnitId] == null) {
            this.formValidationMessages[logicalUnitId] = {};
        }

        if (!this.formValidationMessages[logicalUnitId][validationMessage.code]) {
            this.addMessages(validationMessage, logicalUnitId);
        } else {
            this.formValidationMessages[logicalUnitId][validationMessage.code].serverError = validationMessage.serverError;
        }
    }

    /**
     * Retreive a validation messages via a validator's name within a specific logical unit.
     * Messages stored by logical unit are prioritized.  If none is found, the global (U2000) is retreived.
     *
     * @param {string} validatorName Name of the validator used to retreive the validation message.
     * @param {string} [logicalUnitId='U2000'] Logical unit in which the validation message may be stored.
     * @param {[param: string]: any} [params] Params used in messages placeholders.
     * @returns The validation message.
     *
     * @memberof FormValidationMessageService
     */
    getValidatorErrorMessage(validatorName: string, logicalUnitId: string, control: AbstractControl, params?: { [param: string]: any }) {
        let message: string;
        const validationMessage = this.getValidationMessageByName(validatorName, logicalUnitId);

        if (validationMessage != null) {
            if (control instanceof UntypedFormGroup && validationMessage.groupMessage !== true) {
                return null;
            }
            if (validationMessage.messageFn) {
                message = validationMessage.messageFn(validationMessage.serverError);
            } else {
                message = validationMessage.message;
            }

            if (message && this.provider && this.provider.formatMessage) {
                message = this.provider.formatMessage(message, params);
            }
        }
        return message;
    }

    getValidationMessageByName(validatorName: string, logicalUnitId?: string) {
        if (logicalUnitId == null) {
            logicalUnitId = this.routingService.currentLogicalUnitId;
        }
        let result: IFormValidationMessage;

        if (this.formValidationMessages[logicalUnitId] != null && this.formValidationMessages[logicalUnitId][validatorName] != null) {
            result = this.formValidationMessages[logicalUnitId][validatorName];
        }

        if (result == null) {
            logicalUnitId = 'GAMMA';
            if (this.formValidationMessages[logicalUnitId] != null || this.formValidationMessages[logicalUnitId][validatorName] != null) {
                result = this.formValidationMessages[logicalUnitId][validatorName];
            }
        }

        return result;
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Mixin } from '../../../../gamma/core';
import { HttpService } from '../../../../gamma/http';
import { I18nService } from '../../../../gamma/i18n';
import { DomService } from '../../../../gamma/utils';

import { U2000_AlertComponentMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-component.mixin';
import { U2000_AlertServiceMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-service.mixin';
import { U2000_EmailCompletedComponent } from './U2000_emailcompleted/U2000_emailcompleted.component';
import { U2000_VerifyEmailNavigationStepsEnum } from './U2000_verifyemail';
import { U2000_VerifyEmailService } from './U2000_verifyemail.service';
import { U2000_VerifyEmailCodeComponent } from './U2000_verifyemailcode/U2000_verifyemailcode.component';

@Component({
    selector: 'u2000-verifyemail',
    templateUrl: 'U2000_verifyemail.component.html',
    styleUrls: ['U2000_verifyemail.component.scss'],
    providers: [U2000_VerifyEmailService],
})
@Mixin([U2000_AlertComponentMixin])
export class U2000_VerifyEmailComponent implements OnInit, OnDestroy, U2000_AlertComponentMixin {
    @Input() email: string;
    @Output() valid = new EventEmitter<string>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() error = new EventEmitter<HttpErrorResponse>();

    //#region Mixin requirements
    // Alert mixin requirements
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    initAlertWithoutService: () => void;
    linkAlertWithService: (service: U2000_AlertServiceMixin) => void;
    destroyAlertComponent: () => void;
    alertClosed: (alert: IU2000_Alert) => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    alerts: IU2000_Alert[];
    //#endregion

    loadingObs: Observable<any>;

    constructor(
        private service: U2000_VerifyEmailService,
        public injector: Injector,
        // Alert mixin requirements
        public i18nService: I18nService,
        public domService: DomService,

        private httpService: HttpService,
    ) {
        this.service.valid = this.valid;
        this.service.error = this.error;

        this.linkAlertWithService(service);
    }

    ngOnDestroy() {
        this.destroyAlertComponent();
    }

    ngOnInit() {
        this.loadingObs = this.service.resolve(this.email);
    }

    get currentNavigationComponent() {
        if (this.service.currentNavigationComponentId === U2000_VerifyEmailNavigationStepsEnum.verifyCode) {
            return U2000_VerifyEmailCodeComponent;
        } else if (this.service.currentNavigationComponentId === U2000_VerifyEmailNavigationStepsEnum.completed) {
            return U2000_EmailCompletedComponent;
        }
        return null;
    }
}

/**
 * @license
 * Copyright ACCEO Solution Inc. All Rights Reserved.
 */
/**
 * @module
 * @description
 * Entry point for all public APIs of the i18n package.
 */

export * from './i18n.module';
export * from './i18n.service';

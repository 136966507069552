import { HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../environments/environment';
import { HttpService, IInterceptor } from '../http';
import { RoutingService } from '../routing/routing.service';
import { IInterceptorOptions } from './logicalunit-interfaces';

/**
 * Base logical unit service.
 *
 * @export
 * @abstract
 * @class LogicalUnitBaseService
 */
export abstract class LogicalUnitBaseService {
    static className = 'FormBaseComponent';

    logicalUnitUrl: string;
    translationParts: string[];

    luType: 'Modal' | 'Page' = 'Page';

    /**
     * Hook method called when the logical unit initialization is successfull.
     *
     * @memberof LogicalUnitBaseService
     */
    initCompleted: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => void;

    /**
     * Hook method called when the logical unit initialization failed.
     *
     * @memberof LogicalUnitBaseService
     */
    initFailed: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => void;

    /**
     * Hook method called when the logical unit receives a parameter from another logical unit.
     *
     * @memberof LogicalUnitBaseService
     */
    paramReceived: (param: any) => void;

    /**
     * Hook method called when the logical unit is activated.
     *
     * @memberof LogicalUnitBaseService
     */
    activated: (lastLogicalUnitService?: LogicalUnitBaseService) => void;

    /**
     * Hook method called when the logical unit is deactivated.
     *
     * @memberof LogicalUnitBaseService
     */
    deactivated: (nextLogicalUnitService?: LogicalUnitBaseService) => void;

    /**
     * Hook method called when the logical unit service may be reset like on a logout or a switch of context.
     *
     * @memberof LogicalUnitBaseService
     */
    reset: () => void;

    getNeedHelpInfo: (needHelpInfo: any) => any;

    /**
     * List of interceptors that used thru the whole logical unit (Init LU and all other calls).
     *
     * @type {IInterceptor[]}
     * @memberof LogicalUnitBaseService
     */
    interceptors: IInterceptor[];

    /**
     * Options on how the logical unit interceptors works.
     *
     * @type {IInterceptorOptions}
     * @memberof LogicalUnitBaseService
     */
    interceptorOptions: IInterceptorOptions;

    /**
     * Creates an instance of LogicalUnitBaseService.
     * @param {RoutingService} routingService
     * @memberof LogicalUnitBaseService
     */
    constructor(protected routingService: RoutingService, public httpService: HttpService) {
        this.interceptorOptions = { persistThroughModalLogicalUnit: false };

        this.translationParts = [this.logicalUnitName];
    }

    activatedInternal(lastLogicalUnitService: LogicalUnitBaseService) {
        // Everytime a logical unit is activated its interceptors are added.
        // Note that the interceptors for the InitLU are also added for its only purpose and removed just after.
        if (
            !this.interceptorOptions.persistThroughModalLogicalUnit ||
            lastLogicalUnitService == null ||
            (this.interceptorOptions.persistThroughModalLogicalUnit && lastLogicalUnitService != null && !(lastLogicalUnitService.luType === 'Modal'))
        ) {
            this.addInterceptors();
        }

        if (this.activated) {
            this.activated(lastLogicalUnitService);
        }
    }

    deactivatedInternal(nextLogicalUnitService: LogicalUnitBaseService) {
        if (
            !this.interceptorOptions.persistThroughModalLogicalUnit ||
            (this.interceptorOptions.persistThroughModalLogicalUnit && nextLogicalUnitService != null && !(nextLogicalUnitService.luType === 'Modal'))
        ) {
            this.removeInterceptors();
        }
        if (this.deactivated) {
            this.deactivated(nextLogicalUnitService);
        }
    }

    addInterceptors() {
        if (this.interceptors) {
            this.routingService.luFlowLogs.push({ lu: this.logicalUnitId, action: 'Interceptor added' });

            this.interceptors.forEach(interceptor => {
                this.httpService.addInterceptor(interceptor);
            });
        }
    }

    removeInterceptors() {
        if (this.interceptors) {
            this.routingService.luFlowLogs.push({ lu: this.logicalUnitId, action: 'Interceptor removed' });
            this.interceptors.forEach(interceptor => {
                this.httpService.removeInterceptor(interceptor);
            });
        }
    }

    /**
     * Method that must be called at the end of the service's constructor.
     *
     * @protected
     *
     * @memberof LogicalUnitBaseService
     */
    protected logicalUnitCreated() {
        this.routingService.addLogicalUnit(this);
    }

    /**
     * The default apiUrl built from the environment variable and the logical unit id.
     *
     * @readonly
     * @type {string}
     * @memberof LogicalUnitBaseService
     */
    get apiUrl(): string {
        if (this.logicalUnitUrl) {
            return `${environment.apiUrl}${this.logicalUnitUrl}`;
        } else {
            return `${environment.apiUrl}${this.logicalUnitId}`;
        }
    }

    /**
     * Method that extracts the result or results from the http response.
     *
     * @template T Result type
     * @param {Response} res Response from the server.
     * @returns {T} The result or results node.
     *
     * @memberof LogicalUnitBaseService
     * @example
     *      createPersonalAccount(formValue: IU2111_InvoicePortalBindingCreatePersonalAccountDtoRequest) {
     *          return this.http.post(this.apiUrl + '/createPersonalAccount', formValue)
     *              .pipe(
     *                  map(res => {
     *                      return this.extractResult<IU2111_InvoicePortalBindingCreatePersonalAccountDtoResponse>(res);
     *                  })
     *              );
     *      }
     */
    public extractResult<T>(res: any): T {
        if (res && res.result != null) {
            return res.result;
        } else if (res && (res.results || res.nbResults === 0)) {
            return res.results;
        } else {
            return res as any;
        }
    }

    /**
     * Add search criterias to be send with the http call.
     *
     * @protected
     * @param {IAbstractSearchDtoCriteria} criteria
     * @param {URLSearchParams} urlParams
     *
     * @memberof LogicalUnitBaseService
     * @example
     *      getInvoices(criteria: IU5101_SearchInvoiceSummaryDtoCriteria) {
     *          let urlParams = new URLSearchParams();
     *          this.addSearchCriteriaAsParams(criteria, urlParams);
     *
     *          return <Observable<IU5101_SearchInvoiceSummaryDtoResponse>>this.http
     *              .get(this.apiUrl, { search: urlParams });
     *      }
     */
    protected addSearchCriteriaAsParams(criteria: IAbstractSearchDtoCriteria, urlParams: HttpParams) {
        // TODO: May not be a framework feature but more of a U2000 feature.
        if (criteria.limit || criteria.limit === 0) {
            urlParams = urlParams.set('limit', String(criteria.limit));
        }

        if (criteria.offset) {
            urlParams = urlParams.set('offset', String(criteria.offset));
        }

        if (criteria.scope) {
            urlParams = urlParams.set('scope', criteria.scope);
        }

        if (criteria.sortOrder) {
            urlParams = urlParams.set('sortOrder', criteria.sortOrder);
        }

        if (criteria.computeNumberOfRecords) {
            urlParams = urlParams.set('computeNumberOfRecords', String(criteria.computeNumberOfRecords));
        }
        return urlParams;
    }

    /**
     * Name of the service.
     * On set, the logical unit id and name are extracted from the service name.
     *
     * @memberof LogicalUnitBaseService
     * @example
     *      U5101_StatementOfAccountService
     */
    get name() {
        return (this.constructor as typeof LogicalUnitBaseService).className;
    }

    /**
     * Logical unit id.
     *
     * @readonly
     *
     * @memberof LogicalUnitBaseService
     * @example
     *      U5101
     */
    get logicalUnitId() {
        return this.name.substr(0, 5);
    }

    /**
     * Logical unit name.
     *
     * @readonly
     *
     * @memberof LogicalUnitBaseService
     * @example
     *      U5101_StatementOfAccount
     */
    get logicalUnitName() {
        return this.name.substr(0, this.name.length - 7);
    }
}

import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { UserAgentService } from '../../../../utils/user-agent.service';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormTextboxQuestion } from '../../formquestion-textbox';

@Component({
    selector: 'form-textbox-bootstrap',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.scss'],
})
export class FormTextboxBootstrapComponent extends FormQuestionBaseComponent implements OnInit, AfterViewInit {
    @Input() override question: FormTextboxQuestion;
    @Input() override form: UntypedFormGroup;

    // 8 = Backspace,       9 = tab,            33 = page up,           34 = page down,
    // 35 = end,            36 = home,          37 = left arrow,        38 = up arrow,
    // 39 = right arrow,    40 = down arrow,    46 = Delete
    private RESTRICTION_FREE_KEYBOARD_KEYS = [8, 9, 33, 34, 35, 36, 37, 38, 39, 40, 46];

    private inputType: string;

    constructor(i18nService: I18nService, private el: ElementRef, private userAgent: UserAgentService) {
        super(i18nService);
    }

    ngOnInit() {
        this.inputType = this.question.type;
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }

    onKeyPress(event: KeyboardEvent) {
        if (this.shoudBypassSpecialCharacters(event)) {
            return;
        }

        if (this.question.restrict != null) {
            if (!this.question.restrict.exec(event.key)) {
                event.preventDefault();
            }
        }
    }

    onKeyDownEnter(event: Event) {
        if (this.question.onKeyDownEnter) {
            this.question.onKeyDownEnter(event, this.question);
        }

        const input: HTMLInputElement = event.target as any;
        // On mobile, shut the virtual keyboard if a submit is launched.
        if (this.userAgent.isMobile()) {
            input.blur();
        }
    }

    onChange(event: Event, question: FormTextboxQuestion) {
        // trimEnd and trimRight are not supported to all browsers and raises an error by the Typescript builder;
        this.question.value = this.question.value.replace(/\s+$/, '');

        if (this.question.onChange) {
            this.question.onChange(event, this.question);
        }
    }

    onPaste(event: ClipboardEvent, question: FormTextboxQuestion) {
        if (!this.question.disablePaste) {
            if (this.question.restrict != null) {
                event.preventDefault();
                const clipboardData = event.clipboardData || window['clipboardData'];
                const pastedText: string = clipboardData.getData('text');

                const restrictGlobal = new RegExp(this.question.restrict.source, 'g');

                const restrictedValueList = pastedText.match(restrictGlobal);
                if (restrictedValueList != null) {
                    let restrictedValue = restrictedValueList.join('');
                    if (this.question.maxLength != null) {
                        restrictedValue = restrictedValue.substr(0, this.question.maxLength);
                    }
                    this.question.value = restrictedValue;
                }
            }

            if (this.question.onPaste) {
                this.question.onPaste(event, this.question);
            }
        } else {
            event.preventDefault();
        }
    }

    /**
     * This is the fix for certains browsers(firefox and mozilla) where the key pressed of special characters are rejected by the filter.
     * use to filter out specific characters on firefox and mozilla from  the list to restrict.
     *
     * @export
     * @abstract
     * @method FormQuestionBaseComponent
     */
    shoudBypassSpecialCharacters(event: KeyboardEvent): boolean {
        if (this.userAgent.isFirefox() || this.userAgent.isMozilla()) {
            for (const key of this.RESTRICTION_FREE_KEYBOARD_KEYS) {
                if (event.keyCode === key) {
                    return true;
                }
            }
        }
        if (event.key === undefined) {
            return true;
        }

        return false;
    }

    getInputType() {
        return this.inputType;
    }

    togglePasswordType() {
        if (this.inputType === 'password') {
            this.inputType = 'text';
        } else {
            this.inputType = 'password';
        }
    }
}

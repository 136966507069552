/**
 * @license
 * Copyright ACCEO Solution Inc. All Rights Reserved.
 */
/**
 * @module
 * @description
 * Entry point for all public APIs of the referential package.
 */

export * from './referential.module';
export * from './referential.service';

import { Component, OnDestroy } from '@angular/core';

import { BaseComponent } from '../../../../../gamma/core/components/base.component';
import { ToastService } from '../../../../../gamma/toast';

import { U2000_VerifyEmailService } from '../U2000_verifyemail.service';

@Component({
    selector: 'u2000-verifyemailcode',
    templateUrl: 'U2000_verifyemailcode.component.html',
    styleUrls: ['U2000_verifyemailcode.component.scss'],
})
export class U2000_VerifyEmailCodeComponent extends BaseComponent<U2000_VerifyEmailService> implements OnDestroy {
    constructor(
        service: U2000_VerifyEmailService,
        // Super requirements
        toastService: ToastService,
    ) {
        super(service, toastService);
    }
}

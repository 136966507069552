import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[clickOutside]',
})
export class U2000_ClickOutsideDirective {
    private nativeEl: HTMLElement;

    @Input() clickOutsideSelector: string;
    @Output() clickOutside = new EventEmitter();

    constructor(private _elementRef: ElementRef) {
        this.nativeEl = this._elementRef.nativeElement;
    }

    @HostListener('document:click', ['$event'])
    public onClick(event) {
        const clickedOutside = !this.nativeEl.contains(event.target);

        if (clickedOutside && this.clickOutsideSelector && !event.target.classList.contains(this.clickOutsideSelector)) {
            this.clickOutside.emit(null);
        }
    }
}

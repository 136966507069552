import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { L10nService } from '../../../gamma/l10n/l10n.service';

/*
 * Temporary date formatter that give us the possiblitiy to customize the preset formats.
 */
@Pipe({ name: 'date' })
export class CustomDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe, private l10nService: L10nService) {}

    transform(value: Date, format = 'medium'): string {
        if (this.l10nService.dateFormats[format] != null) {
            format = this.l10nService.dateFormats[format];
        }
        return this.datePipe.transform(value, format);
    }
}

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { FormValidationMessageService, IFormValidationProvider } from '../../../gamma/form/formvalidationmessage.service';

import { U2000_ValidationMessages } from '../U2000_validators/U2000_validationmessages';

@Injectable()
export class U2000_FormValidationProvider implements IFormValidationProvider {
    service: FormValidationMessageService;

    constructor(private translateService: TranslateService) {}

    registered() {
        this.service.addMessages([
            U2000_ValidationMessages.required_0001,
            U2000_ValidationMessages.validEmail_0002,
            U2000_ValidationMessages.validPassword_0003,
            U2000_ValidationMessages.validUsername_0004,
            U2000_ValidationMessages.validPhoneNumber_0011,
            U2000_ValidationMessages.validPostalCode_0012,
            U2000_ValidationMessages.matchEmail_0014,
            U2000_ValidationMessages.validMinLength_0009,
            U2000_ValidationMessages.validDate_0015,
            U2000_ValidationMessages.startDateBeforeEndDate_0016,
            U2000_ValidationMessages.matchPassword_0018,
            U2000_ValidationMessages.minValue_0025,
            U2000_ValidationMessages.maxValue_0026,
        ]);
    }

    formatMessage(message: string, params: { [param: string]: any }) {
        if (message.indexOf('lbl') === 0 || (message.indexOf('U') === 0 && !isNaN(message.substr(1, 1) as any))) {
            message = this.translateService.instant(message, params);
        }
        return message;
    }
}

import { Injectable } from '@angular/core';

export type Browser = 'Chrome' | 'Firefox' | 'Safari' | 'Edge' | 'Edge Chromium' | 'Internet Explorer' | 'Opera' | 'BlackBerry' | 'Mozilla';

export type OS = 'Windows Phone' | 'Windows' | 'iPhone' | 'iPad' | 'Kindle' | 'Android' | 'PlayBook' | 'BlackBerry' | 'Macintosh' | 'Linux' | 'Palm';

/**
 * Service for browsers categories and version detection
 *
 * @export
 * @class UserAgentService
 */
@Injectable()
export class UserAgentService {
    os: OS;
    osVersion: number;
    browser: Browser;
    browserVersion: number;

    private agentsList: string;
    private osList: { name: OS; value: string; version: string }[];
    private browsersList: { name: Browser; value: string; version: string }[];

    constructor() {
        this.agentsList = [navigator.platform, navigator.userAgent, navigator.appName, navigator.appVersion, navigator.vendor, window['opera']].join(' ');

        this.osList = [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' },
        ];

        this.browsersList = [
            { name: 'Edge', value: 'Edge', version: 'Edge' },
            { name: 'Edge Chromium', value: 'edg/', version: 'Edge' },
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Internet Explorer', value: 'Trident', version: 'rv' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
        ];

        const tempsOS = this.matchItem(this.agentsList, this.osList);
        this.os = tempsOS.name;
        this.osVersion = tempsOS.version;

        const tempBrowser = this.matchItem(this.agentsList, this.browsersList);
        this.browser = tempBrowser.name;
        this.browserVersion = tempBrowser.version;

        // eslint-disable-next-line no-console
        console.info('Current OS: ' + this.os + ' ' + this.osVersion);
        // eslint-disable-next-line no-console
        console.info('Current Browser: ' + this.browser + ' ' + this.browserVersion);
    }

    /**
     *  Returns true if the browser is Internet Explorer
     *
     *  @returns {boolean} True for Internet Explorer detection, False otherwise.
     *  @memberof UserAgentService
     */
    isInternetExplorer(): boolean {
        return this.browser === 'Internet Explorer';
    }

    /**
     *  Returns true if the current browser is Edge (Internet Explorer > 11)
     *
     *  @returns {boolean} True for Edge detection, False otherwise.
     *  @memberof UserAgentService
     */
    isEdge(): boolean {
        return this.browser === 'Edge';
    }

    /**
     *  Returns true if the current browser is Edge Chromium
     *
     *  @returns {boolean} True for Edge Chromium detection, False otherwise.
     *  @memberof UserAgentService
     */
    isChrome(): boolean {
        return this.browser === 'Chrome';
    }

    /**
     *  Returns true if the current browser is Edge Chromium
     *
     *  @returns {boolean} True for Edge Chromium detection, False otherwise.
     *  @memberof UserAgentService
     */
    isEdgeChromium(): boolean {
        return this.browser === 'Edge Chromium';
    }

    /**
     * Tells if the current browser is IPad or IPhone.
     *
     * @returns {boolean} True for IOS detection, False otherwise.
     * @memberof UserAgentService
     */
    isIOS(userAgentOS?: string): boolean {
        let os = userAgentOS;
        if (!os) {
            os = this.os;
        }
        return os === 'iPad' || os === 'iPhone';
    }

    /**
     *  Returns true if the current browser is Safari
     *
     *  @returns {boolean} True for Safari detection, False otherwise.
     *  @memberof UserAgentService
     */
    isSafari(): boolean {
        return this.browser === 'Safari';
    }

    isFirefox(): boolean {
        return this.browser === 'Firefox';
    }

    isMozilla(): boolean {
        return this.browser === 'Mozilla';
    }

    /**
     * Tells if the current OS is IOS, Android ou Windows phone.
     *
     * @returns {boolean} True for Mobile detection, False otherwise.
     * @memberof UserAgentService
     */
    isMobile(userAgentOS?: string): boolean {
        let os = userAgentOS;
        if (!os) {
            os = this.os;
        }
        return this.isIOS(os) || os === 'Android' || os === 'Windows Phone';
    }

    isDeprecatedBrowser() {
        return this.isInternetExplorer() || this.isEdge() || (this.isChrome() && this.browserVersion < 88);
    }

    getBrowserFromUserAgent(userAgent: string) {
        const tempBrowser = this.matchItem(userAgent, this.browsersList);
        return tempBrowser;
    }

    getIsMobileFromUserAgent(userAgent: string) {
        const tempsOS = this.matchItem(userAgent, this.osList);
        return this.isMobile(tempsOS.name);
    }

    private matchItem(string: string, data) {
        let regex: RegExp;
        let regexv: RegExp;
        let match: boolean;
        let matches: any;
        let version: string;

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i');
            match = regex.test(string);
            if (match) {
                regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                matches = string.match(regexv);
                version = '';
                if (matches) {
                    if (matches[1]) {
                        matches = matches[1];
                    }
                }
                if (matches) {
                    matches = matches.split(/[._]+/);
                    for (let j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                            version += matches[j] + '.';
                        } else {
                            version += matches[j];
                        }
                    }
                } else {
                    version = '0';
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version),
                };
            }
        }
        return { name: 'unknown', version: 0 };
    }
}

import { Subscription } from 'rxjs';

import { I18nService } from '../../../gamma/i18n';
import { DomService } from 'src/gamma/utils';

import { U2000_AlertServiceMixin } from './U2000_alert-service.mixin';

/* Mixin implementation (To copy to the file implementing the mixin)
    //#region Mixin requirements
    // Alert mixin requirements
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    initAlertWithoutService: () => void;
    linkAlertWithService: (service: U2000_AlertServiceMixin) => void;
    destroyAlertComponent: () => void;
    alertClosed: (alert: IU2000_Alert) => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    alerts: IU2000_Alert[];
    //#endregion
*/
/* Mixin constructor implementation (To copy to the constructor class implementing the mixin)
    // Alert mixin requirements
    public i18nService: I18nService,
    domService: DomService;
*/

/* HTML code to use within the component template
<alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="alert.dismissible" [dismissOnTimeout]="alert.timeout"
    (onClosed)="alertClosed(alert)">
    {{ alert.message }}
</alert>
*/

export class U2000_AlertComponentMixin {
    i18nService: I18nService;
    alerts: IU2000_Alert[];
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    domService: DomService;

    initAlertWithoutService() {
        this.alerts = [];
    }

    linkAlertWithService(service: U2000_AlertServiceMixin) {
        this.initAlertWithoutService();

        this.addAlertSubscription = service.alertAdded$.subscribe(alert => {
            this.addAlert(alert);
        });

        this.updateAlertSubscription = service.alertUpdated$.subscribe(alert => {
            this.updateAlert(alert);
        });

        this.clearAlertSubscription = service.alertsCleared$.subscribe(() => {
            this.clearAlerts();
        });

        service.alertsAdded.forEach(x => {
            this.addAlert(x);
        });
        service.alertsAdded = [];
    }

    destroyAlertComponent() {
        if (this.addAlertSubscription) {
            this.addAlertSubscription.unsubscribe();
        }
        if (this.updateAlertSubscription) {
            this.updateAlertSubscription.unsubscribe();
        }
        if (this.clearAlertSubscription) {
            this.clearAlertSubscription.unsubscribe();
        }
    }

    alertClosed(alert: IU2000_Alert) {
        const alertPos = this.alerts.indexOf(alert);
        if (alertPos !== -1) {
            this.alerts.splice(alertPos, 1);
        }
    }

    addAlert(alert: IU2000_Alert) {
        if (alert.message.indexOf('lbl') === 0) {
            alert.lblMessage = alert.message;
        }
        alert.message = this.i18nService.formatLabel(alert.message, alert.translationOptions);
        alert.dismissible = alert.dismissible == null ? true : alert.dismissible;
        const alertAlreadyExists = this.alerts.find(x => x.message === alert.message);
        if (!alertAlreadyExists) {
            this.alerts.push(alert);
        }
        // Must wait for the DOM to write before scrolling to the first alert.
        setTimeout(() => {
            this.domService.scrollToAlert();
        }, 1);
    }

    updateAlert(alert: IU2000_Alert) {
        if (alert.lblMessage != null) {
            alert.message = this.i18nService.formatLabel(alert.lblMessage, alert.translationOptions);
        } else {
            alert.message = this.i18nService.formatLabel(alert.message, alert.translationOptions);
        }

        // Must wait for the DOM to write before scrolling to the first alert.
        setTimeout(() => {
            this.domService.scrollToAlert();
        }, 1);
    }

    clearAlerts() {
        this.alerts = [];
    }
}

import { NgModule } from '@angular/core';

import { U2000_SharedModule } from '../U2000_shared';
import { U2021_EulaRoutingModule, U2021_RoutedComponents } from './U2021_eula-routing.module';
import { U2021_EulaResolver } from './U2021_eula.resolver';
import { U2021_EulaService } from './U2021_eula.service';

@NgModule({
    imports: [U2021_EulaRoutingModule, U2000_SharedModule],
    exports: [],
    declarations: [U2021_RoutedComponents],
    providers: [U2021_EulaService, U2021_EulaResolver],
})
export class U2021_EulaModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicLabelComponent } from './components/dynamiclabel/dynamiclabel.component';
import { FileUploadButtonComponent } from './components/fileuploadbutton/fileuploadbutton.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CustomCurrencyPipe } from './pipes/customcurrency.pipe';
import { CustomDatePipe } from './pipes/customdate.pipe';
import { CustomNumberPipe } from './pipes/customnumber.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { SafeStylePipe } from './pipes/safestyle.pipe';
import { SafeUrlPipe } from './pipes/safeurl.pipe';
import { SecondsToTimePipe } from './pipes/secondstotime.pipe';

/**
 * Gamma shared module.
 *
 * @export
 * @class SharedModule
 */
@NgModule({
    imports: [CommonModule],
    exports: [CapitalizePipe, DynamicLabelComponent, FileUploadButtonComponent, CustomCurrencyPipe, CustomDatePipe, CustomNumberPipe, SafeHtmlPipe, SafeStylePipe, SafeUrlPipe, SecondsToTimePipe],
    declarations: [CapitalizePipe, DynamicLabelComponent, FileUploadButtonComponent, CustomCurrencyPipe, CustomDatePipe, CustomNumberPipe, SafeHtmlPipe, SafeStylePipe, SafeUrlPipe, SecondsToTimePipe],
    providers: [CustomCurrencyPipe, CustomDatePipe, CustomNumberPipe, SafeHtmlPipe, SafeStylePipe, SafeUrlPipe, SecondsToTimePipe],
})
export class SharedModule {}

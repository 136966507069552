import { NgModule } from '@angular/core';
import { ClipboardService } from './clipboard.service';

import { ColorService } from './color.service';
import { DateService } from './date.service';
import { DomService } from './dom.service';
import { MathService } from './math.service';
import { ObjectService } from './object.service';
import { PrintService } from './print.service';
import { RxjsService } from './rxjs.service';
import { UserAgentService } from './user-agent.service';
import { UuidService } from './uuid.service';

/**
 * Utils service.
 *
 * @export
 * @class UtilsModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorService, //
        ClipboardService,
        DateService,
        DomService,
        MathService,
        ObjectService,
        PrintService,
        RxjsService,
        UserAgentService,
        UuidService,
    ],
})
export class UtilsModule {}

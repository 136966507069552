import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { I18nProvider, I18nService, I18nServiceConfig } from './i18n.service';

/**
 * Internalinalization (i18n) module.
 *
 * @export
 * @class I18nModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [I18nService],
})
export class I18nModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof I18nModule
     */
    static withConfig(config: IServiceProviderConfig<I18nProvider> = {}): ModuleWithProviders<I18nModule> {
        return {
            ngModule: I18nModule,
            providers: [{ provide: I18nServiceConfig, useValue: config }],
        };
    }
}

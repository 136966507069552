import { NgModule } from '@angular/core';

import { RoutingService } from './routing.service';

/**
 * Routing module.
 *
 * @export
 * @class RoutingModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [RoutingService],
})
export class RoutingModule {}

import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { I18nService } from '../../../i18n';
import { UserAgentService } from '../../../utils';

export interface IDynamicLabelEvents {
    context: any;
    event: (value: any) => void;
    param?: any;
}

/**
 * Dynamic label that translates with parameters and allows to attach events to links contained in the lable.
 *
 * @export
 * @class DynamicLabelComponent
 * @implements {AfterViewInit}
 * @implements {OnInit}
 * @example
 *  // Implementation in the HTML template.
 *  <dynamic-label [label]="'lblAnyLabel'" [events]="dynamicLabelEvents" [translationOptions]="dynamicLabelTranslationOptions"/>
 *
 *  // Implementation in the TS component.
 *  get dynamicLabelEvents() {
 *      return [
 *          <IDynamicLabelEvents>{
 *              context: this,
 *              event: () => {
 *                  window.open('https://www.google.com', '_blank');
 *              }
 *          }
 *      ];
 *  }
 *
 *  get dynamicLabelTranslationOptions() {
 *      return {
 *          variable1: 'Text 1',
 *          variable2: 'Text 2'
 *      }
 *   }
 */
@Component({
    selector: 'dynamic-label',
    template: `<span [innerHtml]="formattedLabel"></span>`,
})
export class DynamicLabelComponent implements AfterViewInit, OnInit {
    private _label: string;
    @Input() translationOptions: any;
    @Input() events: IDynamicLabelEvents[];

    formattedLabel: SafeHtml;

    constructor(private el: ElementRef, private i18nService: I18nService, private sanitizer: DomSanitizer, private userAgentService: UserAgentService) {}

    @Input()
    get label(): string {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
        if (this._label != null) {
            const translatedLabel = this.i18nService.formatLabel(this._label, this.translationOptions);
            this.formattedLabel = this.sanitizer.bypassSecurityTrustHtml(translatedLabel);
        } else {
            this.formattedLabel = '';
        }
    }

    ngOnInit() {
        if (this.label != null) {
            const translatedLabel = this.i18nService.formatLabel(this.label, this.translationOptions);
            this.formattedLabel = this.sanitizer.bypassSecurityTrustHtml(translatedLabel);
        } else {
            this.formattedLabel = '';
        }
    }

    ngAfterViewInit() {
        const elements = (this.el.nativeElement as HTMLElement).getElementsByTagName('a');
        for (let i = 0; i < elements.length; i++) {
            elements.item(i).dataset['index'] = i.toString();

            if (this.userAgentService.isMobile()) {
                elements.item(i).ontouchstart = (event: Event) => {
                    this.hrefOnClick(event);
                };
            } else {
                elements.item(i).onclick = (event: Event) => {
                    this.hrefOnClick(event);
                };
            }
        }
    }

    hrefOnClick(event: Event) {
        const target = event.target || event.srcElement;

        const index = parseInt(target['getAttribute']('data-index'), 0);
        event.stopPropagation();
        event.preventDefault();

        this.events[index].event.apply(this.events[index].context, [this.events[index].param]);
    }
}

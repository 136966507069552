import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Mixin } from '../../gamma/core';
import { HttpErrorService, HttpService } from '../../gamma/http';
import { LogicalUnitInitMixin, LogicalUnitModalBaseService } from '../../gamma/logicalunit';
import { RoutingService } from '../../gamma/routing';
import { ToastService } from '../../gamma/toast';

@Injectable()
@Mixin([LogicalUnitInitMixin])
export class U2021_EulaService extends LogicalUnitModalBaseService implements LogicalUnitInitMixin {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'U2021_EulaService';

    // InitLU mixin requirements
    luConfigs: IU2021_EulaInitDto;
    initLU: () => Observable<any>;

    constructor(
        public override routingService: RoutingService,
        // InitLU mixin requirements
        public http: HttpClient,
        public override httpService: HttpService,
        public httpErrorService: HttpErrorService,
        public toastService: ToastService,
        public translateService: TranslateService,
    ) {
        super(routingService, httpService);

        // Tells the framework that everything is ready for activation.
        super.logicalUnitCreated();
    }
}

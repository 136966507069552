import { ComponentFactoryResolver, ComponentRef, Directive, Injector, Input, NgModuleRef, OnChanges, OnDestroy, SimpleChanges, Type, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormQuestionBase } from './formquestion-base';
import { FormQuestionBaseComponent } from './formquestion-base.component';

@Directive({
    selector: '[formQuestionOutlet]',
})
export class FormQuestionOutletDirective implements OnChanges, OnDestroy {
    @Input() formQuestionOutlet: Type<any>;
    @Input() question: FormQuestionBase;
    @Input() form: UntypedFormGroup;
    @Input() injector: Injector;

    private componentRef: ComponentRef<FormQuestionBaseComponent> = null;
    private moduleRef: NgModuleRef<any> = null;

    constructor(private viewContainerRef: ViewContainerRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.componentRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.componentRef.hostView));
        }
        this.viewContainerRef.clear();
        this.componentRef = null;

        if (this.formQuestionOutlet) {
            let injector = this.injector || this.viewContainerRef.parentInjector;

            if (this.moduleRef) {
                injector = this.moduleRef.injector;
            }

            const componentFactory = injector.get(ComponentFactoryResolver).resolveComponentFactory(this.formQuestionOutlet);

            this.componentRef = this.viewContainerRef.createComponent<FormQuestionBaseComponent>(componentFactory, this.viewContainerRef.length, injector);

            this.componentRef.instance.question = this.question;
            this.componentRef.instance.form = this.form;
        }
    }

    ngOnDestroy() {
        if (this.moduleRef) {
            this.moduleRef.destroy();
        }
    }
}

import { Validators } from '@angular/forms';

import { FormTextboxQuestion, IFormTextboxQuestion } from '../../gamma/form/formquestion/formquestion-textbox';

import { U2000_Validators } from '../U2000_core';

export class U2000_EmailQuestion extends FormTextboxQuestion {
    static schema: IFormTextboxQuestion = {
        key: 'email',
        label: 'lblEmail',
        type: 'email',
        maxLength: 100,
        validators: [Validators.required, U2000_Validators.email],
        restrict: /[A-Za-z0-9!#$%&'*+-/=?^_`{|}~@.]/,
    };

    constructor(options: IFormTextboxQuestion = {}) {
        super(options);
        const schema = U2000_EmailQuestion.schema;

        this.onChange = options.onChange;

        if (String.isNullOrEmpty(this.key)) {
            this.key = schema.key;
        }
        if (String.isNullOrEmpty(this.label)) {
            this.label = schema.label;
        }
        this.type = schema.type;
        if (this.maxLength == null) {
            this.maxLength = schema.maxLength;
        }
        if (this.validators == null) {
            this.validators = schema.validators;
        }

        if (String.isNullOrEmpty(this.label) && this.placeholder !== '') {
            this.labelClass += ' sr-only';
        }
        if (this.restrict == null) {
            this.restrict = schema.restrict;
        }
    }
}

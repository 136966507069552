import { EventEmitter, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ServiceProviderBase } from '../core/serviceprovider/serviceprovider-base';
import { IProvider, IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { LocalStorageService } from '../localstorage';

export const LS_Language = 'language';
export const I18nServiceConfig = new InjectionToken<IServiceProviderConfig<I18nProvider>>('I18N_SERVICE_CONFIG');

/**
 * Interface to define the I18n service provider.
 *
 * @export
 * @interface I18nProvider
 * @extends {IProvider<I18nService>}
 */
export interface I18nProvider extends IProvider<I18nService> {
    formatLabel?(label: string): string;
}

/**
 * Service that adds tools for internalinalization.
 *
 * @export
 * @class I18nService
 * @extends {ServiceProviderBase<I18nProvider, I18nService>}
 */
@Injectable()
export class I18nService extends ServiceProviderBase<I18nProvider, I18nService> {
    private LANG_PARAM = 'lang';
    private _currentLanguageCode: string;
    public languageChanged$: EventEmitter<string>;

    /**
     * Creates an instance of I18nService.
     * @param {Injector} injector
     * @param {IServiceProviderConfig<I18nProvider>} config
     *
     * @memberof I18nService
     */
    constructor(
        protected injector: Injector,
        @Inject(I18nServiceConfig) config: IServiceProviderConfig<I18nProvider>,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super(injector, config);

        // This configuration is declared in the appInitService.
        for (const luLanguage in window['forceLanguageLogicalUnit']) {
            if (window['forceLanguageLogicalUnit'].hasOwnProperty(luLanguage)) {
                if (window.location.hash.indexOf(luLanguage) !== -1) {
                    this._currentLanguageCode = window['forceLanguageLogicalUnit'][luLanguage];
                }
            }
        }

        if (this._currentLanguageCode == null && window.location.hash.indexOf('?') > -1) {
            let hash = window.location.hash.substring(window.location.hash.indexOf('?') + 1);

            const urlParams = new URLSearchParams(hash);
            if (urlParams.has(this.LANG_PARAM)) {
                this._currentLanguageCode = urlParams.get(this.LANG_PARAM);

                if (this._currentLanguageCode != null && this._currentLanguageCode.toLowerCase() !== 'fr' && this._currentLanguageCode.toLowerCase() !== 'en') {
                    this._currentLanguageCode = null;
                }
            }
        }

        if (String.isNullOrEmpty(this._currentLanguageCode)) {
            const storedLanguage = this.localStorageService.get<string>(LS_Language);

            if (storedLanguage != null) {
                this._currentLanguageCode = storedLanguage;
            } else {
                let navigatorLanguage = navigator.language || navigator['userLanguage'] || 'fr';
                if (navigatorLanguage.length > 2) {
                    navigatorLanguage = navigatorLanguage.substr(0, 2);
                }

                // Manage a fallback if the navigator error is different than FR or EN.
                if (navigatorLanguage === 'fr' || navigatorLanguage === 'en') {
                    this._currentLanguageCode = navigatorLanguage;
                } else {
                    this._currentLanguageCode = 'fr';
                }
            }
        }

        this.languageChanged$ = new EventEmitter();
    }

    /**
     * Format labels that passes through this method.
     * The default behavior is that it translates label beginning with lbl or U followed by a number.
     * All other strings will pass right throught the method.
     *
     * @param {string} label Label to format.
     * @returns The formatted label.
     *
     * @memberof I18nService
     */
    formatLabel(label: string, translationOptions?: any) {
        // this.translateService = this.injector.get(TranslateService);

        if (this.provider && this.provider.formatLabel) {
            label = this.provider.formatLabel(label);
        } else {
            if (!String.isNullOrEmpty(label) && (label.indexOf('lbl') === 0 || ((label.indexOf('A') === 0 || label.indexOf('U') === 0) && !isNaN(label.substr(1, 1) as any)))) {
                label = this.translateService.instant(label, translationOptions);
            }
        }
        return label;
    }

    get currentLanguageCode() {
        return this._currentLanguageCode;
    }

    set currentLanguageCode(languageCode) {
        if (languageCode.length > 2) {
            languageCode = this._currentLanguageCode.substr(0, 2);
        }
        if (languageCode !== this._currentLanguageCode) {
            this._currentLanguageCode = languageCode;
            this.languageChanged$.emit(this._currentLanguageCode);
            this.localStorageService.set(LS_Language, this._currentLanguageCode);

            if (window.location.hash.indexOf(this.LANG_PARAM) > -1) {
                // Cannot use the router because its too soon in the app init process.
                let hash = window.location.hash.substr(0, window.location.hash.indexOf(this.LANG_PARAM));
                hash = hash + window.location.hash.substr(window.location.hash.indexOf(this.LANG_PARAM) + 8);
                window.location.hash = hash;
            }
            window.location.reload();
        }
    }

    toggleLanguage() {
        if (this.currentLanguageCode === 'fr') {
            this.currentLanguageCode = 'en';
        } else {
            this.currentLanguageCode = 'fr';
        }
    }
}

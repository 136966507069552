import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { ISecurityProvider, SecurityService, SecurityServiceConfig } from './security.service';

/**
 * Security module.
 *
 * @export
 * @class SecurityModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [SecurityService],
})
export class SecurityModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof SecurityModule
     */
    static withConfig(config: IServiceProviderConfig<ISecurityProvider> = {}): ModuleWithProviders<SecurityModule> {
        return {
            ngModule: SecurityModule,
            providers: [{ provide: SecurityServiceConfig, useValue: config }],
        };
    }
}

import { DatePipe } from '@angular/common';
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { PopoverDirective } from 'ngx-bootstrap/popover';

import { I18nService } from '../../../../../i18n';
import { L10nService } from '../../../../../l10n';
import { FormDateQuestion } from '../../../formquestion-date';

@Component({
    selector: 'form-date-input-bootstrap',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormDateInputBootstrapComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormDateInputBootstrapComponent),
            multi: true,
        },
    ],
})
export class FormDateInputBootstrapComponent implements ControlValueAccessor, Validator {
    @ViewChild('dp', { static: true }) datePicker: PopoverDirective;
    @Input() question: FormDateQuestion;
    @Input() form: UntypedFormGroup;

    bsConfig: Partial<BsDatepickerConfig>;
    value: Date;
    dateMask = [/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/];
    dateError = false;

    constructor(
        //
        private i18nService: I18nService,
        protected datePipe: DatePipe,
        private l10nService: L10nService,
        private localeService: BsLocaleService,
        private translateService: TranslateService,
    ) {
        this.value = null;

        this.translateService.setTranslation(
            'fr',
            {
                sysToday: `Aujourd'hui`,
                sysClear: 'Effacer',
            },
            true,
        );
        this.translateService.setTranslation(
            'en',
            {
                sysToday: 'Today',
                sysClear: 'Clear',
            },
            true,
        );

        this.bsConfig = {
            dateInputFormat: 'YYYY-MM-DD,h:mm:ss a',
            showWeekNumbers: false,
            containerClass: 'theme-acceo',
            showTodayButton: true,
            showClearButton: true,
            todayPosition: 'left',
            customTodayClass: 'bg-light',
            todayButtonLabel: this.translateService.instant('sysToday'),
            clearButtonLabel: this.translateService.instant('sysClear'),
        };

        this.localeService.use(this.l10nService.localeId);
    }

    onDatePickerChange(value: Date) {
        this.convertValue(value);
        this.onTouched();

        setTimeout(() => {
            // let htmlElement: HTMLElement = this.el.nativeElement;
            // let inputElement = htmlElement.getElementsByTagName('input')[0];

            const evt = document.createEvent('HTMLEvents');

            evt.initEvent('change', false, true);
            if (this.question.onChange != null) {
                this.question.onChange(evt, this.question);
            }
            // inputElement.dispatchEvent(evt);
        }, 0);
    }

    onQuestionChange(event: Event) {
        if (this.question.onChange != null) {
            this.question.onChange(event, this.question);
        }
    }

    onClearButtonClick() {
        this.onDatePickerChange(null);
        if (this.datePicker.isOpen) {
            this.datePicker.hide();
        }
    }

    onSearchButtonClick() {
        this.toggleVisibility();
    }

    toggleVisibility() {
        this.datePicker.show();
    }

    hideDatePicker() {
        if (this.datePicker.isOpen) {
            this.datePicker.hide();
        }
    }

    private convertValue(value: Date, propagateChange = true) {
        try {
            if (value != null) {
                //this.value = this.datePipe.transform(value, 'yyyy-MM-dd');
                this.value = value;
            } else {
                this.value = null;
            }
            this.dateError = false;
        } catch (e) {
            this.value = null;
            this.dateError = true;
        }
        if (propagateChange) {
            this.propagateChange(value);
        }
    }

    private propagateChange = (_: any) => {
        /* Must be empty */
    };

    onTouched = () => {
        /* Must be empty */
    };

    writeValue(value: string) {
        // Fix a bug introduced in the ngx-boostrap. The value was overriden by the affectation of the bsConfig initialization.
        setTimeout(() => {
            if (String.isNullOrEmpty(value)) {
                value = null;
            }
            if (typeof value === 'string') {
                if (!String.isNullOrEmpty(value)) {
                    this.value = new Date(value);
                } else {
                    this.value = null;
                }
            } else {
                this.value = value;
            }
        }, 0);
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /**
     * Form the question label with the i18n service.
     *
     * @param {string} label Label to format.
     * @returns {string} The formatted label.
     *
     * @memberof FormQuestionBaseComponent
     */
    formatLabel(label: string): string {
        return this.i18nService.formatLabel(label);
    }

    get componentId() {
        return this.question.idPrefix + '-' + this.question.key.toLowerCase();
    }

    validate(c: UntypedFormControl) {
        return !this.dateError ? null : { validDate: false };
    }
}

/*
 * Interface that adds features to the Array object declared in the lib.d.ts.
 */
declare global {
    interface ArrayConstructor {
        /**
         * Tests if an array is null or empty.
         * @function isNullOrEmpty
         * @param {any[]} anArray Array to validate.
         * @return {boolean} Tells if the array is null or empty.
         * @static
         */
        isNullOrEmpty(aArray: any[]): boolean;
    }
}

/**
 * Tests if an array is null or empty.
 * @function isNullOrEmpty
 * @param {any[]} anArray Array to validate.
 * @return {boolean} Tells if the array is null or empty.
 * @static
 */
Array.isNullOrEmpty = (anArray: any[]): boolean => {
    if (anArray == null || anArray === undefined) {
        return true;
    } else if (anArray.length === 0) {
        return true;
    }
    return false;
};

export {};

import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { of } from 'rxjs';

import { LocalStorageService } from '../../gamma/localstorage';

import { languageHashValues } from '../../languageshashvalues';

export class U2000_TranslateInitialLoader implements TranslateLoader {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

    public getTranslation(lang: string) {
        if (this.localStorageService.get('flag-translationVariablesDisplayed')) {
            return of({});
        } else {
            return this.http.get(`assets/i18n/global_${lang}${languageHashValues['GLOBAL']}.json`);
        }
    }
}

import { NgModule } from '@angular/core';

import { MaskDirective } from './mask.directive';
import { MaskService } from './mask.service';

/**
 * Mask module.
 *
 * @export
 * @class MaskModule
 */
@NgModule({
    imports: [],
    exports: [MaskDirective],
    declarations: [MaskDirective],
    providers: [MaskService],
})
export class MaskModule {}

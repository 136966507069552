import { Component, OnInit } from '@angular/core';

import { BootstrapResolutionService } from '../../../../gamma/bootstrap';
import { I18nService } from '../../../../gamma/i18n';

@Component({
    selector: 'u2000-language',
    templateUrl: 'U2000_language.component.html',
    styleUrls: ['U2000_language.component.scss'],
})
export class U2000_LanguageComponent implements OnInit {
    currentLanguageLabel: string;

    constructor(private i18nService: I18nService, public bootstrapResolutionService: BootstrapResolutionService) {}

    ngOnInit() {
        if (this.i18nService.currentLanguageCode !== 'fr') {
            this.currentLanguageLabel = 'lblFrenchInv';
        } else {
            this.currentLanguageLabel = 'lblEnglishInv';
        }
    }

    toggleLanguage() {
        this.i18nService.toggleLanguage();
    }
}

import { Injectable, ɵisObservable, ɵisPromise } from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { every, mergeAll } from 'rxjs/operators';

/**
 * Service for Rxjs utils.
 *
 * @export
 * @class RxjsService
 */
@Injectable()
export class RxjsService {
    wrapIntoObservable(value) {
        if (ɵisObservable(value)) {
            return value;
        }
        if (ɵisPromise(value)) {
            // Use `Promise.resolve()` to wrap promise-like instances.
            // Required ie when a Resolver returns a AngularJS `$q` promise to correctly trigger the
            // change detection.
            return from(Promise.resolve(value));
        }
        return of(value);
    }

    andObservables(observables): Observable<any> {
        return observables.pipe(
            mergeAll(),
            every(result => result === true),
        );
    }
}

import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IInterceptor } from '../../gamma/http/http.service';
import { I18nService } from '../../gamma/i18n';

import { environment } from '../../environments/environment';

@Injectable()
export class U2000_BaseHttpInterceptor implements IInterceptor {
    /* eslint-disable */
    private VALID_DATE_REG_EXP: RegExp = /(^(((\d\d)(([02468][048])|([13579][26]))-02-29)|(((\d\d)(\d\d)))-((((0\d)|(1[0-2]))-((0\d)|(1\d)|(2[0-8])))|((((0[13578])|(1[02]))-31)|(((0[1,3-9])|(1[0-2]))-(29|30))))){1}[\s|T]?(([01]\d|2[0-3]):([0-5]\d):([0-5]\d))?.?(\d)*[-]?(\d\d):(\d\d)$)/;
    /* eslint-enable */

    constructor(private i18nService: I18nService) {}

    request(requestId: number, req: HttpRequest<any>) {
        let newHeaders = req.headers ? req.headers : new HttpHeaders();
        let newMethod = req.method;
        if (!environment.mock) {
            if (req.method === 'DELETE' || req.method === 'PUT') {
                if (req.method === 'DELETE') {
                    newHeaders = newHeaders.set('X-HTTP-Method-Override', 'DELETE');
                } else if (req.method === 'PUT') {
                    newHeaders = newHeaders.set('X-HTTP-Method-Override', 'PUT');
                }

                newMethod = 'POST';
            }

            newHeaders = newHeaders.set('Accept-Language', this.i18nService.currentLanguageCode);
        }
        return req.clone({ headers: newHeaders, method: newMethod });
    }

    response(requestId: number, res: HttpResponse<any>) {
        if (res.body.result) {
            this.convertStringToDate(res.body.result);
        } else if (res.body.results) {
            this.convertStringToDate(res.body.results);
        }

        return res;
    }

    // Pour l'instant laisser de même.
    // Voir autre solution: https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
    private convertStringToDate(obj: any): void {
        let key: string;

        if (obj instanceof Array) {
            (obj as Array<any>).forEach((arrayItem: any): void => {
                this.convertStringToDate(arrayItem);
            });
        } else if (typeof obj === 'object') {
            // Convert string dates to date format.
            for (key in obj) {
                if (key[0] !== '_') {
                    if (obj[key] instanceof Array) {
                        (obj[key] as Array<any>).forEach((arrayItem: any): void => {
                            this.convertStringToDate(arrayItem);
                        });
                    } else if (typeof obj[key] === 'object') {
                        this.convertStringToDate(obj[key]);
                    } else if (typeof obj[key] === 'string') {
                        if (this.VALID_DATE_REG_EXP.test(obj[key])) {
                            obj[key] = new Date(obj[key]);
                        }
                    }
                }
            }
        }
    }
}

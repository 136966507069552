export class ExceptionEvent {
    private _error: any;
    private _isDefaultPrevented: boolean;

    constructor(error) {
        this._error = error;
        this._isDefaultPrevented = false;
    }

    preventDefault(): void {
        this._isDefaultPrevented = true;
    }

    defaultPrevented(): boolean {
        return this._isDefaultPrevented;
    }

    get error() {
        return this._error;
    }
}

import { NgModule } from '@angular/core';

import { FormModule } from '../../../gamma/form/form.module';

import { U2000_FormValidationProvider } from '../../U2000_core';
import { U2000_SharedModule } from '../../U2000_shared';
import { U2000_ChooseSendingMethodFormComponent } from './U2000_esignature/U2000_choosesendingmethod/U2000_choosesendingmethod-form/U2000_choosesendingmethod-form.component';
import { U2000_ChooseSendingMethodComponent } from './U2000_esignature/U2000_choosesendingmethod/U2000_choosesendingmethod.component';
import { U2000_ESignatureComponent } from './U2000_esignature/U2000_esignature.component';
import { U2000_ESignatureVerifyCodeFormComponent } from './U2000_esignature/U2000_esignatureverifycode/U2000_esignatureverifycode-form/U2000_esignatureverifycode-form.component';
import { U2000_ESignatureVerifyCodeComponent } from './U2000_esignature/U2000_esignatureverifycode/U2000_esignatureverifycode.component';
import { U2000_RadioGroupComponent } from './U2000_radiogroup/U2000_radiogroup.component';
import { U2000_VerifyEmailModalComponent } from './U2000_verifyemail-modal/U2000_verifyemail-modal.component';
import { U2000_EmailCompletedComponent } from './U2000_verifyemail/U2000_emailcompleted/U2000_emailcompleted.component';
import { U2000_VerifyEmailComponent } from './U2000_verifyemail/U2000_verifyemail.component';
import { U2000_VerifyEmailCodeFormComponent } from './U2000_verifyemail/U2000_verifyemailcode/U2000_verifyemailcode-form/U2000_verifyemailcode-form.component';
import { U2000_VerifyEmailCodeComponent } from './U2000_verifyemail/U2000_verifyemailcode/U2000_verifyemailcode.component';
import { U2000_VerifyIdentityModalComponent } from './U2000_verifyidentity-modal/U2000_verifyidentity-modal.component';
import { U2000_ChooseCommunicationFormComponent } from './U2000_verifyidentity/U2000_choosecommunication/U2000_choosecommunication-form/U2000_choosecommunication-form.component';
import { U2000_ChooseCommunicationComponent } from './U2000_verifyidentity/U2000_choosecommunication/U2000_choosecommunication.component';
import { U2000_CompletedComponent } from './U2000_verifyidentity/U2000_completed/U2000_completed.component';
import { U2000_SecurityQuestionFormComponent } from './U2000_verifyidentity/U2000_securityquestion/U2000_securityquestion-form/U2000_securityquestion-form.component';
import { U2000_SecurityQuestionComponent } from './U2000_verifyidentity/U2000_securityquestion/U2000_securityquestion.component';
import { U2000_VerifyCodeFormComponent } from './U2000_verifyidentity/U2000_verifycode/U2000_verifycode-form/U2000_verifycode-form.component';
import { U2000_VerifyCodeComponent } from './U2000_verifyidentity/U2000_verifycode/U2000_verifycode.component';
import { U2000_VerifyIdentityComponent } from './U2000_verifyidentity/U2000_verifyidentity.component';

@NgModule({
    imports: [
        U2000_SharedModule,
        FormModule.withConfig({
            provider: U2000_FormValidationProvider,
        }),
    ],
    exports: [U2000_SharedModule, U2000_VerifyIdentityComponent, U2000_VerifyIdentityModalComponent, U2000_VerifyEmailModalComponent, U2000_ESignatureComponent],
    declarations: [
        U2000_RadioGroupComponent,
        U2000_VerifyIdentityComponent,
        U2000_ChooseCommunicationComponent,
        U2000_ChooseCommunicationFormComponent,
        U2000_VerifyCodeComponent,
        U2000_VerifyCodeFormComponent,
        U2000_SecurityQuestionComponent,
        U2000_SecurityQuestionFormComponent,
        U2000_CompletedComponent,
        U2000_VerifyIdentityModalComponent,
        U2000_VerifyCodeComponent,
        U2000_VerifyCodeFormComponent,
        U2000_VerifyEmailComponent,
        U2000_VerifyEmailModalComponent,
        U2000_VerifyEmailCodeComponent,
        U2000_VerifyEmailCodeFormComponent,
        U2000_EmailCompletedComponent,
        U2000_ESignatureComponent,
        U2000_ChooseSendingMethodComponent,
        U2000_ChooseSendingMethodFormComponent,
        U2000_ESignatureVerifyCodeComponent,
        U2000_ESignatureVerifyCodeFormComponent,
    ],
    providers: [],
})
export class U2000_SecurityModule {}

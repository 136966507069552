import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { L10nService } from '../../../gamma/l10n/l10n.service';

/*
 * Temporary currency formatter to avoid CA$500.00 when using the Angular one
 */
@Pipe({ name: 'number' })
export class CustomNumberPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe, private l10nSettingsService: L10nService) {}

    transform(value: number, digits: string = null): string {
        const formattedValue = this.decimalPipe.transform(value, digits);

        /* if (formattedValue == null) {
            return '';
        }
        if (formattedValue.indexOf(',') >= 0) {
            formattedValue = formattedValue.replace(',', this.l10nSettingsService.thousandSeparator);
        }

        formattedValue = formattedValue.replace('.', this.l10nSettingsService.decimalSeparator); */

        return formattedValue;
    }
}

import { DatePipe } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { BusyIndicatorService } from '../../../gamma/busyindicator';
import { BaseComponent } from '../../../gamma/core/components/base.component';
import { I18nService } from '../../../gamma/i18n';
import { LocalStorageService } from '../../../gamma/localstorage';
import { ILogicalUnitFlow, RoutingService } from '../../../gamma/routing';
import { SecurityService } from '../../../gamma/security';
import { ToastService } from '../../../gamma/toast';

import { U2000LS_TokenInfo } from '../U2000_localstorages';
import { U2000_AuthenticationService } from './U2000_authentication.service';
import { U2000_SeleniumService } from './U2000_selenium.service';

export interface IDevTool<T = BaseComponent<any>> {
    init(component: T);
    destroy(component: T);
}

export interface IDevDropdownOption {
    label: string;
    action: () => void;
    keyboardShortcut?: number;
}
export interface IDevSubDropdownOption {
    label: string;
    subOptions?: IDevSubDropdownSubOption[];
}

export interface IDevSubDropdownSubOption {
    label: string;
    action: () => void;
    keyboardShortcut?: number;
}

export interface ICompareSecurityTags {
    tag: string;
    comparison: 'Same' | 'Missing from loggued' | 'Missing from response';
}

@Injectable()
export class U2000_DevelopmentService {
    private _translationVariablesDisplayed = false;
    private _displayBusyIndicator = false;
    public isBarOpened = false;
    public lockedBar = false;
    public dropdownOptions: IDevDropdownOption[];
    public subDropdownOptions: IDevSubDropdownOption[];
    public flags: { label: string; variable: string; saveLocalStorage?: boolean }[];
    public panels: any[] = [];
    displayInstantCapture: boolean;
    displayLogicalUnitFlows: boolean;
    displayRoutingLogs: boolean;
    private _displayBusyIndicatorShowHide: boolean;
    public get displayBusyIndicatorShowHide(): boolean {
        return this._displayBusyIndicatorShowHide;
    }
    public set displayBusyIndicatorShowHide(value: boolean) {
        this.busyIndicatorService.logShowHide = value;
        this._displayBusyIndicatorShowHide = value;
    }
    routerEvents: RouterEvent[];

    constructor(
        private translateService: TranslateService,
        private authService: U2000_AuthenticationService,
        private securityService: SecurityService,
        private i18nService: I18nService,
        private toastService: ToastService,
        private datePipe: DatePipe,
        private busyIndicatorService: BusyIndicatorService,
        private localStorageService: LocalStorageService,
        private routingService: RoutingService,
        private http: HttpClient,
        router: Router,
        private seleniumService: U2000_SeleniumService,
    ) {
        this.dropdownOptions = [
            {
                label: 'Edit any label',
                action: () => {
                    document.body.contentEditable = 'true';
                },
            },
            {
                label: 'Invalidate token',
                action: () => this.invalidateToken(),
            },
            {
                label: 'Save URL location',
                action: () => this.saveUrlLocation(),
                keyboardShortcut: 83,
            },
            {
                label: 'Load URL location',
                action: () => this.loadUrlLocation(),
                keyboardShortcut: 76,
            },
            {
                label: 'Raise javascript error',
                action: () => this.raiseJavascriptError(),
            },
            {
                label: 'Raise Http Error',
                action: () => this.raiseHttpError(),
            },
            {
                label: 'Generate Selenium test base',
                action: () => this.seleniumService.generateSeleniumTestBase(),
            },
        ];

        this.subDropdownOptions = [];

        this.flags = [
            { label: 'Display label variables', variable: 'translationVariablesDisplayed' },
            { label: 'Display Logical unit flows', variable: 'displayLogicalUnitFlows' },
            { label: 'Display instant capture', variable: 'displayInstantCapture' },
            { label: 'Display routing logs', variable: 'displayRoutingLogs' },
            { label: 'Display Busy Indicator', variable: 'displayBusyIndicator', saveLocalStorage: false },
            { label: 'Display Busy Indicator Show/Hide', variable: 'displayBusyIndicatorShowHide' },
        ];

        this._translationVariablesDisplayed = this.localStorageService.get('translationVariablesDisplayed', false);

        this.flags.forEach(flag => {
            if (flag.saveLocalStorage !== false) {
                this[flag.variable] = this.localStorageService.get('flag-' + flag.variable, false);
            }
        });

        this.routingService.logFlows$.subscribe(flows => this.logLogicalUnitFlows(flows));

        /*if (!this._translationVariablesDisplayed) {
            this.translateService.resetLang(this.i18nService.currentLanguageCode);
        }*/
        router.events.subscribe((route: RouterEvent) => {
            route['name'] = route.constructor.name;
            if (this.displayRoutingLogs) {
                if (route instanceof NavigationStart) {
                    this.routerEvents = [];
                    this.routerEvents.push(route);
                } else if (route instanceof NavigationEnd) {
                    this.routerEvents.push(route);
                    this.logRoutings(this.routerEvents);
                } else {
                    this.routerEvents.push(route);
                }
            }
        });

        this.lockedBar = this.localStorageService.get('developmentBarlocked', false);
        this.isBarOpened = this.lockedBar;
    }

    get appVersion(): string {
        const version: string = this.getMetaValue('version');
        if (version.startsWith('${')) {
            return '<developper>';
        } else {
            return version;
        }
    }

    get appTimestamp(): string {
        let timestamp: Date;
        const metaTagValue: string = this.getMetaValue('last-updated');

        if (metaTagValue.startsWith('${')) {
            timestamp = new Date();
        } else {
            timestamp = new Date(metaTagValue);
        }

        try {
            return this.datePipe.transform(timestamp, 'yyyy-MM-dd HH:mm');
        } catch (e) {
            return null;
        }
    }

    toggleBar() {
        this.isBarOpened = !this.isBarOpened;
    }

    lockBar() {
        this.lockedBar = true;
        this.localStorageService.set('developmentBarlocked', true);
    }

    unlockBar() {
        this.lockedBar = false;
        this.localStorageService.remove('developmentBarlocked');
    }

    showTranslationVariables() {
        this.localStorageService.set('translationVariablesDisplayed', true);
        this.translateService.resetLang(this.i18nService.currentLanguageCode);
        this.toastService.info(`Vous venez d'activer l'affichage des variables de langues`);
    }

    hideTranslationVariables() {
        this.localStorageService.set('translationVariablesDisplayed', false);
        this._translationVariablesDisplayed = false;
        this.toastService.info(`Vous venez de désactiver l'affichage des variables de langues`);
        location.reload();
    }

    showBusyIndicator() {
        this.busyIndicatorService.show(null, 'Development service show busy indicator');
        // this.busyIndicatorService.show({ invisible: true});
    }

    flagChange(flag: { label: string; variable: string; saveLocalStorage?: boolean }) {
        if (flag.saveLocalStorage !== false) {
            this.localStorageService.set('flag-' + flag.variable, this[flag.variable]);
        }
    }

    private raiseJavascriptError() {
        this.showBusyIndicator();

        let i = 0;
        const intervalID = setInterval(() => {
            i = i + 1;
            if (i === 3) {
                window.clearInterval(intervalID);
            } else {
                this['AnyUnkownObject']['AnyUnknownValueOnInterval' + i] = 'AValue';
            }
        }, 2) as any;

        this['AnyUnkownObject']['AnyUnknownValue'] = 'AValue';
    }

    private invalidateToken() {
        this.authService.tokenInfo.access_token = 'invalid_token';
        this.localStorageService.set(U2000LS_TokenInfo, {
            access_token: 'invalid_token',
            token_type: 'bearer',
        });
        this.toastService.info(`Le token a été invalidé`);
    }

    // Made of IOS that does not support document.getElementsByTagName('meta')['version'];
    private getMetaValue(name: string) {
        const arMeta = document.getElementsByTagName('meta');
        for (let i = 0; i < arMeta.length; i++) {
            if (arMeta[i].name === name) {
                return arMeta[i].content;
            }
        }
        return null;
    }

    get translationVariablesDisplayed() {
        return this._translationVariablesDisplayed;
    }

    set translationVariablesDisplayed(value: boolean) {
        this._translationVariablesDisplayed = value;
        this.translateService.resetLang(this.i18nService.currentLanguageCode);
    }
    get displayBusyIndicator() {
        return this._displayBusyIndicator;
    }

    set displayBusyIndicator(value: boolean) {
        this._displayBusyIndicator = value;
        if (value) {
            this.busyIndicatorService.show(null, 'Development service display busy indicator');
        } else {
            this.busyIndicatorService.hide(null, 'Development service display busy indicator');
        }
    }

    saveUrlLocation() {
        this.localStorageService.set('dev_url_location', window.location.href);
        this.toastService.info('URL location saved');
    }

    loadUrlLocation() {
        const urlLocation = this.localStorageService.get('dev_url_location', null);
        if (!String.isNullOrEmpty(urlLocation)) {
            window.location.href = urlLocation;
        } else {
            this.toastService.warning('No URL location saved');
        }
    }

    getOptionByKeyShortcut(e: KeyboardEvent) {
        if (e.ctrlKey && e.shiftKey) {
            return this.dropdownOptions.find(x => x.keyboardShortcut === e.keyCode);
        }
        return null;
    }

    private logLogicalUnitFlows(flows: ILogicalUnitFlow[]) {
        if (this.displayLogicalUnitFlows) {
            if (!Array.isNullOrEmpty(flows)) {
                console.table(flows);
            }
        }
    }

    private logRoutings(routes: RouterEvent[]) {
        if (this.displayRoutingLogs) {
            if (!Array.isNullOrEmpty(routes)) {
                console.table(routes);
            }
        }
    }

    private raiseHttpError() {
        this.http.get<HttpResponse<any>>('test').subscribe(res => {});
    }

    compareSecurityTags(securityTagsResponse: string) {
        try {
            const response = JSON.parse(securityTagsResponse);
            const tags: string[] = response.result.securityTags;
            console.log('Compare security tags');

            const same: ICompareSecurityTags[] = [];
            const missingRes: ICompareSecurityTags[] = [];
            const missingLog: ICompareSecurityTags[] = [];
            tags.forEach(x => {
                if (this.securityService.hasAccess(x)) {
                    same.push({ tag: x, comparison: 'Same' });
                } else {
                    missingLog.push({ tag: x, comparison: 'Missing from loggued' });
                }
            });

            this.securityService.securityTags.forEach(x => {
                if (tags.find(y => y === x) == null) {
                    missingRes.push({ tag: x, comparison: 'Missing from response' });
                }
            });

            console.table(missingLog.concat(missingRes, same));
            this.toastService.success('Security tags compared. View console (F12 -> Console)');
        } catch (e) {
            this.toastService.error('Invalid response');
        }
    }

    addDropdownOption(option: IDevDropdownOption): IDevDropdownOption {
        this.dropdownOptions.push(option);
        return option;
    }

    removeDropdown(option: IDevDropdownOption) {
        const index = this.dropdownOptions.findIndex(x => x.label === option.label);
        if (index !== -1) {
            this.dropdownOptions.splice(index, 1);
        }
    }
}

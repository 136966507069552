/*
 * Interface that adds features to the String object declared in the lib.d.ts.
 */
declare global {
    interface StringConstructor {
        /**
         * Reference to an empty string ("").
         * @property empty
         * @static
         * @memberof StringConstructor
         */
        empty: string;
        /**
         * Reference to an empty string ("\u00A0").
         * @type {string}
         * @static
         * @memberof StringConstructor
         */
        nbsp: string;

        /**
         * Tests if a string is null or empty.
         * @function isNullOrEmpty
         * @param {string} aString String to validate.
         * @return {boolean} Tells if the value is null or empty.
         * @static
         * @memberof StringConstructor
         */
        isNullOrEmpty(aString: string): boolean;

        /**
         * Tests if a string is null or empty or blank
         * @function isBlank
         * @param {string} aString String to validate.
         * @return {boolean} Tells if the value is null or empty or blank.
         * @static
         */
        isBlank(aString: string): boolean;

        padLeft(aString: string, size: number, paddedWith?: string): string;

        /**
         * Gets the rightmost len characters of a String.
         * @function right
         * @param {string} aString Source string
         * @param {number} length The length of the required String
         * @return {string} The rightmost characters, null if null String input
         * @static
         */
        right(aString: string, length: number): string;
    }
}

/**
 * Tests if a string is null or empty.
 * @function isNullOrEmpty
 * @param {string} aString String to validate.
 * @return {boolean} Tells if the value is null or empty.
 * @static
 */
String.isNullOrEmpty = (aString: string): boolean => {
    return aString === undefined || aString == null || aString === String.empty;
};

/**
 * Tests if a string is null or empty or blank.
 * @function isBlank
 * @param {string} aString String to validate.
 * @return {boolean} Tells if the value is null or empty or blank
 * @static
 */
String.isBlank = (aString: string): boolean => {
    if (String.isNullOrEmpty(aString)) {
        return true;
    } else {
        return String(aString).trim().length === 0;
    }
};

String.padLeft = (aString: string, size: number, paddedWith = '0'): string => {
    let paddedString = aString + String.empty;
    while (paddedString.length < size) {
        paddedString = paddedWith + paddedString;
    }
    return paddedString;
};

/**
 * Gets the rightmost len characters of a String.
 * @function right
 * @param {string} aString Source string
 * @param {number} length The length of the required String
 * @return {string} The rightmost characters, null if null String input
 */
String.right = (aString: string, length: number): string => {
    if (String.isNullOrEmpty(aString) || aString.length <= length) {
        return aString;
    }

    return aString.substr(aString.length - length, length);
};

/**
 * Reference to an empty string ("").
 * @property empty
 * @static
 */
String.empty = '';

String.nbsp = '\u00A0';

export {};

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import { combineLatest, from } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { BusyIndicatorService } from '../../../../gamma/busyindicator';
import { Mixin } from '../../../../gamma/core';
import { FormBaseComponent, IRegistrationFormConfigs } from '../../../../gamma/form/form-base.component';
import { FormMixin, IRegisteredForm } from '../../../../gamma/form/form.mixin';
import { IFormValidationMessage } from '../../../../gamma/form/formvalidationmessage.service';
import { HttpService, IInterceptor } from '../../../../gamma/http';
import { UuidService } from '../../../../gamma/utils/uuid.service';

import { environment } from '../../../../environments/environment';
import { U2000_TranslatePartialLoader } from '../../../U2000_core';
import { U2000_AlertServiceMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-service.mixin';
import { U2000_ESignatureNavigationStepsEnum } from './U2000_esignature';

@Injectable()
@Mixin([U2000_AlertServiceMixin, FormMixin])
export class U2000_ESignatureService implements U2000_AlertServiceMixin, FormMixin {
    // There to avoid using the constructor's name that is not available when minified.
    static className = 'U2000_ESignatureService';

    //#region Mixin requirements
    // Alert mixin requirements
    alertsAdded: IU2000_Alert[];
    alertAdded$: EventEmitter<IU2000_Alert>;
    alertUpdated$: EventEmitter<IU2000_Alert>;
    alertsCleared$: EventEmitter<any>;
    initAlert: () => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    // Form mixin requirements
    formValidationMessages: IFormValidationMessage[];
    registeredForms: IRegisteredForm[];
    registerForm: (form: FormBaseComponent, configs: IRegistrationFormConfigs) => void;
    unregisterForm: (form: FormBaseComponent) => void;
    hasDirtyForms: () => boolean;
    cleanupForms: () => void;
    //#endregion

    _currentNavigationComponentId: U2000_ESignatureNavigationStepsEnum;

    valid: EventEmitter<string>;
    error: EventEmitter<HttpErrorResponse>;
    changeNavigationStepId: EventEmitter<number>;

    verificationCode: string;
    codeSendingMode: 'EMAIL' | 'SMS' = 'EMAIL';

    componentConfigs: IU2000_ESignatureInitResultDto;
    validatecodeResult: IU2000_ESignatureVerifyCodeResultDto;
    sendCodeResult: IU2000_ESignatureSendCodeResultDto;

    workflowInterceptor: IInterceptor;
    currentWorkflowReference: string;

    validationBeforeSendingCode: () => boolean;

    phoneNumber: string;
    private defaultEmail: string;
    $emailChanged: EventEmitter<string> = new EventEmitter();
    contactMediaControlFlowUuid: string;

    get email(): string {
        return this.defaultEmail;
    }
    set email(email: string) {
        this.defaultEmail = email;
        this.$emailChanged.emit(email);
    }

    constructor(
        public http: HttpClient,
        private httpService: HttpService,
        private translatePartialLoader: U2000_TranslatePartialLoader,
        private busyIndicatorService: BusyIndicatorService,
        private uuidService: UuidService,
    ) {
        this.initAlert();

        this.formValidationMessages = [];
        this.navigateTo(U2000_ESignatureNavigationStepsEnum.chooseSendingMethod);

        this.workflowInterceptor = {
            request: (requestId: number, req: HttpRequest<any>) => {
                let newHeaders = req.headers ? req.headers : new HttpHeaders();
                if (!newHeaders.has('X-SAIA-Workflow-Reference') && this.currentWorkflowReference != null) {
                    newHeaders = newHeaders.set('X-SAIA-Workflow-Reference', this.currentWorkflowReference);
                }

                return req.clone({ headers: newHeaders });
            },
        };
    }

    resolve(workflowReference: string) {
        this.currentWorkflowReference = workflowReference;

        this.busyIndicatorService.show(null, 'ESignature');
        const promise = new Promise((resolve, reject) => {
            combineLatest([this.initComponent(), this.initTranslation()]).subscribe(
                result => {
                    this.componentConfigs = result[0];
                    this.sendCodeResult = this.componentConfigs.sendCodeResult;

                    this.busyIndicatorService.hide(null, 'ESignature');
                    resolve(result[0]);
                },
                error => {
                    reject();

                    this.busyIndicatorService.hide(null, 'ESignature');
                    this.valid.emit(error);
                },
            );
        });

        return from(promise);
    }

    navigateTo(componentId: U2000_ESignatureNavigationStepsEnum) {
        this._currentNavigationComponentId = componentId;
        if (this.changeNavigationStepId != null) {
            this.changeNavigationStepId.emit(componentId);
        }
    }

    get currentNavigationComponentId() {
        return this._currentNavigationComponentId;
    }

    initComponent() {
        this.contactMediaControlFlowUuid = this.uuidService.generateUnsafeUuid();

        let url = environment.apiUrl + 'U2000/esignature/init';
        url = url + '?contactMediaControlFlowUuid=' + this.contactMediaControlFlowUuid;

        return this.http.get<IU2000_VerifyIdentityInitDtoResponse>(url, this.httpService.optionsWithExtra({ silent: true, globalErrorHandling: false })).pipe(
            map(res => {
                return res.result;
            }),
            tap(res => {
                this.componentConfigs = res;
                this.sendCodeResult = res.sendCodeResult;
            }),
            share(),
        );
    }

    initTranslation() {
        return this.translatePartialLoader.getPartialTranslation('U2000_ElectronicSignature').pipe(share());
    }

    verifyIdentityCode(formValue: IU2000_ESignatureVerifyCodeDtoRequest = {}) {
        if (formValue.verificationCode == null) {
            formValue.verificationCode = this.verificationCode;
        }
        formValue.contactMediaControlId = this.sendCodeResult.contactMediaControlId;
        formValue.contactMediaControlFlowUuid = this.contactMediaControlFlowUuid;
        formValue.securityKey = this.sendCodeResult.securityKey;

        return this.http.post<IU2000_ESignatureVerifyCodeDtoResponse>(environment.apiUrl + 'U2000/esignature/verifycode', formValue).pipe(
            share(),
            map(res => {
                return res.result;
            }),
            tap(res => {
                this.validatecodeResult = res;
            }),
        );
    }

    sendVerificationCode(formValue: IU2000_ESignatureSendCodeDtoRequest) {
        this.email = formValue.email;
        this.phoneNumber = formValue.phoneNumber;
        this.codeSendingMode = formValue.verificationCodeTypeId === 0 ? 'EMAIL' : 'SMS';
        formValue.contactMediaControlFlowUuid = this.contactMediaControlFlowUuid;

        return this.http.post<IU2000_ESignatureSendCodeDtoResponse>(environment.apiUrl + 'U2000/esignature/sendcode', formValue).pipe(
            map(res => {
                return res.result;
            }),
            tap(res => {
                this.sendCodeResult = res;
            }),
        );
    }

    resendVerificationCode(formValue: IU2000_ESignatureResendCodeDtoRequest) {
        formValue.contactMediaControlId = this.sendCodeResult.contactMediaControlId;
        formValue.contactMediaControlFlowUuid = this.contactMediaControlFlowUuid;
        formValue.securityKey = this.sendCodeResult.securityKey;
        return this.http.post<IU2000_ESignatureResendCodeDtoResponse>(environment.apiUrl + 'U2000/esignature/resendcode', formValue).pipe(
            map(res => {
                return res.result;
            }),
            tap(res => {}),
        );
    }

    onValid() {
        this.valid.emit(this.sendCodeResult.securityKey);
    }
}

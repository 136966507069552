import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';

export interface IU2000_PasswordValidation {
    upperCase: boolean;
    lowerCase: boolean;
    number: boolean;
    specialChar: boolean;
    minChars: boolean;
    valid: boolean;
}

export interface IU2000_UsernameValidation {
    charList: boolean;
    minChars: boolean;
    valid: boolean;
}

export class U2000_Validators {
    static email(control: AbstractControl): { [key: string]: boolean } | null {
        /* eslint-disable */
        let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        /* eslint-enable */

        if (control.value == null || control.value === '') {
            return null;
        }
        return !EMAIL_REGEXP.test(control.value) ? { validEmail: true } : null;
    }

    static website(control: AbstractControl): { [key: string]: boolean } | null {
        /* eslint-disable */
        let REGEXP = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/
        ;
        /* eslint-enable */

        if (control.value == null || control.value === '') {
            return null;
        }
        return !REGEXP.test(control.value) ? { validWebsite: true } : null;
    }

    static password(control: AbstractControl): { [key: string]: any } | null {
        if (control.value == null || control.value === '') {
            return null;
        }
        return !U2000_Validators.validatePassword(control).valid ? { validPassword: true } : null;
    }

    static username(control: AbstractControl): { [key: string]: any } | null {
        if (control.value == null || control.value === '') {
            return null;
        }
        return !U2000_Validators.validateUsername(control).valid ? { validUsername: true } : null;
    }

    static phoneNumber(control: AbstractControl): { [key: string]: any } | null {
        if (control.value == null || control.value === '') {
            return null;
        }
        const numbersOnly = control.value.replace(/\D+/g, '');
        return numbersOnly.length < 10 ? { validPhoneNumber: true } : null;
    }

    static postalCode(control: AbstractControl): { [key: string]: any } | null {
        if (control.value == null || control.value === '') {
            return null;
        }
        const charsOnly = control.value.replace(/_/g, '');
        return charsOnly.length < 6 ? { validPostalCode: true } : null;
    }

    static validatePassword(control: AbstractControl): IU2000_PasswordValidation {
        const password = control.value;
        const upperCase = !String.isNullOrEmpty(password) && /[A-Z]/.test(password);
        const lowerCase = !String.isNullOrEmpty(password) && /[a-z]/.test(password);
        const number = !String.isNullOrEmpty(password) && /[0-9]/.test(password);
        const specialChar = !String.isNullOrEmpty(password) && /[._@\!#$%&'*+=?^`{|}\/~\-]/.test(password);
        const minChars = !String.isNullOrEmpty(password) && password.length >= 8;
        const valid = upperCase && lowerCase && number && specialChar && minChars;

        return {
            upperCase: upperCase,
            lowerCase: lowerCase,
            number: number,
            specialChar: specialChar,
            minChars: minChars,
            valid: valid,
        };
    }

    static validateUsername(control: AbstractControl): IU2000_UsernameValidation {
        const username = control.value;

        const charList = !String.isNullOrEmpty(username) && /^[a-zA-Z0-9._@\!#$%&'*+=?^`{|}\/~\-]*$/.test(username);
        const minChars = !String.isNullOrEmpty(username) && username.length >= 8;
        const valid = charList && minChars;

        return {
            charList: charList,
            minChars: minChars,
            valid: valid,
        };
    }

    static emailMatcher(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
        const control = formGroup.get('email');
        const confirmControl = formGroup.get('confirmedEmail');

        // required error  will be raised by the validator of  each question
        if (String.isNullOrEmpty(control.value) || String.isNullOrEmpty(confirmControl.value)) {
            return null;
        }

        if (control.value.toLowerCase() === confirmControl.value.toLowerCase()) {
            confirmControl.setErrors(null);
            return null;
        }

        // Just want the message to be defined on the confirm messages
        confirmControl.markAsDirty();
        confirmControl.markAsTouched();
        confirmControl.setErrors({ matchEmail: true });
        //  return { 'matchEmail': true };
        return null;
    }

    static passwordMatcher(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
        const control = formGroup.get('accountPassword');
        const confirmControl = formGroup.get('confirmedPassword');
        if (control.pristine || confirmControl.pristine) {
            return null;
        }
        if (control.value === confirmControl.value) {
            confirmControl.setErrors(null);
            return null;
        }

        confirmControl.setErrors({ matchPassword: true });

        return null;
        // return { 'matchPassword': true };
    }

    static dateOrderValidation(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
        const startingDate = formGroup.get('dateFrom');
        const endingDate = formGroup.get('dateTo');

        // required error  will be raised by the validator of  each question
        if (String.isNullOrEmpty(startingDate.value) || String.isNullOrEmpty(endingDate.value)) {
            return null;
        }

        if (startingDate.value <= endingDate.value) {
            return null;
        }

        // Just want the message to be defined on the confirm messages
        return { startDateBeforeEndDate: true };
    }

    static searchFilterDateRangeValidation(formGroup: FormGroup): { [key: string]: boolean } | null {
        const dates = formGroup.value;
        if (!dates || Object.keys(dates).length === 0) {
            return null;
        }

        const startingDate = dates[0];
        const endingDate = dates[1];

        if (startingDate != null && endingDate != null) {
            // To calculate the time difference of two dates
            let differenceInTime = endingDate.getTime() - startingDate.getTime();

            // To calculate the no. of days between two dates
            let differenceInDays = differenceInTime / (1000 * 3600 * 24);

            // required error  will be raised by the validator of  each question
            if (differenceInDays > 90) {
                return { ninetyDaysBetweenDate: true };
            }
        }

        return null;
    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * Service for Dom utils.
 *
 * @export
 * @class DomService
 */
@Injectable()
export class DomService {
    constructor() {
        // Add a quick access via the console for developpers.
        if (!environment.production) {
            window['domService'] = this;
        }
    }

    /**
     * Get the selected text from the window / document.
     *
     * @returns The selected text
     * @memberof DomService
     */
    getSelection() {
        if (window.getSelection) {
            return window.getSelection().toString();
        } else if (document['selection'] && document['selection'].type !== 'Control') {
            return document['selection'].createRange().text;
        }
        return null;
    }

    /**
     * Tells if some text is selected from the window / document.
     *
     * @returns True if text is selected.
     * @memberof DomService
     */
    isTextSelected() {
        return !String.isNullOrEmpty(this.getSelection());
    }

    scrollToError() {
        const element = document.getElementsByClassName('has-error')[0];

        if (element) {
            this.scrollTo(element as HTMLElement);
        }
    }

    scrollToAlert() {
        const element = document.getElementsByClassName('alert')[0];

        if (element) {
            if (!element.classList.contains('dont-scroll-to')) {
                this.scrollTo(element as HTMLElement);
            }
        }
    }

    scrollTo(element: HTMLElement) {
        let top = this.getElementTop(element);

        if (element != null) {
            const modalContainer = document.getElementsByTagName('modal-container');
            if (modalContainer.length > 0) {
                let modalContainerElement: HTMLElement = modalContainer[0] as HTMLElement;
                let topOfElement = top - this.getElementTop(modalContainerElement) + modalContainerElement.scrollTop;

                modalContainerElement.scroll({ top: topOfElement, behavior: 'smooth' });
                // If a modal is shown, scroll the container instead of the body.
            } else {
                // 110 corresponds to the header height.
                let topOfElement = top - 108;
                window.scroll({ top: topOfElement, behavior: 'smooth' });
            }
        }
    }

    private getElementTop(element: HTMLElement) {
        let rect = element.getBoundingClientRect();
        let win = element.ownerDocument.defaultView;

        return rect.top + win.pageYOffset;
    }
}

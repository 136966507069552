import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { BusyIndicatorService } from '../../gamma/busyindicator/busyindicator.service';
import { LogicalUnitResolver } from '../../gamma/logicalunit';

import { U2011_LoginService } from './U2011_login.service';

@Injectable()
export class U2011_LoginResolver extends LogicalUnitResolver<U2011_LoginService> implements Resolve<LogicalUnitResolver<U2011_LoginService>> {
    constructor(service: U2011_LoginService, busyIndicatorService: BusyIndicatorService) {
        super(service, busyIndicatorService);
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { U2000_WebSocketService } from '../U2000_core/U2000_services/U2000_websocket.service';
import { U2000_SharedModule } from '../U2000_shared';
import { U2000_WorkspaceService } from './U2000_workspace.service';

@NgModule({
    imports: [U2000_SharedModule, RouterModule],
    exports: [],
    declarations: [],
    providers: [U2000_WorkspaceService],
})
export class U2000_WorkspaceModule {
    constructor(webSocketService: U2000_WebSocketService) {}
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { ISessionStorageServiceConfig } from './sessionstorage.interface';
import { SessionStorageService, SessionStorageServiceConfig } from './sessionstorage.service';

/**
 * Local storage module.
 *
 * @export
 * @class SessionStorageModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [SessionStorageService],
})
export class SessionStorageModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof SessionStorageModule
     */
    static withConfig(config: ISessionStorageServiceConfig = {}): ModuleWithProviders<SessionStorageModule> {
        return {
            ngModule: SessionStorageModule,
            providers: [{ provide: SessionStorageServiceConfig, useValue: config }],
        };
    }
}

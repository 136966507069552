import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from 'src/gamma/i18n';
import { UserAgentService } from 'src/gamma/utils';

import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormTextareaQuestion } from '../../formquestion-textarea';
import { FormTextboxQuestion } from '../../formquestion-textbox';

@Component({
    selector: 'form-textarea-bootstrap',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class FormTextareaBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormTextareaQuestion;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, private el: ElementRef, private userAgent: UserAgentService) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }

    onKeyPress(event: KeyboardEvent) {
        if (this.shoudBypassSpecialCharacters(event)) {
            return null;
        }
    }

    onKeyDownEnter(event: Event) {
        if (this.question.onKeyDownEnter) {
            this.question.onKeyDownEnter(event, this.question);
        }
        const input: HTMLInputElement = event.target as any;
        // On mobile, shut the virtual keyboard if a submit is launched.
        if (this.userAgent.isMobile()) {
            input.blur();
        }
    }

    onChange(event: Event, question: FormTextboxQuestion) {
        if (this.question.onChange) {
            this.question.onChange(event, this.question);
        }
    }

    /**
     * This is the fix for certains browsers(firefox and mozilla) where the key pressed of special characters are rejected by the filter.
     * use to filter out specific characters on firefox and mozilla from  the list to restrict.
     *
     * @export
     * @abstract
     * @method FormQuestionBaseComponent
     */
    shoudBypassSpecialCharacters(event: KeyboardEvent): boolean {
        if (event.key === undefined) {
            return true;
        }

        return false;
    }
}

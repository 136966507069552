import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormQuestionBaseComponent } from '../../../../gamma/form/formquestion/formquestion-base.component';
import { FormRadioQuestion, IFormRadioButton } from '../../../../gamma/form/formquestion/formquestion-radio';
import { I18nService } from '../../../../gamma/i18n';

@Component({
    selector: 'u2000-radiogroup',
    templateUrl: './U2000_radiogroup.component.html',
    styleUrls: ['./U2000_radiogroup.component.scss'],
})
export class U2000_RadioGroupComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormRadioQuestion<any>;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, private el: ElementRef) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
    }

    onChange(event: Event) {
        if (this.question.onChange != null) {
            this.question.onChange(event, this.question);
        }
    }

    getRadioButtonId(radioButton: IFormRadioButton<any>) {
        return 'rb-' + this.componentId + '-' + radioButton.key;
    }

    getRadioButtonLabelId(radioButton: IFormRadioButton<any>) {
        return 'lbl-' + this.componentId + '-' + radioButton.key;
    }
}

import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';

import { FormQuestionBase, IFormQuestionBase } from './formquestion-base';
import { FormQuestionBaseComponent } from './formquestion-base.component';

/**
 * Interface to define a form control question (Question with a specific type of display).
 *
 * @export
 * @interface IFormQuestionControlBase
 * @extends {IFormQuestionBase}
 * @template T
 */
export interface IFormQuestionControlBase<T> extends IFormQuestionBase {
    /**
     * Default value.
     *
     * @type {T}
     * @memberof IFormQuestionControlBase
     */
    defaultValue?: T;

    /**
     * Label displayed with the component. May be a lbl variable or a direct message define as a string.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    label?: string;

    /**
     * List of validators.
     *
     * @type {ValidatorFn[]}
     * @memberof IFormQuestionControlBase
     */
    validators?: ValidatorFn[];

    /**
     * Placeholder defined in the input before any thing is defined.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    placeholder?: string;

    /**
     * Reference to the form control.
     *
     * @type {FormControl}
     * @memberof IFormQuestionControlBase
     */
    formControlRef?: UntypedFormControl;

    /**
     * Reference to dom element.
     *
     * @type {HTMLElement}
     * @memberof IFormQuestionControlBase
     */

    domRef?: HTMLElement;

    /**
     * Maximum characters allowed in the input.
     *
     * @type {number}
     * @memberof IFormQuestionControlBase
     */
    maxLength?: number;

    /**
     * Minimum characters allowed in the input.
     *
     * @type {number}
     * @memberof IFormQuestionControlBase
     */
    minLength?: number;

    /**
     * With of the input. Exemple: '100px', '50%', '30em'.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    width?: string;

    /**
     * Event triggered on click.
     *
     * @memberof IFormQuestionControlBase
     */
    onClick?: (event: MouseEvent, question: FormQuestionBase) => void;

    /**
     * Event triggered on blur.
     *
     * @memberof IFormQuestionControlBase
     */
    onBlur?: (event: Event, question: FormQuestionBase) => void;

    /**
     * Event triggered on focus.
     *
     * @memberof IFormQuestionControlBase
     */
    onFocus?: (event: Event, question: FormQuestionBase) => void;

    /**
     * Event triggered on key pressed.
     *
     * @memberof IFormQuestionControlBase
     */
    onKeyPress?: (event: Event, question: FormQuestionBase) => void;

    /**
     * Event triggered on paste.
     *
     * @memberof IFormQuestionControlBase
     */
    onPaste?: (event: Event, question: FormQuestionBase) => void;

    /**
     * Event triggered on input.
     *
     * @memberof IFormQuestionControlBase
     */
    onInput?: (event: Event, question: FormQuestionBase) => void;

    /**
     * Event triggered on change.
     *
     * @memberof IFormQuestionControlBase
     */
    onChange?: (event: Event, question: FormQuestionBase) => void;

    /**
     * Classes added to the label element.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    labelClass?: string;

    /**
     * Classes added to the input container element.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    inputContainerClass?: string;

    /**
     * Classes added to the input element.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    inputClass?: string;

    /**
     * Tells if the input is disabled or not.
     *
     * @type {boolean}
     * @memberof IFormQuestionControlBase
     */
    disabled?: boolean;

    /**
     * Classes added to the form group element.
     *
     * @type {string}
     * @memberof IFormQuestionControlBase
     */
    formGroupClass?: string;

    /**
     * Hide the required marker event if the question is required.
     *
     * @type {boolean}
     * @memberof IFormQuestionControlBase
     */
    hideRequiredMarker?: boolean;
}

export interface IFormQuestionConvertValue {
    buildFormValueFromCriteria(criteria: any, formValue: any): void;
    buildCriteriaFromForm(formValue: any, criteria: any): void;
}

/**
 * Form control type of question.
 *
 * @export
 * @abstract
 * @class FormQuestionControlBase
 * @extends {FormQuestionBase}
 * @template T Type of value.
 */
export abstract class FormQuestionControlBase<T = any> extends FormQuestionBase {
    private _maxLength: number;
    private _validators: ValidatorFn[];

    defaultValue: T;
    label: string;
    placeholder: string;
    minLength: number;
    width: string;
    formControlRef: UntypedFormControl;
    domRef: HTMLElement;
    componentRef: FormQuestionBaseComponent;
    onClick: (event: MouseEvent, question: FormQuestionControlBase<T>) => void;
    onBlur: (event: Event, question: FormQuestionControlBase<T>) => void;
    onFocus: (event: Event, question: FormQuestionControlBase<T>) => void;
    onKeyPress: (event: Event, question: FormQuestionControlBase<T>) => void;
    onPaste: (event: Event, question: FormQuestionControlBase<T>) => void;
    onInput: (event: Event, question: FormQuestionControlBase<T>) => void;
    onChange: (event: Event, question: FormQuestionControlBase<T>) => void;
    labelClass: string;
    inputContainerClass: string;
    inputClass: string;
    disabled: boolean;
    formGroupClass: string;
    hideRequiredMarker: boolean;

    constructor(options: IFormQuestionControlBase<T> = {}) {
        super(options);
        this.defaultValue = options.defaultValue != null ? options.defaultValue : ('' as any);
        this.label = options.label || '';
        this.validators = options.validators;
        this.placeholder = options.placeholder || '';
        this.width = options.width || '';
        this.maxLength = options.maxLength;
        this.minLength = options.minLength;
        this.onClick =
            options.onClick ||
            (() => {
                /* Must be empty */
            });
        this.onBlur =
            options.onBlur ||
            (() => {
                /* Must be empty */
            });
        this.onFocus =
            options.onFocus ||
            (() => {
                /* Must be empty */
            });
        this.onKeyPress =
            options.onKeyPress ||
            (() => {
                /* Must be empty */
            });
        this.onPaste =
            options.onPaste ||
            (() => {
                /* Must be empty */
            });
        this.onInput =
            options.onInput ||
            (() => {
                /* Must be empty */
            });
        this.onChange = options.onChange;
        this.labelClass = options.labelClass;
        this.inputContainerClass = options.inputContainerClass;
        this.inputClass = options.inputClass || '';
        this.disabled = options.disabled || false;
        this.formGroupClass = options.formGroupClass || '';
        this.hideRequiredMarker = options.hideRequiredMarker || false;

        // This time out is there to help support question domains that sets the label right after the super.
        setTimeout(() => {
            if (String.isNullOrEmpty(this.label)) {
                this.labelClass += ' sr-only';
            }
        }, 1);
    }

    get value(): T {
        if (this.formControlRef) {
            return this.formControlRef.value;
        }
        return null;
    }

    set value(value: T) {
        if (this.formControlRef) {
            this.formControlRef.setValue(value);
        }
    }

    get isRequired() {
        let required = false;
        if (this.validators != null) {
            required = this.validators.find(x => x === Validators.required) != null;
        }
        return required;
    }

    set isRequired(required: boolean) {
        if (required) {
            if (this.validators != null) {
                if (this.validators.find(x => x === Validators.required) == null) {
                    this.validators.push(Validators.required);
                }
            } else {
                this.validators = [Validators.required];
            }
        } else {
            if (this.validators != null) {
                const index = this.validators.findIndex(x => x === Validators.required);
                if (index > -1) {
                    this.validators.splice(index, 1);
                }
            }
        }
        this.formControlRef.setValidators(this.validators);
    }

    get maxLength(): number {
        return this._maxLength;
    }

    set maxLength(value: number) {
        this._maxLength = value;
        // Auto adjust width of an edit with its max length.
        if (this.width === '' && this.maxLength > 0) {
            this.width = this.maxLength.toString() + 'em';
        }
    }

    get emptyLabel(): boolean {
        return String.isNullOrEmpty(this.label);
    }

    set validators(validators: ValidatorFn[]) {
        if (this.formControlRef) {
            this.formControlRef.setValidators(validators);
            this.formControlRef.updateValueAndValidity();
        }
        this._validators = validators;
    }

    get validators(): ValidatorFn[] {
        return this._validators;
    }

    setFocus() {
        setTimeout(() => {
            if (this.domRef != null && this.domRef.getElementsByTagName('input').length > 0) {
                this.domRef.getElementsByTagName('input')[0].focus();
            }
        }, 1);
    }
}

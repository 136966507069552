import { Type } from '@angular/core';

import { FormQuestionType } from '../form.service';
import { FormGroupQuestion } from './formquestion-formgroup';

/**
 * Interface to define a question.
 *
 * @export
 * @interface IFormQuestionBase
 */
export interface IFormQuestionBase {
    id?: string;
    key?: string;
    order?: number;
    formQuestionType?: FormQuestionType;
    component?: Type<any>;
    holder?: any;
    note?: string;
    onAfterViewInit?: (htmlElement: HTMLElement) => void;
}

/**
 * Base class for a question.
 *
 * @export
 * @abstract
 * @class FormQuestionBase
 */
export abstract class FormQuestionBase {
    /**
     * Id used instead of the key
     *
     * @type {string}
     * @memberof FormQuestionBase
     */
    id: string;
    /**
     * Id prefix to be able to create ids on all questions components.
     *
     * @type {string}
     * @memberof FormQuestionBase
     */
    idPrefix: string;
    /**
     * Key of the question. Defide as the name of the HTML form control and also its id.
     *
     * @type {string}
     * @memberof FormQuestionBase
     */
    key: string;

    /**
     * Potential order of display. This property has no internal effect on the sort, it's only a tool for you to sort if needed.
     *
     * @type {number}
     * @memberof FormQuestionBase
     */
    order: number;
    /**
     * Form question type.
     *
     * @type {FormQuestionType}
     * @memberof FormQuestionBase
     */
    formQuestionType: FormQuestionType;
    /**
     * Alternative component used to display the question instead of the default one.
     *
     * @type {Type<any>}
     * @memberof FormQuestionBase
     */
    component?: Type<any>;

    /**
     * Free object that can be used to hold values into the question.
     *
     * @type {*}
     * @memberof FormQuestionBase
     */
    holder?: any;

    /**
     * Note displayed under the question.
     *
     * @type {*}
     * @memberof FormQuestionBase
     */
    note?: any;

    /**
     * Method called during the AfterViewInit phase of the lifecycle.
     *
     *
     * @memberof FormQuestionBase
     */
    onAfterViewInit?: (htmlElement: HTMLElement) => void;

    onReset?: () => void;

    /**
     * Parent question group.
     *
     * @type {FormGroupQuestion}
     * @memberof FormQuestionBase
     */
    parentQuestion: FormGroupQuestion;

    /**
     * Creates an instance of FormQuestionBase.
     * @param {IFormQuestionBase} [options={}] Creation options.
     *
     * @memberof FormQuestionBase
     */
    constructor(options: IFormQuestionBase = {}) {
        this.id = options.id;
        this.key = options.key || '';
        this.order = options.order === undefined ? 1 : options.order;
        this.formQuestionType = options.formQuestionType || FormQuestionType.Textbox;
        this.component = options.component;
        this.holder = options.holder;
        this.note = options.note;
        this.onAfterViewInit = options.onAfterViewInit;
    }

    get componentId() {
        let id: string;
        if (this.id != null) {
            if (!String.isNullOrEmpty(this.idPrefix)) {
                return this.idPrefix + '-' + this.id;
            }
            return id;
        }
        if (!String.isNullOrEmpty(this.idPrefix) && !String.isNullOrEmpty(this.key)) {
            id = this.idPrefix + '-' + this.key.toLowerCase();
        } else if (!String.isNullOrEmpty(this.idPrefix)) {
            id = this.idPrefix;
        } else if (!String.isNullOrEmpty(this.key)) {
            id = this.key.toLowerCase();
        }

        if (this.parentQuestion != null) {
            id = this.parentQuestion.componentId + '_' + id;
        }
        return id;
    }
}

import { Component, OnInit } from '@angular/core';

import { BootstrapResolutionService, BootstrapResolutionType } from '../../../gamma/bootstrap';
import { ModalService } from '../../../gamma/modal';
import { UserAgentService } from '../../../gamma/utils';

import { RouterInitService } from '../../router-init.service';

@Component({
    selector: 'u2000-workspace',
    templateUrl: 'U2000_workspace.component.html',
    styleUrls: ['U2000_workspace.component.scss'],
})
export class U2000_WorkspaceComponent implements OnInit {
    BootstrapResolutionType = BootstrapResolutionType;

    constructor(
        private routerInitService: RouterInitService,
        private modalService: ModalService,
        public userAgentService: UserAgentService,
        public bootstrapResolutionService: BootstrapResolutionService,
    ) {
        this.routerInitService.initialize();
    }

    ngOnInit() {
        this.modalService.activated = true;
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { IServiceProviderConfig } from '../core/serviceprovider/serviceprovider.interface';
import { IReferentialProvider, ReferentialService, ReferentialServiceConfig } from './referential.service';

/**
 * Referential module.
 *
 * @export
 * @class ReferentialModule
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ReferentialService],
})
export class ReferentialModule {
    /**
     * Configure the module.
     *
     * @static
     * @param {IServiceProviderConfig<IExceptionProvider>} [config={}] Configuration of the module.
     * @returns {ModuleWithProviders}
     *
     * @memberof LocalStorageModule
     */
    static withConfig(config: IServiceProviderConfig<IReferentialProvider> = {}): ModuleWithProviders<ReferentialModule> {
        return {
            ngModule: ReferentialModule,
            providers: [{ provide: ReferentialServiceConfig, useValue: config }],
        };
    }
}

import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { I18nService } from '../../../../i18n';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormRadioQuestion, IFormRadioButton } from '../../formquestion-radio';

@Component({
    selector: 'form-radio-bootstrap',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
})
export class FormRadioBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormRadioQuestion<any>;
    @Input() override form: UntypedFormGroup;

    constructor(i18nService: I18nService, private el: ElementRef) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);
    }

    onChange(event: Event) {
        if (this.question.onChange != null) {
            this.question.onChange(event, this.question);
        }
    }

    getRadioButtonId(radioButton: IFormRadioButton<any>) {
        return 'rb-' + this.componentId + '-' + radioButton.key;
    }

    getRadioButtonLabelId(radioButton: IFormRadioButton<any>) {
        return 'lbl-' + this.componentId + '-' + radioButton.key;
    }

    override get formGroupClass() {
        let formGroupClass = this.question.formGroupClass;
        if (this.hasError) {
            formGroupClass += ' has-error';
        }
        if (this.question.hideRequiredMarker === true) {
            formGroupClass += ' hide-required-marker';
        }
        if (this.question.condensed) {
            formGroupClass += ' condensed';
        }

        return formGroupClass;
    }
}
